<mat-card appearance="outlined" style="padding: 0px 5px; margin: 5px 2px" [ngClass]="{'no-response-background': ResponseItem.Response && !ResponseItem.Response.ResponseCode && ResponseItem.Response.ResponseRequired, 'event-background': ResponseItem.Event}"
          matRipple matRippleCentered matRippleColor="rgba(0,0,0,.1)" (click)="AddNewResponse()">
    <div style="display: flex">
        <div style="flex: 1 1 auto; display: flex; flex-direction: column; width: 100%; overflow: hidden; padding: 3px 0;">
            <ng-container *ngIf="ResponseItem.Response">
                <div style="display:flex; gap:10px">
                    <div style="flex-grow:1">
                        <div style="font-weight: bold">{{ResponseItem.Response.ServiceAreaName}}</div>
                        <div class="iq-caption gray-primary-text">{{ResponseItem.Response.ServiceAreaCode}}</div>
                    </div>
                    <div style="display:flex; flex-direction:column; align-items:flex-end">
                        <div *ngIf="StatusDescription" class="field-status" [ngStyle]="{'color': StatusColor, 'border-color': StatusColor }">{{StatusDescription}}</div>
                        <div class="field-right-side" *ngIf="ResponseItem.Response.UtilityTypeName">{{ ResponseItem.Response.UtilityTypeName }}</div>
                    </div>
                </div>
                <ng-container *ngIf="ResponseItem.Response.ResponseCode">
                    <div [ngStyle]="{'color': StatusColor }">{{ResponseItem.Response.ResponseCode +": "+ResponseItem.Response.ResponseDescription}}</div>
                    <div class="row">
                        <div class="iq-caption gray-primary-text">Date Entered:</div>
                        <div>{{ResponseItem.Response.EnteredDate | date: SettingsService.DateTimeFormat}}</div>
                    </div>
                    <div class="row">
                        <div class="iq-caption gray-primary-text">Entered via</div>
                        <div>
                            {{ResponseItem.Response.EntryMethod | ResponseEntryMethodEnum}}
                            <span *ngIf="ResponseItem.Response.EnteredByPersonName">: {{ResponseItem.Response.EnteredByPersonName}}</span>
                        </div>
                    </div>
                    <div *ngIf="ResponseItem.Response.Respondant" class="row">
                        <div class="iq-caption gray-primary-text">Respondent: </div>
                        <div>{{ResponseItem.Response.Respondant}}</div>
                    </div>
                    <div *ngIf="ResponseItem.Response.Comment" class="row">
                        <div class="iq-caption gray-primary-text">Comment: </div>
                        <div>{{ResponseItem.Response.Comment}}</div>
                    </div>
                    <div *ngIf="ResponseItem.Response.PublicURL" class="row">
                        <div class="iq-caption gray-primary-text">Public URL:</div>
                        <div style="display:flex; flex-grow:1; overflow:hidden">
                            <span style="overflow:hidden; text-overflow:ellipsis">{{ResponseItem.Response.PublicURL}}</span>
                            <!-- stopPropagation needed so that the click event on the div is not triggered by clicking this button -->
                            <a class="link" style="flex-shrink:0" href="{{ResponseItem.Response.PublicURL}}" target="_blank" (click)="$event.stopPropagation()">
                                <i class="fa fa-arrow-up-right-from-square" style="padding-left:5px"></i>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="ResponseItem.Response.PublicURL && ResponseItem.Response.PublicURLIsBlacklisted" class="row" style="color:red; font-size:smaller">
                        <div></div>
                        <div>* URL is Blacklisted</div>
                    </div>
                </ng-container>
                <div *ngIf="ResponseItem.Response.DiscussionStatus" [ngClass]="{'red':ResponseItem.Response.DiscussionStatus.IsPending, 'green':!ResponseItem.Response.DiscussionStatus.IsPending}">
                    * {{ResponseItem.Response.DiscussionStatus.Status}}
                </div>
            </ng-container>
            <ng-container *ngIf="ResponseItem.Event">
                <div style="display:flex; gap:10px">
                    <div style="flex-grow:1">{{ResponseItem.Event.EventDate | date: SettingsService.DateTimeFormat}}</div>
                    <div><span class="iq-caption gray-primary-text">Version: </span>{{ResponseItem.Event.TicketVersion}}</div>
                </div>
                <div>{{ResponseItem.Event.PersonName}}</div>
                <div>{{ResponseItem.Event.Message}}</div>
            </ng-container>
        </div>
    </div>
</mat-card>

<!--
<div class="list-item" style="width: 100%;">
    <div class="column status-column">{{ResponseItem.Status}}</div>
    <ng-container *ngIf="ResponseItem.Event; else response">
        <div class="column date-column">{{ResponseItem.Event.EventDate | date: SettingsService.DateTimeFormat}}</div>
        <div class="column ticket-version-column">Version: {{ResponseItem.Event.TicketVersion}}</div>
        <div class="column event-message-column">{{ResponseItem.Event.Message}}</div>
    </ng-container>
    <ng-template #response>
        <div class="column date-column">
            <ng-container *ngIf="ResponseItem.Response.ResponseID">
                {{ResponseItem.Response.EnteredDate | date: SettingsService.DateTimeFormat}}
            </ng-container>
        </div>
        <div class="column service-area-column">
            <div class="text-overflow-2">{{ResponseItem.Response.ServiceAreaName}}</div>
            <div class="iq-caption text-overflow-1 gray-primary-text">{{ResponseItem.Response.ServiceAreaCode}}</div>
        </div>
        <div *ngIf="HaveUtilityTypes" class="column utility-type-column">{{ResponseItem.Response.UtilityTypeName}}</div>
        <div class="column response-column">
            <div *ngIf="ResponseItem.Response.ResponseCode">
                {{ResponseItem.Response.ResponseCode +": "+ResponseItem.Response.ResponseDescription}}
            </div>
            <div *ngIf="ResponseItem.Response.SuppressUntilDate">
                <span class="gray-primary-text">Suppress Until: </span>{{ResponseItem.Response.SuppressUntilDate | date: SettingsService.DateTimeFormat}}
            </div>
        </div>
        <div class="column entry-method-column">
            {{ResponseItem.Response.EntryMethod | ResponseEntryMethodEnum}}
            <span *ngIf="ResponseItem.Response.EnteredByPersonName">: {{ResponseItem.Response.EnteredByPersonName}}</span>
        </div>
        <div class="column comments-column">
            <div *ngIf="ResponseItem.Response.Respondant">
                <span class="gray-primary-text">Respondent: </span>{{ResponseItem.Response.Respondant}}
                <mat-divider *ngIf="ResponseItem.Response.Comment"></mat-divider>
            </div>
            <div *ngIf="ResponseItem.Response.Comment">{{ResponseItem.Response.Comment}}</div>
        </div>
    </ng-template>
</div>
-->
