import { Pipe, PipeTransform } from '@angular/core';
import { conformToMask } from 'text-mask-core';
import { IQ_MASK_FORMATS } from '@iqSharedUtils/MaskFormats.model';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    
    transform(value: string): any {
        if (!value || isNaN(Number(value))) {
            return value;
        }

        //  These use the "AllowEmpty" masks in case we are displaying an "empty" phone number.  Needed for IN/KY...
        return conformToMask(value, value.length > 10 ? IQ_MASK_FORMATS.PhoneWithExtensionAllowEmpty : IQ_MASK_FORMATS.PhoneLineNumberAllowEmpty, { guide: false }).conformedValue;
    }

}
