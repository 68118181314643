import { OLGeometryTypeEnum } from 'Enums/GeometryType.enum';
import { MapTileSearchRequest } from 'Models/Searching/MapTileSearchRequest.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { GeometryUtils } from 'Shared/Components/Maps/GeometryUtils';
import { AuthenticatedVectorTileLayerBase } from 'Shared/Components/Maps/Layers/AuthenticatedVectorTileLayerBase';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { Feature, Map, VectorTile } from 'ol';
import { MVT } from 'ol/format';
import { Geometry } from 'ol/geom';
import VectorTileLayer from 'ol/layer/VectorTile';
import RenderFeature from 'ol/render/Feature';
import VectorTileSource from 'ol/source/VectorTile';
import { Fill, Stroke, Style, Text } from 'ol/style';

export class TicketsLayer extends AuthenticatedVectorTileLayerBase {

    constructor(
        map: Map,
        tileURL: string,
        authenticationService: AuthenticationService,
        public MapTileSearchRequest: MapTileSearchRequest,
        private _IDPropertyName: string
    ) {
        super(map, tileURL, authenticationService);
    }

    protected override GetRequestBody(): Document | XMLHttpRequestBodyInit | null {
        return JSON.stringify(this.MapTileSearchRequest);
    }

    protected override CreateLayer(tileURL: string): VectorTileLayer<Feature<Geometry>> {
        //  ** Note that the VectorTile source has a grid size of 512 pixels.  Where the image based XYZ source
        //  is 256.  This results in the vector tiles being 1 zoom level less than the zoom level of the image tiles.
        //  The resolutions we calculate are from the "map" - not the source - which seems to match up with the
        //  image source resolution.  So when relating vector tile zoom levels to resolutions, we need to add 1.

        const me = this;
        return new VectorTileLayer({
            maxResolution: this.Map.getView().getResolutionForZoom(6),
            minResolution: this.Map.getView().getResolutionForZoom(20 + 1),
            declutter: true,
            source: new VectorTileSource({
                //  By default, the MVT format will generate RenderFeatures *NOT* regular Features.  These are lightweght/read-only
                //  features that CANNOT be used as regular features in regular VectorSource layers.
                //  Can set "{ featureClass: ol.Feature }" in the constructor to make it generate regular features if needed.
                format: new MVT(/*{ featureClass: ol.Feature }*/),
                url: tileURL,
                tileLoadFunction: (tile, src) => this.AuthenticatedTileLoadFile(tile as VectorTile, src)
            }),
            style: (feature/*, resolution*/) => me.BuildStyleForFeature(feature)
        });
    }

    private _HoveringOverTicketID: string;
    get HoveringOverTicketID(): string { return this._HoveringOverTicketID; }
    set HoveringOverTicketID(val: string) {
        const changed = val !== this._HoveringOverTicketID;
        this._HoveringOverTicketID = val;
        if (changed)
            this.Layer.getSource().changed();
    }

    private _MapItemPagerVisibleTicketID: string;
    get MapItemPagerVisibleTicketID(): string { return this._MapItemPagerVisibleTicketID; }
    set MapItemPagerVisibleTicketID(val: string) {
        const changed = val !== this._MapItemPagerVisibleTicketID;
        this._MapItemPagerVisibleTicketID = val;
        if (changed)
            this.Layer.getSource().changed();
    }

    //  ID of the property with the name _IDPropertyName (value is either Ticket.ID or TicketResponse.ID)
    private _SelectedFeatureIDList: string[];
    get SelectedFeatureIDList(): string[] { return this._SelectedFeatureIDList; }
    set SelectedFeatureIDList(list: string[]) {
        const changed = (list?.length ?? 0) !== (this._SelectedFeatureIDList?.length ?? 0) || !list?.every((v, i) => v === this._SelectedFeatureIDList[i]);
        this._SelectedFeatureIDList = list;
        if (changed)
            this.Layer.getSource().changed();
    }

    public BuildStyleForFeature(feature: Feature<any> | RenderFeature): Style | Style[] {
        let fill = [0x0d, 0x72, 0xef, 0.2];         //  0d72ef
        let outline = [0x0d, 0x72, 0xef, 0.5];
        let zIndex = 1;

        //  These IDs are NOT necessarily Ticket.IDs.  For the service area user dashboard, these are TicketResponse.ID.
        const featureID = feature.getProperties()[this._IDPropertyName];
        const isSelected = (this.HoveringOverTicketID === featureID) || (this.MapItemPagerVisibleTicketID === featureID)
                            || (this.SelectedFeatureIDList?.includes(featureID) ?? false);
        if (isSelected) {
            fill = [255, 0, 0, 0.2];
            outline = [255, 0, 0, 0.6],
            zIndex = 100;
        }

        const geomType = GeometryUtils.GeometryTypeOfFeature(feature);
        if (geomType === OLGeometryTypeEnum.Point) {
            fill[3] = 1;        //  remove opacity
            return new Style({
                text: new Text({
                    text: '\uf3c5',     //  fa-map-marker-alt
                    font: '900 24px "' + MapConstants.FONT_AWESOME_FREE_NAME + '"',     //  If doesn't work, see comments on this constant - package version probably changed!
                    textBaseline: 'bottom',
                    fill: new Fill({
                        color: fill
                    })
                })
            });
        } else {
            return new Style({
                fill: new Fill({
                    color: fill
                }),
                stroke: new Stroke({
                    color: outline,
                    width: 2
                }),
                zIndex: zIndex
            });
        }
    }
}
