import { Component } from '@angular/core';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { FieldConfiguration } from 'Models/Configuration/Fields/FieldConfiguration.model';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { EnumService } from 'Services/Enum.service';
import { take } from 'rxjs';
import { ServiceAreaAssignmentFilterTypeEnum } from 'Enums/ServiceAreaAssignmentFilterType.enum';
import { SettingsService } from 'Services/SettingsService';
import { AuthenticationService } from 'Services/AuthenticationService';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Component({
    templateUrl: './ProfileVerificationsTicketAssignmentStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsTicketAssignmentStepInfo.component.scss']
})

export class ProfileVerificationsTicketAssignmentStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    public UsesMeetScheduling: boolean = false;
    public FieldConfigurationsAffectingSuppression: FieldConfiguration[] = [];
    public UsesNearEdgeOfRoadFilter: boolean = false;
    public ShowNearEdgeOfRoadFilter: boolean = false;
    public NearEdgeOfRoadValue: string = "";
    public UseAllTicketTypes: boolean = false;
    public UseAllLocateTypes: boolean = false;
    public ShowLocateTypesSection: boolean = false;
    public ShowCustomDigSiteBuffer: boolean = false;

    public override Name = StepNameConstants.TICKET_ASSIGNMENT;

    constructor(private _EnumService: EnumService, private _SettingsService: SettingsService, private _AuthenticationService: AuthenticationService) {
        super();
        this.ShowNearEdgeOfRoadFilter = this._SettingsService.UsesServiceAreaNearEdgeOfRoadFilter;
        this.ShowCustomDigSiteBuffer = (coerceNumberProperty(this._AuthenticationService.CurrentUser.OneCallCenterSettings.ServiceArea_MinCustomDigSiteBufferFt)) > 0;
    }

    public override SetData(data: ProfileVerification, currentStep: number, isExisting: boolean, formIsReadonly: boolean, setButtonFlagsCallback: () => void): void {
        super.SetData(data, currentStep, isExisting, formIsReadonly, setButtonFlagsCallback);

        setTimeout(() => {
            this.UsesMeetScheduling = this.OriginalProfile.MeetingPattern !== null && this.OriginalProfile.MeetingPattern !== undefined;
            this.UseAllTicketTypes = this.OriginalProfile.TicketTypes.length === 0;
            this.UseAllLocateTypes = this.OriginalProfile.LocateTypes.length === 0;
        });

        this._EnumService.LocateTypes.pipe(take(1)).subscribe(val => {
            //  Only FL has multiple Locate Types.  So hide the section if there is only 1.
            setTimeout(() => this.ShowLocateTypesSection = (val.length > 1));
        });

        if (this.ShowNearEdgeOfRoadFilter) {
            const nearEdgeOfRoadFilter = this.OriginalProfile.AssignmentFilters.find(f => f.FilterType === ServiceAreaAssignmentFilterTypeEnum.NearEdgeOfRoad);
            setTimeout(() => this.UsesNearEdgeOfRoadFilter = nearEdgeOfRoadFilter ? true : false);
            if (nearEdgeOfRoadFilter) {
                switch (nearEdgeOfRoadFilter.FilterValue) {
                    case "N":
                        setTimeout(() => this.NearEdgeOfRoadValue = "NO");
                        break;
                    case "Y":
                        setTimeout(() => this.NearEdgeOfRoadValue = "YES");
                        break;
                    case "U":
                        setTimeout(() => this.NearEdgeOfRoadValue = "UNKNOWN");
                        break;
                    default:
                        break;
                }
            }
        }

        this._EnumService.FieldConfigurations.pipe(take(1)).subscribe(fcList => {
            this.FieldConfigurationsAffectingSuppression = fcList.filter(fc => fc.ServiceAreaAssignmentFilterType === ServiceAreaAssignmentFilterTypeEnum.SuppressIfFieldValuePicked);

            this.FieldConfigurationsAffectingSuppression.forEach(config => {
                config.Values.forEach(val => {
                    val.Selected = this.OriginalProfile.AssignmentFilters.some(af => (af.FilterType === ServiceAreaAssignmentFilterTypeEnum.SuppressIfFieldValuePicked) && af.FieldValueID === val.ID);
                });
            });
        });
    }

    public HaveSelectedFieldValues(config: FieldConfiguration): boolean {
        return config.Values.some(fv => fv.Selected);
    }
}
