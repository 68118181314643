import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketMinimal } from 'Models/Tickets/TicketMinimal.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';
import { AnonymousPageBase } from "Shared/BaseClasses/AnonymousPageBase";
import { AnonymousTicketService } from '../../Services/AnonymousTicket.service';

@Component({
    templateUrl: './ViewTicketID.component.html',
    styleUrls: ['./ViewTicketID.component.scss']
})
export class ViewTicketIDComponent extends AnonymousPageBase implements OnInit {

    public Ticket: TicketMinimal;
    public NotFound: boolean = false;

    constructor(mainMenuService: MainMenuService, private _Route: ActivatedRoute, private _AnonymousTicketService: AnonymousTicketService,
        private _SettingsService: SettingsService, authenticationService: AuthenticationService)
    {
        super(mainMenuService, authenticationService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        //  This is in a timeout because there is some kind of situation where AuthenticationService.CurrentUser is being set to an empty
        //  object *AFTER* this api call returns and sets the CurrentOneCallCenterCode!  Which means CurrentOneCallCenterCode gets set to undefined.
        //  That causes the settings we need to check when printing a ticket (to not show the service area code for in/ky) to not have the
        //  CurrentOneCallCenterCode value set.  The timeout seemed to fix it so it must be something about how the AuthenticationService
        //  is initializing itself and didn't want to mess with that.
        setTimeout(() => {
            //console.warn("ViewTicketIDComponent: Route = ", this._Route.snapshot.params);
            this._AnonymousTicketService.FindTicketByID(this._Route.snapshot.params.occ, this._Route.snapshot.params.id)
                .subscribe({
                    next: ticket => {
                        //  Must set this for settings for the One Call to be applied!
                        this._SettingsService.CurrentOneCallCenterCode = this._Route.snapshot.params.occ;
                        this.Ticket = ticket;
                    },
                    error: () => this.NotFound = true
                });
        });
    }
}
