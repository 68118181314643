import { Component } from '@angular/core';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { Person } from 'Models/People/Person.model';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

export class VerificationContact extends Person {
    public ContactTypes?: SelectOption[];
}

@Component({
    templateUrl: './ProfileVerificationsContactsStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsContactsStepInfo.component.scss']
})
export class ProfileVerificationsContactsStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    public VerificationContacts: VerificationContact[] = [];
    public override Name = StepNameConstants.CONTACTS;

    constructor() {
        super();
    }

    public override SetData(data: ProfileVerification, currentStep: number, isExisting: boolean, formIsReadonly: boolean, setButtonFlagsCallback: () => void): void {
        super.SetData(data, currentStep, isExisting, formIsReadonly, setButtonFlagsCallback);

        if (this.OriginalProfile.FullContacts && this.OriginalProfile.FullContacts.length > 0) {
            for (let i = 0; i < this.OriginalProfile.FullContacts.length; i++) {
                const contact: VerificationContact = this.OriginalProfile.FullContacts[i];
                const matchingPerson = this.OriginalProfile.Contacts.find(p => p.PersonID === contact.ID);

                if (matchingPerson)
                    contact.ContactTypes = matchingPerson.ContactTypes;

                this.VerificationContacts.push(contact);
            }
        }
    }
}
