import { Component, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { ProfileVerificationSectionStatusEnum } from 'Enums/ProfileVerificationSectionStatusEnum.enum';
import { ProfileVerificationStatusEnum } from 'Enums/ProfileVerificationStatusEnum.enum';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { SettingsService } from 'Services/SettingsService';
import { ProfileVerificationsStepBaseComponent } from './Steps/ProfileVerificationsStepBase.component';
import { ServiceAreaProfileVerificationService } from 'Pages/ProfileVerifications/Services/ServiceAreaProfileVerification.service';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { ProfileVerificationsDialogData } from './Models/ProfileVerificationsDialogData.model';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import { ProfileVerificationsStatusFlags } from './Models/ProfileVerificationStatusFlags.model';
import { ProfileVerificationsButtonFlags } from './Models/ProfileVerificationButtonFlags.model';
import { ProfileVerificationStepInfo } from 'Pages/ProfileVerifications/Models/ProfileVerificationStepInfo.model';

@Component({
    templateUrl: './ProfileVerificationsBase.component.html',
    styleUrls: ['./ProfileVerificationsBase.component.scss']
})

export class ProfileVerificationsBaseComponent extends ComponentWithDestroySubscription {
    @ViewChild(DynamicContentDirective, { static: true })
    private _ContentContainer: DynamicContentDirective;

    public set ProfileVerification(val: ProfileVerification) {
        this.SetProfileVerification(val);
    }

    public ButtonFlags: ProfileVerificationsButtonFlags = new ProfileVerificationsButtonFlags();
    public CurrentStep: number = 1;
    public DynamicSteps: ProfileVerificationStepInfo[];
    public IsExisting: boolean = false;
    public CreatedByCurrentUser: boolean = false;
    public IsLocalUser: boolean = false;
    public CancelLabel: string = "Cancel";
    public DialogWidth: string;

    private _StatusFlags: ProfileVerificationsStatusFlags = new ProfileVerificationsStatusFlags();
    private _ProfileVerification: ProfileVerification;
    private _ContentViewComponentInstance: ProfileVerificationsStepBaseComponent;

    constructor(protected ProfileVerificationsService: ServiceAreaProfileVerificationService,
        protected SettingsService: SettingsService,
        protected _Dialog: MatDialog,
        protected _AuthenticationService: AuthenticationService,
        protected DeviceDetectorService: DeviceDetectorService) {
        super();
        this.DialogWidth = this.DeviceDetectorService.IsPhone ? '100%' : '50%';
    }

    public SetProfileVerification(verification: ProfileVerification, dialogData: ProfileVerificationsDialogData = null): void {
        this._ProfileVerification = verification;
        this.ProfileVerificationsService.EntityID = this._ProfileVerification.ServiceAreaID;

        if (dialogData) 
            this.IsExisting = dialogData.profileVerificationID !== null;
        //when coming from mobile the ProfileVerification should alwasy be considered new
        else
            this.IsExisting = false;

        this.CreatedByCurrentUser = this._AuthenticationService.CurrentUser.ID === this._ProfileVerification.CreatePersonID;
        this.IsLocalUser = this._AuthenticationService.CurrentUser.IsLocalUser;

        this._GetStatusFlags();
        this.CancelLabel = this._StatusFlags.IsPendingReview ? "Close" : "Cancel";

        if (this._StatusFlags.IsPendingReview && !this.IsExisting)
            this._PendingVerificationWarning();

        this._LoadDynamicContent(this._ProfileVerification);
        this.DynamicSteps = this.ProfileVerificationsService.GetDynamicStepsForOneCall(this.SettingsService.CurrentOneCallCenterCode);
    }

    public SaveProgress(): void {
        this._SetVerificationResult();
        this._Dialog.open(ConfirmationDialogComponent, {
            panelClass: 'iq-warn',
            data: new DialogModel("Warning!",
                "<div style='margin-top: -10px; margin-bottom: 10px;'>This will <strong>save</strong> what you have entered so far but will <strong>NOT</strong> submit to the One Call Center for review.</div> All steps must be completed to submit.",
                "Save",
                "",
                "Cancel"),
            width: this.DialogWidth
        }).afterClosed().subscribe((val) => {
            if (val) {
                this._SaveProfileVerification(true);
            }
        });
    }

    public Submit(): void {
        this._SetVerificationResult();
        this._ProfileVerification.Status = ProfileVerificationStatusEnum.PendingReview;

        this._Dialog.open(ConfirmationDialogComponent, {
            panelClass: 'iq-warn',
            data: new DialogModel("Warning!",
                "<div style='margin-top: -10px; margin-bottom: 10px;'>This will <strong>submit</strong> to the One Call Center for review.</div> Our member services team will review the information you provided and may contact you if there are questions about any requested changes.",
                "Submit",
                "",
                "Cancel"),
            width: this.DialogWidth
        }).afterClosed().subscribe((val) => {
            if (val) {
                this._SaveProfileVerification(true);
            }
        });
    }

    public Unsubmit(): void {
        this._SetVerificationResult();
        this._ProfileVerification.Status = ProfileVerificationStatusEnum.InProgress;
        this.ProfileVerificationsService.UpdateVerificationStatus(this._ProfileVerification.ID, this._ProfileVerification.Status).subscribe(Response => {
            if (Response)
                this._ProfileVerification = Response;

            this._GetStatusFlags();
            this._GetButtonFlags();

            this._ContentViewComponentInstance.VerificationForm.EnableFields();
        });

        this.CancelLabel = "Cancel";
    }

    public MarkReviewed(): void {
        this._ProfileVerification.Status = ProfileVerificationStatusEnum.Reviewed;
        this.ProfileVerificationsService.MarkAsReviewed(this._ProfileVerification.ID).subscribe(Response => {
            this._ProfileVerification = Response;
            this.Close();
        });
    }

    public PreviousClicked(): void {
        this.CurrentStep--;
        this._SetVerificationResult();
        this._ContentContainer.ClearComponent();
        this._LoadDynamicContent(this._ProfileVerification);
    }

    public NextClicked(): void {
        this.CurrentStep++;
        this._SetVerificationResult();
        this._ContentContainer.ClearComponent();
        this._LoadDynamicContent(this._ProfileVerification);
    }

    public Close(): void {
        this._UnlockProfileVerificationIfNeeded();
    }

    public OpenServiceAreaInNewWindow(): void {
        const height = window.screen.height;
        const width = window.screen.width / 2;

        const href = '/servicearea/details/' + this._ProfileVerification.ServiceAreaID;
        window.open(href, 'newwindow', 'width=' + width + ',height=' + height);
    }

    private _SaveProfileVerification(closeDialog: boolean = false): void {
        this.ProfileVerificationsService.InsertOrUpdate(this._ProfileVerification).subscribe(Response => {
            if (Response)
                this._ProfileVerification = Response;

            if (closeDialog)
                this.Close();
        });  
    }

    private _UnlockProfileVerificationIfNeeded(): ProfileVerification {
        if (this._ProfileVerification && this._ProfileVerification.LockedDate && this.IsExisting) {
            this.ProfileVerificationsService.UnlockProfileVerification(this._ProfileVerification.ID).subscribe(response => {
                if (response)
                    this._ProfileVerification = response;
            });
        }

        return this._ProfileVerification;
    }

    private _PendingVerificationWarning(): void {
        this._Dialog.open(InformationDialogComponent, {
            data: new DialogModel("Warning!", "This profile has already been submitted, please unsubmit to make changes")
        }).afterClosed().pipe(take(1)).subscribe(() => {
        });
    }

    private _FormIsValid(): boolean {
        return this._ContentViewComponentInstance?.VerificationForm?.FormGroup?.valid;
    }

    private _CanSubmit(): boolean {
        let seenSteps: number = 0;

        if (!this._ProfileVerification || !this._ProfileVerification.VerificationResults)
            return false;

        //cannot submit unless current form is valid
        if (!this._FormIsValid())
            return false;

        //if any step is unreviewed, return false
        for (const key in this._ProfileVerification.VerificationResults) {

            if (!this.DynamicSteps.find(step => step.Name.toUpperCase() === key.toUpperCase()))
                continue;

            const VerificationResult = this._ProfileVerification.VerificationResults[key];

            if (VerificationResult) {
                seenSteps++;
                if (VerificationResult.Status === ProfileVerificationSectionStatusEnum.Unreviewed) {
                    return false;
                }
            }
        }

        //if user has seen all steps and none are in unreviewed status, allow submit
        return this.DynamicSteps.length === seenSteps;
    }

    private _SetVerificationResult(): void {
        if (this._ProfileVerification && this._ProfileVerification.VerificationResults) {
            const verificationResult = this._ProfileVerification.VerificationResults[this._ContentViewComponentInstance.Name];
            if (verificationResult) {
                verificationResult.RequestedChanges = this._ContentViewComponentInstance.VerificationResult.RequestedChanges;
                verificationResult.Status = this._ContentViewComponentInstance.VerificationResult.Status;
            }
        }
    }

    private _LoadDynamicContent(data: ProfileVerification): void {
        this._ContentViewComponentInstance = null;
        setTimeout(() => {
            const viewComponentClassRef = this.DynamicSteps[this.CurrentStep - 1].component;
            this._ContentViewComponentInstance = this._ContentContainer.LoadComponent<ProfileVerificationsStepBaseComponent>(viewComponentClassRef);
            this._ContentViewComponentInstance.SetData(data, this.CurrentStep, this.IsExisting, this._StatusFlags.ReadOnly, () => this._GetButtonFlags());
        }, 0);
    }

    private _GetStatusFlags(): void {
        this._StatusFlags.IsPendingReview = this._ProfileVerification.Status === ProfileVerificationStatusEnum.PendingReview;
        this._StatusFlags.IsInProgress = this._ProfileVerification.Status === ProfileVerificationStatusEnum.InProgress;
        this._StatusFlags.ReadOnly = !this.CreatedByCurrentUser || this._ProfileVerification.Status !== ProfileVerificationStatusEnum.InProgress;
    }

    private _GetButtonFlags(): void {
        this.ButtonFlags.UnsubmitButtonVisible = this._StatusFlags.ReadOnly && this._StatusFlags.IsPendingReview && this.CreatedByCurrentUser;
        this.ButtonFlags.SubmitButtonVisible = !this._StatusFlags.ReadOnly && this._StatusFlags.IsInProgress;
        this.ButtonFlags.SaveProgressButtonVisible = !this._StatusFlags.ReadOnly && this._StatusFlags.IsInProgress;
        this.ButtonFlags.CanSubmitProfile = this._CanSubmit();
        this.ButtonFlags.CanClickPrevious = this.CurrentStep > 1;
        this.ButtonFlags.CanClickNext = this.CurrentStep < this.DynamicSteps.length && this._ContentViewComponentInstance?.VerificationResult?.Status !== ProfileVerificationSectionStatusEnum.Unreviewed;
        this.ButtonFlags.ReviewButtonVisible = this.IsExisting && this._StatusFlags.IsPendingReview && this.IsLocalUser;
        this.ButtonFlags.CanReviewProfile = this.DynamicSteps.length === this.CurrentStep;
    }
}
