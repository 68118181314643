import { EntityEnum } from '../../../Enums/EntityType.enum';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapLayerParams } from '@iqModels/Maps/MapLayerParams.model';
import { Registration } from '@iqModels/Registrations/Registration.model';
import { RegistrationActionRequest } from '@iqModels/Registrations/RegistrationActionRequest.model';
import { RegistrationChange } from '@iqModels/Registrations/RegistrationChange.model';
import { RegistrationChangeGeometry } from '@iqModels/Registrations/RegistrationChangeGeometry.model';
import { RegistrationChangeMapLayerParams } from '@iqModels/Registrations/RegistrationChangeMapLayerParams.model';
import { RegistrationDeleteChangeRequest } from '@iqModels/Registrations/RegistrationDeleteChangeRequest.model';
import { RegistrationGeometryRequest } from '@iqModels/Registrations/RegistrationGeometryRequest.model';
import { RegistrationInsertRequest } from '@iqModels/Registrations/RegistrationInsertRequest.model';
import { RegistrationMergeFromRegistrationRequest } from '@iqModels/Registrations/RegistrationMergeFromRegistrationRequest.model';
import { RegistrationPolygon } from '@iqModels/Registrations/RegistrationPolygon.model';
import { RegistrationQueueImportRequest } from '@iqModels/Registrations/RegistrationQueueImportRequest.model';
import { RegistrationRollbackChangeRequest } from '@iqModels/Registrations/RegistrationRollbackChangeRequest.model';
import { RegistrationSaveChangesRequest } from '@iqModels/Registrations/RegistrationSaveChangesRequest.model';
import { ScheduledTask } from '@iqModels/ScheduledTasks/ScheduledTask.model';
import { ConfirmationDialogComponent } from '@iqSharedComponentControls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from '@iqSharedComponentControls/Dialog/Information/InformationDialog.component';
import { DialogModel } from '@iqSharedComponentControls/Dialog/Models/Dialog.model';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { ScheduledTaskTypeEnum } from 'Enums/ScheduledTaskType.enum';
import { RegistrationChangeActionResponse } from 'Models/Registrations/RegistrationChangeActionResponse.model';
import { ScheduledTaskService } from 'Pages/ScheduledTasks/Services/ScheduledTask.service';
import { merge, Observable, of } from "rxjs";
import { every, map, take } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { DownloadService } from 'Services/Download.service';
import { SettingsService } from 'Services/SettingsService';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { ConfirmCompleteOrApproveRegistrationDialogComponent } from '../Views/ConfirmCompleteOrApproveRegistrationDialog/ConfirmCompleteOrApproveRegistrationDialog.component';
import { ServiceAreaActivateRegistrationDialogComponent, ServiceAreaActivateRegistrationDialogData } from '../../ServiceAreas/Views/Registrations/Dialog/ServiceAreaActivateRegistrationDialog.component';
import { ServiceArea } from '../../../Models/ServiceAreas/ServiceArea.model';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService extends CRUDBaseService<Registration> {
    protected apiPath: string = "Administration/Registration";

    ViewPermission: PermissionsEnum = PermissionsEnum.Registration_View;
    EditPermission: PermissionsEnum = PermissionsEnum.Registration_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.Registration_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.Registration_Delete;
    CopyPermission: PermissionsEnum = PermissionsEnum.NA;

    constructor(crudServices: CRUDServices, private _HttpClient: HttpClient, private _SettingsService: SettingsService,
        private _ScheduledTaskService: ScheduledTaskService, private _Dialog: MatDialog,
        private _AuthenticationService: AuthenticationService, private _DownloadService: DownloadService)
    {
        super(crudServices);
    }

    CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null): Observable<boolean> {
        switch (action) {
            case 'View':
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);
            case 'Edit':
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, null, true);
            case 'Create':
                return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, null, true);
            case 'Delete':
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, null, true);
            default:
                return of(false);
        }
    }

    public CreateNewRegistration(serviceAreaID: string, parentRegistrationID: string): Observable<Registration> {
        //  Can't use the Registration Model to create a new Registration - we need different properties!
        const request = new RegistrationInsertRequest(serviceAreaID);
        request.ParentRegistrationID = parentRegistrationID;

        return this.InsertOrUpdate(request);
    }

    public GetRegistrationMapLayerParams(registrationID: string): Observable<MapLayerParams> {
        return this._HttpClient.get<MapLayerParams>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/RegistrationMapLayerParams/" + registrationID);
    }

    public GetPolygonsInBounds(registrationID: string, minX: number, maxX: number, minY: number, maxY: number, previousFetchedGeometry: object): Observable<RegistrationPolygon[]> {
        const request = new RegistrationGeometryRequest(registrationID, null, minX, maxX, minY, maxY, previousFetchedGeometry);
        return this._HttpClient.post<RegistrationPolygon[]>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/PolygonsInBounds", request);
    }

    public GetChangeMapLayerParams(registrationChangeID: string): Observable<RegistrationChangeMapLayerParams> {
        return this._HttpClient.get<RegistrationChangeMapLayerParams>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/ChangeMapLayerParams/" + registrationChangeID);
    }

    public GetChangesInBounds(registrationChangeID: string, minX: number, maxX: number, minY: number, maxY: number, previousFetchedGeometry: object): Observable<RegistrationChangeGeometry[]> {
        const request = new RegistrationGeometryRequest(null, registrationChangeID, minX, maxX, minY, maxY, previousFetchedGeometry);
        return this._HttpClient.post<RegistrationChangeGeometry[]>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/ChangesInBounds", request);
    }

    //  Status description (other than "Active" which can't be determined without the ServiceArea)
    public StatusDescription(registration: Registration): string {
        const scheduledTaskDesc = this._ScheduledTaskService.ScheduledTaskDescription(registration.ScheduledTask);
        if (scheduledTaskDesc)
            return scheduledTaskDesc;

        if (registration.ApprovedDate)
            return "Approved for use";
        if (registration.CompletedDate)
            return "Completed; Awaiting approval";
        return "Not Complete";
    }

    public IsApproved(registration: Registration): boolean {
        return coerceBooleanProperty(registration.ApprovedDate);
    }

    public CanAddChanges(registration: Registration): boolean {
        return !registration.ScheduledTask && !registration.CompletedDate;
    }

    public CanComplete(registration: Registration): boolean {
        return !registration.ScheduledTask && !registration.CompletedDate;
    }

    public CanUnComplete(registration: Registration): boolean {
        return !registration.ScheduledTask && registration.CompletedDate && !registration.ApprovedDate;
    }

    public CanApprove(registration: Registration): boolean {
        return !registration.ScheduledTask && registration.CompletedDate && !registration.ApprovedDate;
    }

    public CanUnApprove(registration: Registration): boolean {
        return !registration.ScheduledTask && registration.ApprovedDate && !registration.IsActive;
    }

    public CanActivate(registration: Registration, serviceArea: ServiceArea): boolean {
        const scheduledRegistration = serviceArea?.Registrations?.find(reg => reg.ScheduledTask && reg.ScheduledTask.TaskType === ScheduledTaskTypeEnum.EntityPropertyUpdate);
        const isScheduledRegistration = scheduledRegistration && registration.ID === scheduledRegistration.ID;

        return (!scheduledRegistration || isScheduledRegistration) && (!registration.ScheduledTask || registration.ScheduledTask.TaskType === ScheduledTaskTypeEnum.EntityPropertyUpdate) && registration.ApprovedDate && !registration.IsActive;
    }

    public SaveChanges(registration: Registration, request: RegistrationSaveChangesRequest): Observable<RegistrationChange> {
        request.NextChangeNumber = this.NextChangeNumber(registration);

        return this._HttpClient.post<RegistrationChangeActionResponse>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/SaveChanges", request)
            .pipe(map(response => {
                //  Response contains an updated Registration object so that we can update the SqlMiles/Pct values based on the new change that was just saved
                if (response?.Registration)
                    this.CopyChangedRegistrationProperties(response.Registration, registration);

                return response?.Change;
            }));
    }

    public DeleteMostRecentChange(registration: Registration): Observable<boolean> {
        const request = new RegistrationDeleteChangeRequest(registration, registration.Changes[0].ID);
        return this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/DeleteChange", request)
            .pipe(map(newReg => {
                if (newReg)
                    this.CopyChangedRegistrationProperties(newReg, registration);
                return coerceBooleanProperty(newReg);
            }));
    }

    public RollbackToChange(registration: Registration, registrationChange: RegistrationChange): Observable<RegistrationChange> {
        const request = new RegistrationRollbackChangeRequest(registration, registrationChange.ID, registrationChange.Number);
        request.NextChangeNumber = this.NextChangeNumber(registration);

        return this._HttpClient.post<RegistrationChangeActionResponse>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/RollbackToChange", request)
            .pipe(map(response => {
                //  Response contains an updated Registration object so that we can update the SqlMiles/Pct values based on the new change that was just saved
                if (response?.Registration)
                    this.CopyChangedRegistrationProperties(response.Registration, registration);

                return response?.Change;
            }));
    }

    private NextChangeNumber(registration: Registration): number {
        if (!registration.Changes || (registration.Changes.length === 0))
            return 1;
        return registration.Changes[0].Number + 1;
    }

    public Complete(registration: Registration, callback: (r: Registration) => void): void {
        ConfirmCompleteOrApproveRegistrationDialogComponent.Show(this._Dialog, "Complete", registration)
            .subscribe((val) => {
                if (val) {
                    const request = new RegistrationActionRequest(registration);
                    this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/Complete", request)
                        .subscribe(newReg => {
                            this.CopyChangedRegistrationProperties(newReg, registration);
                            if (callback)
                                callback(newReg);
                        });
                }
            });
    }

    public UnComplete(registration: Registration, callback: (r: Registration) => void): void {
        this._Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel("Un-Complete Registration", "This will reject the changes made in this registration and allow the Member to make adjustments.",
                    "Un-Complete", "Are you sure you want to Un-Complete the registration?"),
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val) {
                    const request = new RegistrationActionRequest(registration);
                    this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/UnComplete", request)
                        .subscribe(newReg => {
                            this.CopyChangedRegistrationProperties(newReg, registration);
                            if (callback)
                                callback(newReg);
                        });
                }
            });
    }

    public Approve(registration: Registration, callback: (r: Registration) => void): void {
        ConfirmCompleteOrApproveRegistrationDialogComponent.Show(this._Dialog, "Approve", registration)
            .subscribe((val) => {
                if (val) {
                    const request = new RegistrationActionRequest(registration);
                    this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/Approve", request)
                        .subscribe(newReg => {
                            if (newReg) {
                                //  If we got back a registration that now has a ScheduledTask, it means it has been scheduled for processing as a scheduled task
                                //  because it is so big/complex.
                                if (newReg.ScheduledTask)
                                    this.ShowApproveScheduled();

                                this.CopyChangedRegistrationProperties(newReg, registration);
                                if (callback)
                                    callback(newReg);
                            }
                        });
                }
            });
    }

    private ShowApproveScheduled(): void {
        this._Dialog
            .open(InformationDialogComponent, {
                data: new DialogModel("Scheduled for Processing", "Because of the large number of changes made to this registration, Approval has been scheduled for processing.</br></br>You will receive an email within the next several minutes when processing has completed.")
            });
    }

    public UnApprove(registration: Registration, callback: (r: Registration) => void): void {
        this._Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel("Un-Approve Registration", "This will Un-Approve the registration for use by the Service Area.",
                    "Un-Approve", "Are you sure you want to Un-Approve the registration?"),
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val) {
                    const request = new RegistrationActionRequest(registration);
                    this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/UnApprove", request)
                        .subscribe(newReg => {
                            this.CopyChangedRegistrationProperties(newReg, registration);
                            if (callback)
                                callback(newReg);
                        });
                }
            });
    }

    public Activate(registration: Registration, callback: (r: Registration) => void): void {
        const dialogData = new ServiceAreaActivateRegistrationDialogData();
        dialogData.ActivateNow = !registration.ScheduledTask || registration.ScheduledTask.TaskType !== ScheduledTaskTypeEnum.EntityPropertyUpdate;
        dialogData.Registration = registration;

        this._Dialog
            .open(ServiceAreaActivateRegistrationDialogComponent, {
                data: dialogData,
                minWidth: '35%',
                width: '700px',
                maxWidth: '80%',
                disableClose: true
            }).afterClosed().subscribe((val) => {
                if (val.ActivateNow) {
                    const request = new RegistrationActionRequest(registration);
                    this._HttpClient.post<Registration>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/Activate", request)
                        .subscribe(newReg => {
                            this.CopyChangedRegistrationProperties(newReg, registration);
                            if (callback)
                                callback(newReg);
                        });
                }
                else if (val.ActivateDate || val.CancelTask) {
                    let obs: Observable<ScheduledTask>;

                    if (val.CancelTask) {
                        this._ScheduledTaskService.CancelAsync(registration.ScheduledTask).pipe(take(1))
                            .subscribe(success => {
                                if (success)
                                    registration.ScheduledTask = null;
                            });
                    }
                    else if (registration.ScheduledTask) {
                        obs = this._ScheduledTaskService.SavePropertyAsync(registration.ScheduledTask.ID, "NextRunDate", val.ActivateDate, null, true);
                    }
                    else {
                        obs = this._ScheduledTaskService.CreateEntityPropertyChangeTask(registration.ID, EntityEnum.Registration, val.ActivateDate, "IsActive", true, "Active");
                    }

                    if (obs) {
                        obs.subscribe(task => {
                            registration.ScheduledTask = task;
                        });
                    }
                }
            });
    }

    //  This copies the registration and creates a new one (if targetRegistrationID is null)
    //  or it merges the source registation into a new change in the targetRegistrationID.
    public QueueMergeRegistration(targetServiceAreaID: string, targetRegistrationID: string, sourceRegistrationID: string): Observable<ScheduledTask> {
        const request = new RegistrationMergeFromRegistrationRequest(targetServiceAreaID, targetRegistrationID, sourceRegistrationID);
        return this._HttpClient.post<ScheduledTask>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/QueueMergeRegistration", request);
    }

    public QueueShapeFileImport(serviceAreaID: string, fileUploadIDs: string[], nonPolygonBufferFt: number): Observable<ScheduledTask> {
        const request = new RegistrationQueueImportRequest(serviceAreaID, fileUploadIDs, nonPolygonBufferFt);
        return this._HttpClient.post<ScheduledTask>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/QueueImportFromShapeFile", request);
    }

    private CopyChangedRegistrationProperties(sourceRegistration: Registration, targetRegistration: Registration): void {
        targetRegistration.IsActive = sourceRegistration.IsActive;
        targetRegistration.CompletedDate = sourceRegistration.CompletedDate;
        targetRegistration.CompletedByPersonName = sourceRegistration.CompletedByPersonName;
        targetRegistration.ApprovedDate = sourceRegistration.ApprovedDate;
        targetRegistration.ApprovedByPersonName = sourceRegistration.ApprovedByPersonName;
        targetRegistration.ScheduledTask = sourceRegistration.ScheduledTask;
        targetRegistration.xmin = sourceRegistration.xmin;
        targetRegistration.RegistrationRecommendationSummary = sourceRegistration.RegistrationRecommendationSummary;
        targetRegistration.SqMilesTotal = sourceRegistration.SqMilesTotal;
        targetRegistration.SqMilesAdded = sourceRegistration.SqMilesAdded;
        targetRegistration.SqMilesRemoved = sourceRegistration.SqMilesRemoved;
        targetRegistration.PctAdded = sourceRegistration.PctAdded;
        targetRegistration.PctRemoved = sourceRegistration.PctRemoved;
        targetRegistration.PctOverall = sourceRegistration.PctOverall;

        if (sourceRegistration.Changes)
            targetRegistration.Changes = sourceRegistration.Changes;
    }

    public CanCancelScheduledTask(registration: Registration): Observable<boolean> | boolean {
        if (!this._ScheduledTaskService.CanCancel(registration.ScheduledTask))
            return false;

        //  If user has permission to do the action that's being done, they also have permission to cancel it.
        switch (registration.ScheduledTask.TaskType) {
            case ScheduledTaskTypeEnum.RegistrationApprove:
                return this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Registration_ApproveRegistrationChanges, [registration.ID]);
            case ScheduledTaskTypeEnum.RegistrationMerge:
                return this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Registration_Edit, [registration.ID]);

            default:
                return false;   //  unhandled type???
        }
    }

    public Refresh(registration: Registration, includeChanges: boolean, callback: (r: Registration) => void): void {
        this.Get(registration.ID)
            .subscribe(newReg => {
                this.CopyChangedRegistrationProperties(newReg, registration);
                if (callback)
                    callback(newReg);
            });
    }

    public CurrentUserCanUseFindAffectedTicketsTool(): Observable<boolean> {
        //  Allow only if the user is a Local User, has Approve Registration permission, and can create tickets.
        const isLocalUserObs = this._AuthenticationService.CurrentUserObserver().pipe(take(1), map(user => user.IsLocalUser));
        const canApproveObs = this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Registration_ApproveRegistrationChanges);
        const canCreateTicketsObs = this.services.permissionService.CurrentUserHasPermission(PermissionsEnum.Ticket_Create);

        return merge(isLocalUserObs, canApproveObs, canCreateTicketsObs).pipe(every(canDo => canDo));
    }

    public FindTickets(serviceAreaID: string, registrationID: string, startDate: string, endDate: string, assigned: boolean, affectedByRegistration: boolean): Observable<[]> {
        return this._HttpClient.post<[]>(this._SettingsService.ApiBaseUrl + "/Administration/Registration/FindTickets", {
            ServiceAreaID: serviceAreaID,
            RegistrationID: registrationID,
            StartDate: startDate,
            EndDate: endDate,
            Assigned: assigned,
            AffectedByRegistration: affectedByRegistration
        });
    }

    public ExportToShapefile(registrationID: string): void {
        const url = this._SettingsService.ApiBaseUrl + "/Administration/Registration/Export/" + registrationID;
        this._DownloadService.DownloadFileFromUrl(url);
    }
}
