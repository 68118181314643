<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 style="margin: 0px;">Business Hours: </h4>
    <ng-container *ngIf="!OriginalProfile.AlwaysOpen">
        <div *ngFor="let time of OriginalProfile.OpenTimes; last as isLast" style="display: flex; width: 100%; flex-wrap: wrap;">
            <div class="timeField">
                <span *ngIf="!isLast">{{time | openTime}}</span>
                <span *ngIf="isLast" style="margin-bottom: 20px;">{{time | openTime}} </span>
            </div>
        </div>
        <div *ngIf="!OriginalProfile.OpenTimes || OriginalProfile.OpenTimes.length === 0" style="margin-bottom: 20px;">
            <span>No times available</span>
        </div>
    </ng-container>
    <div *ngIf="OriginalProfile.AlwaysOpen" style="margin-bottom: 20px;">
        <span>Always Open</span>
    </div>
    <h4 style="margin: 0px;">Holidays: </h4>
    <div *ngFor="let Holiday of OriginalProfile.Holidays" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
        <div *ngIf="Holiday.Name" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Name: </label>
            <span>{{Holiday.Name}}</span>
        </div>
        <div *ngIf="Holiday.NextDate" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Next Occurrence: </label>
            <span>{{Holiday.NextDate | date: dateFormat }}</span>
        </div>
        <div *ngIf="Holiday.PreviousDate" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Previous Occurrence: </label>
            <span>{{Holiday.PreviousDate | date: dateFormat }}</span>
        </div>
        <div *ngIf="hasMultipleStates" class="holidayField holidayLabel" style="margin-left: 10px;">
            <label class="iq-caption gray-primary-text">Applied States: </label>
            <span>{{Holiday.States == null ? 'All' : Holiday.States | commaSeparatedFormat : null : null : true}}</span>
        </div>
    </div>
    <div *ngIf="!OriginalProfile.Holidays || OriginalProfile.Holidays.length === 0" style="margin-bottom: 20px;">
        <span>No Holidays available</span>
    </div>
    <h4 style="margin: 0px;">System Holidays: </h4>
    <div *ngFor="let Holiday of OriginalProfile.SystemHolidays" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
        <div *ngIf="Holiday.Name" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Name: </label>
            <span>{{Holiday.Name}}</span>
        </div>
        <div *ngIf="Holiday.NextDate" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Next Occurrence: </label>
            <span>{{Holiday.NextDate | date: dateFormat }}</span>
        </div>
        <div *ngIf="Holiday.PreviousDate" class="holidayField">
            <label class="iq-caption gray-primary-text holidayLabel">Previous Occurrence: </label>
            <span>{{Holiday.PreviousDate | date: dateFormat }}</span>
        </div>
        <div class="holidayField desktopMarginLeft10px">
            <label class="iq-caption gray-primary-text holidayLabel">Observed: </label>
            <span *ngIf="Holiday.Excluded">No</span>
            <span *ngIf="!Holiday.Excluded">Yes</span>
        </div>
        <div *ngIf="hasMultipleStates" class="holidayField" style="margin-left: 10px;">
            <label class="iq-caption gray-primary-text">Applied States: </label>
            <span>{{Holiday.States == null ? 'All' : Holiday.States | commaSeparatedFormat : null : null : true}}</span>
        </div>
    </div>
    <div *ngIf="!OriginalProfile.SystemHolidays || OriginalProfile.SystemHolidays.length === 0" style="margin-bottom: 20px;">
        <span>No Holidays available</span>
    </div>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>


