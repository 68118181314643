import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { TicketAffectedServiceAreaInfo } from '@iqModels/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketServiceArea } from '@iqModels/Tickets/TicketServiceArea.model';
import { InformationDialogComponent } from '@iqSharedComponentControls/Dialog/Information/InformationDialog.component';
import { DialogModel } from '@iqSharedComponentControls/Dialog/Models/Dialog.model';
import { DigSiteIntersectionItemTypeEnum } from 'Enums/DigSiteIntersectionItemType.enum';
import { DigsiteEnteredTypeEnum } from 'Enums/DigsiteEnteredType.enum';
import { GeocodeTypeEnum } from 'Enums/GeocodeType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { DigSiteIntersection } from 'Models/DigSites/DigSiteIntersection.model';
import { FeatureItemResponse } from 'Models/Maps/FeatureItemResponse.model';
import { RegistrationService } from 'Pages/Registrations/Services/Registration.service';
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { ManuallyAddServiceAreaData, ManuallyAddServiceAreaDialogComponent } from 'Pages/Tickets/Details/Components/ServiceAreaList/ManuallyAdd/Dialog/ManuallyAddServiceAreaDialog.component';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { CommonService } from 'Services/CommonService';
import { GeocodeService } from 'Services/GeocodeService';
import { MapSearchService } from 'Services/MapSearchService';
import { PermissionsService } from 'Services/PermissionsService';
import { DigSiteSizeControl } from 'Shared/Components/Maps/Controls/DigSiteSizeControl';
import { DigsiteEditor } from 'Shared/Components/Maps/DigsiteEditor';
import { GeometryTransformer } from 'Shared/Components/Maps/GeometryTransformer';
import { DigSiteUnbufferedVectorLayer } from 'Shared/Components/Maps/Layers/DigSiteUnbufferedVectorLayer';
import { DigSiteVectorLayer } from 'Shared/Components/Maps/Layers/DigSiteVectorLayer';
import { NearStreetVectorLayer } from 'Shared/Components/Maps/Layers/NearStreetVectorLayer';
import { RegistrationVectorLayer } from 'Shared/Components/Maps/Layers/RegistrationVectorLayer';
import { MapBaseComponent } from 'Shared/Components/Maps/MapBase.component';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { IDictionary } from 'Shared/Interfaces/IDictionary';
import * as _ from 'lodash';
import { Map } from "ol";
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { Pixel } from 'ol/pixel';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ManuallyAddServiceAreaFlyoutComponent } from '../ServiceAreaList/ManuallyAdd/Flyout/ManuallyAddServiceAreaFlyout.component';

@Component({
    selector: 'ticket-digsite-map',
    templateUrl: './DigsiteMap.component.html',
    styleUrls: ['./DigsiteMap.component.scss'],
    providers: [CommonService]
})
export class DigsiteMapComponent extends MapBaseComponent implements OnInit, OnDestroy {

    private _TicketEntryForm: TicketEntryFormGroup;
    @Input()
    get TicketEntryForm(): TicketEntryFormGroup {
        return this._TicketEntryForm;
    }
    set TicketEntryForm(value: TicketEntryFormGroup) {
        this._TicketEntryForm = value;
        this.SubscribeToTicketEntryFormChanges();
    }

    @Input()
    public ReadOnly: boolean = false;

    private _DigSiteLayer: DigSiteVectorLayer;
    private _UnbufferedDigSiteLayer: DigSiteVectorLayer;
    private _NearStreetLayer: NearStreetVectorLayer;

    private _CanViewServiceAreasDuringTicketCreation: boolean = false;
    private _CanViewRegistrationsForServiceAreaIDs: string[] = null;
    private _ManuallyAddedServiceAreas: TicketServiceArea[] = [];

    private _DigsiteEditor: DigsiteEditor;

    //  Used to manage subscriptions that are internal to this component and that can change without the
    //  entire component being re-created.
    private _Destroyed: Subject<void> = new Subject();

    constructor(commonService: CommonService, mapSearchService: MapSearchService, private _TicketService: TicketService, private _GeocodeService: GeocodeService,
        private _RegistrationService: RegistrationService, private _PermissionsService: PermissionsService) {
        super(commonService, mapSearchService);

        this.MapToolService.ConfigureForDigSiteDrawing();
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        super.ngOnDestroy();

        if (this._DigsiteEditor)
            this._DigsiteEditor = this._DigsiteEditor.OnDestroy();

        if (this._DigSiteLayer)
            this._DigSiteLayer = this._DigSiteLayer.OnDestroy();

        if (this._UnbufferedDigSiteLayer)
            this._UnbufferedDigSiteLayer = this._UnbufferedDigSiteLayer.OnDestroy();

        if (this._NearStreetLayer)
            this._NearStreetLayer = this._NearStreetLayer.OnDestroy();

        this._TicketEntryForm = null;
        this._TicketService = null;
        this._GeocodeService = null;
    }

    ngOnInit() {
        super.ngOnInit();
        if (!this.TicketEntryForm)
            return;     //  Already uninitialized!  Saw this happen to an iphone user...

        this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketCreation_ViewServiceAreas).subscribe(hasPerm => this._CanViewServiceAreasDuringTicketCreation = hasPerm);

        //  Only check for this permission when viewing a ticket.  Otherwise, if the service arfea user also has ticket entry permission,
        //  it would allow them to see their registrations while creating a ticket.  It would limit to only their own, but that can lead to
        //  them fudging the digsite to include/avoid themselves.
        if (!this.TicketEntryForm.IsEditing.value)
            this._PermissionsService.EntityIDsCurentUserHasPermissionFor(PermissionsEnum.Registration_View).subscribe(entityIDs => this._CanViewRegistrationsForServiceAreaIDs = entityIDs);

        this._TicketService.Ticket
            .pipe(takeUntil(this.MapBaseDestroyed))        //  to unsubscribe when we are destroyed
            .subscribe(() => {
                this._ManuallyAddedServiceAreas = [];
                this.ClearAllServiceAreaLayers();
            });
    }

    private SubscribeToTicketEntryFormChanges(): void {
        //  The DigsiteMap is in the side panel which does not get rebuilt when creating another ticket.
        //  So we need to unsubscribe from any previous TicketEntryForm and re-subscribe when it changes.
        //  Otherwise, the map won't update on changes.
        this._Destroyed.next();     //  Unsubscribes from any existing subscriptions (for old TicketEntryForm)

        const me = this;

        this.TicketEntryForm.get("DigSite.GeometryJson").valueChanges
            .pipe(takeUntil(this._Destroyed))        //  to unsubscribe when we are destroyed
            .subscribe(data => {
                if (me._DigSiteLayer) {
                    me._DigSiteLayer.LoadGeoJSON(data);                 //  Will clear if null/undefined

                    //  If the geocode failed, data will be null.  This method will find the best zoom extents (possibly zooming
                    //  to the place/county/state).
                    me.ZoomToBestFit();
                }
            });

        this.TicketEntryForm.get("DigSite.UnbufferedGeometryJson").valueChanges
            .pipe(takeUntil(this._Destroyed))        //  to unsubscribe when we are destroyed
            .subscribe(data => {
                if (me._UnbufferedDigSiteLayer)
                    me._UnbufferedDigSiteLayer.LoadGeoJSON(data);       //  Will clear if null/undefined
            });

        //  Don't need to watch for changes on the unbuffered near street geometry.  It always changes at the same
        //  time the buffered geometry changes and both go to the same layer.
        const nearStreetsFormArray = this.TicketEntryForm.get("NearStreets") as UntypedFormArray;
        nearStreetsFormArray.controls.forEach(nearStreetFormGroup => {
            nearStreetFormGroup.get("GeometryJson").valueChanges
                .pipe(takeUntil(this._Destroyed), debounceTime(100))        //  debounced because all can change if there are multiple so only load layer once on any change
                .subscribe(() => me.LoadNearStreetLayer());
        });

        if (this.MapIsInitialized) {
            //  Map is already initialized so set and position the map layers.
            //  If returns false, the digsite is not loaded so we need to ZoomToBestFit().
            if (!this.LoadAndZoomToAllTicketDigSiteLayers())
                this.ZoomToBestFit();
        }
    }

    protected OnMapInitialized(map: Map): boolean {
        //  Create the layers needed to show all of the different ticket digsite/near streets.

        this._NearStreetLayer = new NearStreetVectorLayer(this.MapToolService);
        map.addLayer(this._NearStreetLayer.Layer);

        this._DigSiteLayer = new DigSiteVectorLayer(MapConstants.LAYERNAME_DIGSITE, this.MapToolService);
        map.addLayer(this._DigSiteLayer.Layer);

        //  Need this added after the DigSite layer so that it will take precence when showing the DigSite Size
        this._UnbufferedDigSiteLayer = new DigSiteUnbufferedVectorLayer(MapConstants.LAYERNAME_UNBUFFERED_DIGSITE, this.MapToolService);
        map.addLayer(this._UnbufferedDigSiteLayer.Layer);

        //  Load all of the layers and zoom to the digsite.  This returns false if not loaded
        //  which tells base to position to default extents.  When true is returned, the base
        //  does not do any positioning (because we just did it here).
        return this.LoadAndZoomToAllTicketDigSiteLayers();
    }

    /**
     * Loads all of the ticket digsite layers and zooms to the DigSite.Geometry.
     * Returns true if loaded and zoomed, false if DigSite.Geometry not set.
     * */
    private LoadAndZoomToAllTicketDigSiteLayers(): boolean {
        this.LoadNearStreetLayer();

        //  The layers are cleared if LoadGeoJSON is given a null/undefined geoJson object.  Which is what we want
        //  in case the digsite has been cleared.
        const digSiteGeometryJson = this.TicketEntryForm.get("DigSite.GeometryJson").value;
        this._DigSiteLayer.LoadGeoJSON(digSiteGeometryJson);

        //  Need to always set unbuffered last because that should take precendence when displaying the DigSite Size
        const unbufferedDigSiteGeometryJson = this.TicketEntryForm.get("DigSite.UnbufferedGeometryJson").value;
        this._UnbufferedDigSiteLayer.LoadGeoJSON(unbufferedDigSiteGeometryJson);

        if (!digSiteGeometryJson)
            return false;

        //  Have geocode json loaded.  Set it and zoom to it.
        this.ZoomToBestFit();
        return true;
    }

    private LoadNearStreetLayer(): void {
        if (!this._NearStreetLayer)
            return;

        const list: object[] = [];
        const nearStreetsFormArray = this.TicketEntryForm.get("NearStreets") as UntypedFormArray;

        nearStreetsFormArray.controls.forEach(nearStreetFormGroup => {
            let geometryJson = nearStreetFormGroup.get("GeometryJson").value;
            if (geometryJson)
                list.push(geometryJson);

            //  Line segments can be in same layer - they will just style differently
            geometryJson = nearStreetFormGroup.get("UnbufferedGeometryJson").value;
            if (geometryJson)
                list.push(geometryJson);
        });

        this._NearStreetLayer.LoadGeoJSONCollection(list);
    }

    protected BuildControlBars(): void {
        super.BuildControlBars();

        //  Font-awesome icons: https://fontawesome.com/icons?d=gallery
        //  Material icons: https://material.io/tools/icons/?style=baseline
        //      Material style info: http://google.github.io/material-design-icons/#icon-font-for-the-web
        //      Reference like this: <i class="material-icons">timeline</i>
        //          * Our project has a font-size of 14px which may be too small - can add new style tags to resize the icons.

        this.Map.addControl(new DigSiteSizeControl());

        if (!this.TicketEntryForm.CanModifyDigSite() || this.ReadOnly || !_.includes(this._TicketService.TicketConfiguration.AllowedGeocodeTypes, GeocodeTypeEnum.Manual))
            return;

        this._DigsiteEditor = new DigsiteEditor(this.Map, this.MapToolService);

        this._DigsiteEditor.OnSaveManualDigsiteJson = (geoJson, bufferFt, unbufferedGeoJson) => this.OnSaveManualDigsite(geoJson, bufferFt, unbufferedGeoJson);

        this.LeftControlBar.setVisible(true);
        this._DigsiteEditor.AddControlBar(this.LeftControlBar, this.AllowedMapTools);
    }

    public get CurrentStateAbbreviation(): string {
        const digsite = (this.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        if (digsite) {
            const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];
            if (inter1)
                return inter1.State;
        }

        return null;
    }

    public get CurrentCountyName(): string {
        const digsite = (this.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        if (digsite) {
            const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];
            if (inter1)
                return inter1.CountyName;
        }

        return null;
    }

    public get MapSearchFilterBounds(): Extent {
        return this.GetBestFitExtents();
    }

    public HaveUnsavedMapChanges(): boolean {
        return this._DigsiteEditor && this._DigsiteEditor.HaveUnsavedFeaturesInEditor();
    }

    public SaveMapChanges(): Observable<boolean> {
        if (!this._DigsiteEditor)
            return of(true);        //  Digsite editor not created until map is created.  If map not created, then they could not possibly have unsaved changes!
        return this._DigsiteEditor.Save();
    }

    public DiscardMapChanges(): boolean {
        if (!this._DigsiteEditor)
            return true;        //  Digsite editor not created until map is created.  If map not created, then they could not possibly have unsaved changes!
        this._DigsiteEditor.Clear();
        return true;
    }

    private OnSaveManualDigsite(geoJson: object, bufferFt: number, unbufferedGeoJson: object): Observable<boolean> {
        if (!geoJson)
            return of(false);       //  Should not happen!

        //  Only compare against intersection1.  This matches what also happens in GeocodeService.PlaceOrCountyChangeRequiresFindBestGeocode()
        //  (which handles changes to the county/place - to invalidate the manual digsite if they change).
        const digsite = (this.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];
        const usesCounty = this.CommonService.SettingsService.UsesCountyInLocations;

        if (_.isEmpty(inter1.State) || (usesCounty && _.isEmpty(inter1.CountyName)) || _.isEmpty(inter1.PlaceName)) {
            this.CommonService.Dialog.open(InformationDialogComponent, {
                data: new DialogModel("Site Location is Empty", "Please enter the Site Location before manually drawing a dig site."),
                width: "50em",
                maxWidth: "90%",        // width + maxWidth needed for phone
            });
            return of(false);
        }

        const ticketType = this._TicketService.TicketEntryOptionsService.TicketType;
        if (!ticketType?.ID) {
            this.CommonService.Dialog.open(InformationDialogComponent, {
                data: new DialogModel("Select Ticket Type", "Please select a Ticket Type before manually drawing a dig site."),
                width: "50em",
                maxWidth: "90%",        // width + maxWidth needed for phone
            });
            return of(false);
        }

        //  Process the manual digsite.  This will clip it against the county/place if necessary and also
        //  compute the NearRailroad if necessary.  If nothing needed to be done at all,
        return this._GeocodeService.ProcessManualGeocode(ticketType.ID, inter1.State, inter1.CountyName, inter1.PlaceName, geoJson, bufferFt, unbufferedGeoJson)
            .pipe(map(response => {
                //  If nothing needed to be done at all, this will return an http 204/no content response and response will be null

                if (response?.ClippedByCounty || response?.ClippedByPlace) {
                    //  We clipped the geometry to either the place or county
                    const clippedBy = response?.ClippedByCounty ? "county" : this.CommonService.SettingsService.PlaceNameLabel.toLowerCase();

                    if (!response.GeometryJson) {
                        //  Entire geometry was clipped away!
                        this.CommonService.Dialog.open(InformationDialogComponent, {
                            panelClass: 'iq-warn',
                            data: new DialogModel("Digsite outside " + clippedBy, "This dig site cannot be saved because it falls outside the boundaries of the current " + clippedBy + "."),
                            width: "50em",
                            maxWidth: "90%",        // width + maxWidth needed for phone
                        });
                        return false;
                    }

                    //  Part of geometry was clipped - we still save it but warn the user.
                    this.CommonService.Dialog.open(InformationDialogComponent, {
                        data: new DialogModel("Digsite outside " + clippedBy, "A portion of the dig site was removed because it extended outside the boundaries of the current " + clippedBy + ".  If you are excavating in the bordering " + clippedBy + " enter another ticket for that location."),
                        width: "50em",
                        maxWidth: "90%",        // width + maxWidth needed for phone
                    });

                    //  Set these to the values returned by the api.  When clipped, it always gives us the results which can result in 1 or both
                    //  of them being completely clipped away.  Including the entire unbuffered geometry being clipped away yet the buffered geometry
                    //  only partially clipped (happens if you draw near a county/place border such that the unbuffered is outside the county/place while
                    //  the buffered portion falls inside the county/place).
                    geoJson = response.GeometryJson;
                    unbufferedGeoJson = response.UnbufferedGeometryJson;
                }

                //  Success - save the manual digsite.  If geometry was given in the response, then it was clipped and we replaced these values with
                //  the clip results.  Otherwise, nothing was clipped so we use the original values.
                this.TicketEntryForm.SetManualDigsite(geoJson, bufferFt, unbufferedGeoJson);

                const nearRailroad = response?.NearRailroad;
                if ((nearRailroad !== null) && (nearRailroad !== undefined))
                    this.TicketEntryForm.get("DigSite.NearRailroad").setValue(nearRailroad);
                return true;
            }));
    }

    protected IsDrawingToolActive(): boolean {
        //  If we are currently drawing, disable the context menu so that it doesn't actually open
        if (super.IsDrawingToolActive())
            return true;
        return (this._DigsiteEditor && this._DigsiteEditor.IsActive());
    }

    //  ol-ext info:
    //      github: https://github.com/Viglino/ol-ext
    //      Examples page: http://viglino.github.io/ol-ext/
    //

    //  This is called when the context menu is displayed if IsContextMenuDirty is set to true.
    //  So can rebuild the menu by setting that property.
    protected override BuildContextMenuItems(event: { type: string, pixel: Pixel, coordinate: Coordinate }): any[] {
        const me = this;

        //  Styling:
        //      How to use font-awesome: https://github.com/jonataswalker/ol-contextmenu/issues/129
        //      Items can contain properties like these:
        //          classname: 'some-style-class', // add some CSS rules
        //          icon: 'img/marker.png',  // this can be relative or absolute

        const contextMenuItems = super.BuildContextMenuItems(event);
        contextMenuItems.push({
            text: '<i class="fas fa-crosshairs"></i>Zoom to Dig Site',
            classname: 'iq-image-item',
            callback: function () {
                me.ZoomToBestFit();
            }
        });
        if (this._NearStreetLayer && this._NearStreetLayer.GetBestFitExtents()) {
            contextMenuItems.push({
                text: '<i class="fas fa-crosshairs"></i>Zoom to Near Street',
                classname: 'iq-image-item',
                callback: function () {
                    const extents = me._NearStreetLayer.GetBestFitExtents();
                    if (extents)
                        me.Map.getView().fit(extents, { padding: [80, 80, 80, 80] });
                }
            });
        }

        if (this.CanShowPlace()) {
            contextMenuItems.push({
                text: '<i class="fas fa-crosshairs"></i>Show ' + this.CommonService.SettingsService.PlaceNameLabel,
                classname: 'iq-image-item',
                callback: function () {
                    me.ShowPlace();
                }
            });
        }

        //{
        //    text: 'Build Dig Site at Point',
        //    callback: function (obj) {
        //        console.warn("Callback for Build Dig Site at Point", obj);
        //    }
        //},

        if (this.CommonService.DeviceDetectorService.IsDesktop) {
            //  Can only add this sub menu if desktop.  Otherwise, the context menu does not have any way to detect a hover so it will never open.
            //  And could not figure out how to hook in a touch event to detect it.  It's only available for local users anyway and there should never
            //  be a reason for them to be using a phone...
            if (this._CanViewServiceAreasDuringTicketCreation || (this._CanViewRegistrationsForServiceAreaIDs && (this._CanViewRegistrationsForServiceAreaIDs.length > 0)))
                contextMenuItems.push(this.BuildServiceAreasContextMenuItem(event));
        }

        if (this.TicketEntryForm.CanModifyDigSite() && !this.ReadOnly) {
            if (_.includes(this._TicketService.TicketConfiguration.AllowedGeocodeTypes, GeocodeTypeEnum.LatLonCoordinate)) {
                contextMenuItems.push('-');     //  Separator
                contextMenuItems.push({
                    text: '<i class="fas fa-crosshairs"></i>Create Lat/Lon Dig Site',
                    classname: 'iq-image-item',
                    callback: evt => me.SetLatLonDigSite(evt.coordinate)
                });
            }
        }

        return contextMenuItems;
    }

    /**
     *  Returns the DigSiteIntersection if we can show the place.  Otherwise, returns null.
     * */
    private CanShowPlace(): DigSiteIntersection {
        const digsite = (this.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];

        if (_.isEmpty(inter1.State) || _.isEmpty(inter1.PlaceName))
            return null;
        if (this.CommonService.SettingsService.UsesCountyInLocations && _.isEmpty(inter1.CountyName))
            return null;

        return inter1;
    }

    private ShowPlace(): void {
        const inter1 = this.CanShowPlace();
        if (!inter1)
            return;

        this.MapSearchButton.ShowPlace(inter1.State, inter1.CountyName, inter1.PlaceName);
    }

    private SetLatLonDigSite(coord: Coordinate): void {
        const latLonCoord = GeometryTransformer.TransformCoordinate(coord, null, MapConstants.LATLON_PROJECTION);

        const maxDigits = this.CommonService.SettingsService.LatLonCoordinateMaxDecimalDigits;

        this.TicketEntryForm.get("DigSite.DigsiteEnteredType").setValue(DigsiteEnteredTypeEnum.LatLon);
        this.TicketEntryForm.get("DigSite.Latitude").setValue(latLonCoord[1].toFixed(maxDigits));
        this.TicketEntryForm.get("DigSite.Longitude").setValue(latLonCoord[0].toFixed(maxDigits));
    }

    private BuildServiceAreasContextMenuItem(event: { type: string, pixel: Pixel, coordinate: Coordinate }): any {
        const me = this;

        let serviceAreas: TicketServiceArea[];

        if (this._TicketService.ServiceAreasAreDirty.value) {
            //  Service area list is dirty.  Kick of a lookup which will then re-build the context menu when it's finished.
            this.TicketEntryForm.FindAffectedServiceAreas().subscribe(() => this.RebuildContextMenu(event));

            //  Until that's done, use an empty list since whatever we have is not correct
            serviceAreas = [];
        }
        else {
            //  Check to see if anything in this._ManuallyAddedServiceAreas exists in the main list.  This can happen
            //  if we search for a service area on the map, go to affected service areas, add it to the ticket, then
            //  come back to the map.  We could use a comparison on this union, but we want to make sure we're
            //  giving preference to the item from the main list so that we can indicate that it's on the ticket but was
            //  manually added.
            //  User could have also changed the dig site so that the service area is now affected.
            this._ManuallyAddedServiceAreas = _.filter(this._ManuallyAddedServiceAreas, (m) => {
                return !this._TicketService.Ticket.value.ServiceAreas.find(sa => sa.ServiceAreaID === m.ServiceAreaID);
            });
            serviceAreas = _.union(this._TicketService.Ticket.value.ServiceAreas, this._ManuallyAddedServiceAreas);
        }

        const serviceAreaItems: (string | { text: string; classname: string; callback: (obj: any) => void; })[] = [
            {
                text: '<i class="fas fa-search-plus"></i>Search',
                classname: 'iq-image-item',
                callback: function () {
                    me.ServiceAreaSearch();
                },
            }
        ];

        if (!_.isEmpty(this._ServiceAreaCodeLayers)) {
            serviceAreaItems.push({
                text: '<i class="far fa-trash-alt"></i>Clear all from Map',
                classname: 'iq-image-item',
                callback: function () {
                    me.ClearAllServiceAreaLayers();
                }
            });
        }

        if (!_.isEmpty(this._ManuallyAddedServiceAreas)) {
            serviceAreaItems.push({
                text: '<i class="fas fa-search-minus"></i>Remove Searched',
                classname: 'iq-image-item',
                callback: function () {
                    me.RemoveAllSearchedServiceAreas();
                }
            });
        }

        serviceAreaItems.push('-');     //  Separator

        serviceAreas
            .sort((a, b) => a.ServiceAreaInfo.Code.localeCompare(b.ServiceAreaInfo.Code))
            .filter(sa => this._CanViewServiceAreasDuringTicketCreation || (this._CanViewRegistrationsForServiceAreaIDs && this._CanViewRegistrationsForServiceAreaIDs.includes(sa.ServiceAreaID)))
            .forEach(sa => serviceAreaItems.push(this.CreateContextMenuItemForServiceArea(sa)));

        return {
            text: "<span class='iq-no-image'><span>Show Service Areas</span></span>",
            //classname: 'iq-no-image',       //  This is not being respected atm due to a bug (so have span stuff above): https://github.com/jonataswalker/ol-contextmenu/issues/148
            items: serviceAreaItems
        };
    }

    private CreateContextMenuItemForServiceArea(sa: TicketServiceArea): (string | { text: string; classname: string; callback: (obj: any) => void; }) {
        let text: string = '';
        let className: string = 'iq-no-image';

        const registrationLayer = this._ServiceAreaCodeLayers[sa.ServiceAreaInfo.Code];
        if (registrationLayer) {
            text = '<i class="fas fa-square" style="opacity:' + registrationLayer.Opacity + '; color: ' + registrationLayer.FillColor + '"></i>';
            className = 'iq-image-item';
        }
        if (sa.ManuallyAdded) {
            if (this._ManuallyAddedServiceAreas.find(m => m.ServiceAreaID === sa.ServiceAreaID))
                text += '<i class="fas fa-search-plus added-from-search"></i>';
            else
                text += '<span class="manually-added" matTooltip="Manually Added">*</span>';
        }
        text += sa.ServiceAreaInfo.Code;

        const me = this;
        return {
            text: text,
            classname: className,
            callback: function () {
                me.ToggleServiceArea(sa.ServiceAreaInfo);
            }
        };
    }

    public ZoomToBestFit(): void {
        //  If the geocode failed (which includes if not enough information has been entered and the user does not
        //  have permission to do a county or place lookup), extents will be null so we will attempt to zoom to the state/county/place.
        const extents = this.GetBestFitExtents();
        if (extents)
            this.ZoomToExtent(extents);
        else {
            //  No dig site.  Try to find the next best extents to zoom to.
            this.ZoomToCountyPlace();
        }
    }

    /**
     * Zoom to the best state/county/place based on what has been entered in to the dig site so far.
     * This is used if we fail to geocode (i.e. the user does not have permission to do county or place geocodes and has
     * not entered a valid street).
     * */
    private ZoomToCountyPlace(): void {
        if (!this.TicketEntryForm)
            return;     //  Not done initializing yet so ignore this - will be called again.

        //  Get the dig site and see if we have enough informtion to fetch the bounds.  If not, we will zoom to the full map extents.
        const digsite = (this.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        if (digsite) {
            const inter1 = digsite.Intersections[DigSiteIntersectionItemTypeEnum[DigSiteIntersectionItemTypeEnum.Inter1]];

            //  Must always have a State to do this - even for DigSafe (default will always zoom to full extents)
            if (inter1 && inter1.State) {
                //  Not sure how, but there were some errors where this._TicketService.TicketConfiguration is null/undefined.
                //  And the pages referenced in the error were the TicketDashboard & TicketSearch - which don't even show the DigSiteMap!
                const canGeocodeCounty = this._TicketService.TicketConfiguration && this._TicketService.TicketConfiguration.AllowedGeocodeTypes.some(gt => gt === GeocodeTypeEnum.County);
                const canGeocodePlace = this._TicketService.TicketConfiguration && this._TicketService.TicketConfiguration.AllowedGeocodeTypes.some(gt => gt === GeocodeTypeEnum.Place);

                const haveState = this.CommonService.SettingsService.HasMultipleStates;        //  So a digsafe map can position to the state level when picked (and nothing else entered yet)
                const haveCounty = inter1.CountyName && this.CommonService.SettingsService.UsesCountyInLocations && !canGeocodeCounty;
                const havePlace = inter1.PlaceName && !canGeocodePlace;
                if (haveState || haveCounty || havePlace) {
                    //  Have enough to send to make api call.  If that fails, we will still ZoomToFullMapExtents().
                    this._GeocodeService.FindBestZoomExtents(inter1.State, inter1.CountyName, inter1.PlaceName)
                        .subscribe(bounds => this.ZoomToLatLonBounds(bounds, 5));
                    return;
                }
            }
        }

        this.ZoomToFullMapExtents();
    }

    protected GetBestFitExtents(): Extent {
        if (!this._DigSiteLayer)
            return null;

        return this._DigSiteLayer.GetBestFitExtents();
    }

    private _RegistrationLayer: RegistrationVectorLayer;

    public ShowRegistrationLayer(registrationID: string): void {
        //  Not using the _ServiceAreaCodeLayers for this because then this laye (which is used to force displaying
        //  a specific Registration) gets all mixed up with the service area registration layers that the user can add
        //  by searching/adding/clearing via the context menu.

        this.RemoveRegistrationLayer();

        this._RegistrationLayer = new RegistrationVectorLayer(this.Map, this._RegistrationService, null, "Registration");
        this.Map.addLayer(this._RegistrationLayer.Layer);

        this._RegistrationLayer.SetRegistrationID(registrationID);
    }

    public RemoveRegistrationLayer(): void {
        if (this._RegistrationLayer) {
            //  Already added to map!  This really should not be happening...
            this.Map.removeLayer(this._RegistrationLayer.Layer);
        }
    }

    private _ServiceAreaCodeLayers: IDictionary<RegistrationVectorLayer> = { }

    private ToggleServiceArea(serviceAreaInfo: TicketAffectedServiceAreaInfo): void {
        const registrationLayer = this._ServiceAreaCodeLayers[serviceAreaInfo.Code];
        if (registrationLayer) {
            this.Map.removeLayer(registrationLayer.Layer);
            delete this._ServiceAreaCodeLayers[serviceAreaInfo.Code];
        }
        else {
            const registrationLayer = new RegistrationVectorLayer(this.Map, this._RegistrationService, null, serviceAreaInfo.Code);
            this.Map.addLayer(registrationLayer.Layer);
            this._ServiceAreaCodeLayers[serviceAreaInfo.Code] = registrationLayer;

            registrationLayer.SetRegistrationID(serviceAreaInfo.CurrentRegistrationID);
        }
    }

    private ClearAllServiceAreaLayers(): void {
        for (const saCode in this._ServiceAreaCodeLayers)
            this.RemoveLayerForServiceAreaCode(saCode);
    }

    private RemoveLayerForServiceAreaCode(saCode: string): void {
        const registrationLayer = this._ServiceAreaCodeLayers[saCode];
        if (registrationLayer) {

            this.Map.removeLayer(registrationLayer.Layer);
            delete this._ServiceAreaCodeLayers[saCode];
        }
    }

    private RemoveAllSearchedServiceAreas(): void {

        this._ManuallyAddedServiceAreas.forEach(sa => {
            this.RemoveLayerForServiceAreaCode(sa.ServiceAreaInfo.Code);
        });

        this._ManuallyAddedServiceAreas = [];
    }

    private ServiceAreaSearch() {
        const componentData = new ManuallyAddServiceAreaData();
        componentData.ExistingServiceAreaIDs = _.map(this._TicketService.Ticket.value.ServiceAreas, function (sa) { return sa.ServiceAreaID; });
        componentData.LimitToServiceAreaIDs = this._CanViewServiceAreasDuringTicketCreation ? null : this._CanViewRegistrationsForServiceAreaIDs;
        componentData.TicketEntryForm = this.TicketEntryForm;
        componentData.AddButtonText = "Show on Map";

        if (this.CommonService.DeviceDetectorService.IsPhone) {
            this.CommonService.SideSlideoutService.AttachComponent("Right", ManuallyAddServiceAreaFlyoutComponent, componentData)
                .OnClose
                .subscribe(results => { this.ComponentResults(results); });

        }
        else {
            this.CommonService.Dialog
                .open(ManuallyAddServiceAreaDialogComponent, {
                    data: componentData,
                    minWidth: '60%', height: '80%'
                })
                .afterClosed()
                .subscribe(results => { this.ComponentResults(results); });
        }
    }

    private ComponentResults(results: any): void {
        const serviceAreas = results as TicketAffectedServiceAreaInfo[];
        if (serviceAreas && (serviceAreas.length > 0)) {
            serviceAreas.forEach(sa => {
                const ticketServiceArea = new TicketServiceArea(sa.ID, true, sa.ServiceAreaType, false, null, null, false);
                ticketServiceArea.ServiceAreaInfo = sa;

                this._ManuallyAddedServiceAreas.push(ticketServiceArea);
                this.ToggleServiceArea(sa);
            });
        }
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        return super.GetAdditionalMapFeaturesForPopup(pixel, selectionBox).pipe(map(val => {
            if (_.isEmpty(this._ServiceAreaCodeLayers))
                return val;

            //  Service areas have been added to the map.  Show them in the tooltip/info too.
            const features: FeatureItemResponse[] = val.Features ?? [];
            const sortedLayers = Object.entries(this._ServiceAreaCodeLayers).sort((a, b) => a[0].localeCompare(b[0]));
            for (const [saCode, registrationLayer] of sortedLayers) {
                //  Just need to check to see if there are any features that intersect the selectionBox.
                if (registrationLayer.HaveFeaturesInExtent(selectionBox))
                    features.push(new FeatureItemResponse("Service Area", saCode, null));
            }

            return { Features: features, Exclusive: false };
        }));
    }

    //ToggleMapFullscreen(): void {
    //    this.PageEventService.MapIsFullscreen.next(!this.PageEventService.MapIsFullscreen.value);

    //    this.UpdateMapSize();
    //}

    //  For testing tiles.  The 'readFeatures' call doesn't actually work - think it needs an extent and correct featureProjection.
    //let url = "http://localhost:8080/geoserver/gwc/service/tms/1.0.0/map:ny_base@EPSG%3A900913@pbf/15/9451/20725.pbf";
    //this.commonService.HttpClient.get(url, { responseType: 'blob' })
    //    .subscribe(data => {
    //        let f = new format.MVT();
    //        let projection = f.readProjection(data);
    //        let features = f.readFeatures(data, { dataProjection: projection, featureProjection: projection });
    //        console.log("features: ", features, );
    //    });


    /*  This will style vector tiles
    private static style_Place = new style.Style({
        fill: new style.Fill({
            color: '#ADD8E6'
        }),
        stroke: new style.Stroke({
            color: '#880000',
            width: 1
        })
    });
    private static style_County = new style.Style({
        stroke: new style.Stroke({
            color: '#24FF12',
            width: 1
        })
    });
    private static style_Water = new style.Style({
        fill: new style.Fill({
            color: '#9BD1FE'
        }),
        stroke: new style.Stroke({ color: '#9BD1FE', width: 1 })
    });
    private static style_road = new style.Style({
        stroke: new style.Stroke({ color: '#9BD1FE', width: 1 })
    });
    private static style_highway = new style.Style({
        stroke: new style.Stroke({ color: 'red', width: 1 })
    });

    public StyleFeature(feature: any, resolution: any): style.Style {
        //console.log("StyleFeature: ", feature, resolution);

        var layer = feature.get("layer");

        switch (layer) {
            case 'BoundaryDisplays':
                var boundaryType = feature.get("BoundaryType");
                switch (boundaryType) {
                    case 'County':
                        return DigsiteMapComponent.style_County;
                    case 'Place':
                        return DigsiteMapComponent.style_Place;
                    default:
                        console.log("Unhandled BoundaryDisplays Feature: ", feature, resolution, layer);
                }
                break;
            case 'ChoppedDisplays':
                var layerName = feature.get("LayerName");
                switch (layerName) {
                    case 'WaterPolygon':
                        return DigsiteMapComponent.style_Water;
                    default:
                        console.log("Unhandled ChoppedDisplays Feature: ", feature, resolution, layer);
                }
            case 'WaterLines':
                return DigsiteMapComponent.style_Water;
            case 'StreetDisplays':
                return DigsiteMapComponent.style_road;
            case 'HighwayDisplays':
                return DigsiteMapComponent.style_highway;
            default:
                console.log("Unhandled Feature: ", feature, resolution, layer);
        }
    }*/
}
