import { Component } from '@angular/core';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { DeliveryMethodEnum, DeliveryMethodEnumDescriptions } from 'Enums/DeliveryRules/DeliveryMethod.enum';
import { DeliveryRuleHolidayRuleEnum, DeliveryRuleHolidayRuleEnumDescriptions } from 'Enums/DeliveryRules/DeliveryRuleHolidayRule.enum';
import { DeliveryRuleScheduleTypeEnum, DeliveryRuleScheduleTypeEnumDescriptions } from 'Enums/DeliveryRules/DeliveryRuleScheduleType.enum';
import { DeliveryRuleAuditFormatEnum, DeliveryRuleAuditFormatEnumDescriptions } from 'Enums/DeliveryRules/DeliveryRuleAuditFormat.enum';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Component({
    templateUrl: './ProfileVerificationsDeliveryRulesStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsDeliveryRulesStepInfo.component.scss']
})

export class ProfileVerificationsDeliveryRulesStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    public DeliveryMethodEnum = DeliveryMethodEnum;
    public DeliveryMethodEnumDescriptions = DeliveryMethodEnumDescriptions;
    public DeliveryRuleHolidayRuleEnum = DeliveryRuleHolidayRuleEnum;
    public DeliveryRuleHolidayRuleEnumDescriptions = DeliveryRuleHolidayRuleEnumDescriptions;
    public DeliveryRuleScheduleTypeEnum = DeliveryRuleScheduleTypeEnum;
    public DeliveryRuleScheduleTypeEnumDescriptions = DeliveryRuleScheduleTypeEnumDescriptions;
    public DeliveryRuleAuditFormatEnum = DeliveryRuleAuditFormatEnum;
    public DeliveryRuleAuditFormatEnumDescriptions = DeliveryRuleAuditFormatEnumDescriptions;

    public override Name = StepNameConstants.DELIVERY_RULES;

    constructor() {
        super();
    }
}
