import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'Services/AuthenticationService';
import { MapSearchService } from 'Services/MapSearchService';
import { SettingsService } from 'Services/SettingsService';

export class MapToolService {

    /**
     *  Fired when a map tool becomes active and all other tools should deactivate themself.
     */
    public CloseMapToolsExcept: EventEmitter<object> = new EventEmitter();

    /**
     *  Called to activate a tool with the given name.  Names are defined in MapConstants.
     */
    public ActivateTool: EventEmitter<string> = new EventEmitter();

    //  Buffers (in feet) used by the drawing tools
    public CircleMinimumRadiusFt: number = 200;
    public LineBufferFt: number = 200;
    public PolygonBufferFt: number = 0;

    /**
     *  When true, using the same drawing tools multiple times (without hitting the Save button) will allow drawing multiple
     *  shapes at once.  i.e. Can draw a circle, click circle button and draw another one.
     *  When false, using the drawing tool again and drawing a new shape will clear the existing shape when a new one is drawn.
     */
    public AllowMultipleShapes: boolean;

    constructor(public MapSearchService: MapSearchService, private _SettingsService: SettingsService, public Dialog: MatDialog, private _AuthenticationService: AuthenticationService) {
        this.AllowMultipleShapes = this._SettingsService.MapDigSiteDrawingAllowMultipleShapes;
    }

    public ConfigureForDigSiteDrawing(): void {
        this.CircleMinimumRadiusFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Circle ?? 200);
        this.LineBufferFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Line ?? 200);
        this.PolygonBufferFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Polygon?? 50);
    }

    public ConfigureForRegistrationDrawing(): void {
        this.CircleMinimumRadiusFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Circle ?? 200);

        //  Only SC is difficult about this...
        switch (this._SettingsService.CurrentOneCallCenterCode) {
            case "SC811":
                this.LineBufferFt = 100;
                break;
            default:
                this.LineBufferFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Line ?? 200);
        }

        this.PolygonBufferFt = 0;
    }

    public ConfigureForMapFeatureDrawing(): void {
        this.CircleMinimumRadiusFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Circle ?? 200);
        this.LineBufferFt = Number(this._AuthenticationService.CurrentUser?.OneCallCenterSettings?.MapDrawingDigsiteBuffer_Line ?? 200);
        this.PolygonBufferFt = 0;
    }

    public get LabelDistancesOnlyInFeet(): boolean {
        return this._SettingsService.MapLabelDistancesOnlyInFeet;
    }
}
