import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlacklistAddressTypeEnum } from 'Enums/BlacklistAddressType.enum';
import { DayOfWeekEnum } from 'Enums/DayOfWeek.enum';
import { DeliveryRuleAuditFormatEnum, DeliveryRuleAuditFormatEnumDescriptions } from 'Enums/DeliveryRules/DeliveryRuleAuditFormat.enum';
import { ManualCallActionResponse } from 'Models/Communications/ManulCallActionResponse.model';
import { Dashboard } from 'Models/Configuration/Dashboard.model';
import { ExcavatorCompanyType } from 'Models/Configuration/ExcavatorCompanyType.model';
import { FieldConfiguration } from "Models/Configuration/Fields/FieldConfiguration.model";
import { IndustryType } from 'Models/Configuration/IndustryType.model';
import { MapLayer } from 'Models/Configuration/Maps/MapLayer.model';
import { MeetingPattern } from 'Models/Configuration/MeetingPattern.model';
import { MessageFormat } from 'Models/Configuration/MessageFormat.model';
import { ProjectType } from 'Models/Configuration/ProjectType.model';
import { ResponseConfig } from 'Models/Configuration/Response.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { TicketFunction } from 'Models/Configuration/TicketFunction.model';
import { UtilityType } from 'Models/Configuration/UtilityType.model';
import { DeliveryCategory } from 'Models/DeliveryRules/DeliveryCategory.model';
import { MapImportConfig } from 'Models/MapImport/MapImportConfig.model';
import { SettingsService } from 'Services/SettingsService';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactType } from '../Models/Configuration/ContactType.model';

@Injectable({
    providedIn: 'root'
})
export class EnumService {

    //  TODO: Don't make server calls to fetch enum values!
    //  Enums are intended to be written in code and only change when we need to build.
    //  Just copy any changes made in the c# enum to a .ts enum.  Yes it will be duplicated, but it's a small
    //  amount of code, takes a couple seconds, and eliminates the server api call.
    //
    //  To build SelectOptions for an enum with user displayable names, follow this pattern:
    //      EnumHelpers.getAsSelectOptions(ReportCategoryEnum, ReportCategoryEnumDescriptions)
    //  To filter those options (in this case, to the enums < "DoNotDisplay" threshold):
    //      EnumHelpers.getAsSelectOptions(ReportCategoryEnum, ReportCategoryEnumDescriptions, (e) => e < ReportCategoryEnum.DoNotDisplay)
    //  To set the options into a filterOptions property of a list view (and filter it at the same time - which is optional)
    //      categories.filterOptions = of(EnumHelpers.getAsSelectOptions(ReportCategoryEnum, ReportCategoryEnumDescriptions, (e) => e < ReportCategoryEnum.DoNotDisplay));

    constructor(private _Http: HttpClient, private _SettingsService: SettingsService) {
    }

    //Add any configuration calls in here so that the cached values will get cleared when a person switches one calls
    //or logs out(may not be needed when they logout, because to login they get redirected to the IdSvr, but just to be safe we clear it out)
    public ClearCache() {
        this._AuditFormats = null;
        this._EntityTypes = null;
        this._LocateTypes = null;
        this._MessageFormats = null;
        this._BillingDeliveryType = null;
        this._DeliveryCategories = null;
        this._TicketTypes = null;
        this._ContactTypesAsSelectOptions = null;
        this._ContactTypes = null;
        this._MapLayers = null;
        this._TicketFunctions = null;
        this._ProjectTypes = null;
        this._Dashboards = null;
        this._ServiceAreaTypes = null;
        this._DigsiteTypes = null;
        this._GeocodeTypes = null;
        this._TicketStatuses = null;
        this._TicketEntryForms = null;
        this._ManualCallActions = null;
        this._Responses = null;
        this._ExcavatorCompanyTypes = null;
        this._States = null;
        this._MeetingPatterns = null;
        this._DiscussionTypes = null;
        this._AdditionalEmailConfigurationSetNames = null;
        this._MapImportConfigs = null;
    }

    //Not really an enum, but I want to cache these because they are static per state
    private _States: string[] = null;
    public get States(): Observable<string[]> {
        return this.ReturnOrFetchConfigValues<string[]>("/Maps/State/GetStates", this._States, newValue => this._States = newValue)
            .pipe(map(val => {//Capitalize the states
                const values = [];
                val.forEach(f => values.push(f.iqToUppercase()));
                return values;
            }));
    }

    private _AdditionalEmailConfigurationSetNames: string[] = null;
    public get AdditionalEmailConfigurationSetNames(): Observable<string[]> {
        return this.ReturnOrFetchConfigValues<string[]>("/Config/Enums/AdditionalEmailConfigurationSetNames", this._AdditionalEmailConfigurationSetNames, newValue => this._AdditionalEmailConfigurationSetNames = newValue);
    }

    private _ManualCallActions: ManualCallActionResponse[] = null;
    public get ManualCallActions(): Observable<ManualCallActionResponse[]> {
        return this.ReturnOrFetchConfigValues<ManualCallActionResponse[]>("/Config/Enums/ManualCallActions", this._ManualCallActions, newValue => this._ManualCallActions = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    //Don't need to clear this out when switching the centers because they should always be the same
    private _AlertTypes: SelectOption[] = null;
    public get AlertTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/AlertTypes", this._AlertTypes, newValue => this._AlertTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    //Don't need to clear this out when switching the centers because they should always be the same
    private _ServiceProviderTypes: SelectOption[] = null;
    public get ServiceProviderTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/ServiceProviderTypes", this._ServiceProviderTypes, newValue => this._ServiceProviderTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }
    //Don't need to clear this out when switching the centers because they should always be the same
    private _BroadcastMessageTypes: SelectOption[] = null;
    public get BroadcastMessageTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/BroadcastMessageTypes", this._BroadcastMessageTypes, newValue => this._BroadcastMessageTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }
    //Don't need to clear this out when switching the centers because they should always be the same
    private _BroadcastMessageRecipientSelectionMethods: SelectOption[] = null;
    public get BroadcastMessageRecipientSelectionMethods(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/BroadcastMessageRecipientSelectionMethods", this._BroadcastMessageRecipientSelectionMethods, newValue => this._BroadcastMessageRecipientSelectionMethods = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _DigsiteTypes: SelectOption[] = null;
    public get DigsiteTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/DigsiteTypes", this._DigsiteTypes, newValue => this._DigsiteTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _GeocodeTypes: SelectOption[] = null;
    public get GeocodeTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/GeocodeTypes", this._GeocodeTypes, newValue => this._GeocodeTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _TicketStatuses: SelectOption[] = null;
    public get TicketStatuses(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/TicketStatuses", this._TicketStatuses, newValue => this._TicketStatuses = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _ServiceAreaTypes: SelectOption[] = null;
    public get ServiceAreaTypes(): Observable<SelectOption[]> {
        //  The server filters these types using ICenterBiz.AllowedServiceAreaTypes
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/ServiceAreaTypes", this._ServiceAreaTypes, newValue => this._ServiceAreaTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _AuditFormats: SelectOption[] = null;
    public get AuditFormats(): Observable<SelectOption[]> {
        if (this._AuditFormats === null) {
            this._AuditFormats = [];
            const formats = this._SettingsService.DeliveryRuleAuditFormats;
            formats.forEach(f => {
                this._AuditFormats.push(new SelectOption(f, DeliveryRuleAuditFormatEnumDescriptions[DeliveryRuleAuditFormatEnum[f]]));
            });
        }

        return of<SelectOption[]>(this._AuditFormats);
    }

    private _EntityTypes: SelectOption[] = null;
    public get EntityTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/EntityTypes", this._EntityTypes, newValue => this._EntityTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    //  Do not cache configuration values that are configurable!  Otherwise, once cached, changes made by another user will not be reflected unless the entire page is refreshed!
    public get UtilityTypes(): Observable<UtilityType[]> {
        return this.ReturnOrFetchConfigValues<UtilityType[]>("/Config/UtilityTypes", null, null)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _BillingDeliveryType: SelectOption[] = null;
    public get BillingDeliveryTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/BillingDeliveryTypes", this._BillingDeliveryType, newValue => this._BillingDeliveryType = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _DeliveryCategories: DeliveryCategory[] = null;
    public get DeliveryCategories(): Observable<DeliveryCategory[]> {
        return this.ReturnOrFetchConfigValues<DeliveryCategory[]>("/Config/DeliveryCategories", this._DeliveryCategories, newValue => this._DeliveryCategories = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _LocateTypes: SelectOption[] = null;
    public get LocateTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/LocateTypes", this._LocateTypes, newValue => this._LocateTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _MessageFormats: MessageFormat[] = null;
    public get MessageFormats(): Observable<MessageFormat[]> {
        return this.ReturnOrFetchConfigValues<MessageFormat[]>("/Config/MessageFormats", this._MessageFormats, newValue => this._MessageFormats = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _MeetingPatterns: MeetingPattern[] = null;
    public get MeetingPatterns(): Observable<MeetingPattern[]> {
        return this.ReturnOrFetchConfigValues<MeetingPattern[]>("/Config/MeetingPatterns", this._MeetingPatterns, newValue => this._MeetingPatterns = newValue);
    }

    private _TicketTypes: SelectOption[] = null;
    public get TicketTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/TicketTypes/GetSelectItems", this._TicketTypes, newValue => this._TicketTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _ContactTypesAsSelectOptions: SelectOption[] = null;
    public get ContactTypesAsSelectOptions(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/ContactTypes/GetSelectItems", this._ContactTypesAsSelectOptions, newValue => this._ContactTypesAsSelectOptions = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _ContactTypes: ContactType[] = null;
    public get ContactTypes(): Observable<ContactType[]> {
        return this.ReturnOrFetchConfigValues<ContactType[]>("/Config/ContactTypes", this._ContactTypes, newValue => this._ContactTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    //  This is duplicated by the MapLayers returned by GetUserInfo.  Should probably get rid of one of these.
    private _MapLayers: MapLayer[] = null;
    public get MapLayers(): Observable<MapLayer[]> {
        return this.ReturnOrFetchConfigValues<MapLayer[]>("/Config/MapLayers", this._MapLayers, newValue => this._MapLayers = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _TicketEntryForms: SelectOption[] = null;
    public get TicketEntryForms(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Forms", this._TicketEntryForms, newValue => this._TicketEntryForms = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _TicketFunctions: TicketFunction[] = null;
    public get TicketFunctions(): Observable<TicketFunction[]> {
        return this.ReturnOrFetchConfigValues<TicketFunction[]>("/Config/TicketFunctions", this._TicketFunctions, newValue => this._TicketFunctions = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way i.e. for roles
    }

    private _ProjectTypes: ProjectType[] = null;
    public get ProjectTypes(): Observable<ProjectType[]> {
        return this.ReturnOrFetchConfigValues<ProjectType[]>("/Config/ProjectTypes", this._ProjectTypes, newValue => this._ProjectTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _Dashboards: Dashboard[] = null;
    public get Dashboards(): Observable<Dashboard[]> {
        return this.ReturnOrFetchConfigValues<Dashboard[]>("/Config/Dashboards", this._Dashboards, newValue => this._Dashboards = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _Responses: ResponseConfig[] = null;
    public get Responses(): Observable<ResponseConfig[]> {
        return this.ReturnOrFetchConfigValues<ResponseConfig[]>("/Config/Responses", this._Responses, newValue => this._Responses = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    private _DiscussionTypes: SelectOption[] = null;
    public get DiscussionTypes(): Observable<SelectOption[]> {
        return this.ReturnOrFetchConfigValues<SelectOption[]>("/Config/Enums/DiscussionTypes", this._DiscussionTypes, newValue => this._DiscussionTypes = newValue)
            .pipe(map(x => x.map(i => Object.assign({}, i))));//Do a deep copy of the values so that if we modify the collection it isn't cached in any way
    }

    //  Do not cache configuration values that are configurable!  Otherwise, once cached, changes made by another user will not be reflected unless the entire page is refreshed!
    public get IndustryTypes(): Observable<IndustryType[]> {
        return this.ReturnOrFetchConfigValues<IndustryType[]>("/Config/IndustryTypes", null, null);
    }

    //  Do not cache configuration values that are configurable!  Otherwise, once cached, changes made by another user will not be reflected unless the entire page is refreshed!
    public get FieldConfigurations(): Observable<FieldConfiguration[]> {
        return this.ReturnOrFetchConfigValues<FieldConfiguration[]>("/Config/FieldConfigurations", null, null);
    }

    private _ExcavatorCompanyTypes: ExcavatorCompanyType[] = null;
    public get CompanyTypes(): Observable<ExcavatorCompanyType[]> {
        return this.ReturnOrFetchConfigValues<ExcavatorCompanyType[]>("/Config/ExcavatorCompanyTypes", this._ExcavatorCompanyTypes, newValue => this._ExcavatorCompanyTypes = newValue);
    }

    private _MapImportConfigs: MapImportConfig[] = null;
    public get MapImportConfigs(): Observable<MapImportConfig[]> {
        return this.ReturnOrFetchConfigValues<MapImportConfig[]>("/MapImport/Config/List", this._MapImportConfigs, newValue => this._MapImportConfigs = newValue);
    }

    private ReturnOrFetchConfigValues<T>(controllerPath: string, currentValue: T, setValue: (newValue: T) => void): Observable<T> {
        if (currentValue) {
            //  Already have data cached so return it immediately
            //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
            return of(currentValue);
        }

        return this._Http.get<T>(this._SettingsService.ApiBaseUrl + controllerPath).pipe(
            map(data => {
                if (setValue)
                    setValue(data);

                //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                return data;
            }));
    }

    get BlacklistAddressType(): SelectOption[] {
        //  Don't iterate over the enum to build this.  There only 2, won't be any more added, and when it was doing that, there was
        //  special handling for BOTH of them anyway!  This is way less code...
        return [
            new SelectOption(BlacklistAddressTypeEnum.Email, "Email"),
            new SelectOption(BlacklistAddressTypeEnum.Phone, "Phone/Fax"),
            new SelectOption(BlacklistAddressTypeEnum.URL, "URL"),
        ];
    }

    private _dayOfWeek: SelectOption[] = null;
    private _dayOfWeekWeekDay: SelectOption[] = null;
    private _dayOfWeekWeekend: SelectOption[] = null;
    private _dayOfWeekShort: SelectOption[] = null;
    private _dayOfWeekWeekDayShort: SelectOption[] = null;
    private _dayOfWeekWeekendShort: SelectOption[] = null;

    private GetDayShortFormat(day: DayOfWeekEnum): string {
        if (day === DayOfWeekEnum.Friday)
            return "F";
        else if (day === DayOfWeekEnum.Monday)
            return "M";
        else if (day === DayOfWeekEnum.Saturday)
            return "Sat";
        else if (day === DayOfWeekEnum.Sunday)
            return "Sun";
        else if (day === DayOfWeekEnum.Thursday)
            return "Th";
        else if (day === DayOfWeekEnum.Tuesday)
            return "T";
        else if (day === DayOfWeekEnum.Wednesday)
            return "W";

        return null;
    }

    DayOfWeek(days: 'Weekday' | 'Weekend' | null, shortFormat: boolean = false): SelectOption[] {
        if (!this._dayOfWeek) {
            this._dayOfWeek = [];
            this._dayOfWeekWeekDay = [];
            this._dayOfWeekWeekend = [];
            this._dayOfWeekWeekendShort = [];
            this._dayOfWeekWeekDayShort = [];
            this._dayOfWeekShort = [];
            const keys = Object.keys(DayOfWeekEnum).filter((type) => isNaN(type as any) && type !== 'values');
            for (const key of keys) {
                const name = key;
                const shortName = this.GetDayShortFormat(DayOfWeekEnum[key]);

                if (DayOfWeekEnum[key] === DayOfWeekEnum.Saturday || DayOfWeekEnum[key] === DayOfWeekEnum.Sunday) {
                    this._dayOfWeekWeekend.push(new SelectOption(DayOfWeekEnum[key], name));
                    this._dayOfWeekWeekendShort.push(new SelectOption(DayOfWeekEnum[key], shortName));
                }
                else {
                    this._dayOfWeekWeekDay.push(new SelectOption(DayOfWeekEnum[key], name));
                    this._dayOfWeekWeekDayShort.push(new SelectOption(DayOfWeekEnum[key], shortName));
                }

                this._dayOfWeek.push(new SelectOption(DayOfWeekEnum[key], name));
                this._dayOfWeekShort.push(new SelectOption(DayOfWeekEnum[key], shortName));
            }
        }

        if (days === 'Weekday') {
            if (shortFormat)
                return this._dayOfWeekWeekDayShort;

            return this._dayOfWeekWeekDay;
        }
        else if (days === 'Weekend') {
            if (shortFormat)
                return this._dayOfWeekWeekendShort;

            return this._dayOfWeekWeekend;
        }
        else {
            if (shortFormat)
                return this._dayOfWeekShort;

            return this._dayOfWeek;
        }
    }
}
