//  Contains components that should be included everywhere
//  See: https://angular.io/guide/ngmodule-faq#sharedmodule

import { CommonModule } from '@angular/common';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { TextMaskModule } from 'Shared/TextMask/TextMask.module';
import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmailVerificationComponent } from 'Pages/Emails/Verification/EmailVerification.component';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { ObservableProgressDialogComponent } from 'Shared/Components/Controls/Dialog/ObservableProgressDialog/ObservableProgressDialog.component';
import { GenericFlyoutComponent } from 'Shared/Components/Controls/Flyout/Generic/GenericFlyout.component';
import { IconButtonModule } from 'Shared/Components/Controls/IconButton/IconButton.module';
import { IQListModule } from 'Shared/Components/Controls/Lists/List.module';
import { NextPreviousNavigationComponent } from 'Shared/Components/Controls/NextPreviousNavigation/NextPreviousNavigation.component';
import { TimeDropdownPickerComponent } from 'Shared/Components/Controls/Time/DropdownPicker/TimeDropdownPicker.component';
import { DropdownPickerRangeComponent } from 'Shared/Components/Controls/Time/DropdownPickerRange/DropdownPickerRange.component';
import { DateWithPickerComponent } from 'Shared/Components/Forms/FormControls/DateWithPickerComponent';
import { DynamicContentModule } from 'Shared/Directives/DynamicContent/DynamicContent.module';
import { MaterialSelectClearDirective } from 'Shared/Directives/MaterialSelectClear.directive';
import { UtilsModule } from 'Shared/Modules/Utils.module';
import { PrintLayoutComponent } from "Shared/Printing/PrintLayout/PrintLayout.component";
import { CustomDateTimeAdapter } from 'Shared/Utils/CustomDateTimeAdapter';
import { AutocompleteTemplatesModule } from '../Components/Autocomplete/AutocompleteTemplates.module';
import { BrowserInfoComponent } from "../Components/Controls/BrowserInfo/BrowserInfo.component";
import { DetailLayoutModule } from '../Components/Controls/DetailLayout/Modules/DetailLayout.module';
import { YesNoConfirmationDialogComponent } from '../Components/Controls/Dialog/YesNoConfirmation/YesNoConfirmationDialog.component';
import { IQSectionModule } from '../Components/Controls/Section/Modules/Section.module';
import { IQPipesModule } from '../Pipes/Pipes.module';
import { AddressModule } from './Address.module';
import { ApiInterceptorModule } from './ApiInterceptor.module';
import { BusyIndicatorModule } from './BusyIndicator.module';
import { DateTimePickerModule } from './DateTimePicker.module';
import { MaterialModule } from './Material.module';
import { PhoneModule } from './Phone.module';

//  Slight change to the date formats used by ng-date-picker (owl date picker).
//  Came from here: https://danielykpan.github.io/date-time-picker/
//  Changed some numerics to force the right number of digits.  Otherwise, the format varies from 1-2 digits...
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export const CUSTOM_DATE_FORMATS = {
    fullPickerInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
    datePickerInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
    timePickerInput: { hour: '2-digit', minute: '2-digit' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

const _Dialogs: any[] = [
    ConfirmationDialogComponent, InformationDialogComponent,
    GenericFlyoutComponent, ObservableProgressDialogComponent,
    YesNoConfirmationDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule, DateTimePickerModule,
        RouterModule,
        OwlDateTimeModule, OwlNativeDateTimeModule,
        FontAwesomeModule,
        TextMaskModule,
        IQListModule,
        UtilsModule,
        BusyIndicatorModule,
        NgxPaginationModule,
        DetailLayoutModule,
        ApiInterceptorModule,
        IconButtonModule,
        AutocompleteTemplatesModule,
        IQPipesModule,
        IQSectionModule,
        PhoneModule,
        AddressModule,
        DynamicContentModule,
        MtxSelectModule         //  Part of @ng-matero/extensions npm package
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule, DateTimePickerModule,
        RouterModule,
        IQListModule,
        UtilsModule,
        BusyIndicatorModule,
        NgxPaginationModule,
        OwlDateTimeModule, OwlNativeDateTimeModule,
        FontAwesomeModule,
        TextMaskModule,
        EmailVerificationComponent,
        MaterialSelectClearDirective,
        DetailLayoutModule,
        NextPreviousNavigationComponent,
        DropdownPickerRangeComponent, TimeDropdownPickerComponent,
        IconButtonModule,
        AutocompleteTemplatesModule,
        IQPipesModule,
        IQSectionModule,
        PhoneModule,
        AddressModule,
        DynamicContentModule,
        DateWithPickerComponent,
        MtxSelectModule,        //  Part of @ng-matero/extensions npm package
        PrintLayoutComponent,
        BrowserInfoComponent,
        ..._Dialogs
    ],
    providers: [
        { provide: DateTimeAdapter, useClass: CustomDateTimeAdapter },
        { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_FORMATS }, //  Necessary to make the ng-date-picker 0 pad the date/time components
        provideHttpClient(withInterceptorsFromDi())
    ],
    declarations: [
        EmailVerificationComponent,
        MaterialSelectClearDirective,
        NextPreviousNavigationComponent,
        DropdownPickerRangeComponent, TimeDropdownPickerComponent,
        DateWithPickerComponent,
        PrintLayoutComponent,
        BrowserInfoComponent,
        ..._Dialogs
    ]
})

export class GlobalSharedModule { }
