import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from 'Models/Security/AppUser.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import _ from 'lodash';
import { takeUntil } from 'rxjs';
import { ChatService } from '../Chat.service';
import { IHubSpotConversations } from './IHubSpotConversations.interface';

//  How to configure HubSpot Live Chat: https://knowledge.hubspot.com/chatflows/create-a-live-chat
//  Requires a Tracking Code from HubSpot
//  Chat Widget API documentation: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk

@Component({
    selector: 'iq-chat-hubspot',
    templateUrl: './HubSpotChat.component.html',
    styleUrls: ['./HubSpotChat.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class HubSpotChatComponent extends ComponentWithDestroySubscription implements AfterViewInit, OnDestroy {

    private _HubSpotConversations: IHubSpotConversations = null;

    constructor(private _AuthenticationService: AuthenticationService, private _ChatService: ChatService,
        private _ElementRef: ElementRef, private _Renderer: Renderer2, private router: Router)
    {
        super();

        _ChatService.PositionAboveMobileToolbar.pipe(takeUntil(this.Destroyed)).subscribe(() => this.UpdateButtonPosition());

        //  This function is called when the HubSpot Conversations API is ready.  Use this to do the initial setup of the chat widget.
        (window as any).hsConversationsOnReady = [() => {
            this._HubSpotConversations = (window as any).HubSpotConversations;
            this.UpdateButtonPosition();
        }];

        //  Detect route changes and update the chat widget when they happen.  This allows HubSpot to show different chat options
        //  based on the page the user is on.
        //  Note that doing this will cause the chat pop-up to close if it is currently open.  The "userInteractedWithWidget" event is fired
        //  when the user opens the widget - so we could potentially track that and re-open it.
        //  So only doing this if configured.
        if (coerceBooleanProperty(this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_HubSpot_RefreshOnRouteChange)) {
            router.events.pipe(takeUntil(this.Destroyed)).subscribe(() => {
                if (this._HubSpotConversations?.widget)
                    this._HubSpotConversations.widget.refresh({ openToNewThread: false });     //  true will start a new chat thread and discard the current one
            });
        }
    }

    public static IsEnabled(user: AppUser): boolean {
        return !_.isEmpty(user.OneCallCenterSettings.Chat_HubSpot_TrackingCode);
    }

    public ngAfterViewInit(): void {
        //  Once loaded, HubSpot stays in the DOM (it loads several secondary scripts).  This will detect it and tell it to reload itself
        //  if we previously removed it.
        if (this._HubSpotConversations?.widget) {
            //  This does nothing if already loaded.
            this._HubSpotConversations.widget.load();
            return;
        }

        //  Check to see if the HubSpotConversations object is already in the DOM.  If so, reload it.
        const hsc = (window as any).HubSpotConversations as IHubSpotConversations;
        if (hsc?.widget) {
            hsc.widget.load();
            this._HubSpotConversations = hsc;
            this.UpdateButtonPosition();
        }

        const script = this._Renderer.createElement('script');
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        script.onload = () => this.UpdateButtonPosition();        //  Must set this before "src" or may not fire all the time
        script.src = "//js-na1.hs-scripts.com/" + this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_HubSpot_TrackingCode + ".js";

        //  HubSpot requires adding this script to document.body or it does not work correctly all the time (especially when switching to phone view).
        this._Renderer.appendChild(document.body, script);
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();

        this._HubSpotConversations = null;

        //  HubSpot is pretty invasive at adding several additional scripts to the html document.  But it also has a pretty nice api.
        //  So rather than trying to purge it from the DOM like we do with other chat clients, we can tell their api to remove itself.
        //  It has an api to then load itself later if needed.
        const hubSpot = (window as any).HubSpotConversations;
        if (hubSpot?.widget)
            hubSpot.widget.remove();

        (window as any).hsConversationsOnReady = [];
    }

    private UpdateButtonPosition(): void {
        const chatButton: HTMLDivElement = document.querySelector('#hubspot-messages-iframe-container');
        if (!chatButton)
            return;

        //  Must use setAttribute so that we can also use !important (the HubSpot style has !important specified so if we don't do this, their style trumps ours).
        //  And they have some other properties set on the element that we need to maintain or the button disappears.
        if (this._ChatService.PositionAboveMobileToolbar.value)
            chatButton.style.setProperty("bottom", "48px", "important");
        else
            chatButton.style.setProperty("bottom", "0px", "important");
    }
}
