export enum TicketAttachmentTypeEnum {
    /**
     * File uploaded by the Excavator
     */
    File = 0,

    /**
     * Ticket Map uploaded by the Excavator
     */
    TicketMap = 1,

    /**
     * URL uploaded by the Excavator
     */
    URL = 2
}

export enum TicketAttachmentTypeEnumDescription {
    File = "File",
    TicketMap = "Ticket Map",
    URL = "URL"
}
