import { Component } from '@angular/core';
import { ServiceAreaTypeEnum, ServiceAreaTypeEnumDescriptions } from 'Enums/ServiceAreaType.enum';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Component({
    templateUrl: './ProfileVerificationsGeneralStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsGeneralStepInfo.component.scss']
})

export class ProfileVerificationsGeneralStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    public ServiceAreaTypeEnum = ServiceAreaTypeEnum;
    public ServiceAreaTypeEnumDescriptions = ServiceAreaTypeEnumDescriptions;

    public override Name = StepNameConstants.GENERAL_INFO;

    constructor() {
        super();
    }
}
