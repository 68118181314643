<iq-view-list *ngIf="!DeviceDetectorService.IsPhone" style="height:100%; overflow:auto">
    <div style="display:flex">
        <div style="flex-grow:1"></div>
        <iq-icon-button *ngIf="CanEnterResponse" icon="New" button-class="Button-Small" (click)="AddNewResponse()">Add Response</iq-icon-button>
    </div>

    <div class="iq-list-items" style="padding-bottom:unset">
        <div class="iq-list-header no-more-option" style="box-sizing: border-box;">
            <ng-container *ngFor="let col of DisplayedColumns">
                <ng-container *ngIf="col.canSearchAndFilter; else normalHeaderTemplate">
                    <iq-column-header class="link column {{col.class}}" [Title]="col.name" [style.max-width]="col.width" style="flex: 1 1 0%;"
                                      [ClearSortAndFilters]="_clearSortAndFilter" [StoredFiltersKey]="StoredFiltersKey" [isDisabled]="disableFilters"
                                      [SearchColumn]="col" (change)="ColumnSortFilterChange(col, $event)">
                        {{col.name}}
                    </iq-column-header>
                </ng-container>
                <ng-template #normalHeaderTemplate>
                    <label class="column-header {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%;">
                        {{col.name}}
                    </label>
                </ng-template>
            </ng-container>
        </div>

        <iq-list-item *ngFor="let item of ServiceAreas" [ngClass]="{'no-response-background': !item.CurrentResponses || item.CurrentResponses.length===0 || (!item.CurrentResponses[0].ResponseID && item.CurrentResponses[0].ResponseRequired)}">
            <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">
                <div *ngSwitchCase="'ServiceArea'" class="column {{col.class}}" style="display: flex; flex-direction: column; flex: 1 1 0%;" [style.max-width]="col.width">
                    <div class="text-overflow-2">{{item.Name}}</div>
                    <div class="iq-caption text-overflow-1 gray-primary-text">{{item.Code}}</div>
                </div>

                <div *ngSwitchCase="'CurrentResponses'" class="column {{col.class}}" style="display: flex; flex-direction: column; flex: 1 1 0%;" [style.max-width]="col.width">
                    <ng-container *ngFor="let response of item.CurrentResponses; last as isLast">
                        <div *ngIf="response.UtilityTypeName">
                            <label class="iq-caption gray-primary-text">Utility Type:</label>
                            <span style="font-weight: bold">{{response.UtilityTypeName}}:</span>
                        </div>
                        <div *ngIf="!response.ResponseCode && !response.ResponseRequired">
                            Response not required
                        </div>
                        <div *ngIf="response.ResponseCode" style="padding-bottom: 5px;">
                            <div>{{response.ResponseCode}} - {{response.ResponseDescription}}</div>
                            <div>
                                <label class="iq-caption gray-primary-text">Date:</label>
                                <span>{{response.EnteredDate | date: SettingsService.DateTimeFormat}}</span>
                            </div>
                            <div *ngIf="response.Respondant">
                                <label class="iq-caption gray-primary-text">Respondent:</label>
                                <span>{{response.Respondant}}</span>
                            </div>
                            <div *ngIf="response.Comment">
                                <label class="iq-caption gray-primary-text">Comment:</label>
                                <span>{{response.Comment}}</span>
                            </div>
                            <div *ngIf="response.PublicURL" style="display:flex; align-items:baseline">
                                <div class="iq-caption gray-primary-text" style="flex-shrink:0">Public URL:&nbsp;</div>
                                <div style="overflow:hidden; text-overflow:ellipsis">{{response.PublicURL}}</div>
                                <a class="link" style="flex-shrink:0" href="{{response.PublicURL}}" target="_blank">
                                    <i class="fa fa-arrow-up-right-from-square" style="padding-left:5px"></i>
                                </a>
                            </div>
                            <div *ngIf="response.PublicURL && response.PublicURLIsBlacklisted" style="color:red; padding-left:1em; font-size:smaller">* URL is Blacklisted</div>
                            <div>
                                <span class="iq-caption gray-primary-text">Entered via {{response.EntryMethod | ResponseEntryMethodEnum}}</span>
                                <span *ngIf="response.EnteredByPersonName">: {{response.EnteredByPersonName}}</span>
                            </div>
                        </div>
                        <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
                        <div *ngIf="item.CurrentResponses && item.CurrentResponses.length > 0" style="display:flex">
                            <div *ngIf="item.CurrentResponses[0].ResponseID" class="link" (click)="ViewServiceAreaResponses(item)" style="padding-right:2em">view all</div>
                            <ng-container *ngIf="CanEnterResponse">
                                <div *iqHasPermission="EnterResponsesPermission for [item.ID]" class="link" (click)="AddNewResponse(item)">add new</div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div *ngSwitchCase="'ExcavatorComments'" class="column {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%; display: flex;">
                    <div style="display: flex; flex-direction: column; flex: 1 1 0%;">
                        <ng-container *ngFor="let response of item.ExcavatorComments; last as isLast">
                            <label style="white-space: pre-wrap; padding: 5px 0; word-break: break-all; margin-right: 10px;">{{response.Message}}</label>
                            <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
                        </ng-container>
                    </div>
                </div>

                <div *ngSwitchCase="'Discussions'" class="column {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%; display:flex; flex-direction: column;">
                    <iq-ticket-response-discussion-list [ServiceArea]="item" [ServiceAreaList]="ServiceAreas" (Changed)="OnDiscussionChanged()"></iq-ticket-response-discussion-list>
                </div>

                <label *ngSwitchDefault class="column {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%;">
                    {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                </label>
            </ng-container>
        </iq-list-item>
    </div>
</iq-view-list>
<div *ngIf="DeviceDetectorService.IsPhone" style="height:100%; overflow:auto">
    <mat-card *ngFor="let item of ServiceAreas" style="padding: 0px 5px; margin: 5px 2px" [ngClass]="{'no-response-background': 'event-background'}"
              matRipple matRippleCentered matRippleColor="rgba(0,0,0,.1)">
        <div style="display: flex">
            <div style="flex: 1 1 auto; display: flex; flex-direction: column; width: 100%; overflow: hidden; padding: 3px 0;">
                <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">
                    <div style="display:flex; gap:10px">
                        <div *ngSwitchCase="'ServiceArea'" style="flex-grow:1">
                            <div style="font-weight: bold">{{item.Name}}</div>
                            <div class="iq-caption gray-primary-text">{{item.Code}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>
</div>
