import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import { ChatService } from './Chat.service';
import { Five9ChatComponent } from './Five9/Five9Chat.component';
import { HubSpotChatComponent } from './HubSpot/HubSpotChat.component';
import { MyAskAIChatComponent } from './MyAskAI/MyAskAIChat.component';
import { TawkChatComponent } from './Tawk/TawkChat.component';

@Component({
    selector: 'iq-chat',
    templateUrl: './Chat.component.html',
    styleUrls: ['./Chat.component.scss'],
    standalone: true,
    imports: [NgIf, MyAskAIChatComponent, Five9ChatComponent, HubSpotChatComponent, TawkChatComponent]
})
export class ChatComponent extends ComponentWithDestroySubscription {

    public IsMyAskAIEnabled: boolean = false;
    public IsFive9Enabled: boolean = false;
    public IsHubSpotEnabled: boolean = false;
    public IsTawkEnabled: boolean = false;

    constructor(authenticationService: AuthenticationService, chatService: ChatService) {
        super();

        //  takeUntil destroyed to handle switching to a new One Call.  This will refresh the chat components based on the new One Call's settings
        //  (and unload the previous ones).
        authenticationService.CurrentUserObserver().pipe(takeUntil(this.Destroyed)).subscribe(user => {
            //  Chat is only enabled for Excavator and Service Area users.
            const isExcavatorOrServiceAreaUser = user.IsExcavatorUser || user.IsServiceAreaUser;

            this.IsMyAskAIEnabled = isExcavatorOrServiceAreaUser && MyAskAIChatComponent.IsEnabled(user);
            this.IsFive9Enabled = isExcavatorOrServiceAreaUser && Five9ChatComponent.IsEnabled(user);
            this.IsHubSpotEnabled = isExcavatorOrServiceAreaUser && HubSpotChatComponent.IsEnabled(user);
            this.IsTawkEnabled = isExcavatorOrServiceAreaUser && TawkChatComponent.IsEnabled(user);

            chatService.IsAvailable.next(this.IsMyAskAIEnabled || this.IsFive9Enabled || this.IsHubSpotEnabled || this.IsTawkEnabled);
        });

        authenticationService.UserLoggedOutEvent.pipe(takeUntil(this.Destroyed)).subscribe(() => {
            this.IsMyAskAIEnabled = false;
            this.IsFive9Enabled = false;
            this.IsHubSpotEnabled = false;
            this.IsTawkEnabled = false;
            chatService.IsAvailable.next(false);
        });
    }
}
