import { Pipe, PipeTransform } from '@angular/core';
import { OpenTime } from '@iqModels/OpenTime/OpenTime.model';
import { DayOfWeekEnum } from 'Enums/DayOfWeek.enum';
import { EnumService } from 'Services/Enum.service';
import { SelectOption } from '@iqModels/Configuration/SelectOption.model';

@Pipe({
    name: 'openTime'
})
export class OpenTimePipe implements PipeTransform {
    daysOfWeek: SelectOption[] = this.enumService.DayOfWeek(null, true);
    constructor(private enumService: EnumService) {

    }

    formatTime(date: Date) {
        let hour = date.getHours() === 0 ? 12 : date.getHours();//Convert midnight '0' to '12' for readablity
        return (hour > 12 ? hour - 12 : hour) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) + " " + (date.getHours() >= 12 ? "PM" : "AM");
    }
    formatDays(days: DayOfWeekEnum[]) {
        let dayStr: string;
        for (var day of days) {
            if (dayStr == null)
                dayStr = " ";
            else if (dayStr.length > 0)
                dayStr += ", ";

            dayStr += this.daysOfWeek.find(d => d.Value === day).Name;
        }

        return dayStr;
    }
    transform(value: OpenTime): any {
        if (!value) {
            return value;
        }

        //mock the date part, we only care about the time formatting of the date
        let openDate = new Date("1/1/0001 " + value.Open);
        let closeDate = new Date("1/1/0001 " + value.Close);

        return this.formatTime(openDate) + " - " + this.formatTime(closeDate) + " " + this.formatDays(value.DayOfWeek);
    }

}
