import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { NavigationTypes } from 'Enums/NavigationTypes.enum';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private _IsAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get IsAvailable(): BehaviorSubject<boolean> { return this._IsAvailable }

    private _PositionAboveMobileToolbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get PositionAboveMobileToolbar(): BehaviorSubject<boolean> { return this._PositionAboveMobileToolbar }

    constructor() { }
}
