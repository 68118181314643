<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Change Password
</h2>
<mat-dialog-content class="mat-typography" [formGroup]="group" style="overflow: auto; display: flex; flex-direction: column;">
    <busy-overlay [show]="isBusy"></busy-overlay>

    <ng-container *ngIf="isSignedIn; else noLoggedIn">
        <p style="flex: 1 1 0%;" class="mat-body-2">
            You are about to change your password.
        </p>

        <iq-aws-cognito-password-input style="flex: 1 1 0%;"  Placeholder="Old Password" [FormControl]="oldPassword" (keyup.enter)="onChangePassword()"></iq-aws-cognito-password-input>

        <div class="mat-caption" *ngIf="PasswordRequirements != null">
            <p>Password requirements:</p>
            <ul>
                <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
                <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
                <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
                <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
                <li *ngIf="PasswordRequirements.RequireASpecialCharacter">Must have a special character ($ * . ? - ! &#64; # % &)
                </li>
            </ul>
        </div>

        <iq-aws-cognito-password-input style="flex: 1 1 0%;"  Placeholder="New Password" [FormControl]="newPassword" (keyup.enter)="onChangePassword()"></iq-aws-cognito-password-input>

        <iq-aws-cognito-password-input style="flex: 1 1 0%;"  Placeholder="Confirm Password" [FormControl]="confirmNewPassword" (keyup.enter)="onChangePassword()"></iq-aws-cognito-password-input>
    </ng-container>

    <ng-template #noLoggedIn>
        <label class="mat-caption mat-error">No logged in user, this should not be shown</label>
    </ng-template>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button (click)="onChangePassword()" color="primary" [disabled]="!group.valid">
        Confirm
    </button>
</mat-dialog-actions>
