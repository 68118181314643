
export class TicketResponseCreateRequest {
    public TicketNumbers: string[];
    public ServiceAreaID: string;
    public UtilityTypeID?: string;

    public ResponseID: string;

    public Comment?: string;
    public PublicURL?: string;
    public Respondant?: string;

    public Data: object;
}
