import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component } from '@angular/core';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { SettingsService } from 'Services/SettingsService';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Component({
    templateUrl: './ProfileVerificationsHoursAndHolidaysStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsHoursAndHolidaysStepInfo.component.scss']
})

export class ProfileVerificationsHoursAndHolidaysStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    hasMultipleStates = false;
    public override Name = StepNameConstants.HOURS_AND_HOLIDAYS;

    constructor(private settingsService: SettingsService) {
        super();
    }

    public override SetData(data: ProfileVerification, currentStep: number, IsExisting: boolean, formIsReadonly: boolean, setButtonFlagsCallback: () => void): void {
        super.SetData(data, currentStep, IsExisting, formIsReadonly, setButtonFlagsCallback);
        if (this.OriginalProfile.SystemHolidays && this.OriginalProfile.SystemHolidays.length > 0) {
            //Have to do holidays so that we can clear all out if they decide not to observe one, then decide to observe it again
            this.OriginalProfile.SystemHolidays.forEach(e => {
                if (!this.OriginalProfile.ExcludedSystemHolidays || this.OriginalProfile.ExcludedSystemHolidays.length === 0)
                    e.Excluded = false;
                else {
                    const holiday = this.OriginalProfile.ExcludedSystemHolidays.find(f => f.HolidayID === e.ID);
                    e.Excluded = coerceBooleanProperty(holiday);
                }
            });
        }

        setTimeout(() => this.hasMultipleStates = this.settingsService.TrackHolidaysAndDatesByState);
    }
}
