import { EntryFieldDropdownItem } from "./EntryFieldDropdownItem.model";
import { FieldUIControlTypeEnum } from "Enums/FieldUIControlType.enum";
import { EntityEnum } from "Enums/EntityType.enum";

export class EntryFieldConfigurationResponse {
    public UIControlType: FieldUIControlTypeEnum;

    public ReadOnly: boolean;

    public Required: boolean;
    public Hidden: boolean;

    public MaxLength?: number;

    //  Used on FieldUIControlTypeEnum.TextMultiLine to constrain the input (i.e. if the ticket text has limitations - DigSafe)
    public MaxColumns: number;
    public MaxRows: number;

    // If true, this field can configure values in the FieldValues table - used for autocompletes and dropdowns.
    public CanConfigureValues: boolean;

    public DateFormat?: string;

    public Spellcheck?: boolean;

    public IncludeInCopy: boolean;

    public DropdownItems: EntryFieldDropdownItem[];

    public DisplayName: string;

    //  Extra configuration added by One Call Center specific UI
    public ShortcutKeys: string[];

    //  If UIControlType = TextMask, can put the text mask object here to allow the field to be validated.
    //  See https://github.com/text-mask/text-mask
    //  This should be an object with a property named "mask" that is either an array or a function.
    //  Validation is done by Shared/Components/Forms/Validation/Validators/TextMaskIsValid.validator
    public TextMask: any;

    /**
     * If the field is configured with FieldValues, must set EntityType and PropertyName.
     * If not, they are not required so can leave then as defaults.
     * @param EntityType
     * @param PropertyName
     */
    constructor(public EntityType: EntityEnum = EntityEnum.None, public PropertyName: string = null) {
    }
}

