<div style="display:flex; flex-direction:column; height:100%; overflow:hidden">
    <div class="iq-display-2 primary-color iq-primary-bottom-border">Past Due Profile Verifications</div>
    <iq-view-list>
        <div style="display:flex; flex-grow:1; align-items:center; color:initial; text-decoration:none">
            <label style="color: red; font-size: small; margin: 10px;" class="column-fixed">You have one or more Service Areas that require you to verify their profile information. Click to start the verification.</label>
        </div>
        <div class="iq-list-items">
            <iq-list-item *ngFor="let item of VerificationReminders">
                <div style="display:flex; flex-grow:1; align-items:center; color:initial; text-decoration:none">
                    <label class="link column-fixed" style="flex: 1 1 0%;" (click)="ServiceAreaClicked(item.ServiceAreaID)">
                        {{item.ServiceAreaCode}}: {{item.ServiceAreaName}}
                    </label>
                </div>
            </iq-list-item>
        </div>
    </iq-view-list>
</div>
