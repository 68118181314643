import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { DeviceDetectorService } from "../DeviceDetector.service";

/**
 *  Put this in the "canActivate" list of a route (after AuthenticationGuardService) to prevent a phone device from accessing the route.
 *  Phone users will be redirected to /home
 */
export const PreventPhoneGuardService: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService),
    router: Router = inject(Router)) => {
    //  Note that there is another check for this in the DeviceDetectorService which will check this if the browser size changes to a phone.
    if (deviceDetectorService.IsPhone) {
        router.navigate(["./home"]);
        return false;
    }

    return true;
}
