export class ProfileVerificationsButtonFlags {
    constructor() {
    }

    public UnsubmitButtonVisible: boolean = false;
    public SubmitButtonVisible: boolean = false;
    public IsPendingReview: boolean = false;
    public CanSubmitProfile: boolean = false;
    public CanClickPrevious: boolean = false;
    public CanClickNext: boolean = false;
    public SaveProgressButtonVisible: boolean = false;
    public ReviewButtonVisible: boolean = false;
    public CanReviewProfile: boolean = false;

}
