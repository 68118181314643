<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 style="margin: 0px;">General: </h4>
    <div class="fieldGroup" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
        <div *ngIf="OriginalProfile.Code" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Code: </label>
            <span>{{OriginalProfile.Code}}</span>
        </div>
        <div *ngIf="OriginalProfile.Name" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Name: </label>
            <span>{{OriginalProfile.Name}}</span>
        </div>
        <div *ngIf="OriginalProfile.IsActive !== undefined" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Active: </label>
            <span>{{OriginalProfile.IsActive | yesNoFormat}}</span>
        </div>
        <div *ngIf="OriginalProfile.WebURL !== undefined && OriginalProfile.WebURL !== null && OriginalProfile.WebURL.length > 0" class="profile-field" style="flex-grow: 1;">
            <label class="iq-caption gray-primary-text profile-label">URL: </label>
            <span>{{OriginalProfile.WebURL}}</span>
        </div>
        <div *ngIf="OriginalProfile.ServiceAreaType !== undefined" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Type: </label>
            <span>{{OriginalProfile.ServiceAreaType.Name}}</span>
        </div>
        <div *ngIf="OriginalProfile.Member" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Member: </label>
            <span>{{OriginalProfile.Member.Name}}</span>
        </div>
        <div *ngIf="OriginalProfile.MarkingColor" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Marking Color: </label>
            <span>{{OriginalProfile.MarkingColor}}</span>
        </div>
    </div>
    <h4 style="margin: 0px;">Positive Response: </h4>
    <div style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
        <div *ngIf="OriginalProfile.PositiveResponseIdentificationCode" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Code: </label>
            <span>{{OriginalProfile.PositiveResponseIdentificationCode}}</span>
        </div>
        <div *ngIf="OriginalProfile.ResponseEntryType" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Type: </label>
            <span>{{OriginalProfile.ResponseEntryType.Name}}</span>
        </div>
    </div>
    <ng-container *ngIf="OriginalProfile.AutoResponse || OriginalProfile.AutoResponseComment">
        <h4 style="margin: 0px;">Auto Response: </h4>
        <div style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
            <div *ngIf="OriginalProfile.AutoResponse" class="profile-field profile-field-large">
                <span>{{OriginalProfile.AutoResponse.Description}}</span>
            </div>
            <div *ngIf="OriginalProfile.AutoResponseComment" class="profile-field profile-field-large">
                <label class="iq-caption gray-primary-text profile-label profile-label-large">Comment: </label>
                <span>{{OriginalProfile.AutoResponseComment}}</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="OriginalProfile.Phones && OriginalProfile.Phones.length !== 0">
        <h4 style="margin: 0px;">Phones: </h4>
        <div *ngFor="let Phone of OriginalProfile.Phones" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px; ">
            <div *ngIf="Phone.PhoneNumber" class="PhoneColumn" style="display: flex; column-gap: 10px; align-items: baseline;">
                <label class="iq-caption gray-primary-text profile-label">Number: </label>
                <span>{{Phone.PhoneNumber | phone}}</span>
            </div>
            <div *ngIf="Phone.PhoneType" class="PhoneColumn" style="display: flex; column-gap: 10px; align-items: baseline;">
                <label class="iq-caption gray-primary-text profile-label">Type: </label>
                <span>{{Phone.PhoneType.Name}}</span>
            </div>
            <div *ngIf="Phone.Usage" class="PhoneColumn" style="display: flex; column-gap: 10px; align-items: baseline;">
                <label class="iq-caption gray-primary-text profile-label">Usage: </label>
                <span>{{Phone.Usage.Name}}</span>
            </div>
            <div *ngIf="Phone.Notes" class="PhoneColumn" style="display: flex; column-gap: 10px; align-items: baseline;">
                <label class="iq-caption gray-primary-text profile-label">Notes: </label>
                <span>{{Phone.Notes}}</span>
            </div>
        </div>
    </ng-container>
    <h4 *ngIf="OriginalProfile.Emails && OriginalProfile.Emails.length !== 0" style="margin: 0px;">Emails: </h4>
    <div *ngFor="let Email of OriginalProfile.Emails" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px; ">
        <div *ngIf="Email.EmailAddress" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Address: </label>
            <span>{{Email.EmailAddress}}</span>
        </div>
        <div *ngIf="Email.EmailType" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Type: </label>
            <span>{{Email.EmailType.Name}}</span>
        </div>
    </div>
    <h4 *ngIf="OriginalProfile.UtilityTypes && OriginalProfile.UtilityTypes.length !== 0" style="margin: 0px;">Utility Types: </h4>
    <div class="desktopField" style="display: flex; width: 100%; margin-bottom: 20px; column-gap: 10px;">
        <ng-container *ngIf="OriginalProfile.UtilityTypes">
            <div *ngFor="let type of OriginalProfile.UtilityTypes; last as isLast">
                <span *ngIf="!isLast">{{type.UtilityType.Name}}, </span>
                <span *ngIf="isLast">{{type.UtilityType.Name}} </span>
            </div>
        </ng-container>
    </div>
    <div *ngIf="OriginalProfile.UtilityTypes && OriginalProfile.UtilityTypes.length > 0" class="listField mobileField" style="margin-bottom: 20px;">
        <span *ngFor="let type of OriginalProfile.UtilityTypes; last as isLast">
            <span *ngIf="!isLast">{{type.UtilityType.Name}}, </span>
            <span *ngIf="isLast">{{type.UtilityType.Name}} </span>
        </span>
    </div>
    <ng-container *ngIf="OriginalProfile.ApprovedExcavators && OriginalProfile.ApprovedExcavators.length > 0">
        <h4 style="margin: 0px;">Approved Excavators: </h4>
        <div *ngFor="let Excavator of OriginalProfile.ApprovedExcavators" class="listField" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px; ">
            <div *ngIf="Excavator.ExcavatorContact" class="profile-field" style="display: flex; column-gap: 10px;">
                <label class="iq-caption gray-primary-text profile-label">Contact: </label>
                <span>{{Excavator.ExcavatorContact.Name}}</span>
            </div>
            <div *ngIf="Excavator.ExcavatorContact" class="profile-field" style="display: flex; column-gap: 10px; align-items: baseline;">
                <label class="iq-caption gray-primary-text profile-label">Login: </label>
                <span *ngIf="Excavator.ExcavatorContact.EmailAddress">{{Excavator.ExcavatorContact.EmailAddress}}</span>
                <span *ngIf="!Excavator.ExcavatorContact.EmailAddress" style="color: red;">none</span>
            </div>
        </div>
    </ng-container>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>
