export enum DeliveryRuleScheduleTypeEnum {
    Always = 1,
    BusinessHours = 2,
    NonBusinessHours = 3,
    Custom = 4
}

export enum DeliveryRuleScheduleTypeEnumDescriptions {
    Always = "Always",
    BusinessHours = "Business Hours",
    NonBusinessHours = "Non-Business Hours",
    Custom = "Custom"
}
