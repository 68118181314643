import { inject } from '@angular/core';
import { Router, ResolveFn, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, Observable, take } from 'rxjs';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { ServiceAreaProfileVerificationService } from '../Services/ServiceAreaProfileVerification.service';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';

export const ProfileVerificationsRouteResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    service: ServiceAreaProfileVerificationService = inject(ServiceAreaProfileVerificationService),
    router: Router = inject(Router),
    deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService)): Observable<any> => {
    const ServiceAreaID: string = route.params.id;

    if (deviceDetectorService.IsDesktop && ServiceAreaID) {
        router.navigateByUrl("/servicearea/details/" + ServiceAreaID + "?function=verifyprofile");
        return null;
    }

    return service.StartProfileVerification(ServiceAreaID).pipe(take(1),
        catchError(() => router.navigateByUrl("/home")),
        map(response => {
        if (!response || !(response as ProfileVerification)?.ServiceAreaID) {
            router.navigateByUrl("/home");
            return null;
        }
        else {
            return response;
        }
    }));
}
