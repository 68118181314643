//  Maximum field lengths for various properties.
//  Build by OneCallCenterConfigurationBiz.GetFieldLengths which finds some values from the Ticket entry configuration (for fields
//  that relate to admin entities - i.e. ExcavatorContacts) and also uses ICenterBiz.AddAdminFieldLengths() to add One Call
//  specific field lengths for other fields.
//
//  Custom validator that can be used on a FormControl to require based on the value here: FieldIsRequiredAsync
//  Custom directive that can be used on an <input> to set the maxLength: FieldLengthDirective - i.e.: [iqFieldLength]="Address_Address1"
export class FieldLengths {

    //  The initializers specify the defaults if we don't have this sent from the server for some reason (which is an error
    //  that needs to be fixed!).
    //  These should match what is set in EntityPropertyLengthConstants.cs

    public TicketNumber_Length: number;
    public TicketNumber_RegEx: string;

    public Address_Address1: number = 80;
    public Address_Address2: number = 80;
    public Address_City: number = 80;
    public Address_State: number = 2;
    public Address_Zip: number = 10;
    public Address_County?: number = 80;              //  optional - if not set, one call does not use it so do not show it!

    public Email_EmailAddress: number = 100;

    //  There are several places where we do not want to enforce the to the configured Email_EmailAddress limit.
    //  DigSafe has a smaller limit than they should (because of their ticket text restrictions) so any address that could
    //  potentially be used on the ticket, must be enforced to the one call center value.  Other places
    //  (like a person email, destination device, etc) should be limited to the default/max of 100.
    public Email_EmailAddress_Max: number = 100;

    //  This is digits only.  Number = 10, rest is extension
    //  Our masks already limit the extension to 5 digits (so total of 15) so this default doesn't affect that.
    //  Add 6 to maxLength if the field includes formatting.
    public Phone_PhoneNumber: number = 15;      //  default is 10 digits + 5 for extension = 15

    public ExcavatorCompany_Name: number = 80;
    public ExcavatorCompany_CompanyID_Hidden: boolean = false;

    //  FL does not use this field at all so it's hidden.  AZ uses it but it's not required.
    //  Other centers (NY and DigSafe) required it.
    public ExcavatorCompany_IndustryTypeID_Hidden: boolean = false;
    public ExcavatorCompany_IndustryTypeID_Required: boolean = false;

    public ExcavatorOffice_Name: number = 80;
    public ExcavatorOffice_Ticket_Phone_Hidden: boolean;

    public ExcavatorContact_Name: number = 100;
    public ExcavatorContact_ExcavatorID_Hidden: boolean = false;

    public Member_Code: number = 50;
    public Member_Name: number = 100;

    public ServiceArea_Code: number = 50;
    public ServiceArea_Name: number = 100;

    public Destination_Code: number = 50;
    public Destination_Name: number = 100;

    public ServiceProvider_Code: number = 50;
    public ServiceProvider_Name: number = 100;
}
