import { NgModule } from '@angular/core';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { ProfileVerificationsDialogComponent } from 'Pages/ProfileVerifications/Dialog/ProfileVerificationsDialog.component';
import { ProfileVerificationsGeneralStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsGeneralStepInfo.component';
import { ProfileVerificationsHoursAndHolidaysStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsHoursAndHolidaysStepInfo.component';
import { ProfileVerificationsContactsStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsContactsStepInfo.component';
import { ProfileVerificationsTicketAssignmentStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsTicketAssignmentStepInfo.component';
import { ProfileVerificationsDeliveryRulesStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsDeliveryRulesStepInfo.component';
import { ProfileVerificationsDefaultFormComponent } from 'Pages/ProfileVerifications/Forms/ProfileVerificationsDefaultForm.component';
import { ProfileVerificationsListComponent } from 'Pages/ProfileVerifications/List/ProfileVerificationsList.component';
import { ProfileVerificationRemindersListComponent } from 'Pages/ProfileVerifications/List/ProfileVerificationRemindersList.component';
import { ProfileVerificationsAttributesStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsAttributesStepInfo.component';
import { ProfileVerificationsBaseComponent } from 'Pages/ProfileVerifications/ProfileVerificationsBase.component';
import { ProfileVerificationsMobilePageComponent } from 'Pages/ProfileVerifications/Mobile/ProfileVerificationsMobilePage.component';
import { ProfileVerificationsRoutingModule } from 'Pages/ProfileVerifications/Modules/ProfileVerifications-routing.module';

@NgModule({
    imports: [
        GlobalSharedModule,
        ProfileVerificationsRoutingModule
    ],
    declarations: [
        ProfileVerificationsDialogComponent, ProfileVerificationsGeneralStepInfoComponent, ProfileVerificationsHoursAndHolidaysStepInfoComponent,
        ProfileVerificationsContactsStepInfoComponent, ProfileVerificationsTicketAssignmentStepInfoComponent, ProfileVerificationsDeliveryRulesStepInfoComponent, ProfileVerificationsAttributesStepInfoComponent,
        ProfileVerificationsDefaultFormComponent, ProfileVerificationsListComponent, ProfileVerificationsBaseComponent, ProfileVerificationsMobilePageComponent, ProfileVerificationRemindersListComponent
    ],
    exports: [
        ProfileVerificationsDialogComponent, ProfileVerificationsGeneralStepInfoComponent, ProfileVerificationsHoursAndHolidaysStepInfoComponent,
        ProfileVerificationsContactsStepInfoComponent, ProfileVerificationsTicketAssignmentStepInfoComponent, ProfileVerificationsDeliveryRulesStepInfoComponent, ProfileVerificationsAttributesStepInfoComponent,
        ProfileVerificationsDefaultFormComponent, ProfileVerificationsListComponent, ProfileVerificationsBaseComponent, ProfileVerificationsMobilePageComponent, ProfileVerificationRemindersListComponent
    ]
})
export class ProfileVerificationsModule { }
