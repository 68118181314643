import { AfterViewInit, Directive, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import { MapLayerSourceEnum } from 'Enums/MapLayerSource.enum';
import { MapLayerTypeEnum } from 'Enums/MapLayerType.enum';
import { MapToolsEnum } from 'Enums/MapTools.enum';
import { MapLayer } from 'Models/Configuration/Maps/MapLayer.model';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { MapRouteSegment } from "Models/Maps/MapRouteSegment.model";
import { AppUser } from 'Models/Security/AppUser.model';
import { Collection, ImageTile, Map, View } from 'ol';
import ol_control_Bar from 'ol-ext/control/Bar';
import ol_control_Button from 'ol-ext/control/Button';
import ol_control_LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import ol_control_TextButton from 'ol-ext/control/TextButton';
import ol_control_Toggle from 'ol-ext/control/Toggle';
import { defaults as control_defaults, FullScreen, MousePosition, OverviewMap } from 'ol/control';
import { Coordinate, CoordinateFormat, createStringXY, format } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { Extent, containsCoordinate as extent_containsCoordinate } from 'ol/extent';
import { Interaction, defaults as interaction_defaults } from 'ol/interaction';
import { Group, Layer, Tile } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { unByKey } from 'ol/Observable';
import { Pixel } from 'ol/pixel';
import * as Proj from 'ol/proj';
import { BingMaps, XYZ } from 'ol/source';
import { Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { LocationService } from "Services/Location.service";
import { MapSearchService } from 'Services/MapSearchService';
import { MapChangesTileLayer } from 'Shared/Components/Maps/Layers/MapChangesTileLayer';
import { MapFeaturesTileLayer } from 'Shared/Components/Maps/Layers/MapFeaturesTileLayer';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { MapToolService } from 'Shared/Components/Maps/MapToolService';
import { MeasureDistanceTool } from 'Shared/Components/Maps/Tools/MeasureDistanceTool';
import { PushPinTool } from 'Shared/Components/Maps/Tools/PushPinTool';
import { SearchMapButtonComponent } from './Controls/Search/SearchMapButton.component';
import { ToggleBaseMapControl } from "./Controls/ToggleBaseMapControl";
import { GeometryTransformer } from './GeometryTransformer';
import { RouteVectorLayer } from "./Layers/RouteVectorLayer";
import ContextMenu from './ol-contextmenu/main';
import { InformationTool } from './Tools/InformationTool';
import { ShowTooltipsTool } from './Tools/ShowTooltipsTool';

@Directive()
export abstract class MapBaseComponent implements OnInit, OnDestroy, AfterViewInit {
    protected TopControlBar: any;           //  type = ol.control.Bar, but there are no typescript mappings currently available for this
    protected LeftControlBar: any;        //  type = ol.control.Bar, but there are no typescript mappings currently available for this
    protected AllowedMapTools: MapToolsEnum[];

    private _GeoServerTileUrl: string;
    protected _MapBounds: LatLonBounds;
    private _AlternateBaseMap: string;
    private _BingApiKey: string;
    private _MapHavePendingBaseMapChanges: boolean = false;

    private _MeasureDistanceTool: MeasureDistanceTool;
    private _PushPinTool: PushPinTool;
    private _InformationTool: InformationTool;
    private _ShowTooltipsTool: ShowTooltipsTool;

    //  Fires when this component is destroyed.  The ProjectMapView and DigsiteMap also track their own
    //  private subscriptions that are used to manage subscriptions on objects that can change without the entire
    //  component being destroyed.
    protected MapBaseDestroyed: Subject<void> = new Subject();

    private _Map: Map;
    public get Map(): Map { return this._Map; }

    private _MapToolService: MapToolService;
    public get MapToolService(): MapToolService { return this._MapToolService; }

    public MapSearchIsVisible: boolean = false;

    //  If/when we support other types of layers (like WFS?), may need a more general class for this.  But at the moment,
    //  all overlay layers are served using a MapFeaturesTileLayer.
    private _MapOverlayLayers: MapFeaturesTileLayer[];
    protected get MapOverlayLayers(): MapFeaturesTileLayer[] { return this._MapOverlayLayers; }

    //  Must be overridden if the map should allow external users to see the map changes (i.e. the Registration map allows this but ticket map does not)
    protected get AllowMapChangesLayerForExternalUsers(): boolean { return false; }

    private _MapChangesTileLayer: MapChangesTileLayer = null;
    private _MapChangesTileLayerVisibilityChangedEventsKey: EventsKey = null;
    protected get MapChangesTileLayer(): MapChangesTileLayer { return this._MapChangesTileLayer; }

    private _ShowMapChanges: boolean = false;
    public get ShowMapChanges(): boolean { return this._ShowMapChanges; }
    public set ShowMapChanges(show: boolean) {
        if (show === this._ShowMapChanges)
            return;     //  without this check, unchecking does not refresh the map about the layer being removed!?!?!?!

        if (this._MapChangesTileLayer)
            this._MapChangesTileLayer.Layer.setVisible(show);
        this._ShowMapChanges = show;
    }

    @ViewChild(SearchMapButtonComponent)
    protected MapSearchButton: SearchMapButtonComponent;

    //  Event that fires when the map is repositioned.
    private _OnMapRepositioned: Subject<void> = new Subject();
    private _MapMoveEndEventsKey: EventsKey = null;
    private _ContextMenuBeforeOpenEventsKey: EventsKey = null;
    public get OnMapRepositioned(): Subject<void> { return this._OnMapRepositioned; }

    private _LocationService = inject(LocationService);

    constructor(protected CommonService: CommonService, protected MapSearchService: MapSearchService) {
        this._MapToolService = new MapToolService(MapSearchService, CommonService.SettingsService, CommonService.Dialog, CommonService.AuthenticationService);
    }

    public ngOnInit(): void {
        //  Attempt to init the map.  If not visible (i.e. it's in a tab) then this will not fully initialize it.
        //  In that case, the page that is hosting this map must call OnMapVisible() which will then re-initialize.
        this.InitMap();

        //  Needed to close the context menu because it doesn't do this itself!
        document.addEventListener('click', this._OutsideClickListener);

        //  This finds all button elements inside the map and sets their tabindex to -1.  This prevents the tab
        //  key from switching focus in to the map instead of to the next elements on our input forms.
        if (this.Map) {     //  Should always be set right now - but had an error logged where it wasn't!
            const buttonElements = this.Map.getTargetElement().querySelectorAll("button") as NodeList;
            buttonElements.forEach(n => (n as any).setAttribute("tabindex", -1));
        }

        this.CommonService.DeviceDetectorService.Changed.pipe(takeUntil(this.MapBaseDestroyed)).subscribe(() => {
            //  This is necessary to make sure the map size is set for the new browser size.  Especially on a phone when
            //  rotating the device.  It might be a little excessive since it also calls ZoomToBestFit() but that is sometimes
            //  needed also and it seems like it still performs pretty well.
            this.OnMapVisible();
        });
    }

    public ngAfterViewInit(): void {
        this.MapSearchButton.SetMapBase(this);
    }

    public ngOnDestroy(): void {
        //  This is all happening so that when the map component is destroyed by Angular, everything is dereferenced so that
        //  it is all garbage collected properly.  There are some issues with ol-ext and possibly with open layers & Angular
        //  that prevent that from happening if we don't do this.
        //  Much of what is being done here is probably not necessary and is here because I was chasing so many different things I
        //  was seeing.  But once I got to the point where the entire openlayers map was being 100% destroyed, I quit while I was ahead.
        //  To find leaks:
        //  1) Use in-cognito mode (to reduce browser extensions)
        //  2) Do stuff in the app to show/hide the map and then return to a normal state
        //  3) Open dev console and clear the console (otherwise, Chrome debugging may hold on to memory references!)
        //  4) On Memory tab, force a garbage collection
        //  5) Capture snapshot
        //  6) Search for "Map" - should not see one that is the openlayers map.  If there is one, it will be near the top of the list.
        //     Can also search for "Layer", "Tool", "Edit" to find various map related components.
        document.removeEventListener('click', this._OutsideClickListener);
        this._OutsideClickListener = null;

        if (this._MapMoveEndEventsKey) {
            unByKey(this._MapMoveEndEventsKey);
            this._MapMoveEndEventsKey = null;
        }
        if (this._ContextMenuBeforeOpenEventsKey) {
            unByKey(this._ContextMenuBeforeOpenEventsKey);
            this._ContextMenuBeforeOpenEventsKey = null;
        }
        if (this._MapChangesTileLayerVisibilityChangedEventsKey) {
            unByKey(this._MapChangesTileLayerVisibilityChangedEventsKey);
            this._MapChangesTileLayerVisibilityChangedEventsKey = null;
        }

        //  Unregister render handlers in the map.  See: https://github.com/openlayers/openlayers/issues/10689
        if (this._Map) {
            this._Map.setTarget(null);

            this._Map.getControls().getArray().slice().forEach(c => this.DestroyControl(c));

            this._Map.getInteractions().forEach(i => {
                if (i)
                    i.setMap(null);
            })

            if (this._MeasureDistanceTool)
                this._MeasureDistanceTool = this._MeasureDistanceTool.OnDestroy();

            if (this._PushPinTool)
                this._PushPinTool = this._PushPinTool.OnDestroy();

            if (this._InformationTool)
                this._InformationTool = this._InformationTool.OnDestroy();

            if (this._ShowTooltipsTool)
                this._ShowTooltipsTool = this._ShowTooltipsTool.OnDestroy();

            if (this._MapOverlayLayers) {
                this._MapOverlayLayers.forEach(l => l.OnDestroy());
                this._MapOverlayLayers = [];
            }

            this._ContextMenu.setMap(null);
            this._ContextMenu.clear();
            this._ContextMenu = null;

            this.TopControlBar = null;
            this.LeftControlBar = null;

            this._Map = null;
        }

        this._MapToolService = null;
        this.MapSearchService = null;

        this.MapSearchButton = null;

        this.MapBaseDestroyed.next();
        this.MapBaseDestroyed.complete();
    }

    private DestroyControl(control: any): void {
        if (!control)
            return;

        if (control instanceof ol_control_Bar) {
            const bar = control as ol_control_Bar;
            bar.getControls().slice().forEach(a => this.DestroyControl(a));
        }

        if (control instanceof ol_control_Toggle) {
            const toggle = control as ol_control_Toggle;

            toggle.setInteraction(null);

            const subbar: ol_control_Bar = toggle.getSubBar();
            if (subbar)
                subbar.getControls().slice().forEach(a => this.DestroyControl(a));
        }

        control.setMap(null);

        this._Map.removeControl(control);
    }

    /**
     * Call this method when the map becomes visible.  Only necessary if the map is not initially visible
     * (i.e. it's sitting in a tab).  
     */
    public OnMapVisible(): void {
        if (!this.Map || !this.Map.getTargetElement())
            this.InitMap();
        else {
            this.Map.updateSize();
            if (this.IsMapVisible())
                this.InitVisibleMap();
        }

        setTimeout(() => this.ZoomToBestFit());
    }

    private IsMapVisible(): boolean {
        if (!this.Map)
            return false;

        //  If offsetHeight is 0, it's not visible
        const targetElem = this.Map.getTargetElement() as any;
        if (!targetElem || !targetElem.offsetHeight)
            return false;

        return true;
    }

    //Needs to be true because we need it in the OnInit
    @ViewChild('MapHost', { static: true }) _MapHost;

    private InitMap(): void {
        //  Make sure the current user info is fetched before doing this.  We need the current One Call, some
        //  settings, and permissions to be able to configure the map correctly.  If we are refreshing a page
        //  that contains the map, we need to make sure those settings are loaded!
        this.CommonService.AuthenticationService.CurrentUserObserver()
            .pipe(take(1))
            .subscribe(user => {
                this.InitMapForUser(user);
        });
    }

    private InitMapForUser(user: AppUser): void {
        //  Uses png8 image format because it generates images that are half the size of png with no difference in quality.
        //  png is only useful for satellite images.
        this._GeoServerTileUrl = this.CommonService.SettingsService.GeoServerBaseUrl + '/gwc/service/tms/1.0.0/map:' + user.CurrentOneCallCenterCode + '@EPSG%3A900913@png8/{z}/{x}/{-y}.png8?mapUpdate=' + (user.OneCallCenterSettings.MapUpdateIdentifier || "1");

        this._MapBounds = user.MapBounds;

        this._AlternateBaseMap = user.OneCallCenterSettings.MapAlternateBaseMap;
        this._BingApiKey = user.OneCallCenterSettings.MapBingApiKey;
        this._MapHavePendingBaseMapChanges = (user.OneCallCenterSettings.MCD_HavePendingBaseMapChanges === "true");

        //  TODO: This should not be here.  The allowed map tools could be configured differently for different roles - especially
        //  between a ticket creation role and a service area role (where one applies to ticket map tools and the other to
        //  registration map tools).  Need to move this in to the TicketConfiguration & RegistrationMapParams.
        this.AllowedMapTools = user.AllowedMapTools;

        this._Map = new Map({
            //  Need to use _MapHost.nativeElement so that we find the element that is actually inside
            //  *THIS* map.  If we just specify it as a string, it will find the first element on the entire
            //  page that matches that "id".  Which prevents us from having more than 1 map at at time.
            //  So we can't show a map in a dialog or it will be loaded into the map on the main page!
            //target: 'MapHost',                    //  Matches on element with: id="MapHost"
            target: this._MapHost.nativeElement,    //  Matches on element with #MapHost

            layers: this.BuildBaseMapLayers(user.MapLayers),

            interactions: this.BuildInteractions(),

            controls: this.BuildMapControls(),

            //  default projection is EPSG: 3857 which is equivalent to EPSG: 900913
            //  See trivia comment in question: https://gis.stackexchange.com/questions/34276/whats-the-difference-between-epsg4326-and-epsg900913?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa -->
            view: new View({
                //  Note: Map will not load until center & zoom or extent are set!  (ZoomToBestFit() will do this)
                minZoom: 6,
                maxZoom: 20,
                constrainResolution: true,      // constraint the zoom levels to only integer's.  Fixes issues with the tile images being scaled and looking like crap.
                enableRotation: false
            })
        });

        this.AddMapOverlayLayers(user);

        //  Local users always allowed to see the pending map changes.  External users only allowed if
        //  is AllowMapChangesLayerForExternalUsers (i.e. the Registration Map allows this).
        if (this.AllowMapChangesLayerForExternalUsers || user.IsLocalUser)
            this.AddMapChangesLayer();

        this.ConfigureLayerSwitcher(user.MapLayers);

        if (this.IsMapVisible())
            this.InitVisibleMap();
        else {
            //  If not visible now, it's probably about to be made visible in the message change detection pass.
            setTimeout(() => this.OnMapVisible());
        }
    }

    protected MapIsInitialized: boolean = false;

    private InitVisibleMap(): void {
        if (this.MapIsInitialized || !this.Map)
            return;

        this.MapIsInitialized = true;

        //  The top and bottom control bars - can add additional bars to these as needed
        //  "iq-top" needed on this control bar for it to be repositioned when the map search is open - see ".map-search-visible" in Map.scss
        this.TopControlBar = new ol_control_Bar({ toggleOne: true, group: false, className: "iq-control-bar-container iq-top" }); //  iq-centered-control-bar-container
        this.TopControlBar.setPosition("top");
        this.Map.addControl(this.TopControlBar);
        this.LeftControlBar = new ol_control_Bar({ toggleOne: true, group: false/*, className: "iq-control-bar-container"*/ });
        this.LeftControlBar.setPosition("top-left");
        this.LeftControlBar.setVisible(false);      //  initially set to not be visible - otherwise, when it's empty, it shows the background color of the bar for the amount of padding in the bar!
        this.Map.addControl(this.LeftControlBar);

        //  Do any custom map configuration
        const customLayersLoadingAsync = this.OnMapInitialized(this.Map);

        this.BuildControlBars();

        //  MousePosition control is added here (and not when _Map is first contructed) because otherwise, it's possible for it to receive
        //  an event before the view has finished initializing.  And the event handler is not correctly checking that inside OpenLayers so it
        //  throws a javascript error.  To reproduce that, click on a ticket from the ticket list while moving the mouse around where the map gets displayed.
        this._Map.addControl(this.CreateMousePositionControl());

        this._MapMoveEndEventsKey = this.Map.on('moveend', () => {
            this._OnMapRepositioned.next();

            //  For debugging the current zoom level:
            //  console.warn("CurrentZoom=", this.Map.getView().getZoom(), this.Map.getView().getResolution());
        });

        //  If we have custom layers being loaded async, delay setting the initial map position so we don't
        //  flash to the full extents and then zoom to the custom layer.
        if (!customLayersLoadingAsync)
            this.ZoomToBestFit();
        else {
            setTimeout(() => {
                if (this.Map)
                    this.Map.updateSize();
            });            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)
        }
    }

    private CreateMousePositionControl(): MousePosition {
        //  If the One Call shows coords as lat then lon, need to reverse the order of the mouse position coordinates to match
        let coordFormat: CoordinateFormat;
        if (this.CommonService.SettingsService.LatLonCoordinateEnteredLatFirst) {
            //  Same as the built-in createStringXY but reversed: https://github.com/openlayers/openlayers/blob/4fe091c02df92668ac5ee53d371393b95258d907/src/ol/coordinate.js#L143
            coordFormat = function (coordinate) {
                return format(coordinate, '{y}, {x}', 4);
            }
        } else
            coordFormat = createStringXY(4);

        return new MousePosition({
            coordinateFormat: coordFormat,
            projection: MapConstants.LATLON_PROJECTION
            //  Can change the default target and css class name like this (to position it somewhere else - like outside of the map)
            //className: 'custom-mouse-position',
            //target: document.getElementById('mouse-position'),
        });
    }

    private BuildBaseMapLayers(mapLayers: MapLayer[]): BaseLayer[] {
        const layers = mapLayers
            .filter(ml => ml.MapLayerType === MapLayerTypeEnum.BaseMap)
            .sort((a, b) => b.Order - a.Order)      //  ascending numeric sort
            .map(ml => {
                switch (ml.MapLayerSource) {
                    case MapLayerSourceEnum.Geoserver:
                        return this.CreateGeoserverBaseMapLayer(ml);
                    case MapLayerSourceEnum.HERETileService:
                        return this.CreateHERESatelliteBaseMapLayer(ml);
                    case MapLayerSourceEnum.BingTileService:
                        return this.CreateBingSatelliteBaseMapLayer(ml);
                    case MapLayerSourceEnum.ESRIArcGISTileService:
                        return this.CreateESRIArcGISTileBaseMapLayer(ml);
                    default:
                        console.error("Unhandled MapLayerSource: ", ml.MapLayerSource);
                        return null;
                }
            })
            .filter(l => l !== null);

        //  Can show Google Maps like this.  But it's using their direct tile service and not using their own javascript
        //  map control.  So it is a violation of their usage agreement - so cannot be used in production - test only!
        //new ol.layer.Tile({
        //    name: MapConstants.LAYERNAME_BING,
        //    visible: false,
        //    preload: Infinity,
        //    source: new ol.source.XYZ({
        //        url: 'http://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}'     //  Google Roads
        //        //url: 'http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}'         //  Google Satellite (no roads)
        //        //url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'                //  Google Satellite and roads
        //    })
        //})
        //  Information on HERE Map Tiles: https://developer.here.com/documentation/map-tile/topics/introduction.html
        //      Pricing: https://developer.here.com/plans

        //  These are put in a group for the LayerSwitcher
        const group = new Group({ layers: layers });

        //  Needed for LayerSwitcher
        group.set("title", "Base Map");
        group.set("baseLayer", true);
        group.set("openInLayerSwitcher", true);                 //  Causes group to be expanded in the LayerSwitcher
        group.set("displayInLayerSwitcher", true);
        return [group];
    }

    private CreateGeoserverBaseMapLayer(mapLayer: MapLayer): Layer<any> {
        //  mapLayer can be null if being created for the Overview map
        const visible = mapLayer ? mapLayer.IsInitiallyVisible : true;

        const layer = new Tile({
            visible: visible,
            source: new XYZ({
                url: this._GeoServerTileUrl
            })
        });

        layer.set("name", mapLayer?.Name ?? "Street Map");
        layer.set("title", mapLayer?.Name ?? "Street Map");
        layer.set("baseLayer", true);                  //  For LayerSwitcher and ToggleBaseMapControl
        layer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher

        return layer;
    }

    private CreateHERESatelliteBaseMapLayer(mapLayer: MapLayer): Layer<any> {
        //  HERE satellite maps
        //  Developer account page: https://developer.here.com/projects/PROD-8110aacd-f5d7-477c-b40e-222bae229ddd
        //  URL/request info: https://developer.here.com/documentation/raster-tile-api/api-reference.html
        //  Old links: https://developer.here.com/documentation/map-tile/topics/request-constructing.html#request-constructing__table-basic-request-elements
        //             https://developer.here.com/documentation/map-tile/topics/request-constructing.html#request-constructing__table-basic-request-elements

        //  HERE is replacing AppID/AppCode with just ApiKey.  We support both but prefer ApiKey if that is configured.
        //  Formats = png, png8, jpg.  That's in order of decreasing size.  Visually, image quality of jpg is same as png and it's ~20kb/tile for jpg vs 130+kb for png.
        //      * jpg is only recommended for Satellite and Hybrid though.
        let hereURL: string;
        const key = mapLayer.Params?.Here?.ApiKey;
        if (!key)
            return null;        //  No key or no HERE params!  Cannot create layer.

        //  This uses the v3 api.
        //  v2 -> v3 migration guide: https://www.here.com/docs/bundle/raster-tile-api-migration-guide/page/README.html
        //  OpenAPI spec: https://maps.hereapi.com/v3/openapi
        //  HERE info: https://maps.hereapi.com/v3/info?apiKey=u1JpazQ4NoVarkzAbl_7TBlULo4ZRV7f68cn3flmEWA
        //  This gives all of the available options below.
        //  As of 11/9/2023:
        //      imageFormats: jpeg, png, png8
        //      imageSizes: 256, 512
        //      projections: mc
        //      resources: background, base, blank, label
        //      styles: explore.day, explore.night, explore.satellite.day, lite.day, lite.night, lite.satellite.day, logistics.day, satellite.day
        //      zoomLevels: max=20, min=0
        hereURL = "https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?apiKey=" + key;

        //  Use 'explore.satellite.day' for hybrid view using v3 api - has larger/easier to read labels
        //  Other options are shown for the "style" parameter below.
        const scheme = mapLayer.Params.Here.Scheme ?? "explore.satellite.day";
        hereURL += "&style=" + scheme;

        //  Use larger size to reduce the number of tile requests
        hereURL += "&size=512";

        //  400=high res - this shows larger/easier to read labels.  100 = much smaller labels.
        const ppi = mapLayer.Params.Here.Resolution ?? "400";
        hereURL += "&ppi=" + ppi;

        if (scheme.startsWith("explore.")) {
            //  When using one of the "explore" styles, we can specify "features" to include with the images.
            //  Available features: https://maps.hereapi.com/v3/features?apiKey=u1JpazQ4NoVarkzAbl_7TBlULo4ZRV7f68cn3flmEWA
            //  The default for each feature is the first mode in the list.  Only pois are enabled by default so turn them off.
            //  The other options are not needed and all default to off.
            hereURL += "&features=pois:disabled";
        }

        const layer = new Tile({
            visible: mapLayer.IsInitiallyVisible,
            source: new XYZ({
                url: hereURL,
                attributions: 'Map Tiles &copy; ' + new Date().getFullYear() + ' <a target="_blank" href="http://here.com">HERE</a>',
                tileSize: 512,      //  Url also specifies 512 - this reduces number of requests for typical map from 9 requests down to 4
                tileLoadFunction: (tile, src) => this.TileLoadFunctionWithReattempt(tile as ImageTile, src)
            })
        });

        layer.set("name", mapLayer?.Name ?? "Satellite");
        layer.set("title", mapLayer?.Name ?? "Satellite");
        layer.set("baseLayer", true);                  //  For LayerSwitcher and ToggleBaseMapControl
        layer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher

        return layer;

        //  Satellite images from ESRI.  For free!  But 3-5 years old and resolution may not be as good as HERE
        //  https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer
        //var worldImageryLayer = new Tile({
        //    visible: false,
        //    source: new XYZ({
        //        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        //        maxZoom: 19
        //    })
        //});
        //worldImageryLayer.set("name", MapConstants.LAYERNAME_SATELLITE);
        //worldImageryLayer.set("title", "Satellite");
        //worldImageryLayer.set("baseLayer", true);                  //  For LayerSwitcher and ToggleBaseMapControl
        //worldImageryLayer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher
        //layers.push(worldImageryLayer);

        //  Other possible options from: https://www.trailnotes.org/FetchMap/TileServeSource.html
        //  Satellite images from the USGS: https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{Z}/{Y}/{X}
        //      Works but does not support low zoom levels - returns 404's'
    }

    //  Custom Tile Load Function that will automatically re-attempt 429/Too Many Requests errors.
    //  From: https://openlayers.org/en/latest/apidoc/module-ol_ImageTile-ImageTile.html
    //  And added cleanup of the failed urls stashed in _TileFetchRetries - OpenLayers version never does that so will build up endlessly!
    private _TileFetchRetries = {};
    private TileLoadFunctionWithReattempt(tile: ImageTile, src: string): void {
        const image = (tile as ImageTile).getImage();
        fetch(src)
            .then((response) => {
                //  Only need to retry a 429/Too Many Requests.
                if (response.status === 429) {
                    this._TileFetchRetries[src] = (this._TileFetchRetries[src] || 0) + 1;
                    if (this._TileFetchRetries[src] <= 3) {
                        //  Retry after 1, 2, and 3 seconds.  Tile is set to "error" by the .catch() handler which allows tile.load() to re-queue a download of this tile.
                        setTimeout(() => tile.load(), this._TileFetchRetries[src] * 1000);
                    }  else
                        delete this._TileFetchRetries[src];     //  Do not reattempt after 3 tries
                    return Promise.reject();
                }

                if (this._TileFetchRetries[src])
                    delete this._TileFetchRetries[src];
                return response.blob();
            })
            .then((blob) => {
                const imageUrl = URL.createObjectURL(blob);
                (image as any).src = imageUrl;
                setTimeout(() => URL.revokeObjectURL(imageUrl), 5000);
            })
            .catch(() => tile.setState(3));            // error
    }

    private CreateBingSatelliteBaseMapLayer(mapLayer: MapLayer): Layer<any> {
        //  Bing Maps example (also shows other imagerySets): https://openlayers.org/en/latest/examples/bing-maps.html
        const layer = new Tile({
            visible: mapLayer.IsInitiallyVisible,
            //preload: Infinity,
            source: new BingMaps({
                key: this._BingApiKey,
                //  Values for imagerySet: Aerial, AerialWithLabels, Road, RoadOnDemand
                imagerySet: 'AerialWithLabels'
            })
        });

        layer.set("name", mapLayer?.Name ?? "Satellite");
        layer.set("title", mapLayer?.Name ?? "Satellite");
        layer.set("baseLayer", true);                  //  For LayerSwitcher and ToggleBaseMapControl
        layer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher

        return layer;
    }

    private CreateESRIArcGISTileBaseMapLayer(mapLayer: MapLayer): Layer<any> {
        //  The ESRI ArcGIS server has a main project page that describes it's capabilities.
        //  i.e. https://tiles.arcgis.com/tiles/RvqSyw3diI7dTKo5/arcgis/rest/services/SC_2020_RGB/MapServer
        //  At the top of that, there are links to the REST API documentation including a JSON link with the capabilities.
        //  When adding/configuring a new MapLayer, we may want to use that to figure out the parameters needed to connect to it correctly.
        //  What we are doing below just happened to "work".  It uses the same projection and didn't need anything custom specified.
        //  Another link showing how to set a custom grid: https://stackoverflow.com/questions/64632870/how-to-convert-weird-esri-tile-xyz-format-to-xyz
        //  The server (for SC) also did not support using the TileArcGISRest source.  It would return a 404 for the tile requests.
        //  Which we would not want to use anyway - that causes ArcGIS to build all tiles on demand.

        //  i.e. https://tiles.arcgis.com/tiles/RvqSyw3diI7dTKo5/arcgis/rest/services/SC_2020_RGB/MapServer/tile/{z}/{y}/{x}
        let url = mapLayer.Url;
        if (!url.endsWith("/"))
            url += "/";
        url += "tile/{z}/{y}/{x}";

        const tileSize = 256;           //  If server supports larger, should add this to mapLayer.Params

        const layer = new Tile({
            visible: mapLayer.IsInitiallyVisible,
            source: new XYZ({
                url: url,
                tileSize: tileSize
            })
        });

        layer.set("name", mapLayer.Name);
        layer.set("title", mapLayer.Name);
        layer.set("baseLayer", true);                  //  For LayerSwitcher and ToggleBaseMapControl
        layer.set("displayInLayerSwitcher", true);     //  For LayerSwitcher

        return layer;
    }

    //  TODO: May want to also configure base map layers in our MapLayers to make them more configurable.
    //  Then change this to AddMapLayers and add support for creating the Base Map and Satellite layers here.
    private AddMapOverlayLayers(user: AppUser): void {
        if (!user.MapLayers || (user.MapLayers.length === 0))
            return;

        this._MapOverlayLayers = [];
        user.MapLayers
            .filter(mapLayer => (mapLayer.IsInitiallyVisible || mapLayer.CanToggleVisibility) && mapLayer.MapFeatureTypes && (mapLayer.MapFeatureTypes.length > 0))
            .forEach(mapLayer => {
                //  If/when we support other types of layers (like WFS?), may need a more general class for this.  But at the moment,
                //  all overlay layers are served using a MapFeaturesTileLayer.
                this._MapOverlayLayers.push(new MapFeaturesTileLayer(this._Map, this.CommonService.SettingsService.ApiBaseUrl, user.CurrentOneCallCenterCode, mapLayer));
            });
    }

    private AddMapChangesLayer(): void {
        if (!this._MapHavePendingBaseMapChanges)
            return;

        const mapChangesTileURL = this.CommonService.SettingsService.ApiBaseUrl + "/Maps/Tiles/MapChanges/{z}/{x}/{y}";

        this._MapChangesTileLayer = new MapChangesTileLayer(this._Map, mapChangesTileURL, this.CommonService.AuthenticationService);

        //  This keeps the "Show Pending Map Changes" button on the registration page in-sync with the layer switcher
        this._MapChangesTileLayerVisibilityChangedEventsKey = this._MapChangesTileLayer.Layer.on('change:visible', (event) => {
            this._ShowMapChanges = !event.oldValue;
        });
    }

    protected BuildInteractions(): Collection<Interaction> {
        //  interaction.defaults: https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
        return interaction_defaults();
    }

    private _ContextMenu: ContextMenu;
    private _OutsideClickListener = () => {
        if (this._ContextMenu)
            this._ContextMenu.closeMenu();
    }

    private BuildMapControls(): any {
        this._ContextMenu = new ContextMenu({
            //  ContextMenu does not support "auto" (it displays but internal calculations are then all f'd up).
            //  May need to get this from protected property if 200 doesn't work everywhere.
            width: 230,
        });

        //  ignore needed because of this issue: https://github.com/jonataswalker/ol-contextmenu/issues/233
        //  @ts-ignore
        this._ContextMenuBeforeOpenEventsKey = this._ContextMenu.on('beforeopen', (evt) => this.OnContextMenuBeforeOpen(evt));

        //  Do not add the MousePosition here - needs to be added after the map is visible or can get events fired that cause a javascript error
        return control_defaults({ attributionOptions: { collapsible: false } })
            .extend([
                new OverviewMap({
                    layers: [ this.CreateGeoserverBaseMapLayer(null) ]
                }),
                new FullScreen(),
                this._ContextMenu
            ]);
    }

    private ConfigureLayerSwitcher(mapLayers: MapLayer[]): void {
        const numBaseMapLayers = mapLayers.filter(ml => ml.MapLayerType === MapLayerTypeEnum.BaseMap).length;
        const numOverlayerLayers = mapLayers.filter(ml => ml.MapLayerType !== MapLayerTypeEnum.BaseMap).length;
        if ((numBaseMapLayers < 2) && (numOverlayerLayers === 0))
            return;     //  No layer switcher needed of any kind - we only have a single layer!

        if ((numBaseMapLayers === 2) && (numOverlayerLayers === 0)) {
            //  Only have 2 base map layer group so just use the Toggle.
            this._Map.addControl(new ToggleBaseMapControl());
            return;
        }

        //  Otherwise, we have more than 2 base map layers or we have overlay layers.  Need to use the LayerSwitcher.

        //  There are more configurations in Map.scss.  Quite a few things that should be options can only be hidden via css...
        //  Code: https://github.com/Viglino/ol-ext/blob/master/src/control/LayerSwitcher.js

        const layerSwitcher = new ol_control_LayerSwitcher({
            mouseover: true,        //  Opens layer switched on mouse hover

            //  Allow user to drag layers around to change order.  May be useful, but then it allows moving layers to be below the base maps.
            //  Have that hidden via css for basemap layers, but no way to prevent another layer from being dragged below.
            reordering: false,
            displayInLayerSwitcher: function (layer) {
                //  We override this default such that layers are opt-in where the default is opt-out
                //  https://github.com/Viglino/ol-ext/blob/master/src/control/LayerSwitcher.js
                return (layer.get('displayInLayerSwitcher') === true);
            }
        });

        layerSwitcher.setHeader("<div class='primary-color' style='font-size:large; text-decoration:underline; padding-bottom:10px'>Layers:<div>");

        //  ascii codes and symbols w / html representation: http://www.theasciicode.com.ar/extended-ascii-code/congruence-relation-symbol-ascii-code-240.html -->
        const labelNode = document.createElement("div");
        labelNode.innerHTML = "&equiv;";
        layerSwitcher.button.appendChild(labelNode);
        layerSwitcher.button.className = "ol-control";

        this._Map.addControl(layerSwitcher);
    }

    protected BuildControlBars(): void {

        //  Font-awesome icons: https://fontawesome.com/icons?d=gallery
        //  Material icons: https://material.io/tools/icons/?style=baseline
        //      Material style info: http://google.github.io/material-design-icons/#icon-font-for-the-web
        //      Reference like this: <i class="material-icons">timeline</i>
        //          * Our project has a font-size of 14px which may be too small - can add new style tags to resize the icons.

        this._MeasureDistanceTool = new MeasureDistanceTool(this.Map, this.MapToolService);
        this._InformationTool = new InformationTool(this);
        this._ShowTooltipsTool = new ShowTooltipsTool(this);

        if (this.CommonService.DeviceDetectorService.IsDesktop) {
            //  Show Tooltips Tool
            const showTooltipsToggle = new ol_control_Toggle({
                html: '<i class="far fa-comment-alt" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
                title: 'Show Map Features at the current cursor position',
                interaction: this._ShowTooltipsTool.Interaction,
                active: localStorage.getItem("hideMapTooltips") === null,
                onToggle: function (on) {
                    try {
                        if (on)
                            localStorage.removeItem("hideMapTooltips");
                        else
                            localStorage.setItem("hideMapTooltips", "1");
                    } catch {
                        //  Ignore error if local storage is full!
                    }
                }
            });
            this.TopControlBar.addControl(showTooltipsToggle);
        } else {
            const contextMenuButton = new ol_control_Button({
                html: '<i class="fas fa-ellipsis-vertical" tabIndex="-1"></i>',
                title: 'Open actions menu',
                handleClick: (evt: PointerEvent) => {
                    if (this._ContextMenu.isOpen())
                        this._ContextMenu.closeMenu();
                    else {
                        //  Place the context menu right below the "..." button in the toolbar
                        //  The css to position the top toolbar is a little weird.  If the MapSearch is currently visible, the "left" is calculated in Map.scss (search for .map-search-visible).
                        //  Otherwise, the default positioning sets left to 50% and then translates that to -width/2.  Which means the left value we get is the 50% value and is not translated
                        //  so we have to subtract half the width.
                        const toolbarPosition = this.GetDocumentOffsetPosition(this.TopControlBar.element);
                        const left = toolbarPosition.left - (this.MapSearchButton.ShowPanel ? 0 : (this.TopControlBar.element.offsetWidth / 2));
                        const openEvent = new MouseEvent(evt.type, {
                            clientX: left,
                            clientY: toolbarPosition.top + this.TopControlBar.element.offsetHeight + 10     //  +10 because the context menu subtracts 10 for some reason
                        });
                        this._ContextMenu.handleContextMenu(openEvent);
                    }
                }
            });
            this.TopControlBar.addControl(contextMenuButton);
        }

        //  Information Tool
        const informationToggle = new ol_control_Toggle({
            html: '<i class="fas fa-info" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Show Map Information for Location',
            interaction: this._InformationTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            bar: new ol_control_Bar({
                controls: [
                    new ol_control_TextButton({
                        html: 'clear',
                        //title: "Clear",
                        handleClick: () => this._InformationTool.Clear()
                    }),
                    new ol_control_TextButton({
                        html: 'done',
                        //title: "Done",
                        handleClick: () => this._InformationTool.Interaction.setActive(false)
                    })
                ]
            })
        });
        this.TopControlBar.addControl(informationToggle);

        //  Measure Distance Tool
        //  ol.control.Toggle: https://github.com/Viglino/ol-ext/blob/master/src/control/Toggle.js
        const measureDistanceToggle = new ol_control_Toggle({
            html: '<i class="fas fa-ruler" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
            title: 'Measure Distance',
            interaction: this._MeasureDistanceTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            bar: new ol_control_Bar({
                controls: [
                    new ol_control_TextButton({
                        html: 'clear',
                        title: "Clear all measurements",
                        handleClick: () => this._MeasureDistanceTool.Clear()
                    }),
                    new ol_control_TextButton({
                        html: 'done',
                        title: "Stop measuring",
                        handleClick: () => this._MeasureDistanceTool.Interaction.setActive(false)
                    })
                ]
            })
        });
        this.TopControlBar.addControl(measureDistanceToggle);

        //  Push Pin Tool.  This is configurable on the Role because Idaho users kept thinking the Push Pins were saved with the ticket
        //  and they kept referencing the push pins in the locate instructions!
        if (this.AllowedMapTools.indexOf(MapToolsEnum.PushPins) >= 0) {
            this._PushPinTool = new PushPinTool(this.Map, this.MapToolService);
            const pushPinToggle = new ol_control_Toggle({
                html: '<i class="fas fa-map-marker-alt" ></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
                title: 'Add Push Pins',
                interaction: this._PushPinTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
                bar: new ol_control_Bar({
                    controls: [
                        new ol_control_TextButton({
                            html: 'clear',
                            title: "Clear all Push Pins",
                            handleClick: () => this._PushPinTool.Clear()
                        }),
                        new ol_control_TextButton({
                            html: 'done',
                            title: "Stop adding Push Pins",
                            handleClick: () => this._PushPinTool.Interaction.setActive(false)
                        })
                    ]
                })
            });
            this.TopControlBar.addControl(pushPinToggle);
        }

        if (this.AllowedMapTools.indexOf(MapToolsEnum.CurrentPosition) >= 0) {
            const currentLocationButton = new ol_control_Button({
                html: '<i class="fas fa-location-arrow"></i>',
                title: 'Position to your current location',
                handleClick: () => this.PositionToCurrentLocation()
            });
            this.TopControlBar.addControl(currentLocationButton);
        }
    }

    private GetDocumentOffsetPosition(el): { top: number, left: number} {
        let top = 0, left = 0;
        while (el !== null) {
            top += el.offsetTop;
            left += el.offsetLeft;
            el = el.offsetParent;
        }
        return { top, left };
    }

    public PositionToCurrentLocation(): void {
        this._LocationService.GetCurrentPosition().subscribe({
            next: result => {
                if (result.outOfBounds)
                    this.HandleCurrentPositionNotInMapBounds();
                else {
                    const pos = result.position;
                    const coord = Proj.transform([pos.coords.longitude, pos.coords.latitude], MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);
                    this.ZoomToCoordinate(coord, 17);
                }
            },
            error: error => this.HandleCurrentPositionError(error)
        });
    }

    /**
     * Override this to handle the current browser location not being within the One Call Centers map bounds.
     * An error dialog is always shown to the user by the LocationService with information about how to resolve.
     * @param error
     */
    protected HandleCurrentPositionError(error: GeolocationPositionError): void {
    }

    /**
     * Override this to handle the current browser location not being within the One Call Centers map bounds.
     * A warning is always displayed to the user by the LocationService.
     * By default, the map will be zoomed to the full extents.
     */
    protected HandleCurrentPositionNotInMapBounds(): void {
        this.ZoomToFullMapExtents();
    }

    /**
     * Override this to do any custom map configuration (i.e. add layers or controls).
     * Return true if custom layers are being loaded async and we should delay setting the initial map position.
     * @param map
     */
    protected abstract OnMapInitialized(map: Map): boolean;

    /**
     * Override this to change how to zoom to the best fit for the current map.  i.e. to zoom to the extents
     * of another layer.
     * The default implementation will zoom to the initial x/y/zoom based on the system configuration.
     */
    public ZoomToBestFit(): void {
        if (!this.Map)
            return;

        const extents = this.GetBestFitExtents();
        if (extents)
            this.ZoomToExtent(extents);
        else
            this.ZoomToFullMapExtents();
    }

    /**
     * Zoom to the full map extents (as configured in OneCallSettings).
     * */
    public ZoomToFullMapExtents(): void {
        setTimeout(() => {
            if (this.Map) {
                this.Map.updateSize();            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)

                //  1/29/2022: Not using initial/x/y/zoom any more - now using the MapBounds which come from the envelope of the State boundary(s).
                //  true value here makes it fit better for the state-level view (may extend off the view but leaves much less whitespace).
                this.ZoomToLatLonBounds(this._MapBounds, 0, true);
            }
        });
    }

    public ZoomToCoordinate(coord: Coordinate, zoom: number): void {
        if (!this.Map)
            return;     //  Map not initialized yet

        //  calculateExtent throws an exception if we have not positioned the map at all yet!
        try {
            const bounds = this.Map.getView().calculateExtent();
            if (extent_containsCoordinate(bounds, coord)) {
                this.Map.getView().animate({ center: coord, zoom: zoom });
                return;
            }
        } catch { /* ignore */ }

        this.Map.getView().setCenter(coord);
        this.Map.getView().setZoom(zoom);
    }

    public ZoomToLatLonBounds(bounds: LatLonBounds, padding: number = 0, nearest: boolean = false, maxZoom: number = undefined): void {
        if (bounds) {
            const latLonExtents = [bounds.MinX, bounds.MinY, bounds.MaxX, bounds.MaxY] as Extent;
            const mapExtents = GeometryTransformer.TransformExtent(latLonExtents, MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);
            if (mapExtents) {
                this.ZoomToExtent(mapExtents, padding, nearest, maxZoom);
                return;
            }
        }

        this.ZoomToFullMapExtents();
    }

    //  nearest is the OpenLayers name for the option.
    //  From their docs: If the view constrainResolution option is true, get the nearest extent instead of the closest that actually fits the view.
    //  Translation: When false, fit the entire extents in to the view snapped to the next zoom level that fits the extent.
    //               When true, fits the extents such that the smallest dimension exactly fits and the largest may extend out of view.
    public ZoomToExtent(extents: Extent, padding: number = 80, nearest: boolean = false, maxZoom: number = undefined): void {
        if (!extents)
            return;

        //  Timeout needed here in some cases - when we set the dig site when the map is not visible and then
        //  make the map visible.  With it, the map zooms in REALLY close on the dig site for some reason...
        setTimeout(() => {
            if (this.Map) {
                this.Map.updateSize();            //  Needed (in timeout) or map size may be wrong which affects drawing hit tests!  (try drawing circle that spans entire map - mouse won't snap to entire circle if this is an issue!)
                this.Map.getView().fit(extents, { padding: [padding, padding, padding, padding], nearest: nearest, maxZoom: maxZoom });
            }
        });
    }

    /**
     *  Returns the current view extents in Lat/Lon coordinates.
     */
    public CurrentViewExtents(): Extent {
        const extents = this.Map.getView().calculateExtent(this.Map.getSize());
        return GeometryTransformer.TransformExtent(extents, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION);
    }

    protected abstract GetBestFitExtents(): Extent;

    protected IsDrawingToolActive(): boolean {
        //  If we are currently drawing, disable the context menu so that it doesn't actually open
        //  Should not be possible for these tools to not be created yet, but saw some weird errors that looked like they were!
        if (this._MeasureDistanceTool && this._MeasureDistanceTool.IsActive())
            return true;
        if (this._PushPinTool && this._PushPinTool.IsActive())
            return true;
        if (this._InformationTool && this._InformationTool.IsActive())
            return true;
        return false;
    }

    private OnContextMenuBeforeOpen(event: { type: string, pixel: Pixel, coordinate: Coordinate }): void {
        //  If we are currently drawing, disable the context menu so that it doesn't actually open
        if (this.IsDrawingToolActive()) {
            //  Disabling the context menu does not stop the event propogation.  So it results in the
            //  browsers default menu showing!  So best we can do is show an empty menu and then close it right away.
            //this._ContextMenu.disable();
            this._ContextMenu.clear();
            setTimeout(() => {
                if (this._ContextMenu)
                    this._ContextMenu.closeMenu();
            });
            return;
        }

        //this._ContextMenu.enable();
        this.RebuildContextMenu(event);
    }

    protected RebuildContextMenu(event: { type: string, pixel: Pixel, coordinate: Coordinate }): void {
        //  Note: Can't track if the menu is dirty and needs to be rebuilt because we now have multiple
        //  "tools" that can cause this to happen.  Building this is fast so not worth the effort
        //  to try to track changes in all of those places.

        this._ContextMenu.clear();

        if (!this.MapSearchButton.IsEmpty()) {
            this._ContextMenu.extend([
                {
                    text: '<i class="fas fa-crosshairs"></i>Clear Map Search',
                    classname: 'iq-image-item',
                    callback: () => {
                        this.MapSearchButton.ClearSearchLayer();
                    }
                }
            ]);
        }

        const customItems = this.BuildContextMenuItems(event);

        if (customItems && (customItems.length > 0)) {
            this._ContextMenu.extend(customItems);
            this._ContextMenu.extend(['-']);      //  Adds a separator
        }

        //  Always add in the defaults (zoom in/out).
        this._ContextMenu.extend(this._ContextMenu.getDefaultItems());

        if (this._ContextMenu.isOpen()) {
            //  Context menu is already open.  As of v5 of ol-contextmenu, it does not handle changes to the menu items
            //  correctly if it is already displayed.  To force it to update it's positioning and such, we have to call
            //  this (protected) method.  That forces it to lay itself out again.
            //  This fixes an issue if you are editing a ticket, have not already fetched the service area list, and you
            //  right - click on the map.  Without this change, the "Service Areas" sub-menu is all jacked up.
            //  And alternative to calling the protected method is to call the put "updatePosition" method.  But that requires
            //  passing the pixel of the menu and to get that in here required a bunch of changes to add it to a couple methods.
            //  That pixel is available in the event that is passed to the beforeopen event handler.
            //  Decided to risk it.  Could probably make our own derived class and then expose a public method...
            if ((this._ContextMenu as any).positionContainer)
                (this._ContextMenu as any).positionContainer();     //  At least being safe about it - if this changes, the menu will may just look jacked up
        }
    }

    /**
     * Override this to return any custom context menu items that should be displayed in the right-click menu.
     * This will be called before the context menu is displayed.
     */
    protected BuildContextMenuItems(event: { type: string, pixel: Pixel, coordinate: Coordinate }): any[] {
        const contextMenuItems = [];

        //  Should not be possible for these tools to not be created yet, but saw some weird errors that looked like they were!
        if (this._MeasureDistanceTool && !this._MeasureDistanceTool.IsEmpty()) {
            contextMenuItems.push({
                text: '<i class="fas fa-ruler" ></i>Clear Measurements',
                classname: 'iq-image-item',
                callback: () => this._MeasureDistanceTool.Clear()
            });
        }

        if (this._PushPinTool && !this._PushPinTool.IsEmpty()) {
            contextMenuItems.push({
                text: '<i class="fas fa-map-marker-alt" ></i>Clear Push Pins',
                classname: 'iq-image-item',
                callback: () => this._PushPinTool.Clear()
            });
        }

        return contextMenuItems;
    }

    public ShowFeature(layerName: string, featureName: string, geometryJson: object): void {
        const featureItem = new FeatureItemResponse(layerName, featureName, geometryJson);
        this.MapSearchButton.ShowFeatureOnMap(featureItem);
    }

    public get CurrentSearchFeature(): FeatureItemResponse {
        return this.MapSearchButton.CurrentFeatureOnMap;
    }

    public ClearMapSearch(): void {
        this.MapSearchButton.ClearSearchLayer();
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        let features = this._RouteVectorLayer ? this._RouteVectorLayer.GetFeaturesInExtent(selectionBox, "Route Segment", "name") : [];

        if (this.MapChangesTileLayer)
            features = features.concat(this.MapChangesTileLayer.GetFeatureAttributesAtPixel(pixel));

        return of({ Features: features, Exclusive: false });
    }

    public abstract get CurrentStateAbbreviation(): string;
    public abstract get CurrentCountyName(): string;
    public abstract get MapSearchFilterBounds(): Extent;

    private _RouteVectorLayer: RouteVectorLayer;

    public ShowRoute(routeList: MapRouteSegment[]): void {
        if (!routeList || (routeList.length === 0)) {
            this.ClearRoute();
            return;
        }

        if (!this._RouteVectorLayer) {
            this._RouteVectorLayer = new RouteVectorLayer(MapConstants.LAYERNAME_UNBUFFERED_DIGSITE, this.MapToolService);
            this.Map.addLayer(this._RouteVectorLayer.Layer);
        }

        let isFirst = true;
        routeList.forEach(r => {
            this._RouteVectorLayer.LoadGeoJSON(r.GeometryJson, r.Label, isFirst);
            isFirst = false;
        });
    }

    public ClearRoute(): void {
        if (this._RouteVectorLayer)
            this.Map.removeLayer(this._RouteVectorLayer.Layer);
        this._RouteVectorLayer = null;
    }

}
