import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { Feature, Map, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { Draw, Interaction } from 'ol/interaction';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Style, Text } from 'ol/style';
import { MapConstants } from "../MapConstants";

export class PushPinTool extends DrawingToolBase {

    private _Layer: VectorLayer<Feature<Geometry>>;

    constructor(map: Map, mapToolService: MapToolService) {
        super(map, mapToolService);

        this.CreateLayer();
        this.Init();
    }

    public OnDestroy(): any {
        this._Layer.setMap(null);
        this._Layer = null;

        return super.OnDestroy();
    }

    public Clear(): void {
        this._Layer.getSource().clear();
    }

    public IsEmpty(): boolean {
        return this._Layer.getSource().getFeatures().length === 0;
    }

    private CreateLayer(): void {
        this._Layer = new VectorLayer({
            source: new VectorSource({}),
            style: this.BuildPushPinStyle(false),
        });

        this.Map.addLayer(this._Layer);
    }

    protected CreateInteraction(): Interaction {
        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            source: this._Layer.getSource(),
            type: "Point",
            style: this.BuildPushPinStyle(true),
            condition: (evt: MapBrowserEvent<PointerEvent>) => {
                if (evt.type !== "pointerdown")
                    return false;       //  ???

                const pointerEvent = evt.originalEvent;
                if (pointerEvent.button === 2) {
                    //  End on right click
                    interaction.setActive(false);
                }

                return pointerEvent.button === 0;       //  Only process Left mouse click
            },
        });

        return interaction;
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging)
            return;

        const helpMsg = 'Click to add Push Pin';
        this.SetHelpMessage(helpMsg, evt.coordinate);
    }

    private BuildPushPinStyle(forFeatureBeingDrawn: boolean): Style {
        //  Note: FontSymbol is from ol-ext package and is supposed to work with font awesome.  But it currently does not support Font Awesome 5.
        //  See: https://github.com/Viglino/ol-ext/issues/153
        //style: new ol.style.Style({
        //    image: new ol.style.FontSymbol({           //  from ol-ext: http://viglino.github.io/ol-ext/examples/style/map.style.font.html
        //        form: 'marker',
        //        glyph: 'fa-map-marker-alt',      //  https://fontawesome.com/icons/map-marker-alt?style=solid
        //        fontSize: 1,
        //        radius: 20,
        //        color: 'red',
        //        fill: new ol.style.Fill({
        //            color: 'yellow'
        //        }),
        //        stroke: new ol.style.Stroke({
        //            color: 'white',
        //            width: 2
        //        })
        //    })
        //})

        //  when drawing, dim it a little
        const color = forFeatureBeingDrawn ? 'rgba(255, 0, 0, .3)' : 'red';

        return new Style({
            text: new Text({
                text: '\uf3c5',     //  fa-map-marker-alt
                font: '900 24px "' + MapConstants.FONT_AWESOME_FREE_NAME + '"',     //  If doesn't work, see comments on this constant - package version probably changed!
                textBaseline: 'bottom',
                fill: new Fill({
                    color: color
                })
            })
        });
    }
}
