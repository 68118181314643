import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ScheduledTask } from '@iqModels/ScheduledTasks/ScheduledTask.model';
import { ConfirmationDialogComponent } from '@iqSharedComponentControls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from '@iqSharedComponentControls/Dialog/Information/InformationDialog.component';
import { DialogModel } from '@iqSharedComponentControls/Dialog/Models/Dialog.model';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { ScheduledTaskTypeEnum } from 'Enums/ScheduledTaskType.enum';
import { SettingsService } from 'Services/SettingsService';
import { CRUDBaseService, CRUDServices, BulkEntityActionRequest } from 'Shared/BaseServices/CRUDBase.service';
import { ScheduledTaskFrequencyEnum } from 'Enums/ScheduledTaskFrequency.enum';
import { ScheduledTaskRefreshInfo } from './ScheduledTaskRefreshInfo.model';
import { CreateOneTimeTaskRequest } from 'Models/ScheduledTasks/CreateOneTimeTaskRequest.model';
import { ScheduledTaskGroupEnum } from 'Enums/ScheduledTaskGroup.enum';
import { EntityEnum } from 'Enums/EntityType.enum';
import { Observable, of } from 'rxjs';
import { EntityPropertyUpdateTaskParams } from 'Models/ScheduledTasks/TaskParams/EntityPropertyUpdateTaskParams.model';
import { mergeMap, take } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ScheduledTaskService extends CRUDBaseService<ScheduledTask> {
    protected apiPath: string = "ScheduledTask";

    //  Not (currently at least) using anything that will trigger these permissions.
    //  Scheduled Tasks are currently just viewable & cancelable for the "one time" tasks that apply
    //  to specific entities (on their detail pages).
    //  But put all of this into a normal CRUD service/structure because we will probably need to expose
    //  an admin page to see scheduled task activity (not create - just view or maybe edit to allow adjusting schedules).
    ViewPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_View;
    EditPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;
    DeletePermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;
    CopyPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;

    constructor(crudServices: CRUDServices, private _HttpClient: HttpClient, private _SettingsService: SettingsService, private _Dialog: MatDialog) {
        super(crudServices);
    }

    public ScheduledTaskDescription(scheduledTask: ScheduledTask): string {
        if (!scheduledTask)
            return null;

        let allowMessageAppend: boolean = true;
        let text: string;
        switch (scheduledTask.TaskType) {
            case ScheduledTaskTypeEnum.RegistrationApprove:
                text = "Registration Approval";
                break;
            case ScheduledTaskTypeEnum.RegistrationCopy:
                text = "A task to create a copy of another Registration";
                break;
            case ScheduledTaskTypeEnum.RegistrationImportShapeFileTask:
                text = "A Shape File upload";
                break;
            case ScheduledTaskTypeEnum.RegistrationMerge:
                text = "A Merge Registration task";
                break;
            case ScheduledTaskTypeEnum.MCD_BuildRegistrationRecommendations:
                text = "A task to build Registration Recommendations";
                break;
            case ScheduledTaskTypeEnum.SendRegistrationInvites:
                text = "A Registration Email task";
                break;
            case ScheduledTaskTypeEnum.EntityPropertyUpdate:
                text = "scheduled to activate on " + moment(scheduledTask.NextRunDate).format("MM/DD/YYYY hh:mm a").toString() + " (click activate to modify schedule)";
                allowMessageAppend = false;
                break;
            default:
                text = "A task";   //  This should not be shown!
                break;
        }

        if (!allowMessageAppend)
            return text;

        if (scheduledTask.LockDate)
            return text + " is currently being processed.";
        else
            return text + " is waiting to be processed.";
    }

    public CanCancel(scheduledTask: ScheduledTask): boolean {
        //  This task is not allowed to be canceled if it is for a specific service area.
        //  If we allow that, it could leave the recommendations built for the wrong registration or not built when they should be.
        if ((scheduledTask?.TaskType === ScheduledTaskTypeEnum.MCD_BuildRegistrationRecommendations) && scheduledTask.ReferencedEntityID)
            return false;

        return scheduledTask && (scheduledTask.Frequency === ScheduledTaskFrequencyEnum.OneTime) && !scheduledTask.LockDate;
    }

    /**
     * Cancels the task.  Returns (via the callback) null if successfull (or if it did not exist/was already removed/canceled)
     * or an updated copy of the task if it could not be canceled (could be started now).
     * @param scheduledTask
     */
    public Cancel(scheduledTask: ScheduledTask): void {
        //Don't care about the response, just execute it.
        this.CancelAsync(scheduledTask).pipe(take(1)).subscribe();
    }

    /**
     * Shows a confirmation dialog and cancels the task based on the users selection.
     *
     * Returns the users selection from the dialog (true for cancel the task, false for not canceling the task)
     * @param scheduledTask
     */
    public CancelAsync(scheduledTask: ScheduledTask): Observable<boolean> {
        return this._Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel("Cancel Scheduled Task", "Are you sure you want to cancel this scheduled task?", "Cancel Task"),
                disableClose: true
            }).afterClosed().pipe(mergeMap((val) => {
                if (val) {
                    return this._HttpClient.delete<ScheduledTask>(this._SettingsService.ApiBaseUrl + "/ScheduledTask/Cancel/" + scheduledTask.ID)
                        .pipe(mergeMap(task => {
                            if (!task) {
                                //  task canceled (or it already completed) so stop refreshing it.  Also trigger callback so referenced item is refreshed in case the task completed.
                                this.UnregisterTaskFromRefresh(scheduledTask, true);
                            }
                            if (task && !this.CanCancel(task))
                                this.ShowCancelFailedJobStarted();

                            return of<boolean>(true);
                        }));
                }

                return of<boolean>(false);
            }));
    }

    private ShowCancelFailedJobStarted(): void {
        this._Dialog
            .open(InformationDialogComponent, {
                data: new DialogModel("Task has started", "The task has started processing and cannot be canceled.")
            });
    }

    public CreateEntityPropertyChangeTask(entityID: string, entityType: EntityEnum, nextRunDate: Date,
        propertyName: string, propertyValue: any, displayValue: string): Observable<ScheduledTask>
    {
        const parameters = new EntityPropertyUpdateTaskParams(entityType, propertyName, propertyValue, displayValue);
        return this.CreateOneTimeTask(ScheduledTaskTypeEnum.EntityPropertyUpdate, ScheduledTaskGroupEnum.DailyMaintenance, parameters, entityID, nextRunDate);
    }

    public CreateOneTimeTask(taskType: ScheduledTaskTypeEnum, taskGroup: ScheduledTaskGroupEnum, parameters?: any,
        referencedEntityID?: string, nextRunDate?: Date): Observable<ScheduledTask>
    {
        const request = new CreateOneTimeTaskRequest(taskType, taskGroup, parameters, referencedEntityID, nextRunDate);
        return this._HttpClient.post<ScheduledTask>(this._SettingsService.ApiBaseUrl + "/ScheduledTask/CreateOneTimeTask", request);
    }

    //////////////////////  Methods to automatically handle refreshing the status in the background

    private _IntervalID: any = null;
    private _TasksRegisteredForRefresh: Map<string, ScheduledTaskRefreshInfo> = new Map<string, ScheduledTaskRefreshInfo>();

    public StopBackgroundRefresh(): void {
        if (this._IntervalID)
            clearInterval(this._IntervalID);

        this._IntervalID = null;
        this._TasksRegisteredForRefresh.clear();
    }

    //  TODO: Remove the callback stuff - convert everything to use the event emitted
    public RegisterTaskForRefresh(scheduledTask: ScheduledTask, completedCallback: (task: ScheduledTask) => void, eventHandler?: EventEmitter<ScheduledTask>): void {
        if (!scheduledTask || !scheduledTask.ID)
            return;

        let info = this._TasksRegisteredForRefresh.get(scheduledTask.ID);
        if (!info) {
            info = new ScheduledTaskRefreshInfo(scheduledTask, eventHandler, completedCallback);
            //console.warn("Registered first callback for task ", scheduledTask.ID, info);
        }
        else {
            if (!info.CompletedEventHandlers.some(e => e === eventHandler))
                info.CompletedEventHandlers.push(eventHandler);
            info.CompletedCallbackList.push(completedCallback);
            //console.warn("Registered additional callback for task ", scheduledTask.ID, info);
        }
        this._TasksRegisteredForRefresh.set(scheduledTask.ID, info);
        //console.warn("RegisterTaskForRefresh", this._TasksRegisteredForRefresh);

        if (!this._IntervalID) {
            //  Only need to set up the refresh timer if the NextRunDate is actually past.  Otherwise, there's no point.
            //  This will allow tasks that run immediately (like shape file imports) to update their progress.  But it
            //  won't waste time doing that for a task (like a property update) that is scheduled in the future.
            if (scheduledTask.NextRunDate && (new Date(scheduledTask.NextRunDate) <= new Date())) {
                const me = this;
                this._IntervalID = setInterval(() => me.RefreshTasks(), 10000);
            }
        }
    }

    public UnregisterEventHandler(scheduledTask: ScheduledTask, eventHandler: EventEmitter<ScheduledTask>): void {
        if (!scheduledTask || !scheduledTask.ID || !eventHandler)
            return;

        const info = this._TasksRegisteredForRefresh.get(scheduledTask.ID);
        if (!info)
            return;

        //console.warn("UnregisterEventHandler", eventHandler, info.CompletedEventHandlers.some(e => e === eventHandler));
        info.CompletedEventHandlers = info.CompletedEventHandlers.filter(e => e !== eventHandler);

        if (info.CompletedEventHandlers.length === 0) {
            this._TasksRegisteredForRefresh.delete(scheduledTask.ID);
            if (this._TasksRegisteredForRefresh.size === 0)
                this.StopBackgroundRefresh();       //  No more tasks to check so stop the interval
        }
    }

    public UnregisterTaskFromRefresh(scheduledTask: ScheduledTask, triggerCompletedCallback?: boolean): void {
        const info = this._TasksRegisteredForRefresh.get(scheduledTask.ID);
        if (!info)
            return;

        //console.warn("UnregisterTaskFromRefresh", scheduledTask.ID, triggerCompletedCallback);
        this._TasksRegisteredForRefresh.delete(scheduledTask.ID);
        if (this._TasksRegisteredForRefresh.size === 0)
            this.StopBackgroundRefresh();       //  No more tasks to check so stop the interval

        //  Unregister always unregisters EVERYTHING - it's done when the task has completed or was canceled.
        if (triggerCompletedCallback) {
            info.CompletedEventHandlers.forEach(eventHandler => {
                //console.warn("UnregisterTaskFromRefresh: calling eventHandler");
                if (eventHandler)
                    eventHandler.next(scheduledTask);
            });
            info.CompletedCallbackList.forEach(callback => {
                //console.warn("UnregisterTaskFromRefresh: calling callback");
                if (callback)
                    callback(scheduledTask);
            });
        }
    }

    private RefreshTasks(): void {
        const idList = Array.from(this._TasksRegisteredForRefresh.keys());
        if (idList.length === 0) {
            //  Empty list! should not happen...
            this.StopBackgroundRefresh();
            return;
        }

        const request = new BulkEntityActionRequest();
        request.IDs = idList;
        this._HttpClient.post<{ [key: string]: ScheduledTask }>(this._SettingsService.ApiBaseUrl + "/ScheduledTask/Status", request)
            .subscribe(response => this.OnRefreshedStatusReceived(response));
    }

    private OnRefreshedStatusReceived(status: { [key: string]: ScheduledTask }): void {
        //  Check for any completed tasks
        const newTaskList = new Map<string, ScheduledTaskRefreshInfo>();      //  b/c can't modify the collection we are iterating
        this._TasksRegisteredForRefresh.forEach((info, id) => {
            const updatedTask = status[id];
            if (!updatedTask) {
                //  Not in list/empty - task completed.  Trigger callback and do not add to the new task list
                //  Do this in a timeout in case the callback needs to reset the refresh completely or do something else that
                //  might mess with the list as we are iterating it.  We need to finish this loop before we let it do anything
                //  like that or we'll just overwrite it when we set the list to "newTaskList".
                info.CompletedEventHandlers.forEach(eventHandler => {
                    //console.warn("UnregisterTaskFromRefresh: calling eventHandler");
                    if (eventHandler)
                        setTimeout(() => eventHandler.next(info.ScheduledTask));
                });
                info.CompletedCallbackList.forEach(callback => {
                    if (callback)
                        setTimeout(() => callback(info.ScheduledTask));
                });
            }
            else {
                //  Copy the properties (that can change) in the updatedTask to the instance we were given.  Then the UI will
                //  update to reflect any changes (could now be executing where it was previously waiting).
                info.ScheduledTask.LockDate = updatedTask.LockDate;
                info.ScheduledTask.NextRunDate = updatedTask.NextRunDate;

                newTaskList.set(id, info);
            }
        });

        this._TasksRegisteredForRefresh = newTaskList;

        if (this._TasksRegisteredForRefresh.size === 0)
            this.StopBackgroundRefresh();       //  No more tasks to check so stop the interval
    }

    //////////////////////  Methods to automatically handle refreshing the status in the background
}
