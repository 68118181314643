import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { NavigationTypes } from 'Enums/NavigationTypes.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { GoogleResponseComponent } from 'Pages/Authentication/GoogleResponsePage/GoogleResponsePage.component';
import { LoginComponent } from 'Pages/Authentication/Login/Login.component';
import { LogoutComponent } from 'Pages/Authentication/Logout/Logout.component';
import { NoPersonForLoginComponent } from 'Pages/Authentication/NoPersonForLogin/NoPersonForLogin.component';
import { SwitchCenterComponent } from 'Pages/Authentication/SwitchCenter/SwitchCenter.component';
import { EmailVerificationComponent } from 'Pages/Emails/Verification/EmailVerification.component';
import { EmergencyContactsComponent } from 'Pages/ServiceAreas/Anonymous/EmergencyContacts/EmergencyContacts.component';
import { TicketSearchByNumberAndPhoneComponent } from 'Pages/Tickets/Anonymous/Pages/SearchByNumberAndPhone/TicketSearchByNumberAndPhone.component';
import { ViewTicketIDComponent } from 'Pages/Tickets/Anonymous/Pages/ViewTicketID/ViewTicketID.component';
import { PrintTicketTextAndServiceAreasComponent } from 'Pages/Tickets/Details/Printing/PrintTicketTextAndServiceAreas.component';
import { UserRegistrationComponent } from 'Pages/UserRegistration/NewUserRegistration/UserRegistration.component';
import { PrintLayoutComponent } from 'Shared/Printing/PrintLayout/PrintLayout.component';
import { PhoneAnimationTriggers } from 'Shared/Utils/Animations';
import { LinkUserToPersonComponent } from './Pages/Authentication/LinkUser/LinkUserToPerson.component';
import { HomeComponent } from './Pages/Home/Home.component';
import { AuthenticationGuardService } from './Services/RouteGuards/AuthenticationGuardService';
import { PermissionGuardService } from './Services/RouteGuards/PermissionGuardService';
import { PreventPhoneGuardService } from './Services/RouteGuards/PreventPhoneGuard.service';
import { PreventBrowserBackButtonGuardService } from './Services/RouteGuards/PreventBrowserBackButtonGuardService';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'switch-one-call', component: SwitchCenterComponent, canActivate: [PreventBrowserBackButtonGuardService], data: { type: NavigationTypes.Static, title: 'Switch Center' } },
    { path: 'googleResponse', component: GoogleResponseComponent, canActivate: [PreventBrowserBackButtonGuardService], data: { type: NavigationTypes.Static, title: 'Google Login Response' } },
    {
        path: 'login', component: LoginComponent, canActivate: [PreventBrowserBackButtonGuardService], data: {
            //  Allow these routes to use the back button and not be blocked by the PreventBackButtonRouteGuard
            allowedBackButtonRoutes: ["/findTicketByNumberAndPhone", "/emergencyContacts"],
            animation: PhoneAnimationTriggers.DefaultAnimation, type: NavigationTypes.Static, title: 'Login'
        }
    },
    { path: 'logout', component: LogoutComponent, canActivate: [PreventBrowserBackButtonGuardService], data: { type: NavigationTypes.Static, title: 'Logout' } },
    { path: 'link-login/:occ/:id', component: LinkUserToPersonComponent, canActivate: [PreventBrowserBackButtonGuardService], data: { type: NavigationTypes.Static, title: 'Link Login' } },
    { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuardService], data: { type: NavigationTypes.Static, title: 'Home' } },
    { path: 'newUser', component: UserRegistrationComponent, data: { type: NavigationTypes.Static, title: 'User Registration' } },

    //  Would probably be better to have a base route for these like the other modules.
    //  But...don't really want to prefix it with something like "Anon" so didn't know what to use that wouldn't conflict with "tickets".
    //  Some of this will be included on ticket text (at least for FL) so will be highly visible.
    //  Once FL goes live, the ViewTicket *CANNOT* be changed (unless old continues to be handled) - it will be on their ticket text!
    { path: 'findTicketByNumberAndPhone', component: TicketSearchByNumberAndPhoneComponent, data: { type: NavigationTypes.Static, title: 'Ticket Search' } },
    { path: 'viewticket/:occ/:id', component: ViewTicketIDComponent, data: { type: NavigationTypes.Static, title: 'Ticket View' } },
    { path: 'printticket', outlet: 'print', component: PrintLayoutComponent, children: [{ path: 'text', component: PrintTicketTextAndServiceAreasComponent }] },
    { path: 'emergencyContacts', component: EmergencyContactsComponent, data: { type: NavigationTypes.Static, title: 'Emergency Contacts' } },

    //Maybe make this anonymous.  Not sure why it isn't because all it is doing is flipping the verified flag.  if we make this anonymous then we need to do the same for the API method
    { path: 'verifyemail/:id', component: EmailVerificationComponent, canActivate: [AuthenticationGuardService], data: { type: NavigationTypes.Static, title: 'Verify Email' } },//We shouldn't care if they are logged in or not.  Just verify that the email was recieved and clicked

    //Shouldn't ever show this if they aren't authenticated because it doesn't make sense if not logged in
    { path: 'auth-no-person-for-login', component: NoPersonForLoginComponent, canActivate: [AuthenticationGuardService], data: { type: NavigationTypes.Static, title: 'Login not Found' } },

    { path: 'tickets', loadChildren: () => import('./Pages/Tickets/Tickets.module').then(m => m.TicketsModule), canActivate: [AuthenticationGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Ticket_View] } },

    { path: 'projects', loadChildren: () => import('./Pages/Projects/Modules/Project.module').then(m => m.ProjectModule), canActivate: [AuthenticationGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Projects_View] } },

    { path: 'excavator', loadChildren: () => import('./Pages/Excavators/Modules/Excavator.module').then(m => m.ExcavatorModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.ExcavatorCompany_View] } },
    { path: 'member', loadChildren: () => import('./Pages/Members/Modules/Member.module').then(m => m.MemberModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Member_View] } },
    { path: 'people', loadChildren: () => import('./Pages/People/Modules/People.module').then(m => m.PeopleModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Person_View] } },
    { path: 'servicearea/registration', loadChildren: () => import('./Pages/Registrations/Modules/Registration.module').then(m => m.RegistrationModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService] },

    { path: 'servicearea', loadChildren: () => import('./Pages/ServiceAreas/Modules/ServiceArea.module').then(m => m.ServiceAreasModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.ServiceArea_View] } },
    { path: 'profileverification', loadChildren: () => import('./Pages/ProfileVerifications/Modules/ProfileVerifications.module').then(m => m.ProfileVerificationsModule), canActivate: [AuthenticationGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.ProfileVerification_View] } },

    { path: 'serviceprovider', loadChildren: () => import('./Pages/ServiceProviders/Modules/ServiceProvider.module').then(m => m.ServiceProviderModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.ServiceProvider_View] } },
    { path: 'destination', loadChildren: () => import('./Pages/Destinations/Modules/Destination.module').then(m => m.DestinationModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Destination_View] } },

    { path: 'administration', loadChildren: () => import('./Pages/Administration/Modules/Administration.module').then(m => m.AdministrationModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.OneCallCenter_View] } },

    { path: 'role', loadChildren: () => import('./Pages/RolesAndPermissions/Modules/Roles.module').then(m => m.RolesModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Role_View] } },

    { path: 'subcontractor', loadChildren: () => import('./Pages/Subcontractors/Module/Subcontractor.module').then(m => m.SubcontractorModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.ExcavatorCompany_View] } },

    { path: 'communications', loadChildren: () => import('./Pages/Communications/Modules/Communications.module').then(m => m.CommunicationsModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Communications_View] } },

    { path: 'reports', loadChildren: () => import('./Pages/Reports/Modules/Report.module').then(m => m.ReportModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.Report_View] } },

    { path: 'system', loadChildren: () => import('./Pages/System/Modules/System.module').then(m => m.SystemModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.SystemStatus_View] } },

    { path: 'alerts', loadChildren: () => import('./Pages/Alerts/Modules/Alerts.module').then(m => m.AlertsModule), canActivate: [AuthenticationGuardService, PreventPhoneGuardService, PermissionGuardService], data: { permissions: [PermissionsEnum.OneCallCenter_View] } },


    //  Wildcard route.  Any route that angular can't find will redirect to the home page
    //  Needed because people keep bookmarking OLD sites or old versions of Exactix and then refuse to fix their bookmarks.
    { path: '**', redirectTo: '/home' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: NoPreloading //PreloadAllModules
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
