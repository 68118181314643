import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationTypes } from 'Enums/NavigationTypes.enum';
import { ProfileVerificationsMobilePageComponent } from '../Mobile/ProfileVerificationsMobilePage.component';
import { ProfileVerificationsRouteResolver } from '../Services/ProfileVerificationsRouteResolver.service';

const routes: Routes = [

    { path: 'details/:id', component: ProfileVerificationsMobilePageComponent, data: { type: NavigationTypes.Static, title: 'Profile Verification Details' }, resolve: { item: ProfileVerificationsRouteResolver } },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileVerificationsRoutingModule { }
