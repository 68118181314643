import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject, takeUntil } from 'rxjs';
import { ProfileVerificationSectionStatusEnum } from 'Enums/ProfileVerificationSectionStatusEnum.enum';
import { ProfileVerificationSectionBase } from 'Models/ProfileVerifications/ProfileVerificationSectionBase.model';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';

@Component({
    selector: 'iq-profile-verifications-default-form',
    templateUrl: './ProfileVerificationsDefaultForm.component.html',
    styleUrls: ['./ProfileVerificationsDefaultForm.component.scss']
})

export class ProfileVerificationsDefaultFormComponent extends ComponentWithDestroySubscription implements OnInit {
    @Input()
    public VerificationResult: ProfileVerificationSectionBase;

    public FormGroup: UntypedFormGroup;
    public RequestedChangesFormControl: UntypedFormControl;
    public EverythingCorrectFormControl: UntypedFormControl;

    private _Destroyed = new Subject<void>();

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.BuildFormGroup();
    }

    public EverythingCorrectChanged(event: MatCheckboxChange) {
        this.RequestedChangesFormControl.clearValidators();

        if (event.checked) {
            this.VerificationResult.Status = ProfileVerificationSectionStatusEnum.Confirmed;
            this.RequestedChangesFormControl.setValue("");
            this.VerificationResult.RequestedChanges = "";
            this.RequestedChangesFormControl.disable();
        }
        else {
            this.RequestedChangesFormControl.enable();
            this.VerificationResult.Status = this.RequestedChangesFormControl?.value?.length > 0 ? ProfileVerificationSectionStatusEnum.Changed : ProfileVerificationSectionStatusEnum.Unreviewed;
            this.RequestedChangesFormControl.addValidators([Validators.required]);
            this.RequestedChangesFormControl.updateValueAndValidity();
        }

        this.FormGroup.updateValueAndValidity();
    }

    private BuildFormGroup(): void {
        this.RequestedChangesFormControl = new UntypedFormControl(this.VerificationResult.RequestedChanges ?? null, this.ChangesRequired() ? [Validators.required] : null);
        this.EverythingCorrectFormControl = new UntypedFormControl(this.VerificationResult?.Status === ProfileVerificationSectionStatusEnum.Confirmed ?? false, null);

        this.RequestedChangesFormControl.valueChanges.pipe(takeUntil(this._Destroyed)).subscribe(() => this.RequestedChangesChanged());

        this.FormGroup = new UntypedFormGroup({
            RequestedChanges: this.RequestedChangesFormControl,
            EverythingCorrect: this.EverythingCorrectFormControl
        });

        if (this.EverythingCorrectFormControl.value)
            this.RequestedChangesFormControl.disable();
    }

    public FormIsReadonly(): void {
        this.RequestedChangesFormControl.disable();
        this.EverythingCorrectFormControl.disable();
        this.FormGroup.updateValueAndValidity();
    }

    public EnableFields(): void {
        this.RequestedChangesFormControl.enable();
        this.EverythingCorrectFormControl.enable();
        this.FormGroup.updateValueAndValidity();
    }

    private RequestedChangesChanged(): void {
        if (!this.EverythingCorrectFormControl.value) {
            this.VerificationResult.Status = this.RequestedChangesFormControl?.value?.length > 0 ? ProfileVerificationSectionStatusEnum.Changed : ProfileVerificationSectionStatusEnum.Unreviewed;
            this.VerificationResult.RequestedChanges = this.RequestedChangesFormControl.value;
            this.FormGroup.updateValueAndValidity();
        }
    }

    private ChangesRequired(): boolean {
        return this.VerificationResult?.Status === ProfileVerificationSectionStatusEnum.Unreviewed || this.VerificationResult?.Status === ProfileVerificationSectionStatusEnum.Changed;
    }
}
