import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrintingService } from 'Services/Printing.service';
import { CommonService } from 'Services/CommonService';
import { SettingsService } from 'Services/SettingsService';
import { BaseListDisplayPageClassService } from 'Shared/BaseClasses/BaseListDisplayPage.class';
import { TicketActionsService } from '../../Services/TicketActions.service';
import { DesktopTicketListBase } from './DesktopTicketListBase.component';
import { TicketResponseService } from '../../Services/TicketResponse.service';
import { EntityEnum } from 'Enums/EntityType.enum';

@Component({
    templateUrl: './DesktopTicketListBase.component.html',
    styleUrls: ['./DesktopTicketListBase.component.scss'],
    providers: [CommonService]
})
export class DesktopTicketResponseSearchComponent extends DesktopTicketListBase
{
    public override EntityType: EntityEnum = EntityEnum.TicketResponse;

    constructor(ticketResponseService: TicketResponseService, baseServices: BaseListDisplayPageClassService, ticketActionsService: TicketActionsService,
        settingService: SettingsService, toastr: ToastrService, printService: PrintingService, commonService: CommonService)
    {
        super(ticketResponseService, baseServices, ticketActionsService, settingService, toastr, printService, commonService);
    }

    //  item.ID is the Ticket.ID so this returns TicketResponseID
    protected override EntityIDForRow(item: any): string { return item.TicketResponseID; }
}
