//  Put this guard on a route to prevent it from being activated if the browser
//  Back button is used to navigate to it.

import { LocationStrategy } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

export let _BackClicked = false;
export let _OnPopStateHandlerInitialized = false;

export const PreventBrowserBackButtonGuardService: CanActivateFn = (
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        locationStrategy: LocationStrategy = inject(LocationStrategy),
        router: Router = inject(Router)) => {

    if (!_OnPopStateHandlerInitialized) {
        locationStrategy.onPopState(() => _BackClicked = true);
        _OnPopStateHandlerInitialized = true;
    }

    //Subscribe to the location change events so we can tell if it is a browser back button or a direct navigation.
    //this.location.subscribe(val => {
    //    this.lastState = val;
    //});

    //  Also see here: https://stackoverflow.com/questions/36357204/how-to-disable-browser-back-button-in-angular-2/49402713
    locationStrategy.onPopState(() => _BackClicked = true);

    //  This prevents navigation if the Back button was just clicked.
    //  *BUT* it does not handle if you log in, then refresh your browser, then click Back.
    //  That will result in navigating to an empty page in the browser.  The problem there is
    //  that the navigation history seems to not be set correctly when you refresh.  To get
    //  this to work correctly, we need to figure out out to add the redirect's that happen
    //  in to the browser history.  That should then make this Back button prevention work after a refresh too.
    const allowedBackButtonRoutes = next.data.allowedBackButtonRoutes as string[];
    const canShow = allowedBackButtonRoutes && allowedBackButtonRoutes.includes(router.url) ? true : !_BackClicked;
    _BackClicked = false;

    if (!canShow)
        window.history.pushState({}, "", router.url);
    return canShow;

    //  Original attempt - which does not work very well.  It doesn't seem to block the Back button at all.
    //  It does seems to skip past (some of) the pages that are being guarded.  But the user still ends up
    //  in a weird state / on a bad page.  The above, completely prevents navigation.
    //const curState: any = this.location.getState();

    //const canShow = this.lastState && this.lastState.state.navigationId === curState.navigationId ? !this.lastState.pop : true;
    //if (!canShow)
    //    this.location.back();

    //this.lastState = null;//Clear this out so that if they do this then click logout it will allow them to get the login page

    //return canShow;
}
