import { Component, OnDestroy } from '@angular/core';
import { CommonService } from 'Services/CommonService';
import { BaseTicketMapViewerComponent } from './BaseTicketMapViewer.component';
import { TicketResponseMapViewerService } from './Services/TicketResponseMapViewer.service';

/**
 *  Map to show Ticket Responses (not Tickets).  Can be either a standalone map or shown next to a Ticket Search list.
 */
@Component({
    selector: 'ticket-responses-map-viewer',
    templateUrl: './BaseTicketMapViewer.component.html',
    styleUrls: ['./BaseTicketMapViewer.component.scss'],
    providers: [
        CommonService,
        { provide: "IMapViewerService", useClass: TicketResponseMapViewerService }  //  So can be injected in the TicketMapItemPager.component
    ]
})
export class TicketResponsesMapViewerComponent extends BaseTicketMapViewerComponent implements OnDestroy {

    //  Unique ID used for the MapItemCache.  Normally, this is "ID" (which holds a Ticket.ID).  But for the TicketResponse map,
    //  this needs to be TicketResponseID.
    protected override get MapCachKeyPropertyName(): string { return "TicketResponseID"; }

    constructor(commonService: CommonService, mapViewerService: TicketResponseMapViewerService) {
        super(commonService, mapViewerService);
    }
}
