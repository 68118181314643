import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MomentInput } from "moment";
import { CommonService } from "Services/CommonService";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import moment from "moment";
import { APIDateTimeFormat } from "Shared/Utils/MaskFormats.model";
import { Registration } from "Models/Registrations/Registration.model";

export class ServiceAreaActivateRegistrationDialogData {
    ActivateDate: MomentInput;
    ActivateNow: boolean;
    Registration: Registration;
    CancelTask: boolean;
}

@Component({
    templateUrl: './ServiceAreaActivateRegistrationDialog.component.html',
    styleUrls: ['./ServiceAreaActivateRegistrationDialog.component.scss'],
})
export class ServiceAreaActivateRegistrationDialogComponent {
    private readonly ACTIVATE_NOW_MESSAGE: string = "This will Activate the registration and immediately start using it to notify the Service Area.";
    private readonly ACTIVATE_NOW_BUTTON_TEXT: string = "Activate Now";
    private readonly ACTIVATE_SCHEDULED_MESSAGE: string = "This will schedule activation of the registration for the entered date and time.";
    private readonly ACTIVATE_SCHEDULED_BUTTON_TEXT: string = "Schedule Activation";

    public ActivateMessage: string = "";
    public ActivateButtonText: string = "";
    public ShowActivationDate: boolean = false;

    public FormGroup: UntypedFormGroup;
    public ScheduleActivationFormControl: UntypedFormControl;
    public ActivationDateFormControl: UntypedFormControl;

    public CanCancel: boolean = false;
    
    constructor(public dialogRef: MatDialogRef<ServiceAreaActivateRegistrationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ServiceAreaActivateRegistrationDialogData,
        private _CommonService: CommonService) {
        this.BuildForm(data);
        this.ToggleActivationMode(data.ActivateNow);
    }

    public Save(): void {
        this._HandleDateInput();
        this.dialogRef.close(this.data);
    }

    public OnCancel(): void {
        this.data.ActivateNow = false;
        this.data.CancelTask = true;
        this.dialogRef.close(this.data);
    }

    public ScheduleActivationToggle(event: MatSlideToggleChange): void {
        this.ToggleActivationMode(!event.checked);
    }

    public datePickerOpened() {
        if (!this.ActivationDateFormControl.value)
            this.ActivationDateFormControl.setValue(moment.utc().startOf('day'));
    }

    private ToggleActivationMode(ActivateNow: boolean): void {
        if (ActivateNow) {
            this.ActivateMessage = this.ACTIVATE_NOW_MESSAGE;
            this.ActivateButtonText = this.ACTIVATE_NOW_BUTTON_TEXT;
        }
        else {
            this.ActivateMessage = this.ACTIVATE_SCHEDULED_MESSAGE;
            this.ActivateButtonText = this.ACTIVATE_SCHEDULED_BUTTON_TEXT;                
        }

        this.ShowActivationDate = !ActivateNow;
        this.ScheduleActivationFormControl.setValue(!ActivateNow);
        this.data.ActivateNow = ActivateNow;

        if (this.data.Registration.ScheduledTask) {
            this.ActivationDateFormControl.setValue(moment(this.data.Registration.ScheduledTask.NextRunDate, APIDateTimeFormat));
            this.CanCancel = true;
        }
        else {
            this.ActivationDateFormControl.setValue(moment().startOf('day').add("1", "day"));
            this.CanCancel = false;
        }
    }

    private BuildForm(data: ServiceAreaActivateRegistrationDialogData): void {
        this.ActivationDateFormControl = new UntypedFormControl(data.ActivateDate, [Validators.required]);
        this.ScheduleActivationFormControl = new UntypedFormControl(data.ActivateNow);

        this.FormGroup = this._CommonService.FormBuilder.group({
            ScheduleActivation: this.ScheduleActivationFormControl,
            ActivationDate: this.ActivationDateFormControl
        });
    }

    private _HandleDateInput(): void {
        const activateDateTime = moment(this.ActivationDateFormControl.value);
        this.data.ActivateDate = activateDateTime.isValid() ? activateDateTime.format(APIDateTimeFormat) : (moment().startOf('day').add("1", "day")).format(APIDateTimeFormat);
    }
}
