import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { OLGeometryTypeEnum } from 'Enums/GeometryType.enum';
import { MapSearchTRSQGridTypeEnum } from 'Enums/MapSearchTRSQGridType.enum';
import { FeatureItemResponse } from 'Models/Maps/FeatureItemResponse.model';
import { FeatureSearchResponse } from 'Models/Maps/FeatureSearchResponse.model';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { SearchAutocompleteRequest } from 'Models/Maps/SearchAutocompleteRequest.model';
import { SearchTRSQGridRequest } from 'Models/Maps/SearchTRSQGridRequest.model';
import { CommonService } from 'Services/CommonService';
import { LatLonCoordinateTextMaskService } from 'Services/LatLonCoordinateTextMask.service';
import { MapSearchService } from 'Services/MapSearchService';
import { GeometryTransformer } from 'Shared/Components/Maps/GeometryTransformer';
import { GeometryUtils } from 'Shared/Components/Maps/GeometryUtils';
import { SearchVectorLayer } from 'Shared/Components/Maps/Layers/SearchVectorLayer';
import { MapBaseComponent } from 'Shared/Components/Maps/MapBase.component';
import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import { TooltipOptions } from 'Shared/Tooltips/TooltipOptions.interface';
import { Extent } from 'ol/extent';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { conformToMask } from 'text-mask-core';

enum SearchTypeEnum {
    Name = 0,
    TRSQLong = 1,
    TRSShort = 2,
    LatLonCoord = 3
}

@Component({
    selector: 'iq-map-search-button',
    templateUrl: './SearchMapButton.component.html',
    styleUrls: ['./SearchMapButton.component.scss'],
    providers: [CommonService]
})
export class SearchMapButtonComponent implements OnDestroy {

    public TextMaskValueFormControl: UntypedFormControl;
    public SearchValueFormControl: UntypedFormControl;

    @ViewChild("searchMaskedInput")
    private _Input: ElementRef;

    @ViewChild("searchAutocompleteInput")
    private _AutocompleteInput: ElementRef;

    @ViewChild("searchAutocompleteInput", { read: MatAutocompleteTrigger })
    private _AutocompleteTrigger: MatAutocompleteTrigger;

    public SearchTypeEnum = SearchTypeEnum;
    public SearchType: SearchTypeEnum = SearchTypeEnum.Name;

    public ShowPanel: boolean = false;
    public SearchLabel: string;
    public SearchPlaceholder: string;
    public SearchHint: string;

    public SearchByNameLabel: string;
    public PlaceNameLabel: string;
    public PluralPlaceNameLabel: string;

    public SearchResults: Observable<FeatureItemResponse[]>;
    public History: FeatureItemResponse[] = [];

    public AreaConstraint: number = 0;      //  TODO: enum?  Entire Map=0, State-wide=1, MapView=2, NearDigSite=3
    public HasState: boolean = false;
    public HasBoundsFilter: boolean = false;

    private _SearchVectorLayer: SearchVectorLayer;

    public TRSQGridTypeEnum = MapSearchTRSQGridTypeEnum;
    public MapSearchTRSQGridType: MapSearchTRSQGridTypeEnum;

    public LatLonExample: string;

    private _CurrentFeatureOnMap: FeatureItemResponse = null;
    public get CurrentFeatureOnMap(): FeatureItemResponse { return this._CurrentFeatureOnMap; }

    //  Must be set by the owning MapBase control via a call to SetMapBase() in it's ngAfterViewInit handler.
    private _MapBase: MapBaseComponent;

    private _Destroyed: Subject<void> = new Subject();

    constructor(private _CommonService: CommonService, private _MapSearchService: MapSearchService,
        private _LatLonCoordinateTextMaskService: LatLonCoordinateTextMaskService)
    {
        this.MapSearchTRSQGridType = _CommonService.SettingsService.MapSearchTRSQGridType;
        this.BuildForm();

        this.PlaceNameLabel = _CommonService.SettingsService.PlaceNameLabel.toLowerCase();
        this.PluralPlaceNameLabel = _CommonService.SettingsService.PluralPlaceNameLabel;
        this.SearchByNameLabel = "Streets, " + this.PluralPlaceNameLabel + ", Points of Interest";

        if (_CommonService.SettingsService.LatLonCoordinateEnteredLatFirst)
            this.LatLonExample = "Enter the latitude(Y) followed by the longitude(X).  i.e. " + _CommonService.SettingsService.LatLonCoordinateExample;
        else
            this.LatLonExample = "Enter the longitude(X) followed by the latitude(Y).  i.e. " + _CommonService.SettingsService.LatLonCoordinateExample;

        this.SetSearchType(SearchTypeEnum.Name);
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();

        this.SearchResults = null;
        this.MaskOptions = null;
        this.MaskFunction = null;
        this._MapBase = null;
        this._MapSearchService = null;
    }

    public SetMapBase(mapBase: MapBaseComponent): void {
        this._MapBase = mapBase;
    }

    private BuildForm(): void {
        this.TextMaskValueFormControl = new UntypedFormControl("");
        this.SearchValueFormControl = new UntypedFormControl("");

        this.TextMaskValueFormControl.valueChanges
            .pipe(takeUntil(this._Destroyed))
            .subscribe(val => this.SearchValueFormControl.setValue(val));

        this.SearchResults = this.SearchValueFormControl.valueChanges.pipe(
            takeUntil(this._Destroyed),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(val => {
                //  This value could be the selected object or the searched text.
                if (val === "" || val === null || val === undefined || typeof val !== 'string')
                    return of<FeatureSearchResponse>(null);

                //  Catch and suppress errors on these.  Otherwise, an error here will cause the main observable to end.
                switch (this.SearchType) {
                    case SearchTypeEnum.Name:
                        return this.ExecuteTextSearch().pipe(catchError(() => of(null)));
                    case SearchTypeEnum.TRSQLong:
                    case SearchTypeEnum.TRSShort:
                        return this.ExecuteTRSQSearch(val).pipe(catchError(() => of(null)));
                    case SearchTypeEnum.LatLonCoord:
                        return of(null);
                }
            }),
            map((response: FeatureSearchResponse) => {
                if (!response)
                    return [];

                response.Items.forEach(i => {
                    //  This will format the grids for display (by applying the mask).
                    i.FeatureName = this.FormatFeatureName(i);
                });

                this._AutocompleteTrigger.openPanel();
                return response.Items;
            })
        );
    }

    private ExecuteTextSearch(): Observable<FeatureSearchResponse> {
        const request = new SearchAutocompleteRequest();
        request.SearchValue = this.SearchValueFormControl.value;

        //  Always included if we have.  State will be used to filter.  County is only used to rank results.
        request.State = this._MapBase.CurrentStateAbbreviation;
        request.County = this._MapBase.CurrentCountyName;

        let bounds: Extent = null;
        switch (this.AreaConstraint) {
            case 2:
                bounds = this._MapBase.Map.getView().calculateExtent();
                break;
            case 3:
                bounds = this._MapBase.MapSearchFilterBounds;
                break;
        }
        if (bounds) {
            const llBounds = GeometryTransformer.TransformExtent(bounds, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION);
            request.Bounds = new LatLonBounds(llBounds[0], llBounds[2], llBounds[1], llBounds[3]);
        }

        return this._MapSearchService.Autocomplete(request);
    }

    private ExecuteTRSQSearch(searchValue: string): Observable<FeatureSearchResponse> {
        //  This has already been validated by IsValidTRSQ.  So all non-empty parts are a valid component.
        const parts = searchValue.split(' ');     

        const request = new SearchTRSQGridRequest();
        request.Township = parts[0];
        request.Range = (parts.length >= 2) ? parts[1] : undefined;
        request.Section = (parts.length >= 3) ? parts[2] : undefined;
        request.QtrSection = (parts.length >= 4) ? parts[3] : undefined;

        return this._MapSearchService.Grids(request);
    }

    public TogglePanel(): void {
        this.ShowPanel = !this.ShowPanel;
        this._MapBase.MapSearchIsVisible = this.ShowPanel;
        if (this.ShowPanel) {
            setTimeout(() => {
                if (this._Input?.nativeElement)
                    this._Input.nativeElement.focus();
            });

            this.HasState = coerceBooleanProperty(this._MapBase.CurrentStateAbbreviation);
            this.HasBoundsFilter = coerceBooleanProperty(this._MapBase.MapSearchFilterBounds);

            if (this.HasBoundsFilter)
                this.AreaConstraint = 3;
            else if (this.HasState)
                this.AreaConstraint = 1;
            else
                this.AreaConstraint = 0;
        }

        if (!this.ShowPanel)
            this.ClearSearchLayer();
    }

    public ClearSearchLayer(): void {
        if (this._SearchVectorLayer) {
            this._MapBase.Map.removeLayer(this._SearchVectorLayer.Layer);
            this._SearchVectorLayer = null;
        }

        this._CurrentFeatureOnMap = null;
    }

    public IsEmpty(): boolean {
        return !this._SearchVectorLayer;
    }

    public SetSearchType(searchType: SearchTypeEnum): void {
        if (this.SearchType !== searchType)
            this.ClearSearchValue();

        this.SearchType = searchType;

        //  Need to re-create this every time we set the search type or the text mask control
        //  will not know that the MaskFunction is (potentially) different.  Causes the mask function
        //  to not be changed until you type the first character - which may not be valid for the
        //  current search type (i.e. set to TRSQ grid, type character, switch to lat/lon - mask will
        //  still be set the mask from TRSQ until you type a character).
        this.MaskOptions = {
            mask: this.MaskFunction,
            guide: false
            //showMask: true            //  don't know what this does - did not see a difference with it set either way
        };

        switch (searchType) {
            case SearchTypeEnum.Name:
                this.SearchLabel = this.SearchByNameLabel;
                this.SearchPlaceholder = "search";
                this.SearchHint = "Enter intersection as: street & cross";
                break;
            case SearchTypeEnum.TRSQLong:
                this.SearchPlaceholder = "T##.#a R##.#a ## aa";
                this.SearchLabel = "TRSQ Grids: " + this.SearchPlaceholder;
                this.SearchHint = "Enter TRSQ grid as: T01.0S R04.0E 16 NW";
                break;
            case SearchTypeEnum.TRSShort:
                this.SearchPlaceholder = "##a ##a ###";
                this.SearchLabel = "TRS Grids: " + this.SearchPlaceholder;
                this.SearchHint = "Enter TRS grid as: 19S 30E 03 or 01S 23W 456";
                break;
            case SearchTypeEnum.LatLonCoord:
                this.SearchPlaceholder = this._LatLonCoordinateTextMaskService.DefaultPlaceholder();
                this.SearchLabel = "Lat/Lon Coordinate: " + this.SearchPlaceholder;
                this.SearchHint = "<div style='flex-grow:1'>Example: " + this._CommonService.SettingsService.LatLonCoordinateExample + "</div><div>ENTER to position</div>";
                break;
        }

        if (this._Input)
            setTimeout(() => this._Input.nativeElement.focus());
    }

    public ClearSearchValue(): void {
        this.SearchValueFormControl.setValue("");
        this._AutocompleteTrigger.closePanel();
    }

    //  Wraps a call to FeatureItemDisplay so that FeatureItemDisplay has the correct "this"
    //  when called by the mat-autocomplete...  https://github.com/angular/components/issues/3308
    public get FeatureItemDisplayFn(): any {
        return (val) => this.FeatureItemDisplay(val);
    }

    public FeatureItemDisplay(item?: FeatureItemResponse | string): string | undefined {
        if (!item)
            return "";

        if (typeof item === "string")
            return item;

        switch (item.FeatureTypeName) {
            case "Street":
                return item.EnteredStreetAddress;
            case "Township Grid":
            case "Range Grid":
            case "Section Grid":
            case "Quarter Section Grid":
                return this.FormatFeatureName(item);
            default:
                return item.FeatureName;
        }
    }

    //  TODO: This is only needed because the "Code" stored in the database is not formatted.
    //  When that gets formatted (which is also needed for the tooltip display), can remove this (and
    //  the other place that calls it) and move this back in to FeatureItemDisplay()
    private FormatFeatureName(item: FeatureItemResponse): string {
        switch (item.FeatureTypeName) {
            case "Township Grid":
            case "Range Grid":
            case "Section Grid":
            case "Quarter Section Grid": {
                //  Uses the text mask component to apply the mask to the FeatureName to create the
                //  display text.  See: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#included-conformtomask
                const conformResult = conformToMask(item.FeatureName, this.GridMask(), this.MaskOptions);
                return conformResult.conformedValue;
            }
            default:
                return item.FeatureName;
        }
    }

    public FeatureItemSelected(event: MatAutocompleteSelectedEvent): void {
        this.SelectItem(event.option.value);
    }

    //  Also called from html when item in the history list is picked
    public SelectItem(selectedItem: FeatureItemResponse): void {
        //  Need to set the search type in case we picked from the history and it's a different type
        this.SetSearchType(this.SearchTypeOfItem(selectedItem));

        //  If already in history, remove it - we'll insert it at the beginning to pop it to the front.
        //  Need to also check the display name because we could have searched for the same street name but with
        //  a different address number.
        const selectedItemDisplay = this.FeatureItemDisplay(selectedItem);

        //  Need to set the text of the selected item into the TextMaskValue.  If we don't, closing the
        //  search and re-opening will only show what had been typed before picking the item in the list.
        this.TextMaskValueFormControl.setValue(selectedItemDisplay, { onlySelf: true, emitEvent: false });

        this.History = this.History.filter(i => (i.ID !== selectedItem.ID) || (this.FeatureItemDisplay(i) !== selectedItemDisplay));

        this.History.splice(0, 0, selectedItem);

        if (this.History.length > 5)
            this.History.pop();

        if (!selectedItem.GeometryJson)
            this.GeocodeLocation(selectedItem);
        else
            this.ShowFeatureOnMap(selectedItem);
    }

    private SearchTypeOfItem(item: FeatureItemResponse): SearchTypeEnum {
        switch (item.FeatureTypeName) {
            case "Township Grid":
            case "Range Grid":
            case "Section Grid":
            case "Quarter Section Grid":
                if (this.MapSearchTRSQGridType === this.TRSQGridTypeEnum.TRSQ_Long)
                    return SearchTypeEnum.TRSQLong;
                if (this.MapSearchTRSQGridType === this.TRSQGridTypeEnum.TRS_Short)
                    return SearchTypeEnum.TRSShort;
                break;
            case "Lat/Lon":
                return SearchTypeEnum.LatLonCoord;
        }

        return SearchTypeEnum.Name;
    }

    public ShowFeatureOnMap(featureItem: FeatureItemResponse): void {
        if (!this._MapBase)
            return;

        const features = GeometryUtils.GeoJsonToFeatures(featureItem.GeometryJson);

        if (features && features.length > 0) {
            const geometryType = GeometryUtils.GeometryTypeOfFeatures(features);
            const extent = features[0].getGeometry().getExtent();

            if (geometryType === OLGeometryTypeEnum.Point) {
                this._MapBase.ZoomToCoordinate([extent[0], extent[1]], 17);
                this.AddFeatureToMap(featureItem.GeometryJson, this.FeatureItemDisplay(featureItem));
                //  Not (currently) doing anything to highlight POIs - don't think that would work very well
                //  considering they are part of the tile.  If we placed another symbol, there's no way to remove
                //  the one from the map tile so don't think we could match up with or cover that very well.
            } else {
                this._MapBase.ZoomToExtent(extent);
                this.AddFeatureToMap(featureItem.GeometryJson, this.FeatureItemDisplay(featureItem));
            }
        }

        this._CurrentFeatureOnMap = featureItem;
    }

    private AddFeatureToMap(geoJson: object, name: string): void {
        if (!this._MapBase?.Map)
            return;

        if (!this._SearchVectorLayer) {
            //  Create and initialize the Search layer
            this._SearchVectorLayer = new SearchVectorLayer(this._MapBase.MapToolService);
            this._MapBase.Map.addLayer(this._SearchVectorLayer.Layer);
        }

        this._SearchVectorLayer.VectorSource.clear();
        this._SearchVectorLayer.LoadGeoJSON(geoJson, name);
    }

    private GeocodeLocation(featureItem: FeatureItemResponse): void {
        //  This will also handle counties and places
        this._MapSearchService.GeocodeLocation(featureItem.State, featureItem.CountyName, featureItem.PlaceName, featureItem.EnteredStreetAddress, featureItem.EnteredCrossStreet)
            .subscribe(geocode => {
                //  This should not fail to geocode since we picked from a list.  But did have an issue where the search
                //  was returning places that are not actually searchable.  So doing this check to make sure we don't
                //  cause a js error if something like that happens.
                if (geocode && geocode.GeometryJson) {
                    featureItem.GeometryJson = geocode.GeometryJson;
                    this.ShowFeatureOnMap(featureItem);
                }
            });
    }

    public ShowPlace(state: string, county: string, place: string): void {
        const featureItem = new FeatureItemResponse("Place", place, null);
        featureItem.State = state;
        featureItem.CountyName = county;
        featureItem.PlaceName = place;

        this.GeocodeLocation(featureItem);
    }

    //  mask: https://github.com/text-mask/text-mask/tree/master/angular2/#readme
    //  mask issues on a mat-input control:
    //      https://github.com/angular/angular/issues/16755
    private MaskFunction = (rawValue: string) => {
        switch (this.SearchType) {
            case SearchTypeEnum.Name:
                return false;       //  No mask on regular search
            case SearchTypeEnum.TRSQLong:
            case SearchTypeEnum.TRSShort:
                return this.GridMask();
            case SearchTypeEnum.LatLonCoord: {
                const result = this._LatLonCoordinateTextMaskService.BuildMaskAndPlaceholder(rawValue);
                setTimeout(() => this.SearchPlaceholder = result.placeholder);
                return result.mask;
            }
        }
    };

    private GridMask(): any {
        switch (this.MapSearchTRSQGridType) {
            case MapSearchTRSQGridTypeEnum.TRSQ_Long:
                //  AZ grids look like this: "T24.0S R25.0E 18 NW"
                return ['T', /\d/, /\d/, '.', /\d/, /[nNsS]/, ' ', 'R', /\d/, /\d/, '.', /\d/, /[eEwW]/, ' ', /\d/, /\d/, ' ', /[nNsS]/, /[eEwW]/];
                break;
            case MapSearchTRSQGridTypeEnum.TRS_Short:
                //  FL uses this format.  Their grid codes vary a bit...
                //      Most are formatted like this: "67S26E28"
                //      Some (~800) are like this: "02N13E188"  (with a 3 digit section)
                //      Then there are ~30 odd balls such as:
                //          04N24E3747, 06S18E3334, 56S42E1019, 595S32E9999, 59S355E100, 9999N9999E9999
                //      Query to find oddballs:
                //          select "Code" from "GridSections" 
                //              where (substring("Code", 3, 1) <> 'N' and substring("Code", 3, 1) <> 'S')  
                //                  or (substring("Code", 6, 1) <> 'E' and substring("Code", 6, 1) <> 'W')
                //                  or length("Code")< 8  or length("Code") > 9
                //              order by "Code"
                //  Nothing starts with an 8 so can exclude that.
                return [/[0-7,9]/, /\d/, /[nNsS]/, ' ', /\d/, /\d/, /[eEwW]/, ' ', /\d/, /\d/, /\d/];
            default:
                return false;       //  No mask on regular search
        }
    }

    private PositionToLatLonCoordinate(): void {
        const searchValue = this.SearchValueFormControl.value;
        const coord = this._LatLonCoordinateTextMaskService.ParseLatLonString(searchValue);
        if (!coord)
            return;

        //  US bounds:
        //      Lon (X) = -126 to -64
        //      Lat (Y) = 50 to 23
        //  4iq office = -83.0911, 40.0882
        //  FL office = -81.3119, 28.8753
        //  AZ office = -111.9619, 33.3438
        //  DigSafe office = 42.59252, -71.14964  (they prompt as lat/lon - reverse from other centers)
        if ((coord.x < -126) || (coord.x > -64) || (coord.y < 23) || (coord.y > 50))
            return;

        const geoJson = {
            "type": "Point",
            "coordinates": [coord.x, coord.y]
        };

        const item = new FeatureItemResponse("Lat/Lon", null, geoJson);
        item.FeatureTypeName = "Lat/Lon";
        item.FeatureName = searchValue;
        this.SelectItem(item);
    }

    //  This is the text-mask control configuration.  It's set in SetSearchType because we need to re-create it
    //  every time the SearchType changes or the text-mask will not pick up the change in the MaskFunction
    //  until the first character is typed.
    public MaskOptions = {};

    private _IgnoreNextFocus: boolean = false;
    public OnSearchMaskedInputFocused(): void {
        if (this._IgnoreNextFocus) {
            this._IgnoreNextFocus = false;
            return;
        }

        //  Don't open the panel if we have no input.  Otherwise, it conflicts with clicking the close button
        //  and flashes it open with the previous contents.  The panel is always opened when we fetch new results.
        const formControl = this.SearchValueFormControl;
        if (formControl.value && !this._AutocompleteTrigger.panelOpen) {
            //  We have something entered but the autocomplete panel is closed.  Need to open it.
            if (this._AutocompleteTrigger.autocomplete.options.length === 0) {
                //  If no options in the list, it (probably) means that we just selected an item - which clears out the
                //  results!  So we need to trigger an input change in the text-mask input control.  Which will then
                //  fire the property change on the formcontrol and trigger a new search (which will then finally open the panel).
                //  And for some reason, if we just change the value of the formcontrol, it wipes out the contents
                //  of the text-mask input control.  So need to do it this way.
                //  See also: https://github.com/text-mask/text-mask/issues/696
                formControl.setValue(this.TextMaskValueFormControl.value);
            } else
                this._AutocompleteTrigger.openPanel();
        }
    }

    public OnSearchMaskedInputKeyDown(event: KeyboardEvent): void {
        if ((this.SearchType === SearchTypeEnum.LatLonCoord) && (event.key === "Enter")) {
            //  Lat/lon coord does not have an autocomplete list so trigger position here
            this.PositionToLatLonCoordinate();
        }

        //  In order for the autocomplete panel to respond to keyboard events, we need to catch them in the input control
        //  with the text-mask and forward them to the input control that is attached to the autocomplete.
        //  We could also hack the autocomplete to manually set the selected item but that requires using private methods
        //  to set the selection.  So this is "cleaner"...?
        switch (event.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Enter":
            case "Escape":
                this.ForwardKeyboardEventToAutocompleteInput(event);
                event.stopPropagation();
                event.preventDefault();
                break;
        }
    }

    private ForwardKeyboardEventToAutocompleteInput(event: KeyboardEvent): void {
        //  This forwarding does not work in old versions of Chrome - probably the ones that don't support the .key
        //  property because it gets polyfilled.  Oh well - they should upgrade and be happy that everything else works.
        const args = {
            'bubbles': true,
            'cancelable': true,
            'code': event.code,             // 'ArrowDown',
            'key': event.key,               // 'ArrowDown',
            'charCode': event.charCode,     // 40,
            'keyCode': event.keyCode,       // 40,
            'which': event.which,           // 40,
            'view': window
        }
        const keyEvent = new KeyboardEvent(event.type, args);

        //  This is required by Edge (at least for version 42.17134.1.0) or the event does not seem to get received/recognized by the target.
        //  Despite the docs saying that it's deprecated!!!
        //      https://msdn.microsoft.com/en-us/data/ff975297(v=vs.71)
        //      https://msdn.microsoft.com/en-us/data/dn905219(v=vs.71)
        //  It does not affect the other browsers though so seems safe to do this all the time.
        //  initKeyboardEvent seems to be a deprecated api though so checking to make sure it exists.
        const e: any = keyEvent;
        if (e.initKeyboardEvent)
            e.initKeyboardEvent(event.type, true, true, window, event.key, 0, null, false, null);

        //console.warn("DispatchKeyEventToAutocompleteInput", keyEvent, this._AutocompleteInput, keyEvent.initKeyboardEvent);
        this._AutocompleteInput.nativeElement.dispatchEvent(keyEvent);
    }

    public OnMenuOpened(): void {
        //  If the search input control did not already have focus, it's about to get it.
        //  Ignore the focus event so that it doesn't flash any existing autocomplete panel.
        this._IgnoreNextFocus = true;
        setTimeout(() => {
            this._AutocompleteTrigger.closePanel();
            this._IgnoreNextFocus = false;
        });
    }

    //  For the help tooltip shown via [tooltip] directive provided by the TooltipModule package.
    //  Only need to specify overrides.  Defaults are in Shared/Tooltips/ToolTipOptions.ts.
    public ToolTipOptions: TooltipOptions = {
        "placement": "right",
        "autoPlacement": false
    }
}
