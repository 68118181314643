export enum ServiceAreaTypeEnum {
    Full = 0,
    LBP = 1,
    Landlord = 2,
    New = 3,
    Test = 4,
    Principal = 5,
    General = 6,
    NonUtility = 7,
}

export enum ServiceAreaTypeEnumDescriptions {
    Full = "Full",
    LBP = "LBP",
    Landlord = "Landlord",
    New = "New",
    Test = "Test/In-House",
    Principal = "Principal",
    General = "General",
    NonUtility = "Non-Utility",
}
