import { TooltipOptions } from "./TooltipOptions.interface"

//  Some defaults changed for Exactix from the original source defaults.
//  Only need to specify the options that are different from these defaults.
export const defaultOptions: TooltipOptions = {
    placement: 'top',
    autoPlacement: true,
    contentType: 'template',                //  Exactix default, was "string"
    showDelay: 0,
    hideDelay: 300,
    hideDelayMobile: 300,                   //  Exactix default, was 0
    hideDelayTouchscreen: 0,
    zIndex: 0,
    animationDuration: 300,
    animationDurationDefault: 300,
    trigger: 'hover',
    tooltipClass: 'iq-tooltip',             //  Exactix default, was ""
    display: true,
    displayMobile: true,
    displayTouchscreen: true,
    shadow: true,
    theme: 'dark',
    offset: 15,                             //  Exactix default, was 8
    maxWidth: '',
    hideDelayAfterClick: 2000
}
