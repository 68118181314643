import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileVerificationReminder } from 'Models/ProfileVerifications/ProfileVerificationReminder.model';

@Component({
    selector: 'iq-verification-reminders',
    templateUrl: './ProfileVerificationRemindersList.component.html',
    styleUrls: ['./ProfileVerificationRemindersList.component.scss']
})

export class ProfileVerificationRemindersListComponent {
    @Input()
    public VerificationReminders: ProfileVerificationReminder[];

    constructor(private _router: Router) {
    }

    public ServiceAreaClicked(serviceAreaID: string): void {
        this._router.navigateByUrl("/profileverification/details/" + serviceAreaID);
    }
}
