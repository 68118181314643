import { coerceNumberProperty } from '@angular/cdk/coercion';
import { formatNumber } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFileExport, faRedo, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { ListColumnGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListColumnGroup.model';
import { ListFilterGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListFilterGroup.model';
import { SelectOption } from '@iqModels/Configuration/SelectOption.model';
import { IEntity } from '@iqModels/Interfaces/IEntity.interface';
import { SearchColumn } from '@iqModels/Searching/SearchColumn.model';
import { SearchFilter, SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { SearchOrderBy } from '@iqModels/Searching/SearchOrderBy.model';
import { SearchRequest } from '@iqModels/Searching/SearchRequest.model';
import { SearchResponse } from '@iqModels/Searching/SearchResponse.model';
import { ConfirmationDialogComponent } from '@iqSharedComponentControls/Dialog/Confirmation/ConfirmationDialog.component';
import { DialogModel } from '@iqSharedComponentControls/Dialog/Models/Dialog.model';
import { ListFilterService } from '@iqSharedComponentControls/Lists/Filters/Services/ListFilter.service';
import { SortFilterEvent } from '@iqSharedComponentControls/Lists/Header/ListColumnHeader.component';
import { ListColumnService } from '@iqSharedComponentControls/Lists/ListColumn.service';
import { Dictionary } from '@iqSharedUtils/Dictionary';
import { EntityEnum } from 'Enums/EntityType.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import _ from 'lodash';
import { BehaviorSubject, forkJoin, merge as observableMerge, Observable, of as observableOf, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, merge, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { PermissionsService } from 'Services/PermissionsService';
import { CRUDBaseService } from 'Shared/BaseServices/CRUDBase.service';
import { Guid } from 'Shared/Utils/Guid';
import { InformationDialogComponent } from '../Components/Controls/Dialog/Information/InformationDialog.component';
import { IconButtonComponent } from '../Components/Controls/IconButton/IconButton.component';

export enum ListActions {
    Select,

    Delete,

    Add,
    Remove,

    Edit
}

@Injectable({ providedIn: 'root' })
export class BaseListDisplayPageClassService {
    constructor(public busyService: BusyService, public dialog: MatDialog, public permissionService: PermissionsService,
        public listColumnService: ListColumnService, public listFilterService: ListFilterService, public AuthenticationService: AuthenticationService)
    { }
}

//A base class for any list pages we create, i.e. Person list, Excavator lists, etc.  This will do most of the shared work needed between them so they just need HTML and a few overrides.

//  TODO: This class should have a generic type on it so that everything that is IEntity can be typed properly (including the CRUD Service)!
@Directive()
export abstract class BaseListDisplayPageClass implements OnInit, OnDestroy {

    //virtual scroll stuff.  Put in hacky because we're just testing it out on the alerts page.  If we make it more used then we want to make this
    //  better integrated

    virtualScrollItems: IEntity[];
    useVirtualScroll = false;
    protected virtualScrollPageLoaded() {
        this._refreshSearch.pipe(takeUntil(this.destroyed$)).subscribe(() => this.currentPage = 1);

        //this.refreshPage.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        //    this.ClearSelectedItems();
        //});

        const filterChangeObs = this.filter.filterChange.pipe(/*debounceTime(300),*/ takeUntil(this.destroyed$), tap((clearSelected) => {
            this.currentPage = 1;

            //Doing a new search, need to clear what's selected
            if (clearSelected)
                this.ClearSelectedItems(true);
        }));

        let searchObservable = observableMerge<any>(this._refreshSearch, this.refreshPage, filterChangeObs);
        const newObs = this.AddToSearchObservable();
        if (newObs !== null)
            searchObservable = searchObservable.pipe(merge(newObs));

        searchObservable.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            if (!this.loading)
                this.virtualScrollLoadItems(true);
        });

        this.loading = true;//Just to make sure the paging event doesn't get thrown from the virtual scroll.  Probably not needed, but just being safe since this is just quickly added in for testing the virtual scroll on our lists
        this.virtualScrollLoadItems(true);
    }

    private virtualScrollLoadItems(replaceItems = false) {
        this.services.busyService.showByKey(this.busyKey);

        this.apiError = false;

        return this.GetFiltersAndCallServer().pipe(catchError((err) => {
            //Catch error here so that we don't kill the stream and it will continue to work if one call causes an error
            this.apiError = true;

            //console.log(err);

            return observableOf(new SearchResponse());
        }),
            map(data => {
                // Flip flag to show that loading has finished.
                this.services.busyService.hideByKey(this.busyKey);

                this.resultsLength = data.TotalCount;
                this._MaxPageNumber = Math.ceil(data.TotalCount / this.defaultPageSize);

                return this.MapSearchResults(data);
            }),
            catchError((err) => {
                //Catch error in mapping
                this.apiError = true;

                console.log(err);

                return observableOf([]);
            }))
            .subscribe(data => {
                //if (this.list && this.list.nativeElement.scroll)
                //    this.list.nativeElement.scroll(0, 0);

                //Prevent it from tossing an error about items changing on the first load if there is nothing returned.
                //  Happens if someone tries to view the details of something they don't have permission to see and get booted to the search page and don't
                //  have any items they can see.  Ideally if they don't have permission to view any items it would take them somewhere else, but that's not
                //  getting address right now because it may be a huge pain (may be easy with a route guard, but don't have time to test it right now and
                //  it's not a high priority)
                if (this.items.value === null && (data === null || data.length === 0))
                    return;

                data = this.OnPageReceived(data);

                if (data) {
                    if (replaceItems) {
                        this.virtualScrollItems = data;
                    }
                    else {
                        //Don't replace the Items with a new list or the virtual scroll will re-draw itself.  Just add them to it.
                        data.forEach(f => this.virtualScrollItems.push(f));
                    }
                }
                else
                    this.virtualScrollItems = [];

            }, err => console.log(err), () => this.loading = false);
    }

    loading = false;
    virtualScrollFetchNextPage($event) {
        if (!this.virtualScrollItems || this.virtualScrollItems.length >= this.resultsLength || this.loading)
            return;

        this.loading = true;
        this.currentPage++;
        this.virtualScrollLoadItems();
    }

    //virtual scroll stuff.  Put in hacky because we're just testing it out on the alerts page.  If we make it more used then we want to make this
    //  better integrated

    protected destroyed$: Subject<void> = new Subject();
    exportImage = faFileExport;
    refresh = faRedo;
    downloadImage = faDownload;

    //  These are fetched (or could be manually populated) via the crudservice.  A UI component should never
    //  be creating these lists since we are supposed to be fetching search results from the api - which is a "service"
    //  responsibility, not a UI component.
    private _AvailableColumns: SearchColumn[];
    public get AvailableColumns(): SearchColumn[] { return this._AvailableColumns; }

    private _AvailableFilterColumns: SearchColumn[];
    get AvailableFilterColumns(): SearchColumn[] { return this._AvailableFilterColumns; }

    @Input() ShowColumnSelector = true;
    @Input() ShowFilterSelector = true;
    @Input() SearchColumns: string[];
    @Input() FetchAdditionalColumns: string[];      //  List of additional columns to fetch from the server but that are not displayed
    @Input() SearchFilter: SearchFilter[];
    @Input() SearchOrderBy: SearchOrderBy[];
    /**
     * Used to Determine if the view/filter the user is viewing should be saved and redisplayed next time the list is shown.
     */
    @Input() UseLastViewAndFilter: boolean = true;

    defaultPageSize: number = 25;       //  *** This is not the DEFAULT page size - it's the CURRENT page size!
    pageSizeOptions: number[] = [25, 50, 100];

    currentPage: number = 1;

    public GetIcon(iconName: any) {
        return IconButtonComponent.GetIconSettingsForName(iconName, "Button")?.icon;
    }

    public pageChanged(newPage: number): void {
        this.currentPage = newPage;
        this.RefreshPage(true);
    }

    public pageSizeChanged(): void {
        //  Stash the page size in the service so that it's remembered if we leave the search page and come back
        //  Ticket\ServiceAreaList passes null for the service so make sure to handle that!
        if (this.service)
            this.service.LastPageSize = this.defaultPageSize;

        this.currentPage = 1;//Change the page they are on to 1 because the pages are all going to change.
        this.RefreshPage(true);
    }

    /**
     *  The total number of results that can be paged.  This limits the pager when there are thousands of pages
     *  in the query.  Skipping to the last page of a massive query can be a huge performance problem for the database
     *  because it has to execute the filtered/sorted query and then scan to the end of the results in order to
     *  figure out which items are on that page.
     *  If the list uses this limit, it should use this value for the "totalItems" value in the paginator directive
     *  and also add the "* paging limited to ..." message.
     */
    public get MaxRowsForPaging(): number {
        //  10,000 is still a really big number of rows to page on.  Would really like this to be 1,000...
        return this._resultsLength > 10000 ? 10000 : this._resultsLength;
    }

    @Output() ResultCountChange: EventEmitter<number> = new EventEmitter();

    private _resultsLength = 0;
    set resultsLength(val: number) {
        this._resultsLength = val;

        this.ResultCountChange.emit(val);
        this.UpdateSelectedCount();
    }
    get resultsLength() {
        return this._resultsLength;
    }

    private _MaxPageNumber: number;

    apiError = false;

    DisplayedColumns: SearchColumn[];

    EntityType: EntityEnum;//TODO: Make this abstract so that all lists have to set it.

    //Use this if we need to store the filter with something other than the EntityEnum value.  Needed if we are showing a list multiple times on a page with dfferent filters, etc
    @Input() StoreFilterKeyValue: number;

    protected get StoredFiltersKey(): number {
        if ((this.StoreFilterKeyValue !== null) && (this.StoreFilterKeyValue !== undefined))
            return this.StoreFilterKeyValue;

        return this.EntityType;
    }

    public UseStoredFilters(): boolean {
        const key = this.StoredFiltersKey;
        return (key !== null) && (key !== undefined);
    }

    private _refreshSearch: Subject<boolean> = new Subject();
    refreshSearch(clearSelected: boolean = false) {
        if (clearSelected)
            this.ClearSelectedItems(true);
        this._refreshSearch.next(true);
    }
    refreshPage: Subject<boolean> = new Subject();
    RefreshPage(clearSelected: boolean = false) {
        if (clearSelected)
            this.ClearSelectedItems(true);

        this.refreshPage.next(true);
    }

    //The text to show on the delete dialog
    DeleteText: string = "You are about to permanately delete this item.  Once removed this item will no longer be available.";

    /**
     *  If there are multiple lists on the same page (including in different tabs on the same page),
     *  set the "id" of the paginator to this property on *BOTH* the element that contains the
     *  "paginate" directive (in the options as: "id: PaginatorID" ) and also on the
     *  pagination-controls as [id]="PaginatorID".
     *  Not doing this can lead to "expression has changed" errors because some parts of the list
     *  seem to be stashed in the page so the page counts don't match or update themselves correctly!
     */
    public PaginatorID: Guid = Guid.newGuid();

    protected rawItems: IEntity[] = new Array<IEntity>();
    public items: BehaviorSubject<IEntity[]> = new BehaviorSubject<IEntity[]>(null);//Use a behavior subject so we can manually add a value on new without having to save it and then refresh the list.  This way we can add one in and if the user never enters enough info it won't be saved.
    public selectedItems: IEntity[] = new Array<IEntity>();

    protected MaxExportRows: number = 10000;

    //  TODO: Could we please pick better names?  "service" and "services" right next to each other?!?!?!  How is someone to know the difference?!?!?!
    constructor(protected service: CRUDBaseService<IEntity>, protected services: BaseListDisplayPageClassService) {
        //  Ticket\ServiceAreaList passes null for the service so make sure to handle that!
        this.defaultPageSize = service?.LastPageSize ?? 25;

        services.AuthenticationService.CurrentUserObserver()
            .pipe(take(1))
            .subscribe(user => {
                if (user && user.OneCallCenterSettings) {
                    if (user.IsLocalUser && user.OneCallCenterSettings.MaxExportRowsLocal)
                        this.MaxExportRows = coerceNumberProperty(user.OneCallCenterSettings.MaxExportRowsLocal);
                    else if (!user.IsLocalUser && user.OneCallCenterSettings.MaxExportRowsExternal)
                        this.MaxExportRows = coerceNumberProperty(user.OneCallCenterSettings.MaxExportRowsExternal);
                }
            });
    }

    ngOnInit(): void {
        this.CreateObservable();
        this.SetActionsList();

        this.SetDefaultOrderAndFilterItems();

        //  May or may not need to load the users saved columns & filters.  But need to always fetch the SearchColumns
        //  from the entity CRUD service (which itself may just return null if it doesn't define them).  This allows us to do all 3
        //  in 1 observable so that we know when everything is available.
        const loadUserColumnsAndFilters = this.UseStoredFilters() && this.UseLastViewAndFilter;
        const userCurrentDisplayedColumnsObs = loadUserColumnsAndFilters ? this.services.listColumnService.GetUserCurrentDisplayedColumns(this.StoredFiltersKey).pipe(take(1)) : of(null);
        const userCurrentDisplayedFiltersObs = loadUserColumnsAndFilters ? this.services.listFilterService.GetUserCurrentDisplayedFilters(this.StoredFiltersKey).pipe(take(1)) : of(null);

        forkJoin(userCurrentDisplayedColumnsObs, userCurrentDisplayedFiltersObs, this.service.GetAvailableSearchColumnsAndFilters().pipe(take(1)))
            .subscribe((val: [ListColumnGroup, ListFilterGroup, { columns: SearchColumn[], filters: SearchColumn[] }]) => {

                if (val[2]) {
                    this._AvailableColumns = val[2].columns;
                    this._AvailableFilterColumns = val[2].filters;
                }

                //  If the derived class set SearchColumns, configure the DisplayedColumns from that list (matched against
                //  the _AvailableColumns).  The derived class may also override SetDefaultColumns() to set the columns.
                //  Otherwise, we will set DisplayedColumns to the results returned for the users saved columns.
                if (!this.DisplayedColumns || (this.DisplayedColumns.length === 0))
                    this.SetDefaultColumns();

                //  These 2 are used (via a getter) when showing columns and filters.  But, SetDefaultColumns() also uses to build
                //  DisplayedColumns and the report QueryViewer component needs the UNSORTED column list so that the column order matches
                //  what was returned by the /SearchColumns api.  So do not sort this list until after SetDefaultColumns() has been called.
                //  So many columns lists...what a mess...  Would be better for the component that needs a sorted list to sort it itself.
                //  But who knows how many of those places there are so this was easier and fixed the issue with the Query Viewer.
                //  ** .sort() mutates the array instance so we clone it to prevent the original object from being sorted.
                //     Not doing this would cause the displayed columns being sorted if we view the page again (or edit and save).
                if (this._AvailableColumns)
                    this._AvailableColumns = _.cloneDeep(this._AvailableColumns).sort((a, b) => a.name.localeCompare(b.name));
                if (this._AvailableFilterColumns)
                    this._AvailableFilterColumns = _.cloneDeep(this._AvailableFilterColumns).sort((a, b) => a.name.localeCompare(b.name));

                if (val[0] && this._AvailableColumns && (!this.DisplayedColumns || (this.DisplayedColumns.length === 0))) {
                    //  Build the DisplayedColumns from the users saved columns
                    const cols = val[0];
                    const newCols = [];
                    this.DisplayedColumns = [];
                    for (let i = 0; i < cols.Columns.length; i++) {
                        const col = this._AvailableColumns.find(f => f.column === cols.Columns[i]);
                        if (col)
                            newCols.push(col);
                    }

                    this.DisplayedColumns = newCols;
                }

                if (val[1]) {
                    this.filter.ColumnFilters = val[1].Filters || [];
                    this.filter.OrderBy = val[1].OrderBy;
                }

                //  Do the initial search to populate the page
                this._refreshSearch.next(true);

                this.OnColumnsSet();
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.services.busyService.hideByKey(this.busyKey);
    }

    protected OnColumnsSet(): void {
    }

    //Override this to set the default file name when the download dialog comes up.
    exportAsCsvFileName: string;
    exportAsCsv() {
        if (this.MaxExportRows > 0 && (this.resultsLength > this.MaxExportRows)) {
            this.services.dialog.open(InformationDialogComponent, {
                panelClass: 'iq-info',
                data: new DialogModel("Info",
                    "The max number of items you can export is " + formatNumber(this.MaxExportRows, "en-US") + ". Please update your filter to have a smaller result.",
                ),
                width: '50%'
            });
            return;
        }

        //Set the filters if any
        const searchRequest = this.ApplySearchFilters();
        searchRequest.OrderBy = this.GetOrderBy();
        searchRequest.Columns = this.GetSearchColumns();

        searchRequest.PageNum = 1;
        searchRequest.PageSize = this.MaxExportRows;

        if (this.EntityType)
            searchRequest.EntityType = this.EntityType;

        this.service.ExportList(searchRequest, this.exportAsCsvFileName);
    }

    //  This is used to indicate if the selectedItems array is of items selected or not selected.  i.e. if the user picks select all the start unselecting
    //  items we want those ids so that we know to apply the action on all except those.  We need this because we will have the ID of the items the user
    //  checks/unchecks but we won't have IDs from items on different pages, etc.
    protected selectedItemsAreExcludes = false;

    /*
     *  If false (default), action api calls can specify the current filter.  Allows executing actions on more items that
     *  can fit in a page.  If select-all is used and there are more items in the current filter than fit on a page,
     *  selectedItemsAreExcludes is set to trye and selectedItems will be empty (or will contain items to exclude).
     *  If true, action api calls do NOT include the current filter and only operate on a list of IDs.  This will cause us to
     *  never use selectedItemsAreExcludes and the selected items wil be capped at the current page size.
     */
    protected LimitSelectionsToCurrentPage: boolean = false;

    allSelected: boolean = false;

    //We got rid of the select all for now.  This is because we want it to work so that if they filter down and click the select all it should only select the filtered ones. It's a problem if it's paged because we should also be able to pick other items then filter and select all.
    //  If we decide to use, or not use this, search for all references to the variable and clean it up.
    allSelectedChange(val: MatCheckboxChange) {
        //If this is added back in check any action overrides and make sure it's handled.  i.e. Delete on people (Can't delete self), etc.

        this.ShowSelect = val.checked;

        if (val.checked) {
            //Only want to exclude if we have so many items that they don't all fit on the same page
            this.selectedItemsAreExcludes = !this.LimitSelectionsToCurrentPage && (this.resultsLength > this.defaultPageSize);
            this.allSelected = true;
            if (this.selectedItemsAreExcludes) {
                this.selectedItems = new Array<IEntity>();
            }
            else
                this.selectedItems = this.rawItems.slice();

            this.rawItems.forEach(item => item.Selected = true);
            this.items.next(this.rawItems);

            this.selectedCount = this.LimitSelectionsToCurrentPage ? this.rawItems.length : this.resultsLength;
        }
        else {
            this.ClearSelectedItems();
        }
    }

    selectedCount: number = 0;

    protected UpdateSelectedCount() {
        if (!this.LimitSelectionsToCurrentPage && (this.allSelected || this.selectedItemsAreExcludes))
            this.selectedCount = this.resultsLength - this.selectedItems.length;
        else
            this.selectedCount = this.selectedItems.length;
    }

    protected ClearSelectedItems(fetchingNewRecords: boolean = false) {
        this.selectedItemsAreExcludes = false;
        this.allSelected = false;
        this.selectedItems = new Array<IEntity>();
        this.UpdateSelectedCount();
        this.ShowSelect = false;

        if (!fetchingNewRecords) {
            this.rawItems.forEach(item => item.Selected = false);
            this.items.next(this.rawItems);
        }
    }

    selectedAction: SelectOption = new SelectOption(ListActions.Select, "Select");
    actions: BehaviorSubject<SelectOption[]> = new BehaviorSubject([new SelectOption(ListActions.Select, "Select")]);

    compareAction(ct1: SelectOption | null, ct2: SelectOption | null) {
        if (ct1 !== null && ct2 !== null)
            return ct1.Value === ct2.Value;

        return ct1 === null && ct2 === null;
    }

    protected SetActionsList(): void {
        this.SetActionList().pipe(take(1)).subscribe(list => this.actions.next(list));
    }

    protected SetActionList(): Observable<SelectOption[]> {
        /*For multiple here use the zip function to tie them together then map.  The result in the map function will an array of booleans.
         * example
        this.services.permissionService.CurrentUserHasPermission(this.DeletePermission)
            .zip(this.services.permissionService.CurrentUserHasPermission(this.EditPermission))
            .map(allowed => {
                let list: SelectOption[] = [new SelectOption(ListActions.Select, "Select")]
                console.log(allowed, 'edit', this.EditPermission);
                if (allowed[0])
                    list.push(new SelectOption(ListActions.Delete, "Delete"));
                if (allowed[1])
                    list.push(new SelectOption(ListActions.Delete, "Edit"));

                return list;
            });
        */
        return this.services.permissionService.CurrentUserHasPermission(this.service.DeletePermission).pipe(
            map(allowed => {
                const list: SelectOption[] = [];// [new SelectOption(ListActions.Select, "Select")]
                if (allowed)
                    list.push(new SelectOption(ListActions.Delete, "Delete", "delete_outline"));

                // Do this last because we don't want to show the actions dropdown if the person can't do anything.
                if (list.length > 0)
                    list.unshift(new SelectOption(ListActions.Select, "Select"));

                return list;
            }));
    }

    protected SetActionListForChild(): Observable<SelectOption[]> {
        return this.services.permissionService.CurrentUserHasPermission(this.service.EditPermission).pipe(
            map(allowed => {
                const list: SelectOption[] = [new SelectOption(ListActions.Select, "Select")]
                if (allowed) {
                    list.push(new SelectOption(ListActions.Add, "Add"));
                }

                return list;
            }));
    }

    /**
     * This is used for the '3 dot menu'.  It performs the action on a single item.
     * @param action
     * @param listItem
     */
    ActionClicked(action: SelectOption, listItem: any = null) {
        if (action.Value === ListActions.Delete)
            this.DeleteItems(listItem);
        else if (action.Value === ListActions.Edit)
            this.EditItem(listItem);
        else
            this.SpecialAction(action, listItem);
    }

    /**
     * This is used for the 'Actions dropdown' - check items in the list and then pick the action in the dropdown.
     * It executes the action on all selected items.
     * @param val
     */
    public ActionSelected(val: MatSelectChange): void {
        const actionSelected = () => {
            if (this.selectedAction.Value === ListActions.Delete)
                this.DeleteItems();
            else
                this.SpecialAction();

            this.selectedAction = new SelectOption(ListActions.Select, "Select");
        };

        if (this.selectedCount > this.defaultPageSize) {
            const msg = "You are going to " + this.selectedAction.Name + " "
                + (this.actionMaxAllowedNumber > 0 && this.selectedCount > this.actionMaxAllowedNumber ? (this.actionMaxAllowedNumber + " (max number allowed)") : this.selectedCount) + " items.";
            this.showItemsEffectedDialog(msg).subscribe(accept => {
                if (accept)
                    actionSelected();
            });
        }
        else
            actionSelected();
    }

    protected actionMaxAllowedNumber = 1000;
    protected showItemsEffectedDialog(dialogText: string) {
        return this.services.dialog.open(ConfirmationDialogComponent, {
            panelClass: 'iq-info',
            data: new DialogModel("Information",
                dialogText),
            width: '50%'
        }).afterClosed();
    }

    protected SpecialAction(action: SelectOption = null, listItem: any = null): void { }

    protected EditItem(listItem: any = null) { console.error('Need to implement EditItem!!!'); }

    protected DeleteItems(listItem: any = null) {
        if (!listItem && (!this.allSelected && (!this.selectedItems || this.selectedItems.length === 0)))
            return;

        const dialogRef = this.services.dialog.open(ConfirmationDialogComponent, {
            panelClass: 'iq-warn',
            data: new DialogModel("Warning!",
                this.DeleteText,
                "Delete",
                "Do you wish to continue?"),
            width: '50%'
        });
        dialogRef.afterClosed().subscribe((val) => {
            if (val) {
                if (!listItem) {
                    const filters = this.ApplySearchFilters();
                    this.service.DeleteMultiple(this.selectedItems.map(item => item.ID), this.selectedItemsAreExcludes, filters.Filters, this.GetOrderBy())
                        .pipe(take(1))
                        .subscribe(val => {
                            //Make sure we clear our the selcted items so if they delete the last item and add a new one that new one won't be selected
                            this.RefreshPage(true);
                        });
                }
                else {
                    this.service.Delete(listItem.ID).pipe(take(1)).subscribe(val => {
                        //Shouldn't be needed because the item should be deleted and gone after refresh, but this shouldn't hurt anything to be consistent
                        this.RefreshPage(true);
                    });
                }
            }
        });
    }

    //protected parentEntityID: string;
    //protected parentEntityPropertyName: string;
    //protected AddSelectedToParent() {
    //    if (this.allSelected || this.selectedItems.length > 0) {
    //        this.service.AddCollectionToEntity(this.parentEntityID, this.parentEntityPropertyName, this.selectedItems.map(item => item.ID), this.allSelected, this.allSelected && this.selectedItems.length !== 0).subscribe(data => {
    //            this.refreshPage.next(true);
    //            this.selectedItems = new Array<IEntity>();
    //        });
    //    }
    //}
    //protected RemoveSelectedFromParent() {
    //    if (this.allSelected || this.selectedItems.length > 0) {
    //        this.service.RemoveCollectionFromEntity(this.parentEntityID, this.parentEntityPropertyName, this.selectedItems.map(item => item.ID), this.allSelected, this.allSelected && this.selectedItems.length !== 0).subscribe(data => {
    //            this.refreshPage.next(true);
    //            this.selectedItems = new Array<IEntity>();
    //        });
    //    }
    //}

    //Need this to allow for overriding.  We may have a column that we need to manually set the sort order because we use special text (i.e. for booleans. We want the query on the server will order by the text values and not the boolean value)
    //  Boolean columns are now translated to text values of "Yes" or "No" by the server.  So we don't actually have any
    //  need for doing this any more.  But left it here anyway in case something changes.
    GetDescendingSortOrder(columnName: string, isAscending: boolean) {
        return !isAscending;
    }

    ClearSort(fireEvent: boolean = true) {
        this.filter.OrderBy = this.copyDefaultOrderBy();

        if (fireEvent)
            this.filter.filterChange.next(false);
    }


    ColumnSortFilterChange(column: SearchColumn, $event: SortFilterEvent) {
        this.SetOrderByFilter(column.column, $event);
        const filterChanged = this.SetFilterValuesChanged(column, $event.Filter);

        this.filter.filterChange.next(filterChanged);
    }

    protected SetFilterValuesChanged(column: SearchColumn, filter: SearchFilter): boolean {
        let filterChanged = false;

        const existingColumnFilters: number[] = [];//Not sure why this is needed...Shouldn't there only ever be one??
        this.filter.ColumnFilters.forEach((val, index) => {
            if (val.PropertyName === column.filterColumn || column.OtherFilterColumnNames.includes(val.PropertyName))
                existingColumnFilters.push(index);
        });

        if (existingColumnFilters.length > 0)
            filterChanged = true;

        //Need to go in reverse order or the list we are removing will shift and we'll miss some items
        for (let i = existingColumnFilters.length - 1; i >= 0; i--)
            this.filter.ColumnFilters.splice(existingColumnFilters[i], 1);

        if (filter && filter.Values.length > 0) {
            this.filter.ColumnFilters.push(this.GetSearchFilterObject(column, filter));
            filterChanged = true;
        }

        return filterChanged;
    }

    //By default do a contains for all columns.  Override this if you have a column that needs something different
    protected GetSearchFilterObject(column: SearchColumn, filter: SearchFilter): SearchFilter {
        return filter;
    }

    protected SetOrderByFilter(columnName: string, $event: SortFilterEvent) {
        const existing: number = this.filter.OrderBy.findIndex(f => f.PropertyName === columnName);

        const orderDescending = this.GetDescendingSortOrder(columnName, $event.SortAscending);

        if ($event.Sort) {
            //  For now at least, not supporting multiple column sorts.  See comments below.
            //  So always replace with the 1 column we have just been given.  If we had an existing,
            //  call UpdateDisplayedFilters() so that the other column will be notified to change itself (to not sorted).
            this.filter.OrderBy = [new SearchOrderBy(columnName, orderDescending)];
            if (existing !== -1)
                this.services.listFilterService.UpdateDisplayedFilters(this.StoredFiltersKey, this.filter.ColumnFilters, this.filter.OrderBy);

            /*  The stuff below supports multiple column sorts.  But we don't currently have any way for the user to
             *  see or alter the order of the columns.  This was changed to make it sort in reverse order (so the first
             *  column sort is on the last column the user picked) which helps.  But it's still confusing if you alter
             *  a sort column in the middle.  If we're going to support this, we're going to need to expose more functionality
             *  on it so that the user can specifically choose to sort by multiple columns (with default of not doing that)
             *  and to somehow see and change the order of the columns being sorted.
             *  Look at Google Sheets for possible example of how to do this.
            if (existing != -1) //Need to keep the order they checked them so that we know what to sort by first
                this.filter.OrderBy.splice(existing, 1, new SearchOrderBy(columnName, orderDescending));
            else {
                //  Insert new sort conditions at the beginning of the OrderBy array.  This causes the last sort that
                //  the user requested to be FIRST.  That is the same behavior as every other multiple column sort
                //  (such as Excel, Outlook, etc).
                //  Yes, it builds the sort conditions in reverse.  But it makes it easy for the user to change the
                //  sort - without having to first go and remove an existing sort column.  So it prevents all kinds of
                //  confusion about why a sort isn't working.
                this.filter.OrderBy.unshift(new SearchOrderBy(columnName, orderDescending));

                //  We should probably add another option to the sort controls so that the user needs to actually indicate
                //  that they want to keep existing columns in the sort vs. replace - to keep this list from growing.
                //  And default it to replace.
                //  For now, limiting the number of columns that can be sorted to 3 which should be plenty.
                if (this.filter.OrderBy.length > 3) {
                    this.filter.OrderBy.splice(3, this.filter.OrderBy.length - 3);
                    this.services.listFilterService.UpdateDisplayedFilters(this.StoredFiltersKey, this.filter.ColumnFilters, this.filter.OrderBy);
                }
            }
            */
        }
        else if (existing !== -1)
            this.filter.OrderBy.splice(existing, 1);
    }

    ClearFilters(fireEvent: boolean = true) {
        const lastSaved = this.services.listFilterService.getLastSavedFilter(this.StoredFiltersKey);
        this.filter.ColumnFilters = lastSaved ? lastSaved.Filters : [];
        this.filter.filterString = null;
        this.filter.filterTextValue = null;

        if (fireEvent)
            this.filter.filterChange.next(true);
    }
    protected ModifyFilterValues(columnName: string, filterValues: SearchFilterValue[]): SearchFilterValue[] {
        return filterValues;
    }

    //Called from the column chooser.
    columnChange(newColumns: SearchColumn[]) {
        //  Also clear the displayed list since we are changing the columns - prevents the page from flashing and having
        //  to update the columns with the existing rows while the api search is fetching new data.
        this.items.next([]);
        this.DisplayedColumns = newColumns;
        this._refreshSearch.next(true);
    }

    //Called from the filter buiilder.
    filterChange(filters: SearchFilter[]) {
        this.filter.ColumnFilters = filters || [];
        this.filter.filterChange.next(true);
    }

    _clearSortAndFilter: BehaviorSubject<boolean> = new BehaviorSubject(false);
    ClearSortAndFilters() {
        this._clearSortAndFilter.next(true);
        this.ClearFilters(false);
        this.ClearSort(false);

        this.filter.filterChange.next(true);
    }
    filter = {
        filterString: null,
        filterChange: new Subject<boolean>(),
        filterTextValue: null,
        ColumnFilters: [],//Needs to be seperate so that we can clear out the column filters, but keep the over all text filter (unless that part changes)
        OrderBy: null
    };
    defaultOrderBy = [new SearchOrderBy("Name")];
    copyDefaultOrderBy() {
        const orderBy = [];

        for (let i = 0; i < this.defaultOrderBy.length; i++) {
            orderBy.push(new SearchOrderBy(this.defaultOrderBy[i].PropertyName, this.defaultOrderBy[i].Descending));
        }

        return orderBy;
    }

    minCharsDefaultSearch: number = null;
    applyFilter(value: string): void {
        if (value)
            value = value.trim();

        if (value && value.length > 0) {//If we have a value and it hasn't met the required length, then return
            if (this.minCharsDefaultSearch && value.length < this.minCharsDefaultSearch)
                return;

            this.filter.filterTextValue = this.GetMainFilterPropertiesFilters(value);
        }
        else
            this.filter.filterTextValue = null;

        this.filter.filterChange.next(true);
    }
    protected GetMainFilterPropertiesFilters(value: string): SearchFilter {
        return new SearchFilter("Name", SearchFilterOperatorEnum.Contains, [new SearchFilterValue(value, value)]);
    }
    protected GetSearchColumns(): string[] {
        let columns = this.DisplayedColumns && this.DisplayedColumns.length > 0 ? this.DisplayedColumns.map(i => i.column) : null;

        //  These are additional columns we need to fetch from the server but that are not displayed in the list
        if (this.FetchAdditionalColumns)
            columns = [].concat(columns, this.FetchAdditionalColumns);

        return columns;
    }

    busyKey: string = this.services.busyService.createNew();

    protected SetDefaultOrderAndFilterItems(): void {
        //Override this if you need to set a default filter when the screen is loaded and not fetched from the server.
        //i.e. by default we check for them being passing in.

        if (this.SearchFilter) {
            this.filter.ColumnFilters = this.services.listFilterService.copyFilters(this.SearchFilter) || [];
            const filterGroup = new ListFilterGroup(null);
            filterGroup.Filters = this.SearchFilter;
            this.services.listFilterService.setLastSavedFilter(this.StoredFiltersKey, filterGroup);
        }

        if (this.SearchOrderBy) {
            this.filter.OrderBy = this.services.listFilterService.copyOrderBys(this.SearchOrderBy);
            this.defaultOrderBy = this.services.listFilterService.copyOrderBys(this.SearchOrderBy);
        }
    }

    @ViewChild("iqListItems") protected list: ElementRef;

    protected SetDefaultColumns(): void {
        //If the component doesn't get columns passed in, then this will be called to apply default ones
        if (!this.SearchColumns || !this._AvailableColumns)
            return;

        this.DisplayedColumns = [];
        this.SearchColumns.forEach(s => {
            const column = this._AvailableColumns.find(f => s === f.column);
            if (column)
                this.DisplayedColumns.push(column);
        });
    }

    protected GetSearchRequest() {
        //Set the filters if any
        const filters = this.ApplySearchFilters();
        filters.OrderBy = this.GetOrderBy();
        filters.Columns = this.GetSearchColumns();

        filters.PageNum = this.currentPage;
        filters.PageSize = this.defaultPageSize;

        if (this.EntityType)
            filters.EntityType = this.EntityType;

        return filters;
    }

    protected apiCall: (request: SearchRequest) => Observable<SearchResponse> = (request: SearchRequest): Observable<SearchResponse> => this.service.GetList(request);

    protected GetFiltersAndCallServer(storeFilters: boolean = true): Observable<SearchResponse> {
        //Set the filters if any
        const filters = this.GetSearchRequest();

        this.isFirstSearch = false;

        return this.apiCall(filters);
    }

    //Used for focusing after closing any dialogs that get opened i.e. adding a positive response
    focusActionMenuEvents: Dictionary<Subject<boolean>> = new Dictionary();

    protected MapSearchResults(results: SearchResponse): IEntity[] {
        let returnResult = [];

        this.focusActionMenuEvents = new Dictionary();

        //Update the saved/displayed filters to reflect what is being searched on
        //I don't think we want to save this if there is an error in the call, so here is a good place to do it.
        //Also doing it here will prevent errors from trying to update the view on the header columns in the middle of a digest cycle
        if (this.UseStoredFilters()) {
            if (results && results.Filters) {
                this.services.listFilterService.setLastSavedFilter(this.StoredFiltersKey, results.Filters);

                this.services.listFilterService.UpdateDisplayedFilterGroup(results.Filters);

                this.filter.ColumnFilters = results.Filters.Filters || [];
            }
            else {
                //Need to add in the text filter so that it re applies it when they come back
                const filters = this.services.listFilterService.copyFilters(this.filter.ColumnFilters);
                if (this.filter.filterTextValue)
                    filters.push(this.filter.filterTextValue);

                this.services.listFilterService.UpdateDisplayedFilters(this.StoredFiltersKey, filters, this.filter.OrderBy);
            }
        }

        if (results) {
            this.resultsLength = results.TotalCount;
            this._MaxPageNumber = Math.ceil(results.TotalCount / this.defaultPageSize);

            if (results.Columns) {
                this.services.listColumnService.LastSavedListGroup[this.StoredFiltersKey] = results.Columns;

                this.services.listColumnService.UpdateDisplayedColumns(results.Columns);

                const cols = [];
                for (let i = 0; i < results.Columns.Columns.length; i++) {

                    const found = this._AvailableColumns.find(f => f.column === results.Columns.Columns[i]);
                    if (found)
                        cols.push(found);
                }

                this.DisplayedColumns = cols;
            }

            if (results.Items) {
                results.Items.forEach(val => {
                    //Add events to allow focusing the action menu after an action is done so the user is kept at the same place acter dialogs
                    this.focusActionMenuEvents.Add(val.ID, new Subject<boolean>());

                    if (this.allSelected) {
                        val.Selected = true;
                    }
                    else if (this.selectedItemsAreExcludes) {
                        if (this.selectedItems.findIndex(s => s.ID === val.ID) < 0)
                            val.Selected = true;
                    }
                    else {
                        if (this.selectedItems.findIndex(s => s.ID === val.ID) > -1)
                            val.Selected = true;
                    }
                });

                returnResult = results.Items;
            }
        }
        else {
            this.resultsLength = 0;
            this._MaxPageNumber = 0;
        }

        return returnResult;
    }

    //  Can set this to false if we should NOT search - i.e. we are waiting for the page to fully initialize
    //  and fetch data that the filters depend on.
    protected CanSearch: boolean = true;

    //  TODO: This would be so much simplier if it was just done as a method that was called when needed.
    //  Right now, there are 3 different observables that all trigger this in slightly different ways.
    //  And then 3 of those have an additional subscription on them to handle those other "things" (like
    //  resetting the current page or clearing the selections).
    //  JUST MAKE A METHOD FOR THIS AND LET IT RUN ASYNC!
    protected CreateObservable(): void {
        if (this.useVirtualScroll)
            return;

        this._refreshSearch.pipe(takeUntil(this.destroyed$)).subscribe(() => this.currentPage = 1);

        //this.refreshPage.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        //    this.ClearSelectedItems();
        //});

        const filterChangeObs = this.filter.filterChange.pipe(debounceTime(300), takeUntil(this.destroyed$), tap((clearSelected) => {
            this.currentPage = 1;

            //Doing a new search, need to clear what's selected
            if (clearSelected)
                this.ClearSelectedItems(true);
        }));

        let searchObservable = observableMerge<any>(this._refreshSearch, this.refreshPage, filterChangeObs);
        const newObs = this.AddToSearchObservable();
        if (newObs !== null)
            searchObservable = searchObservable.pipe(merge(newObs));

        searchObservable.pipe(
            takeUntil(this.destroyed$),
            switchMap(() => {
                if (!this.CanSearch)
                    return observableOf(new SearchResponse());

                this.services.busyService.showByKey(this.busyKey);

                this.apiError = false;

                return this.GetFiltersAndCallServer().pipe(catchError((err) => {
                    //Catch error here so that we don't kill the stream and it will continue to work if one call causes an error
                    this.apiError = true;

                    //console.log(err);

                    return observableOf(new SearchResponse());
                }));
            }),
            map(data => {
                // Flip flag to show that loading has finished.
                this.services.busyService.hideByKey(this.busyKey);

                return this.MapSearchResults(data);
            }),
            catchError((err) => {
                //Catch error in mapping
                this.apiError = true;

                console.log(err);

                return observableOf([]);
            }))
            .subscribe(data => {
                if (this.list && this.list.nativeElement.scroll)
                    this.list.nativeElement.scroll(0, 0);

                //Prevent it from tossing an error about items changing on the first load if there is nothing returned.
                //  Happens if someone tries to view the details of something they don't have permission to see and get booted to the search page and don't
                //  have any items they can see.  Ideally if they don't have permission to view any items it would take them somewhere else, but that's not
                //  getting address right now because it may be a huge pain (may be easy with a route guard, but don't have time to test it right now and
                //  it's not a high priority)
                if (this.items.value === null && (data === null || data.length === 0))
                    return;

                data = this.OnPageReceived(data);
                this.rawItems = data;
                this.items.next(data);
                //  ** If "expression has changed" errors are thrown from the above, see the PaginatorID in this
                //  class for why and what to do about it
            });
    }

    //  Override if the derived class needs to do something when a page of items is received (like set a Font color for each item).
    protected OnPageReceived(items: IEntity[]): IEntity[] {
        return items;
    }

    protected AddToSearchObservable(): Observable<{}> {
        return null;//Use this to add any special observables for individual pages
    }

    protected isFirstSearch: boolean = true;

    //This method will be called on page load when it does the first search
    protected ApplySearchFilters(): SearchRequest {
        //Set the filters if any
        const searchRequest = new SearchRequest();

        searchRequest.Filters = [];
        //  Can specify filters like this:
        // AND
        //request.Filters = [new SearchFilter("Version", SearchFilterOperatorEnum.Equals, ['s'])];
        // OR
        //request.Filters = [new SearchFilter("AgentPerson.FirstName", SearchFilterOperatorEnum.Contains, ['et', 'jo'])];

        if (this.filter.filterTextValue) {
            this.filter.filterTextValue.QuickTextSearch = true;
            searchRequest.Filters.push(this.filter.filterTextValue);
        }

        if (this.filter.ColumnFilters && this.filter.ColumnFilters.length > 0)
            this.filter.ColumnFilters.forEach(val => searchRequest.Filters.push(val));

        //See if the filter has a value to be displayed in the 'quick text search' at the top of the page.  If so then set the value if there is only 1 value (Shouldn't be, but just to be safe for the future)
        if (this.isFirstSearch) {

            //If we have either the displayed columns or filters then we don't need to load either from the server.
            // May not want to automatically try to get it from the server if we don't have these, but for now we kind of want it to error if we don't have either of these
            searchRequest.LoadColumnsAndFilters = (!this.DisplayedColumns || this.DisplayedColumns.length === 0) && (!this.filter.ColumnFilters || this.filter.ColumnFilters.length === 0);

            let mainFilter: SearchFilter;

            for (let i = this.filter.ColumnFilters.length; i > 0; i--) {
                const index = i - 1;//Need to subtract one because we're going backwards

                if (this.filter.ColumnFilters[index].QuickTextSearch) {
                    mainFilter = this.filter.ColumnFilters[index];

                    //Remove it from the columns filters because it needs to be in the FilterTextValue property so if the user changes it it will get changed
                    this.filter.ColumnFilters.splice(index, 1);
                }
            }

            if (mainFilter) {
                //timeout so it happens last and we don't get a changed after checked error
                setTimeout(() => {
                    this.filter.filterString = mainFilter.Values[0].DisplayValue;
                    this.filter.filterTextValue = mainFilter;
                });
            }
        }

        //Needed so it will load the default filters
        if (searchRequest.Filters.length === 0)
            searchRequest.Filters = null;

        return searchRequest;
    }

    protected GetOrderBy(): SearchOrderBy[] {

        //Always have to be ordered by something, so if there isn't anything set then set it
        if (!this.filter.OrderBy || this.filter.OrderBy.length === 0) //Won't be null if it comes from a saved search, will be if it isn't
            this.filter.OrderBy = this.copyDefaultOrderBy();

        if (this.filter.OrderBy && this.filter.OrderBy.length > 0)
            return this.filter.OrderBy;
    }

    protected ToggleDetails(show: boolean = false, ID: string = null): void { console.error('Need to implement "ToggleDetails" from the base class!'); }

    public ToggleSelected(selected: boolean, item: IEntity) {

        item.Selected = selected;
        if (this.selectedItemsAreExcludes) {
            if (selected)
                this.selectedItems.splice(this.selectedItems.findIndex(val => val.ID === item.ID), 1);
            else {
                this.selectedItems.push(item);
                this.allSelected = false;
            }

            this.selectedCount = this.resultsLength - this.selectedItems.length;
            if (this.selectedCount === 0) {
                this.ClearSelectedItems();

                this.selectedItemsAreExcludes = false;
                this.allSelected = false;
                this.selectedItems = new Array<IEntity>();
                this.UpdateSelectedCount();
            }
            else if (this.selectedItems.length === 0)
                this.allSelected = true;
        }
        else {
            if (selected)
                this.selectedItems.push(item);
            else
                this.selectedItems.splice(this.selectedItems.findIndex(val => val.ID === item.ID), 1);

            this.selectedCount = this.selectedItems.length;

            if (this.selectedCount === this.resultsLength) {
                //Only want to exclude if we have so many items that they don't all fit on the same page
                this.selectedItemsAreExcludes = this.resultsLength > this.defaultPageSize;
                this.allSelected = true;

                if (this.selectedItemsAreExcludes) {
                    this.selectedItems = new Array<IEntity>();
                    this.UpdateSelectedCount();
                }
            }
            else
                this.allSelected = false;//If selected count isn't same as results length then they can't have all of them selected
        }

        if (this.selectedCount === 0) {
            this.ShowSelect = false;
        }
    }
    ShowSelect: boolean = false;

    //  Reference this in *ngFor for huge performance improvement
    //  https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
    //  * Note that this caused an issue on the TicketServiceAreaList with the permission checks.  There isn't much detail about
    //  the issue (it was TFS #6482, change was made on 10/27/2019).  I think it's because that list uses the Virtual Scroller and
    //  (unlike our main lists used by this class).  And if the dom element is recycled, it was not re-creating the
    //  *iqHasPermission subscription - so the permission checks where not right.
    //  So keep that in mind - at least if trackBy is used on a virtual scroller and/or if *iqHasPermission is used on a list row
    //  (which should not be the case for any lists created by this class).
    public TrackBy(index, item) {
        //  I would have thought that we should return item.ID here (or at least, do that when the item has an ID).
        //  But, that still performs really bad.  By far, the best performance is by just returning "index" here.
        //  It gives a huge performance improvement and I could not see where there were any issues with rows now updating.
        return index;
    }

    /**
     * Removes the item from the list and decrements the count.  Will automatically refresh the list from the server if at least 10 items
     * have been removed from the list and there are more items after the current page.
     * @param item
     */
    public DeleteItem(item: IEntity): void {
        this.rawItems = this.rawItems.filter(i => i !== item);
        this.items.next(this.rawItems);
        this.resultsLength = this.resultsLength - 1;

        const newMaxPageSize = Math.ceil(this.resultsLength / this.defaultPageSize);

        if (this.rawItems.length === 0) {
            //  The list is now empty.  If we're not on page 1, decrement the current page and refresh the list.
            if (this.currentPage > 1)
                this.pageChanged(this.currentPage - 1);
        }
        else if (this._MaxPageNumber != Math.ceil(this.resultsLength / this.defaultPageSize)) {
            //  The total number of pages of all items differs from when we last refreshed the list.  Refresh it now or the
            //  paginator will show the number of pages based on the total items even though we are not showing the last few items in the list.
            //  So user won't be able to advance the page to see them.
            this.pageChanged(this.currentPage <= newMaxPageSize ? this.currentPage : newMaxPageSize);
        } else if (this.currentPage < this._MaxPageNumber) {
            //  If the list has been reduced by at least 10 items and there are more items after this page, force a refresh.
            if ((this.defaultPageSize - this.rawItems.length) >= 10)
                this.pageChanged(this.currentPage);
        }
    }
}
