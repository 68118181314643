import { Component, OnDestroy } from '@angular/core';
import { TicketMapViewerService } from 'Pages/Tickets/TicketMapViewer/Services/TicketMapViewer.service';
import { CommonService } from 'Services/CommonService';
import { BaseTicketMapViewerComponent } from './BaseTicketMapViewer.component';

/**
 *  Map to show Tickets (not Ticket Responses).  Can be either a standalone map or shown next to a Ticket Search list.
 */
@Component({
    selector: 'tickets-map-viewer',
    templateUrl: './BaseTicketMapViewer.component.html',
    styleUrls: ['./BaseTicketMapViewer.component.scss'],
    providers: [
        CommonService,
        { provide: "IMapViewerService", useClass: TicketMapViewerService }  //  So can be injected in the TicketMapItemPager.component
    ]
})
export class TicketsMapViewerComponent extends BaseTicketMapViewerComponent implements OnDestroy {
    constructor(commonService: CommonService, ticketMapViewerService: TicketMapViewerService) {
        super(commonService, ticketMapViewerService);
    }
}
