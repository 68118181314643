<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 *ngIf="OriginalProfile.DeliveryRules && OriginalProfile.DeliveryRules.length !== 0" style="margin: 0px;">Ticket Delivery Rules: </h4>
    <div *ngFor="let Rule of OriginalProfile.DeliveryRules" class="RulesContainer">
        <h5 *ngIf="Rule.SendToDestination" class="row-header">{{Rule.SendToDestination.Name}}</h5>
        <h5 *ngIf="Rule.ForwardToServiceProvider" class="row-header">{{Rule.ForwardToServiceProvider.Name}}</h5>
        <h5 *ngIf="Rule.SendToPerson" class="row-header">{{Rule.SendToPerson.Name}}</h5>
        <div *ngIf="Rule.IsActive !== undefined" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Active: </label>
            <span [style.color]="Rule.IsActive ? 'green' : 'red'">{{Rule.IsActive | yesNoFormat}}</span>
        </div>
        <div *ngIf="Rule.BillingDeliveryTypeName" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Delivery Type: </label>
            <span>{{Rule.BillingDeliveryTypeName}}</span>
        </div>
        <div *ngIf="Rule.DeliveryMethod !== null" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Delivery Method: </label>
            <span>{{Rule.DeliveryMethod | EnumDescription : DeliveryMethodEnum : DeliveryMethodEnumDescriptions}}</span>
        </div>
        <ng-container *ngIf="Rule.SendToDestination">
            <label class="iq-caption gray-primary-text" style="margin-top: 10px; width: 100%;">Destination Info: </label>
            <div class="profile-field">
                <label style="margin-left: 10px;" class="iq-caption gray-primary-text profile-label">Name: </label>
                <span style="margin-left: -10px;">{{Rule.SendToDestination.Name}}</span>
            </div>
            <div *ngIf="Rule.SendToDestination.Code" class="profile-field childField">
                <label class="iq-caption gray-primary-text profile-label">Code: </label>
                <span class="mobileMarginLeftNeg10">{{Rule.SendToDestination.Code}}</span>
            </div>
            <div *ngIf="Rule.SendToDestination.CurrentDevice_Address" class="profile-field">
                <label style="margin-left: 10px;" class="iq-caption gray-primary-text profile-label">Address: </label>
                <span style="margin-left: -10px;">{{Rule.SendToDestination.CurrentDevice_Address}}</span>
            </div>
            <div *ngIf="!Rule.SendToDestination.IsActive" style="margin-left: 10px; width: 100%;">
                <label class="iq-caption" style="color: red;">Not Active</label>
            </div>
            <div *ngIf="!Rule.SendToDestination.SendEnabled" style="margin-left: 10px; width: 100%;">
                <label class="iq-caption" style="color: red;">Sending messages is disabled</label>
            </div>
        </ng-container>
        <div *ngIf="Rule.ForwardToServiceProvider" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Service Provider: </label>
            <span>{{Rule.ForwardToServiceProvider.Name}}</span>
        </div>
        <div *ngIf="Rule.SendToPerson && Rule.DeliveryMethod === 4" style="width: 100% !important; flex-wrap: wrap;" class="AllTypesField">
            <label class="iq-caption gray-primary-text AllTypesLabel">Send To: </label>
            <span *ngIf="Rule.DeliveryMethod === 4 || Rule.DeliveryMethod === 3">{{Rule.SendToPerson.Name}} {{Rule.SendToPerson.VoicePhone.PhoneNumber | phone}}</span>
            <span *ngIf="Rule.FallbackAfterAttempts" class="sub-caption">Switch to manual call after {{Rule.FallbackAfterAttempts}} attempts</span>
            <span *ngIf="Rule.MaxAttempts" class="sub-caption">(Max attemps: {{Rule.MaxAttempts}})</span>
        </div>
        <div *ngIf="Rule.SendToPerson && Rule.DeliveryMethod === 5" class="profile-field" style="width: 50% !important;">
            <label class="iq-caption gray-primary-text profile-label">Send To: </label>
            <span>{{Rule.SendToPerson.Name}} {{Rule.SendToPerson.SMSPhone.PhoneNumber | phone}}</span>
        </div>
        <ng-container *ngIf="Rule.ScheduleType">
            <div *ngIf="Rule.ScheduleType !== 1" style="width: 100%;">
                <div class="AllTypesField">
                    <label class="iq-caption gray-primary-text AllTypesLabel">During Times: </label>
                    <span>{{Rule.ScheduleType | EnumDescription : DeliveryRuleScheduleTypeEnum : DeliveryRuleScheduleTypeEnumDescriptions}}</span>
                </div>
                <div *ngIf="Rule.OpenTimes && Rule.OpenTimes.length > 0">
                    <span *ngFor="let time of Rule.OpenTimes;" class="sub-caption" style="margin-bottom: 0px;">
                        {{ time | openTime }}
                    </span>
                </div>
                <span *ngIf="Rule.HolidayRule" class="sub-caption" style="margin-bottom:0px;">{{Rule.HolidayRule | EnumDescription : DeliveryRuleHolidayRuleEnum : DeliveryRuleHolidayRuleEnumDescriptions}}</span>
                <span *ngIf="Rule.ScheduleType" class="sub-caption">{{Rule.QueueWhenOutOfHours ? "Queue for next available time if outside selected times" : "Do not queue if not in hours"}}</span>
            </div>
            <div *ngIf="Rule.ScheduleType === 1" class="profile-field">
                <label class="iq-caption gray-primary-text profile-label">During Times: </label>
                <span>{{Rule.ScheduleType | EnumDescription : DeliveryRuleScheduleTypeEnum : DeliveryRuleScheduleTypeEnumDescriptions}}</span>
            </div>
        </ng-container>
        <div *ngIf="Rule.DeliveryCategories && Rule.DeliveryCategories.length > 0" class="AllTypesField">
            <label class="iq-caption gray-primary-text profile-label AllTypesLabel">Ticket Categories: </label>
            <span *ngFor="let Category of Rule.DeliveryCategories; last as isLast">
                <span *ngIf="!isLast">{{Category.Name}}, </span>
                <span *ngIf="isLast">{{Category.Name}} </span>
            </span>
        </div>
        <div *ngIf="!Rule.DeliveryCategories" class="AllTypesField">
            <label class="iq-caption gray-primary-text AllTypesLabel">Ticket Categories: </label>
            <span>All Tickets</span>
        </div>
    </div>
    <h4 *ngIf="OriginalProfile.EODDeliveryRules && OriginalProfile.EODDeliveryRules.length !== 0" style="margin-top: 5px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px; ">End Of Day Audit Rules: </h4>
    <div *ngFor="let Rule of OriginalProfile.EODDeliveryRules" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px; row-gap: 10px; margin-left: 10px;">
        <ng-container *ngIf="Rule.SendToDestination">
            <h5 class="row-header" style="margin-bottom: -10px;">{{Rule.SendToDestination.Name}}</h5>
            <div class="desktopWidth50">
                <label class="iq-caption gray-primary-text profile-label">Send To Destination: </label>
                <span style="margin-left: 10px;">{{Rule.SendToDestination.Name}}</span>
                <span *ngIf="Rule.SendToDestination.Code && Rule.SendToDestination.CurrentDevice_Address" class="sub-caption" style="margin-bottom: 0px;">{{Rule.SendToDestination.Code}} | {{Rule.SendToDestination.CurrentDevice_Address}}</span>
                <span *ngIf="Rule.SendToDestination.Code && !Rule.SendToDestination.CurrentDevice_Address" class="sub-caption" style="margin-bottom: 0px;">{{Rule.SendToDestination.Code}}</span>
            </div>
        </ng-container>
        <div *ngIf="Rule.IsActive !== undefined" class="profile-field">
            <label class="iq-caption gray-primary-text" style="width: 4em;">Active: </label>
            <span>{{Rule.IsActive | yesNoFormat}}</span>
        </div>
        <ng-container *ngIf="Rule.AuditDestination">
            <div class="desktopWidth50">
                <label class="iq-caption gray-primary-text profile-label">Filter By: </label>
                <span style="margin-left: 10px;">{{Rule.AuditDestination.Name}}</span>
                <span *ngIf="Rule.AuditDestination.Code && Rule.AuditDestination.CurrentDevice_Address" class="sub-caption" style="margin-bottom: 0px;">{{Rule.AuditDestination.Code}} | {{Rule.AuditDestination.CurrentDevice_Address}}</span>
                <span *ngIf="Rule.AuditDestination.Code && !Rule.AuditDestination.CurrentDevice_Address" class="sub-caption" style="margin-bottom: 0px;">{{Rule.AuditDestination.Code}}</span>
            </div>
        </ng-container>
        <div *ngIf="Rule.AuditFormat" class="profile-field" style="flex-wrap: wrap;">
            <label class="iq-caption gray-primary-text" style="width: 4em;">Format: </label>
            <span>{{Rule.AuditFormat | EnumDescription : DeliveryRuleAuditFormatEnum : DeliveryRuleAuditFormatEnumDescriptions}}</span>
            <span *ngIf="Rule.SendEmptyAudit" class="sub-caption" style="width: 100%;">Send even if empty</span>
        </div>
    </div>
    <div *ngIf="(!OriginalProfile.EODDeliveryRules || OriginalProfile.EODDeliveryRules.length === 0) && (!OriginalProfile.DeliveryRules || OriginalProfile.DeliveryRules.length === 0)">
        No delivery rules available
    </div>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>
