import { FeatureItemResponse } from "@iqModels/Maps/FeatureItemResponse.model";
import { MapLayerParams } from "@iqModels/Maps/MapLayerParams.model";
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import * as jsts from "jsts"; //  JavaScript Topology Suite: 2.7.2 changes how to import, see https://github.com/bjornharrtell/jsts/issues/442
import { Feature, Map } from "ol";
import { Extent, getHeight as extent_getHeight, getWidth as extent_getWidth, intersects as extent_intersects } from "ol/extent";
import { GeoJSON } from "ol/format";
import { Geometry } from "ol/geom";
import VectorSource from "ol/source/Vector";
import { Observable } from "rxjs";
import { GeometryTransformer } from "../GeometryTransformer";
import { GeometryUtils } from "../GeometryUtils";
import { MapConstants } from "../MapConstants";

export abstract class ClusteredVectorLayerBase extends VectorLayerBase {

    private _PolygonSource: VectorSource<Feature<Geometry>>;
    protected get PolygonSource(): VectorSource<Feature<Geometry>> {
        return this._PolygonSource;
    }

    private _ClusteredSource: VectorSource<Feature<Geometry>>;
    protected get ClusteredSource(): VectorSource<Feature<Geometry>> {
        return this._ClusteredSource;
    }

    public get PolygonsAreVisible(): boolean {
        return this.Layer.getSource() === this._PolygonSource;
    }

    private _LayerExtent: Extent;
    private _FeaturesPerSqMapUnit: number;

    constructor(private _Map: Map, mapToolService: MapToolService, layerName: string, opacity: number, legendName: string = null) {
        super(mapToolService, layerName, opacity, legendName);
    }

    protected SetMapLayerParams(mapLayerParams: MapLayerParams): void {
        this.ClearVectorSources();

        //  Store this extent in map coords because it's also needed when zooming
        if (mapLayerParams.NumFeatures) {
            this._LayerExtent = GeometryTransformer.TransformExtent([mapLayerParams.MinX, mapLayerParams.MinY, mapLayerParams.MaxX, mapLayerParams.MaxY], MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);

            //  Need total number of features so that we can compute the avg number of features in an extent.
            this._FeaturesPerSqMapUnit = mapLayerParams.NumFeatures / (extent_getWidth(this._LayerExtent) * extent_getHeight(this._LayerExtent));
        } else {
            this._LayerExtent = null;
            this._FeaturesPerSqMapUnit = 0;
        }

        if (mapLayerParams.GeometryJson) {
            const features = GeometryUtils.GeoJsonToFeatures(mapLayerParams.GeometryJson);
            if (features)
                this._ClusteredSource.addFeatures(features);
        }
    }

    public GetBestFitExtents(): Extent {
        if (!this._LayerExtent)
            return null;

        return this._LayerExtent;
    }

    //  We track 2 different vector sources.
    //  1) The main Polygon source contains the actual polygons and is only used when zoomed in to
    //      a resonable level.  This uses a bounding box strategy so that we only fetch polygons within the current
    //      view (and fetch additional when we pan).
    //  2) The Clustered source contains clustered bounding boxes and is shown when zoomed at higher levels.  This makes it
    //      easier to see where the polygons are and drastically reduces the amount of data sent to the browser when
    //      viewing a very large registration at a high zoom level.
    protected CreateSource(): VectorSource<Feature<Geometry>> {
        const me = this;

        //  Create both sources now.  We will dynamically set the correct one based on the zoom level & number of features
        this._PolygonSource = new VectorSource<Feature<Geometry>>({
            format: new GeoJSON(),
            overlaps: false,     //  Makes the polygon fills look better where polygon edges butt-up against each other
            strategy: (extent, resolution) => me.PolygonSourceLoadingStrategy(extent, resolution),
            loader: (extent) => me.PolygonSourceLoader(extent)
        });

        this._ClusteredSource = new VectorSource<Feature<Geometry>>({
            format: new GeoJSON(),
            overlaps: false,     //  Makes the polygon fills look better where polygon edges butt-up against each other
            strategy: (extent, resolution) => me.ClusterSourceLoadingStrategy(extent, resolution),
            loader: () => { }     //  This source is loaded up-front but still need the strategy being evaluated so we know if need to switch sources
        });

        //  Need to always return the registration polygon source so that it becomes the "main" vector source of
        //  this object (for when we need to get features from it).
        //  It will not fetch any data if the current zoom does not allow it.  And the source will be immediately
        //  switched to the clustered source if necessary.
        return this._PolygonSource;
    }

    //  ** This clears out the cluster source too - which will not reload itself!
    protected ClearVectorSources(): void {
        //  Clearing the current source will cause it to refresh from the server
        this._LayerExtent = null;
        this._PreviousFetchedExtents = [];
        this._PreviousFetchedGeometry = null;
        this._PolygonSource.clear();
        this._ClusteredSource.clear();
    }

    //  Custom ol.loadingstrategy.bbox
    //  Adds checks for the registration extent and previously fetched areas to reduce unnecessary server calls.
    private PolygonSourceLoadingStrategy(extent: Extent, resolution: number): Extent[] {
        //  If can't display the layer, return an empty extent so that we don't attempt.  Otherwise, it will record
        //  the extent in it's (internal) list of loaded extents.  Then if we zoom in and it is valid, the extent won't load!
        if (!this._LayerExtent)
            return [];

        if (!this.CanShowPolygonSource(this._Map.getView().getZoomForResolution(resolution), extent)) {
            //  Switch to the Clustered source
            this.Layer.setSource(this._ClusteredSource);
            return [];
        }

        //  If the extent is not within the bounds of the registration, do not attempt to load anything
        if (!extent_intersects(extent, this._LayerExtent))
            return [];

        if (this._PreviousFetchedGeometry) {
            //  OpenLayers tracks the extents that have been fetched and use that to prevent making calls for extents
            //  that it already has.  But it does that by just checking to see if a previously fetch envelope covers this
            //  extent.  So if we are panning around a little, it still ends up making the server call because there is no
            //  1 extent that covers are new one - even if we've panned around the entire area.
            //  Checking the geometry that we track handles this much better and prevents those extra server calls completely.
            const llExtent = GeometryTransformer.TransformExtent(extent, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION);
            const extentAsPoly = GeometryUtils.ExtentToJstsPolygon(llExtent);
            if (this._PreviousFetchedGeometry.covers(extentAsPoly))
                return [];
        }

        return [extent];
    }

    //  Custom ol.loadingstrategy.bbox
    //  Adds checks for the registration extent and previously fetched areas to reduce unnecessary server calls.
    private ClusterSourceLoadingStrategy(extent: Extent, resolution: number): Extent[] {
        //  If can't display the layer, return an empty extent so that we don't attempt.  Otherwise, it will record
        //  the extent in it's (internal) list of loaded extents.  Then if we zoom in and it is valid, the extent won't load!
        if (!this._LayerExtent)
            return [];

        if (this.CanShowPolygonSource(this._Map.getView().getZoomForResolution(resolution), extent)) {
            //  Switch to the Polygon source
            this.Layer.setSource(this._PolygonSource);
            return [];
        }

        //  We don't actually load this source like this - it's done via the MapLayerParams
        return [];
    }

    private PolygonSourceLoader(extent: Extent): void {
        const llExtent = GeometryTransformer.TransformExtent(extent, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION);

        let previousFetchedExtentsGeoJson: object = null;
        if (this._PreviousFetchedGeometry) {
            const writer = new jsts.io.GeoJSONWriter();
            previousFetchedExtentsGeoJson = writer.write(this._PreviousFetchedGeometry);
        }

        this.FetchPolygonsInExtent(llExtent, previousFetchedExtentsGeoJson)
            .subscribe(features => {
                //  Always add the extent even if empty - it's also used to prevent additional server calls for the same area
                this.AddExtentToFetchedBounds(llExtent);

                this._PolygonSource.addFeatures(features);
            });
    }

    protected abstract FetchPolygonsInExtent(latLonExtent: Extent, previousFetchedExtentsGeoJson: object): Observable<Feature<any>[]>;

    private _PreviousFetchedExtents: Extent[] = [];
    private _PreviousFetchedGeometry: jsts.geom.Geometry = null;

    private AddExtentToFetchedBounds(extent: Extent): void {
        this._PreviousFetchedExtents.push(extent);

        const extentAsPoly = GeometryUtils.ExtentToJstsPolygon(extent);

        if (this._PreviousFetchedGeometry)
            this._PreviousFetchedGeometry = this._PreviousFetchedGeometry.union(extentAsPoly);
        else
            this._PreviousFetchedGeometry = extentAsPoly;
    }

    private CanShowPolygonSource(zoom: number, extent: Extent): boolean {
        if (zoom < 10)
            return false;       //  Always show cluster source at high levels - easier to see small polygons at high level this way.

        const sqMapUnit = (extent_getWidth(extent) * extent_getHeight(extent));

        //  Only show polygons if avg number of features at this zoom level is under this threshold.
        return (this._FeaturesPerSqMapUnit * sqMapUnit < 1000);
    }

    public GetFeatureAttributesInExtent(llExtent: Extent): FeatureItemResponse[] {
        return this.GetFeaturesInExtent(llExtent, "Attribute", "Attribute");
    }
}
