import { Component, Input } from '@angular/core';
import { TicketMinimal } from 'Models/Tickets/TicketMinimal.model';
import { TicketAttachment } from 'Models/Tickets/TicketAttachment.model';
import { DownloadService } from 'Services/Download.service';

@Component({
    selector: 'ticket-anon-simple-view',
    templateUrl: './TicketSimpleView.component.html',
    styleUrls: ['./TicketSimpleView.component.scss']
})
export class TicketSimpleViewComponent {
    @Input()
    public Ticket: TicketMinimal;

    constructor(private _DownloadService: DownloadService) {
    }

    public OnOpenAttachment(attachment: TicketAttachment): void {
        if (attachment.FileUploadID)
            this._DownloadService.OpenFileInNewWindow(attachment.FileUploadID, this.Ticket.OneCallCenterCode);
        else if (attachment.URL)
            this._DownloadService.OpenURLInNewWindow(attachment.URL);
    }

    public OnDownloadAttachment(attachment: TicketAttachment): void {
        this._DownloadService.DownloadFileUploadIDAnonymously(this.Ticket.OneCallCenterCode, attachment.FileUploadID, attachment.FileName, attachment.ContentType);
    }
}
