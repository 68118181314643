<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 style="margin: 0px;">Ticket Assignment: </h4>
    <div style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px;">
        <div *ngIf="OriginalProfile.NotifyOnPlaceLookups !== undefined" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Notify on place lookup: </label>
            <span>{{OriginalProfile.NotifyOnPlaceLookups | yesNoFormat}}</span>
        </div>
        <div *ngIf="ShowCustomDigSiteBuffer" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Custom Dig site buffer: </label>
            <span *ngIf="OriginalProfile.CustomBufferFt">{{OriginalProfile.CustomBufferFt}}</span>
            <span *ngIf="!OriginalProfile.CustomBufferFt">Do not use</span>
        </div>
    </div>
    <div *ngIf="ShowNearEdgeOfRoadFilter" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px;">
        <div *ngIf="ShowNearEdgeOfRoadFilter" class="profile-field" style="width: 40% !important;">
            <label class="iq-caption gray-primary-text">Notify using "within 25ft of the edge of the street" field: </label>
            <span *ngIf="UsesNearEdgeOfRoadFilter">Yes</span>
            <span *ngIf="!UsesNearEdgeOfRoadFilter">No</span>
        </div>
        <div *ngIf="UsesNearEdgeOfRoadFilter" class="sub-caption" style="margin-bottom:0px;">
            Do&nbsp;<span style="color:red">NOT</span>&nbsp;assign the Service Area to Tickets with "within 25ft of the edge of the street" set to "<span style="color:red">{{NearEdgeOfRoadValue}}</span>"
        </div>
    </div>
    <div *ngIf="UsesMeetScheduling" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px;">
        <div class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Participates in Scheduled Meetings: </label>
            <span>{{UsesMeetScheduling | yesNoFormat}}</span>
        </div>
        <div *ngIf="OriginalProfile.MeetingPattern" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Meeting Pattern: </label>
            <span>{{OriginalProfile.MeetingPattern.Name}}</span>
        </div>
        <div *ngIf="OriginalProfile.ConcurrentMeetings" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Concurrent Meetings: </label>
            <span>{{OriginalProfile.ConcurrentMeetings}}</span>
        </div>
    </div>
    <div *ngIf="UseAllTicketTypes || (ShowLocateTypesSection && UseAllLocateTypes)" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px;">
        <div *ngIf="UseAllTicketTypes" class="AllTypesField">
            <label class="iq-caption gray-primary-text AllTypesLabel" style="margin-top: 3px;">Ticket Types: </label>
            <span>All Ticket Types</span>
        </div>
        <div *ngIf="ShowLocateTypesSection && UseAllLocateTypes" class="AllTypesField">
            <label class="iq-caption gray-primary-text AllTypesLabel" style="margin-top: 3px;">Locate Types: </label>
            <span>All Locate Types</span>
        </div>
    </div>
    <div *ngIf="OriginalProfile.TicketTypes && OriginalProfile.TicketTypes.length > 0" class="AllTypesField">
        <label class="iq-caption gray-primary-text AllTypesLabel" style="margin-top: 3px;">Ticket Types: </label>
        <span *ngFor="let Type of OriginalProfile.TicketTypes; last as isLast">
            <span *ngIf="!isLast">{{Type.TicketType.Name}}, </span>
            <span *ngIf="isLast">{{Type.TicketType.Name}} </span>
        </span>
    </div>
    <div *ngIf="ShowLocateTypesSection && OriginalProfile.LocateTypes && OriginalProfile.LocateTypes.length > 0" class="AllTypesField">
        <label class="iq-caption gray-primary-text AllTypesLabel" style="margin-top: 3px;">Locate Types: </label>
        <span *ngFor="let Type of OriginalProfile.LocateTypes; last as isLast">
            <span *ngIf="!isLast">{{Type.LocateType.Name}}, </span>
            <span *ngIf="isLast">{{Type.LocateType.Name}} </span>
        </span>
    </div>
    <div *ngFor="let config of FieldConfigurationsAffectingSuppression">
        <div *ngIf="HaveSelectedFieldValues(config)" class="desktopDisplayFlex" style="margin-top: 20px;">
            <label class="iq-caption gray-primary-text" style="flex-shrink:0; padding-right:1em">
                Suppress the Service Area on New tickets when the {{config.DisplayName}} is:
            </label>
            <div style="display:flex; flex-wrap:wrap">
                <ng-container *ngFor="let value of config.Values">
                    <span style="width: 100%;" *ngIf="value.Selected">{{value.Value}}</span>
                </ng-container>
            </div>
        </div>
        <div *ngIf="!HaveSelectedFieldValues(config)" style="margin-top: 20px;">
            Do not suppress the Service Area based on {{config.DisplayName}}.
        </div>
    </div>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>

