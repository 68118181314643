export enum ProfileVerificationStatusEnum {
    Pending = 1,
    InProgress = 2,
    PendingReview = 3,
    Reviewed = 4,
    Expired = 5
}

export enum ProfileVerificationStatusEnumDescription {
    Pending = "Pending",
    InProgress = "In Progress",
    PendingReview = "Pending Review",
    Reviewed = "Reviewed",
    Expired = "Expired"
}
