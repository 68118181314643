import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../AuthenticationService'
import { AppUpdateService } from '../AppUpdateService'
import { map } from 'rxjs/operators';

export const AuthenticationGuardService: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    authService: AuthenticationService = inject(AuthenticationService),
    appUpdateService: AppUpdateService = inject(AppUpdateService),
    router: Router = inject(Router)) => {
    return authService.IsUserAuthorized.pipe(map(val => {
        if (!val)
            return router.parseUrl('/login?returnUrl=' + state.url);
        else {
            //  If there is a software update available, this will activate it and navigate to the url (which will load it).
            //  In that case, this will return false to prevent this navigation but the page reload will result in the
            //  browser being on the request page (but with the updates applied).
            return !appUpdateService.ActivateUpdateIfAvailable(state.url);
        }
    }));
}
