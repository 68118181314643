import { Feature, Map } from "ol";
import { FeatureLike } from "ol/Feature";
import { unByKey } from "ol/Observable";
import { EventsKey } from "ol/events";
import { platformModifierKeyOnly } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { DragBox } from "ol/interaction";
import VectorTile from "ol/source/VectorTile";

//  Interaction to select features by control-dragging a rectangle.
//  OpenLayers example: https://openlayers.org/en/latest/examples/box-selection.html
export class DragBoxSelectInteraction extends DragBox {

    private _VectorTile: VectorTile<Feature<Geometry>>;

    private _BoxStartEventsKey: EventsKey = null;
    private _BoxEndEventsKey: EventsKey = null;

    constructor(private _OnFeaturesSelected: (features: FeatureLike[]) => void) {
        super({
            condition: platformModifierKeyOnly,
        });
    }

    public setMap(map: Map): void {
        if (this._BoxEndEventsKey) {
            unByKey(this._BoxEndEventsKey);
            this._BoxEndEventsKey = null;
        }

        super.setMap(map);
        if (!map)
            return;     //  being destroyed

        this._BoxEndEventsKey = this.on('boxend', (evt) => this.SelectFeaturesInBox());
    }

    public SetSource(vectorTile: VectorTile<Feature<Geometry>>): void {
        this._VectorTile = vectorTile;
    }

    private SelectFeaturesInBox(): void {
        if (!this._VectorTile || !this._OnFeaturesSelected)
            return;     //  Not initialized correctly!

        const extent = this.getGeometry().getExtent();

        const features = this._VectorTile.getFeaturesInExtent(extent);
        this._OnFeaturesSelected(features);
    }
}
