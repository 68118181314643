import { AutocompleteRequest } from "@iqModels/Base/AutocompleteRequest.model";

export class ExcavatorAutocompleteRequest extends AutocompleteRequest {

    /**
     * Set this if calling from Self Registration.  User is logged in to Cognito (so passes the auth check) but 
     * has not fully logged in to Exactix (so not cookie w/OneCallCenter set).
     */
    public OneCallCenterCode: string;

    /**
      * If known and if it applies to the entity being serched, this can be used to limit the
      * search to a specific company.
     */
    public ExcavatorCompanyID: string;

    /**
      * If known and if it applies to the entity being serched, this can be used to limit the
      * search to a specific office.
     */
    public ExcavatorOfficeID: string;

    /**
      * If known and if it applies to the entity being serched, this can be used to limit the
      * search to a specific contact.
     */
    public ExcavatorContactID: string;

    /**
     * If true, results are limited to only those in the given ExcavatorCompanyID.
     * Default is false if not set.
     */
    public RestrictToCompany?: boolean;

    /**
     * If true, results are limited to only those in the given ExcavatorCompanyID
     * Default is true if not set.
     */
    public AllowHomeowners?: boolean;

    /**
     * If true, only homeowners are returned.
     */
    public OnlyHomeowners?: boolean;

    /**
     * If true, Excavator Companies are only returned if they have delivery rules.
     */
    public OnlyWithDeliveryRules?: boolean;

    constructor(PropertyName: string, SearchValue: string) {
        super(PropertyName, SearchValue);
    }
}
