import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { IconButtonComponent, IconNameType } from 'Shared/Components/Controls/IconButton/IconButton.component';

/**
 *  Similar to the iq-icon-button but for a button displayed in a phone header.
 */
@Component({
    selector: 'iq-phone-button',
    templateUrl: './PhoneButton.component.html',
    styleUrls: ['./PhoneButton.component.scss']
})
export class PhoneButtonComponent {

    //  If null, an icon is not used
    private _IconName: IconNameType = null;
    @Input("icon")
    public get IconName(): IconNameType { return this._IconName }
    public set IconName(iconName: IconNameType) {
        this._IconName = iconName;
        this.Configure();
    }

    private _Icon: IconDefinition;
    public get Icon(): IconDefinition { return this._Icon; }

    //  Change this to control the debounce time.  Set to 0 to disable debounce
    @Input()
    public DebounceTime: number = 700;

    @ViewChild('button')
    private _Button: ElementRef;

    private _Disabled: boolean = false;
    @Input("disabled")
    public get Disabled(): boolean { return this._Disabled; }
    public set Disabled(disabled: boolean) {
        this._Disabled = disabled;

        if (this._Button)
            this._Button.nativeElement.tabIndex = this._Disabled ? -1 : 0;
    }

    @Output("click")
    public ClickEvent = new EventEmitter();

    constructor() { }

    private Configure(): void {
        if (!this._IconName)
            this._Icon = null;
        else
            this._Icon = IconButtonComponent.GetIconSettingsForName(this._IconName, "Button")?.icon;
    }

    //  All clicks (and space/enter) are debounced using the iqPreventDoubleClick directive to prevent
    //  double-submitting for click-happy users.  Any tasks that execute in response to a click that are
    //  potentially long running (i.e. any api call) should also disable this component when they are executing.
    public OnDebouncedClick(event: MouseEvent): void {
        if (!this._Disabled)
            this.ClickEvent.next(event);
    }
}
