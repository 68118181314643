import { Feature, MapBrowserEvent } from "ol";
import { FeatureLike } from "ol/Feature";
import { click, noModifierKeys, platformModifierKeyOnly } from "ol/events/condition";
import { Geometry, Point } from "ol/geom";
import { Interaction } from "ol/interaction";
import VectorTile from "ol/source/VectorTile";

//  Interaction to select features via a single click.  Control-click will toggle selection.
export class ClickSelectInteraction extends Interaction {

    private _VectorTile: VectorTile<Feature<Geometry>>;

    constructor(private _OnFeaturesSelected: (features: FeatureLike[], toggleSelected: boolean) => void) {
        super({
            handleEvent: (evt) => {
                //  Accept click with no modifier keys (replace selection) or click + control (toggle selections).
                if (click(evt) && (noModifierKeys(evt) || platformModifierKeyOnly(evt))) {
                    this.SelectFeaturesAtPixel(evt);
                    return false;               //  Stops event from being processed further
                }
                return true;            //  Causes event to continue to be processed (by other interactions)
            }
        });
    }

    public SetSource(vectorTile: VectorTile<Feature<Geometry>>): void {
        this._VectorTile = vectorTile;
    }

    private SelectFeaturesAtPixel(evt: MapBrowserEvent<any>): void {
        const pt = new Point(this.getMap().getCoordinateFromPixel(evt.pixel));
        const extent = pt.getExtent();

        const features = this._VectorTile.getFeaturesInExtent(extent);
        this._OnFeaturesSelected(features, evt.originalEvent.ctrlKey);
    }
}
