import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { faUfo } from '@fortawesome/pro-solid-svg-icons';
import { ResponseConfig } from 'Models/Configuration/Response.model';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketResponseCreateRequest } from "Models/Tickets/TicketResponseCreateRequest.model";
import { TicketResponseItem } from 'Models/Tickets/TicketResponseItem.model';
import { TicketResponseService } from 'Pages/Tickets/Services/TicketResponse.service';
import { Observable, of } from 'rxjs';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { EnumService } from 'Services/Enum.service';
import { EntityHasIDValidator } from 'Shared/Components/Forms/Validation/Validators/EntityHasID.validator';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { AffectedTicketForServiceArea } from '../../../../Models/Tickets/AffectedTicketForServiceArea.model';
import { ICustomResponseDataComponent } from "../Custom/ICustomResponseDataComponent";
import { PositiveResponseCommonComponent } from './PositiveResponseCommon.component';
import { IQ_VALIDATOR_PATTERNS } from 'Shared/Components/Forms/Validation/ValidationPatterns.model';

export class AddPositiveResponseData {
    /**
     *  If set, this function is called after a response is saved.  Allows the owner to update it's own display (such as a list).
     */
    public OnResponseSaved: (savedServiceAreaInfo: TicketAffectedServiceAreaInfo) => void;

    //  These properties are used to limit the Service Area(s) and Utility Type to the item in a list when we are adding directly from a list item.
    //  ** Do not set these unless you want to limit to only match a row in a list!
    //  If we don't do this and the user is linked to multiple service areas, it will let him pick any service area.
    //  And if the ticket/service area has multiple utility types, the same.
    //  Which means the user may pick a service area/utility type that does not match the current list item.
    //  And then we can't correcly set the Response Code that they picked in to the list or remove the item if the response is now complete.
    public LimitToServiceAreaName: string;
    public LimitToUtilityTypeName: string;

    constructor(
        public TicketNumbers: string[],
        public TicketTypeID: string,
        public SingleItem: boolean,

        /**
         *  If set, limits the responses to the given TicketServiceArea record.  Otherwise, fetches the list 
         *  for the TicketNumber (or uses values in ServiceAreaInfoList if set) and allows the user to pick.
         *  Do not set this *AND* the ServiceAreaInfoList property at the same time.
         */
        public ServiceAreaInfo: TicketAffectedServiceAreaInfo = null,

        /**
         *  If set, contains the list of TicketAffectedServiceAreaInfo objects for the ticket.  If not set, will
         *  fetch from the server.  Can use this if the caller already has the list (and uses it to display responses - so
         *  passing it in will then have changes made to it so the display is up-to-date).
         *  Do not set this *AND* the ServiceAreaInfo property at the same time.
         */
        public ServiceAreaInfoList: TicketAffectedServiceAreaInfo[] = null) { }
}

class UtilityTypeOption {
    constructor(public ID: string, public Name: string, public Response: string)
    { }
}

@Component({
    selector: 'iq-ticket-add-positive-response',
    templateUrl: './AddPositiveResponse.component.html',
    styleUrls: ['./AddPositiveResponse.component.scss'],
    providers: [CommonService]
})
export class AddPositiveResponseComponent implements OnInit {

    @Input()
    public Data: AddPositiveResponseData;

    //  Helpers to access the properties in Data (because originally they were individual Inputs...)
    public get TicketNumber(): string { return this.Data?.TicketNumbers[0]; }
    public get TicketTypeID(): string { return this.Data?.TicketTypeID; }
    public get SingleItem(): boolean { return this.Data?.SingleItem; }
    public get LimitToServiceAreaName(): string { return this.Data?.LimitToServiceAreaName; }
    public get LimitToUtilityTypeName(): string { return this.Data?.LimitToUtilityTypeName; }
    public get ServiceAreaInfo(): TicketAffectedServiceAreaInfo { return this.Data?.ServiceAreaInfo; }
    public get ServiceAreaInfoList(): TicketAffectedServiceAreaInfo[] { return this.Data?.ServiceAreaInfoList; }
    private set ServiceAreaInfoList(list: TicketAffectedServiceAreaInfo[]) { this.Data.ServiceAreaInfoList = list; }

    @ViewChild('serviceAreaSelect', { read: MatSelect, static: true })
    private _ServiceAreaSelect: MatSelect;

    @ViewChild('utilityTypeSelect', { read: MatSelect, static: false })
    private _UtilityTypeSelect: MatSelect;

    @ViewChild(PositiveResponseCommonComponent)
    private _PositiveResponseCommon: PositiveResponseCommonComponent;

    //  Check this in the parent component to see if a Save button can be enabled.
    //  Check to make sure the form is valid and that we are not in the process of saving.
    public get CanSave(): boolean {
        return this.FormGroup && this.FormGroup.valid && !this._IsSaving
            && this._PositiveResponseCommon.CanSave
    }

    public FormGroup: UntypedFormGroup;
    public ServiceAreaInfoFormControl: UntypedFormControl;         //  Contains TicketAffectedServiceAreaInfo object - so can show Code, Name, and current response in UI
    public UtilityTypeFormControl: UntypedFormControl;
    public ResponseFormControl: UntypedFormControl;
    public CommentFormControl: UntypedFormControl;
    public RespondantFormControl: UntypedFormControl;
    public PublicURLFormControl: UntypedFormControl;

    public UtilityTypesForCurrentServiceArea: UtilityTypeOption[] = null;

    public affectedTicketsForServiceArea: AffectedTicketForServiceArea[];
    public affectedTicketNumbers: string[] = [];
    public unaffectedTicketNumbers: string[] = [];

    private _IsSaving: boolean;

    constructor(private _CommonService: CommonService, private _TicketResponseService: TicketResponseService)
    {
    }

    public ngOnInit(): void {
        this.ServiceAreaInfoFormControl = new UntypedFormControl(this.ServiceAreaInfo, [Validators.required, EntityHasIDValidator]);
        this.ServiceAreaInfoFormControl.valueChanges
            .subscribe(() => setTimeout(() => this.OnServiceAreaPicked()));

        this.UtilityTypeFormControl = new UntypedFormControl();
        this.UtilityTypeFormControl.valueChanges
            .subscribe(() => setTimeout(() => this.OnUtilityPicked()));

        this.ResponseFormControl = new UntypedFormControl(null, [Validators.required, EntityHasIDValidator]);
        this.CommentFormControl = new UntypedFormControl();
        this.RespondantFormControl = new UntypedFormControl(null, [Validators.maxLength(150)]);
        this.PublicURLFormControl = new UntypedFormControl(null, [Validators.maxLength(200), Validators.pattern(IQ_VALIDATOR_PATTERNS.urlPattern)]);

        this.FormGroup = new UntypedFormGroup({
            ServiceArea: this.ServiceAreaInfoFormControl,
            UtilityType: this.UtilityTypeFormControl,
            Response: this.ResponseFormControl,
            Comment: this.CommentFormControl,
            Respondant: this.RespondantFormControl,
            PublicURL: this.PublicURLFormControl
        });

        this.InitializeServiceAreaList();
    }

    /**
     * Observable returns a TicketResponseItem if the response was saved.  Otherwise returns null if nothing was saved
     */
    public Save(): Observable<TicketAffectedServiceAreaInfo> {
        if (!this.CanSave)
            return of(null);

        const request = new TicketResponseCreateRequest();
        request.TicketNumbers = this.SingleItem ? [this.TicketNumber] : this.affectedTicketNumbers;
        request.ServiceAreaID = this.ServiceAreaInfoFormControl.value.ID;
        request.UtilityTypeID = this.UtilityTypeFormControl.value?.ID;
        request.ResponseID = this.ResponseFormControl.value.ID;
        request.Comment = this.CommentFormControl.value;
        request.PublicURL = this.PublicURLFormControl.value;
        request.Respondant = this.RespondantFormControl.value;
        request.Data = this._PositiveResponseCommon.CustomResponseData;

        //  This outputs the TicketAffectedServiceAreaInfo with the new response added to it.
        //  If the service area has utility types (including multiple), the responses in the CurrentResponses list will contain *ALL* responses
        //  for all utility types.  That is handled in SetNewCurrentResponse().
        this._IsSaving = true;
        return this._TicketResponseService.CreateResponse(request).pipe(
            map(response => this.SetNewCurrentResponse(response, this.ServiceAreaInfoFormControl.value.ID, this.UtilityTypeFormControl.value?.ID)),
            finalize(() => this._IsSaving = false)
        );
    }

    //  Set the given response in to the correct service area/utility type current response
    private SetNewCurrentResponse(response: TicketResponseItem, serviceAreaID: string, utilityTypeID: string): TicketAffectedServiceAreaInfo {
        const sa = this.ServiceAreaInfoList.find(sa => sa.ID === serviceAreaID);
        if (!sa || !sa.CurrentResponses)
            return null;     //  ???

        sa.SuppressedUntilDate = response.SuppressUntilDate;

        if (utilityTypeID) {
            //  Finds the responses for other utility types, adds in the response (for this utility type), and sorts by the utility type name.
            const responseList = sa.CurrentResponses.filter(r => r.UtilityTypeID !== utilityTypeID);
            responseList.push(response);
            sa.CurrentResponses = responseList.sort((a, b) => a.UtilityTypeName.toLocaleLowerCase().localeCompare(b.UtilityTypeName.toLocaleLowerCase()));
        }
        else
            sa.CurrentResponses = [response];

        const responseList = sa.CurrentResponses.filter(r => r.UtilityTypeID !== utilityTypeID);
        responseList.push(response);

        //  This will recalculate the NeedsResponse and UtilityTypeList and also reset the form values to empty
        this.InitializeForm();

        return sa;
    }

    private InitializeServiceAreaList(): void {
        if (this.ServiceAreaInfo)
            this.SetServiceAreaInfoList([this.ServiceAreaInfo]);
        else if (!this.ServiceAreaInfoList) {
            this._TicketResponseService.GetServiceAreasForAdd(this.Data.TicketNumbers).subscribe(val => {
                const serviceAreaInfoList = val.ServiceAreas.map(tsa => tsa.ServiceAreaInfo)
                    .filter(sa => !this.LimitToServiceAreaName || (this.LimitToServiceAreaName === sa.Name))
                    .sort((a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()));

                if (this.LimitToUtilityTypeName)
                    serviceAreaInfoList.forEach(sa => sa.CurrentResponses = sa.CurrentResponses.filter(r => r.UtilityTypeName === this.LimitToUtilityTypeName));

                if (!this.SingleItem)
                    this.affectedTicketsForServiceArea = val.AffectedTickets;

                this.SetServiceAreaInfoList(serviceAreaInfoList);
            });
        }
        else
            this.InitializeForm();
    }

    private SetServiceAreaInfoList(serviceAreaInfoList: TicketAffectedServiceAreaInfo[]): void {
        this.ServiceAreaInfoList = serviceAreaInfoList;

        this.InitializeForm();
    }

    private InitializeForm(): void {
        if (this.ServiceAreaInfoList.length === 1) {
            //  Exactly 1 so auto select it
            this.ServiceAreaInfoFormControl.setValue(this.ServiceAreaInfoList[0]);
            this.ServiceAreaInfoFormControl.disable();
            //this.OnServiceAreaPicked();
        }
        else
            this.ServiceAreaInfoFormControl.setValue(null);

        this.UtilityTypeFormControl.setValue(null);
        this.ResponseFormControl.setValue(null);
        this.CommentFormControl.setValue(null);
        this.PublicURLFormControl.setValue(null);
        this.RespondantFormControl.setValue(null);
        this.FormGroup.markAsPristine();
        this.FormGroup.markAsUntouched();

        this.SetNeedsResponseOnServiceAreas();

        //  Focus and open the first control that needs an input.  Normally, the dialog would do this automatically.
        //  But if the network is slow, the service area form control will be initially enabled and it will then always
        //  get focus instead of the utility type or response.
        //  SetTimeout needed here or can get a javascript error if we set focus to the responses and the available
        //  responses observable has not been created/initialized yet in the form (takes a change detection cycle).
        setTimeout(() => {
            //  Checking for null @ViewChild properties because had some errors in our logs about these properties
            //  not being set.  We have a 400ms delay on this already so don't know how that would be possible...
            if (this.ServiceAreaInfoList.length > 1) {
                if (this._ServiceAreaSelect) {
                    this._ServiceAreaSelect.focus();
                    this._ServiceAreaSelect.open();
                }
            } else if (this.UtilityTypesForCurrentServiceArea) {
                if (this._UtilityTypeSelect) {
                    this._UtilityTypeSelect.focus();
                    this._UtilityTypeSelect.open();
                }
            }
            else {
                if (this._PositiveResponseCommon?.ResponseInput) {
                    this._PositiveResponseCommon.ResponseInput.focus();
                    this._PositiveResponseCommon.ShowPanel();
                }
            }
        }, 400);        //  400ms needed here or will not show the panel in the phone flyout (it displays before the animation starts)
    }

    //  Adds a "NeedsResponse" flag to each service area that is missing a response.
    //  CurrentResponses is an array because it can contain an item for each utility type.
    private SetNeedsResponseOnServiceAreas() {
        this.ServiceAreaInfoList.forEach(sa => {
            if (sa.CurrentResponses)
                (sa as any).NeedsResponse = sa.CurrentResponses.some(r => !r.ResponseID && r.ResponseRequired);
        });
    }

    public CompareServiceAreaInfo(sa1: TicketAffectedServiceAreaInfo, sa2: TicketAffectedServiceAreaInfo): boolean {
        if (sa1 && sa2)
            return sa1.ID === sa2.ID;

        return !sa1 && !sa2;
    }

    private OnServiceAreaPicked(): void {
        const selectedServiceArea = this.ServiceAreaInfoFormControl.value as TicketAffectedServiceAreaInfo;
        if (selectedServiceArea && selectedServiceArea.CurrentResponses) {
            //  Not sure why, but doing a .sort() directly on this.SelectedServiceArea.CurrentResponses was causing Expression Changed errors if:
            //  1) Service Area uses utility types
            //  2) Has a response entered on one of them already (which one may be important - first or second)
            //  3) Open this dialog to add another response.
            //  The error happens back in the ServiceAreaList.component.html on the line that has this condition:
            //      *ngIf="sa.ServiceAreaInfo.CurrentResponses && sa.ServiceAreaInfo.CurrentResponses.length > 0 && sa.ServiceAreaInfo.CurrentResponses[0].ResponseID"
            //  Changing to sort on the .map() result fixes it.  Something about that sort must be changing the .CurrentResponses instance
            //  (even though it's not supposed to!).
            this.UtilityTypesForCurrentServiceArea = selectedServiceArea.CurrentResponses
                .filter(r => r.UtilityTypeID)
                .map(r => {
                    const responseText = r.ResponseID ? (r.ResponseCode + " - " + r.ResponseDescription) : null;
                    return new UtilityTypeOption(r.UtilityTypeID, r.UtilityTypeName, responseText);
                })
                .sort((a, b) => a.Name.localeCompare(b.Name));
            if (this.UtilityTypesForCurrentServiceArea.length > 0) {
                this.UtilityTypeFormControl.setValidators(Validators.required);

                if (this.UtilityTypesForCurrentServiceArea.length === 1)
                    this.UtilityTypeFormControl.setValue(this.UtilityTypesForCurrentServiceArea[0]);

                this.UtilityTypeFormControl.updateValueAndValidity();

            }
            else {
                this.UtilityTypesForCurrentServiceArea = null;
                this.UtilityTypeFormControl.setValue(null);
                this.UtilityTypeFormControl.clearValidators();
                this.UtilityTypeFormControl.updateValueAndValidity();
            }

            this._GetAffectedTicketNumbers();
        }
    }

    private OnUtilityPicked(): void {
        this._GetAffectedTicketNumbers();
    }

    private _GetAffectedTicketNumbers() {
        this.affectedTicketNumbers = [];
        this.unaffectedTicketNumbers = [];

        const selectedServiceArea = this.ServiceAreaInfoFormControl.value as TicketAffectedServiceAreaInfo;
        const selectedUtility = this.UtilityTypeFormControl.value as UtilityTypeOption;

        if (!this.SingleItem && selectedServiceArea && selectedServiceArea.ID) {
            let matchingItems = this.affectedTicketsForServiceArea.filter(m => m.ServiceAreaID === selectedServiceArea.ID);
            if (this.UtilityTypesForCurrentServiceArea && this.UtilityTypesForCurrentServiceArea.length > 0)
                matchingItems = matchingItems.filter(m => ((!m.UtilityTypeID && !selectedUtility?.ID) || (m.UtilityTypeID === selectedUtility?.ID)));

            //using set ensures a distinct collection
            if (matchingItems && matchingItems.length > 0) {
                const affectedTicketNumbersSet = new Set(this.Data.TicketNumbers.filter(number => matchingItems[0].TicketNumbers.includes(number)));
                if (affectedTicketNumbersSet)
                    this.affectedTicketNumbers = Array.from(affectedTicketNumbersSet);

                if (this.affectedTicketNumbers && this.affectedTicketNumbers.length > 0) {
                    const unaffectedTicketNumbersSet = new Set(this.Data.TicketNumbers.filter(number => !this.affectedTicketNumbers.includes(number)));
                    if (unaffectedTicketNumbersSet)
                        this.unaffectedTicketNumbers = Array.from(unaffectedTicketNumbersSet);
                }
            }
        }
    }

    public ResponseDisplayFn(response: ResponseConfig): string | undefined {
        return this._PositiveResponseCommon.ResponseDisplayFn(response);
    }
}
