
export enum DeliveryRuleHolidayRuleEnum {
    Always = 1,
    Never = 2,
    Ignore = 3
}

export enum DeliveryRuleHolidayRuleEnumDescriptions {
    Always = "Always Send on Holiday regardless of Hours",
    Never = "Never Send on Holiday",
    Ignore = "Ignore Holidays/Observe Normal Hours"
}
