import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from 'Models/Security/AppUser.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import _ from 'lodash';
import { takeUntil } from 'rxjs';
import { ChatService } from '../Chat.service';
import { ITawkAPI } from './ITawkAPI.interface';

//  ** Note: Switching between 2 One Calls that both use Tawk can be hit or miss.  It sometimes causes javascript errors inside the Tawk .js.
//  Could not find any solution for it.  Seems to be bugs on their side that don't handle the switching very gracefully (like not stopping
//  timers or something).  Only an issue on dev site so not worrying about it for now.  But it probably gets logged so may need to figure something out
//  at some point.

//  Chat Widget API documentation: https://developer.tawk.to/jsapi/
//  How to disable the "We are here" message: https://community.tawk.to/t/how-to-disable-the-we-are-here-message/3558

@Component({
    selector: 'iq-chat-tawk',
    templateUrl: './TawkChat.component.html',
    styleUrls: ['./TawkChat.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class TawkChatComponent extends ComponentWithDestroySubscription implements AfterViewInit, OnDestroy {
    private _TawkAPI: ITawkAPI = null;
    private _PropertyID: string = null;
    private _WidgetID: string = null;

    constructor(private _AuthenticationService: AuthenticationService, private _ChatService: ChatService,
        private _ElementRef: ElementRef, private _Renderer: Renderer2, private router: Router)
    {
        super();

        //  Check to see if there is an existing TawkAPI in the window - from a previous One Call that uses it.
        //  i.e. Switch to a One Call that uses it, switch to one that does not, now switch back to original.
        //  We need to set Tawk_API to this or we won't be able to tell that we are already initialized.
        if ((window as any).Tawk_API)
            this._TawkAPI = (window as any).Tawk_API;

        _ChatService.PositionAboveMobileToolbar.pipe(takeUntil(this.Destroyed)).subscribe(() => this.UpdateButtonPosition());

        //  This is necessary to handle switching to a different Tawk account when switching to a different One Call.
        this._AuthenticationService.CurrentUserObserver().pipe(takeUntil(this.Destroyed)).subscribe(user => {
            if (TawkChatComponent.IsEnabled(this._AuthenticationService.CurrentUser))
                this.InitializeTawk();
        });
    }

    public static IsEnabled(user: AppUser): boolean {
        //  Tawk requires 2 values.
        //  These are both in the embed URL (property is first, widget is second).
        //  They are also found in Tawk:
        //  1) Administration (gear icon)
        //  2) Property ID is on the Overview page
        //  3) Widget ID is on the Channels -> Chat Widget page.
        return !_.isEmpty(user.OneCallCenterSettings.Chat_Tawk_PropertyID) && !_.isEmpty(user.OneCallCenterSettings.Chat_Tawk_WidgetID);
    }

    public ngAfterViewInit(): void {
        this.InitializeTawk();
    }

    private InitializeTawk(): void {
        //  Check to see if we are in the process of initializing.  This is the case when this._TawkAPI has not been
        //  set but there is an object in the "window".  If this is the case, we are in the process of initializing and
        //  probably got called and extra time due to our subscription on the current user.
        if (!this._TawkAPI && (window as any).Tawk_API)
            return;

        const propertyID = this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_Tawk_PropertyID;
        const widgetID = this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_Tawk_WidgetID;

        if (this._TawkAPI) {
            //  Already initialized.  Check to see if the current users PropertyID & WidgetID are different from the
            //  current values.  If so, hide the current widget and then switch to the new PropertyID/WidgetID.
            if ((this._PropertyID !== propertyID) || (this._WidgetID !== widgetID)) {
                this.CreateTawkAPI(propertyID, widgetID);
                this._TawkAPI.switchWidget({ propertyId: propertyID, widgetId: widgetID });
                this._TawkAPI = null;       //  So that we know we are initializing.  switchWidget calls the "onload" event which is set in CreateNewTawkAPI()
            }
            else {
                //  Don't think this is possible...
                this._TawkAPI.start({showWidget: true});     //  Same so just show the widget
            }
            return;
        }

        //  Must pre-create the Tawk_API object before the script is loaded or it will not be created.
        //  And then it allows us to set the visitor name/email and an onLoad handler which will be called
        //  as soon as the api is loaded.
        this.CreateTawkAPI(propertyID, widgetID);

        const script = this._Renderer.createElement("script");
        script.type = "text/javascript";
        script.src = "https://embed.tawk.to/" + propertyID + "/" + widgetID;

        this._Renderer.appendChild(document.body, script);
    }

    private CreateTawkAPI(propertyID: string, widgetID: string): void {
        //  Create a new empty TawkAPI with our initial configurations.  This is needed when first loading and also when switching.
        (window as any).Tawk_API = {
            visitor: {
                name: this._AuthenticationService.CurrentUser.FullName,
                email: this._AuthenticationService.CurrentUser.EmailAddress
            },
            onLoad: () => {
                this._TawkAPI = (window as any).Tawk_API;
                this._PropertyID = propertyID;
                this._WidgetID = widgetID;
                this.UpdateButtonPosition();
            }
        };
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();

        //  There is no way to cleanly remove Tawk from the DOM.  It has too many things it loads and an active
        //  background timer.  The best we can do is shut it down - which will hide it from the UI.
        //  Even the "hideWidget" method doesn't actually hide it!
        //  If we later need to re-enable it, we detect it's still there and do a "switchWidget" or showWidget as necessary.
        if (this._TawkAPI?.minimize) {
            //  Must minimize or if the chat is maximized (expanded - user is chatting), it will still be visible!
            this._TawkAPI.minimize();

            //  endChat followed by shutdown causes an error because the js api tries to tell the server that the chat has ended and fails because we shut it down.
            //this._TawkAPI.endChat();

            //  hideWidget does nothing!
            //this._TawkAPI.hideWidget();

            //  This (plus minimize above) is the only way to reliably make it disappear.
            this._TawkAPI.shutdown();
        }
    }

    private UpdateButtonPosition(): void {
        //  Have to set the position of the iframe element that is inside a div off the root.
        const iframeElement: HTMLIFrameElement = document.querySelector("div.widget-visible>iframe");
        if (!iframeElement)
            return;

        if (this._ChatService.PositionAboveMobileToolbar.value)
            iframeElement.style.setProperty("bottom", "58px", "important");
        else
            iframeElement.style.setProperty("bottom", "20px", "important");
    }
}
