<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div [formGroup]="group" style="width: 100%; display: flex; flex-direction: column;">
    <div style="flex: 1 1 0%;">
        Change your Password
    </div>
    <p style="flex: 1 1 0%;" class="mat-body-2">
        You currently have a temporary password and need to set a new one.
    </p>
    <ng-template #waitingMessage>
        <p style="flex: 1 1 0%;" class="mat-body-2">
            {{ActionMessage}}
        </p>
    </ng-template>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Username</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="Username" />
    </mat-form-field>

    <div class="mat-caption" *ngIf="PasswordRequirements != null">
        <p>Password requirements:</p>
        <ul>
            <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
            <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
            <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
            <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
            <li *ngIf="PasswordRequirements.RequireASpecialCharacter">Must have a special character ($ * . ? - ! &#64; #
                % &)
            </li>
        </ul>
    </div>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>New Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="Password"
               (keyup.enter)="onSubmitPasswordChange()" autofocus />
        <mat-error iq-aws-cognito-validator [control]="password"></mat-error>
    </mat-form-field>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Confirm New Password</mat-label>
        <input matInput type="password" autocomplete="off" blockPaste
               formControlName="ConfirmPassword" (keyup.enter)="onSubmitPasswordChange()" />
        <mat-error *ngIf="confirmPassword.hasError('required')">required</mat-error>
        <mat-error *ngIf="confirmPassword.hasError('equalValue')">password must match</mat-error>
    </mat-form-field>

    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <ng-container *ngIf="sessionExpired == false; else sessionExpiredTemplate">
        <button mat-flat-button (click)="onSubmitPasswordChange()" color="primary" [disabled]="!group.valid || isBusy">
            Set Password
        </button>
    </ng-container>

    <ng-template #sessionExpiredTemplate>
        <label class="mat-caption mat-error">Your session has expired.  Please click the button below to restart the process</label>
        <button mat-flat-button (click)="onRestart()" color="primary">
            Restart
        </button>
    </ng-template>
</div>
