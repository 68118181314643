import { Component } from '@angular/core';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { ProfileVerificationsStepBaseComponent } from './ProfileVerificationsStepBase.component';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Component({
    templateUrl: './ProfileVerificationsAttributesStepInfo.component.html',
    styleUrls: ['./ProfileVerificationsAttributesStepInfo.component.scss']
})

export class ProfileVerificationsAttributesStepInfoComponent extends ProfileVerificationsStepBaseComponent {
    public hasAttributes: boolean = false;
    public override Name = StepNameConstants.ATTRIBUTES;

    constructor() {
        super();
    }

    public override SetData(data: ProfileVerification, currentStep: number, isExisting: boolean, formIsReadonly: boolean, setButtonFlagsCallback: () => void): void {
        super.SetData(data, currentStep, isExisting, formIsReadonly, setButtonFlagsCallback);
        setTimeout(() => this.hasAttributes = this.OriginalProfile.Attributes !== null && this.OriginalProfile.Attributes.length > 0);
    }
}
