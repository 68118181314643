<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 style="margin: 0px;">Contacts: </h4>
    <div *ngFor="let Contact of VerificationContacts;" style="display: flex; width: 100%; flex-wrap: wrap; margin-bottom: 20px; column-gap: 10px;">
        <div class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Name: </label>
            <span>{{Contact.FirstName}} {{Contact.LastName}}</span>
        </div>
        <div *ngIf="Contact.HasLogin" class="profile-field">
            <label class="iq-caption gray-primary-text profile-label">Login: </label>
            <span>{{Contact.Login}}</span>
        </div>
        <div *ngIf="Contact.ContactTypes && Contact.ContactTypes.length > 0" class="listField">
            <label class="iq-caption gray-primary-text profile-label" style="margin-top: 3px;">Contact Types: </label>
            <ng-container *ngFor="let Type of Contact.ContactTypes; last as isLast">
                <span *ngIf="!isLast">{{Type.Name}}, </span>
                <span *ngIf="isLast">{{Type.Name}} </span>
            </ng-container>
        </div>
        <div *ngIf="Contact.Emails && Contact.Emails.length > 0" class="listField">
            <label class="iq-caption gray-primary-text profile-label" style="margin-top: 3px;">Emails: </label>
            <span *ngFor="let Email of Contact.Emails; last as isLast">
                <span *ngIf="!isLast">{{Email.EmailAddress}}, </span>
                <span *ngIf="isLast">{{Email.EmailAddress}} </span>
            </span>
        </div>
        <div *ngIf="Contact.Phones && Contact.Phones.length > 0" style="width: 100%; display: flex; column-gap: 10px; flex-wrap: wrap;">
            <label class="iq-caption gray-primary-text" style="margin-top: 3px; margin-right:10px;">Phones: </label>
            <div style="margin-left: 10px; width: 100%;">
                <div *ngFor="let Phone of Contact.Phones;" style="display: flex; width: 100%; flex-wrap: wrap; column-gap: 10px; ">
                    <div *ngIf="Phone.PhoneNumber" style="display: flex; column-gap: 10px; align-items: baseline;">
                        <span>{{Phone.PhoneNumber | phone}}</span>
                    </div>
                    <div *ngIf="Phone.PhoneType" class="phoneField" style=" display: flex; column-gap: 10px; align-items: baseline;">
                        <label style="margin-top: 3px;" class="iq-caption gray-primary-text">Type: </label>
                        <span>{{Phone.PhoneType.Name}}</span>
                    </div>
                    <div *ngIf="Phone.Usage" class="phoneField" style="display: flex; column-gap: 10px; align-items: baseline;">
                        <label style="margin-top: 3px;" class="iq-caption gray-primary-text">Usage: </label>
                        <span>{{Phone.Usage.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="Contact.Addresses && Contact.Addresses.length > 0" style="width: 100%;">
            <label class="iq-caption gray-primary-text" style="margin-top: 3px; margin-right:10px; display: flex; width: 100%;">Addresses: </label>
            <span *ngFor="let Address of Contact.Addresses;" style="width: 100%; margin-left: 10px;">
                <span *ngIf="Address.Attention">{{Address.Attention}} - {{Address.Address1}} {{Address.City}} {{Address.State}}, {{Address.Zip}} </span>
                <span *ngIf="!Address.Attention">{{Address.Address1}} {{Address.City}} {{Address.State}}, {{Address.Zip}} </span>
            </span>
        </div>
    </div>
    <div *ngIf="!OriginalProfile.Contacts || OriginalProfile.Contacts.length === 0">
        <span>No Contacts available</span>
    </div>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>

