export enum DeliveryMethodEnum {
    SendToDestination = 1,
    ForwardToServiceProvider = 2,
    ManualCall = 3,
    AutomatedCall = 4,
    SMS = 5
}

export enum DeliveryMethodEnumDescriptions {
    SendToDestination = "Send to Destination",
    ForwardToServiceProvider = "Forward to a Service Provider",
    ManualCall = "Manual Call Out",
    AutomatedCall = "Automated IVR Call Out",
    SMS = "SMS"
}
