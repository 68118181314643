import { Component, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ProfileVerificationsBaseComponent } from '../ProfileVerificationsBase.component';
import { ServiceAreaProfileVerificationService } from '../Services/ServiceAreaProfileVerification.service';
import { SettingsService } from 'Services/SettingsService';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { MainMenuService } from 'Services/MainMenuService';

@Component({
    templateUrl: './ProfileVerificationsMobilePage.component.html',
    styleUrls: ['./ProfileVerificationsMobilePage.component.scss']
})

export class ProfileVerificationsMobilePageComponent extends ProfileVerificationsBaseComponent {
    protected Destroyed: Subject<void> = new Subject();

    constructor(protected _activatedRoute: ActivatedRoute,
        protected _AuthenticationService: AuthenticationService,
        protected _Router: Router,
        protected _ProfileVerificationsService: ServiceAreaProfileVerificationService,
        protected _SettingsService: SettingsService,
        protected _Dialog: MatDialog,
        protected _DeviceDetectorService: DeviceDetectorService,
        private _MainMenuService: MainMenuService) {

        super(_ProfileVerificationsService, _SettingsService, _Dialog, _AuthenticationService, _DeviceDetectorService);
        this._activatedRoute.data
            .pipe(takeUntil(this.Destroyed))
            .subscribe((data: { item: any }) => {
                if (data && data.item) {
                    this.SetProfileVerification(data.item);
                }
            });
        this._MainMenuService.HideTopMenuBar = true;
    }

    public override Close(): void {
        super.Close();
        this._Router.navigate(['/home']);
    }

}
