import { GeometryTransformer } from "Shared/Components/Maps/GeometryTransformer";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { GeometryTypeEnum, OLGeometryTypeEnum } from "Enums/GeometryType.enum";
import * as _ from 'lodash';
import * as jsts from "jsts";      //  JavaScript Topology Suite: 2.7.2 changes how to import, see https://github.com/bjornharrtell/jsts/issues/442
import { Feature } from "ol";
import { Extent, extend as Extent_extend } from "ol/extent";
import { GeoJSON } from "ol/format";
import { Geometry, Circle, Polygon, LineString, MultiLineString, Point, LinearRing, MultiPoint, MultiPolygon } from "ol/geom";
import { distance as coordinate_distance, Coordinate } from "ol/coordinate";
import RenderFeature from "ol/render/Feature";

/*
    jst 2.7.2+:
    Can import everything like this:
import { Coordinate as jsts_Coordinate, Geometry as jsts_Geometry, GeometryCollection as jsts_GeometryCollection, GeometryFactory, MultiPolygon as jsts_MultiPolygon, Polygon as jsts_Polygon } from "jsts/org/locationtech/jts/geom";
import { GeoJSONReader, GeoJSONWriter, OL3Parser } from "jsts/org/locationtech/jts/io";

    *BUT* Now the library also does not support many api's on the geometry instances!  i.e. .buffer(), .intersects(), .union(), etc.
    So need to find and change all of those places with calls to the various static operation functions (in jsts/org/locationtech/jts/operation)
    See: https://github.com/bjornharrtell/jsts/issues/474
        and notes about on the Caveats section of npm page: https://www.npmjs.com/package/jsts

    * This shows the functions (or maybe could be used to add the functions?).  Could at least use this to search and find them all.
        https://github.com/bjornharrtell/jsts/blob/master/src/org/locationtech/jts/monkey.js

    Did not have time to deal with that so left this for another day and will stay with 2.7.1.

    * Import operations like this.  Can drill in to the source to see what is exported - they are exported as "default".
import { default as jsts_UnionOp } from "jsts/org/locationtech/jts/operation/union/UnionOp";
import { default as jsts_Polygonizer } from "jsts/org/locationtech/jts/operation/polygonize/Polygonizer";

    Can then do this:
        const polygonizer = new jsts_Polygonizer(true);      //  need true here so that it handles holes correctly (otherwise, it returns them as separate polygons too!)
    And this (to fix the missing monkey patched operations):
        lineString = jsts_UnionOp.union(lineString, geometryFactory.createPoint(lineString.getCoordinateN(0)));
 */

export class GeometryUtils {

    public static CreateJstsOLParser(): jsts.io.OL3Parser {
        //  https://github.com/bjornharrtell/jsts/issues/332
        //  OpenLayers Example: https://openlayers.org/en/latest/examples/jsts.html
        const parser: jsts.io.OL3Parser = new jsts.io.OL3Parser();
        parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
        return parser;
    }

    public static GeometryTypeOfFeatures(features: Feature<any>[]): OLGeometryTypeEnum {
        if (!features || (features.length === 0))
            return null;

        return GeometryUtils.GeometryTypeOfFeature(features[0]);
    }

    public static GeometryTypeOfFeature(feature: Feature<any> | RenderFeature): OLGeometryTypeEnum {
        if (!feature)
            return null;

        //  OpenLayers geometry type also defined like this:
        //      type GeometryType = "Point" | "LineString" | "LinearRing" | "Polygon" | "MultiPoint" | "MultiLineString" | "MultiPolygon" | "GeometryCollection" | "Circle";

        const geom = feature.getGeometry() as Geometry;
        const geomType = geom.getType() as string;

        return OLGeometryTypeEnum[geomType];
    }

    public static OLGeometryTypeToGeometryType(olGeometryType: OLGeometryTypeEnum): GeometryTypeEnum {
        switch (olGeometryType) {
            case OLGeometryTypeEnum.Point:
            case OLGeometryTypeEnum.MultiPoint:
                return GeometryTypeEnum.Point;
            case OLGeometryTypeEnum.LineString:
            case OLGeometryTypeEnum.MultiLineString:
                return GeometryTypeEnum.Line;
            case OLGeometryTypeEnum.Polygon:
            case OLGeometryTypeEnum.MultiPolygon:
            case OLGeometryTypeEnum.Circle:
                return GeometryTypeEnum.Polygon;
            default:
                console.error("OLGeometryTypeToGeometryType: Invalid OL Geometry Type", olGeometryType);
                return GeometryTypeEnum.Polygon;
        }
    }

    public static ExtentsOfFeatures(features: Feature<any>[]): Extent {
        if (!features || (features.length === 0))
            return null;

        let extents: Extent = null;

        features.forEach(f => extents = this.AppendExtents(extents, f.getGeometry().getExtent()));

        return extents;
    }

    public static AppendExtents(extents1: Extent, extents2: Extent): Extent {
        if (!extents1)
            return extents2;
        if (!extents2)
            return extents1;

        Extent_extend(extents1, extents2);
        return extents1;
    }

    public static ExtentToJstsPolygon(extent: Extent): jsts.geom.Polygon {
        const geometryFactory = new jsts.geom.GeometryFactory();

        const minX = extent[0];
        const minY = extent[1];
        const maxX = extent[2];
        const maxY = extent[3];

        const coordinates: jsts.geom.Coordinate[] = [
            new jsts.geom.Coordinate(minX, maxY),     //  top-left (NW)
            new jsts.geom.Coordinate(maxX, maxY),     //  top-right (NE)
            new jsts.geom.Coordinate(maxX, minY),     //  bottom-right (SE)
            new jsts.geom.Coordinate(minX, minY),     //  bottom-left (SW)
            new jsts.geom.Coordinate(minX, maxY)      //  close the polygon
        ];

        return geometryFactory.createPolygon(geometryFactory.createLinearRing(coordinates));
    }

    public static UnionJstsGeometries(geoms: jsts.geom.Geometry[]): jsts.geom.Geometry {
        if (!geoms || (geoms.length === 0))
            return null

        if (geoms.length === 1)
            return geoms[0];

        return jsts.operation.union.UnaryUnionOp.union(new jsts.geom.GeometryCollection(geoms, new jsts.geom.GeometryFactory()));
    }

    public static CopyAttributesFromFeature(sourceFeature: Feature<any>, targetFeature: Feature<any>): void {
        sourceFeature.getKeys().forEach(key => {
            if (key !== "geometry")
                targetFeature.set(key, sourceFeature.get(key));
        });
        targetFeature.setId(sourceFeature.getId());
    }

    /**
     * Converts the geometry in the features and returns a jsts geometry by union'ing all of the geometries together.
     * Does not apply any buffers or do any reprojections.
     * @param features
     */
    public static FeaturesToJstsGeometry(features: Feature<any>[]): jsts.geom.Geometry {
        if (!features || (features.length === 0))
            return null;

        //  Must use OpenLayersGeometryToJstsGeometry because there are some cases where we have perfectly valid
        //  registration polygons (according to Postgis) but when they are translated into the map projection, something
        //  about them becomes invalid.  This ensures that they are valid in case we perform some kind of geometric
        //  operation on them.
        const jstsGeomArray = features.map(f => GeometryUtils.OpenLayersGeometryToJstsGeometry(f.getGeometry(), null));
        if (jstsGeomArray.length === 1)
            return jstsGeomArray[0];

        return this.UnionJstsGeometries(jstsGeomArray);
    }

    public static FeatureToLatLonJstsGeometry(feature: Feature<any>, mapToolService: MapToolService): jsts.geom.Geometry {
        //  Read, clean, and buffer the feature geometry
        const jstsGeom = this.OpenLayersGeometryToJstsGeometry(feature.getGeometry(), mapToolService);

        //  Convert back to OpenLayers geometry so we can use the OpenLayers formatter to do the reprojection
        const olParser = GeometryUtils.CreateJstsOLParser();
        const olGeom = olParser.write(jstsGeom);           

        const formatter = new GeoJSON();
        const olLatLonGeom = formatter.writeGeometryObject(olGeom, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: MapConstants.MAP_PROJECTION });

        const parser = new jsts.io.GeoJSONReader();
        return parser.read(olLatLonGeom);
    }

    public static JstsGeometryToOpenLayersGeometry(jstsGeom: jsts.geom.Geometry): Geometry {
        if (!jstsGeom)
            return null;

        const olParser = GeometryUtils.CreateJstsOLParser();
        return olParser.write(jstsGeom);
    }

    public static LatLonJstsGeometryToFeature(geom: jsts.geom.Geometry): Feature<any>[] {
        if (!geom)
            return null;

        const writer = new jsts.io.GeoJSONWriter();
        const geoJson = writer.write(geom);

        return this.GeoJsonToFeatures(geoJson);
    }

    public static LatLonJstsGeometryToGeojson(geom: jsts.geom.Geometry): object {
        if (!geom)
            return null;

        const writer = new jsts.io.GeoJSONWriter();
        return writer.write(geom);
    }

    public static GeoJsonToFeatures(geoJson: object): Feature<any>[] {
        const format = new GeoJSON();
        return format.readFeatures(geoJson, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: MapConstants.MAP_PROJECTION });
    }

    public static OpenLayersGeometryToJstsGeometry(olGeom: Geometry, mapToolService: MapToolService): jsts.geom.Geometry {
        try {
            switch (olGeom.getType()) {
                case "Circle":
                    //  Circle geometry is not supported by geojson or the geometry functions on the server.  So must
                    //  convert it into a polygon.  If we then edit it, we can detect if the polygon is a circle
                    //  and re-constructor it (which makes editing nicer because you can drag on the side to expand/shrink).
                    return GeometryUtils.CircleToPolygon(olGeom as Circle);

                case "Polygon": {
                    //  This ensures that the polygons are valid (do not self-intersect).  Otherwise, jsts will throw topology exceptions.
                    //  And if necessary, will also buffer them.
                    const polyBuffer = mapToolService ? mapToolService.PolygonBufferFt : 0;
                    return GeometryUtils.BufferJstsPolygonOrMultiPolygon(GeometryUtils.MakeValidPolygon(olGeom as Polygon), polyBuffer);
                }
                case "LineString":
                    if (mapToolService) {
                        //  Converts to an OpenLayers polygon.  Will then be converted to a jsts polygon below.
                        olGeom = GeometryUtils.BufferLineString(olGeom as LineString, mapToolService.LineBufferFt);
                        if (!olGeom)
                            return null;
                    }
                    break;
            }

            //  All other types can be converted using the jsts OL3Parser.
            const parser = GeometryUtils.CreateJstsOLParser();
            return parser.read(olGeom);

        } catch (e) {
            //  JSTS will throw topology exceptions.  Should not happen because of how we are handling polygons.
            //  But catching them just to be safe.
            console.error("Exception in OpenLayersGeometryToJstsGeometry when converting geometry", e);
            return null;
        }
    }

    //  https://stackoverflow.com/questions/31473553/is-there-a-way-to-convert-a-self-intersecting-polygon-to-a-multipolygon-in-jts
    private static MakeValidPolygon(polygon: Polygon): jsts.geom.Geometry {
        if (!polygon || (polygon.getLinearRingCount() === 0))
            return null;

        //  Use Polygonizer to construct a polygon from the closed LineString.  This will create multiple
        //  polygons if the LineString intersects itself.
        const polygonizer = new jsts.operation.polygonize.Polygonizer(true);      //  need true here so that it handles holes correctly (otherwise, it returns them as separate polygons too!)

        //  Must use GeometryFactory to construct geometry objects - otherwise they don't get created correctly!
        const geometryFactory = new jsts.geom.GeometryFactory();

        polygon.getLinearRings().forEach(ring => {
            //  Build array of jsts coordinates
            const coordinates: jsts.geom.Coordinate[] = ring.getCoordinates().map(c => new jsts.geom.Coordinate(c[0], c[1]));
            coordinates.push(coordinates[0]);       //  Must close the polygon

            //  Create a LineString - NOT a LinearRing.  Those are not allowed to self-intersect and will throw an exception
            //  if we try to construct one.
            let lineString = geometryFactory.createLineString(coordinates);

            //  Unioning the lineString with the first point will resolve repeated points.
            lineString = lineString.union(geometryFactory.createPoint(lineString.getCoordinateN(0)));

            polygonizer.add(lineString);
        });

        const polygonList = polygonizer.getPolygons();

        switch (polygonList.size()) {
            case 0:
                return null;
            case 1:
                return polygonList.get(0);
            default: {
                //  buffer(0) helps make sure it's valid
                const multiPolygon = geometryFactory.createMultiPolygon(polygonList.toArray()).buffer(0);
                if (multiPolygon.isValid())
                    return multiPolygon;

                //  TODO: This handling is probably not good.  Would be better to detect when the user does this during drawing
                //  and prevent it there.
                //  Not valid!  This can happen if a polygon has a hole and you drag a verticie outside of the shell.
                //  i.e. A hole CANNOT intersect with the shell (or another hole, I believe).
                //  To make this valid, union everything together.  It's the only way to guarantee that the full area is returned.
                console.error("Polygonizer produced multiple polygons and the resulting MultiPolygon is not topologically valid.  Unioning all polygons together.");
                return this.UnionJstsGeometries(polygonList);
            }
        }
    }

    /**
     * If the geometry is a circle stored as a polygon, creates and returns an ol.geom.Circle object.
     * Otherwise, returns null.
     * @param olGeom
     */
    public static PolygonToCircle(olGeom: Geometry): Circle {
        //  Circles are stored as a polygon (because jsts/geojson does not support a Circle object like OpenLayers does).
        //  In order to figure out if it's a circle, convert it to a jsts geometry, find the center, then test the distance
        //  from the center to each point.  If the distance is the same (within small tolerance to account for precision),
        //  then that is the radius of the circle.  If not the same, it's not a circle at all.

        const parser = GeometryUtils.CreateJstsOLParser();
        const poly = parser.read(olGeom);
        if (!poly || (poly.getGeometryType() !== "Polygon") || (poly.getNumInteriorRing() !== 0))
            return null;

        //  When we buffer (either using jsts or PostGis), it uses 32 sides (so will have exactly 33 points).
        //  Using 20 here in case something does that differently.
        //  ** Must have this check or a rectangle will also match!
        if (poly.getExteriorRing().getCoordinates().length < 20)
            return null;

        const center = poly.getCentroid().getCoordinate() as jsts.geom.Coordinate;

        //  Must transform into geographic coordinates so that the distances will be correct.  The width/height of the map projection is NOT equal!
        //  If we don't do this, a perfect circle will produce a different radius value as we traverse the coordinates.  And if the circle is
        //  large enough, it will make us think it's not actually a circle.
        const projection = GeometryTransformer.GetProjectionForCoordinate([center.x, center.y]);
        const centerGeographic = GeometryTransformer.TransformCoordinate([center.x, center.y], null, projection);
        const coordinatesGeographic = GeometryTransformer.TransformCoordinates((olGeom as Polygon).getLinearRing(0).getCoordinates(), null, projection);

        let radius: number = -1;
        let i: number;
        for (i = 0; i < coordinatesGeographic.length; i++) {
            const distance = coordinate_distance(centerGeographic, coordinatesGeographic[i]);//.toFixed(2) * 1;

            if (radius === -1)
                radius = distance;
            else if (Math.abs(radius - distance) > 2) {     //  +/- 2 meters does break down until the circle has radius of about 5mi
                //  Does not match - not a circle
                return null;
            }
        }

        if (radius < 0)
            return null;        //  empty?!?!?

        //  Have a circle!  And the radius is in meters so use CreateCircle() to handle the reprojection.
        return GeometryUtils.CreateCircle([center.x, center.y], radius);
    }

    /**
     * Circles are not a supported geometry type by anything other than OpenLayers.  So need to convert it
     * to a polygon.
     * @param circle
     */
    private static CircleToPolygon(circle: Circle): Polygon {
        try {
            //  Must do this in geographic coordinates.  Using the current map projection will look like a circle,
            //  but it will actually be "off" slightly.  Enough that it causes issues trying to detect if it's
            //  a circle stored as a polygon (in PolygonToCircle() method)

            //  Transform the line from the center to the edge into geographic coordinates.
            const center = circle.getCenter();
            const projection = GeometryTransformer.GetProjectionForCoordinate(center);
            const lineGeographic = GeometryTransformer.TransformCoordinates([center, [center[0] + circle.getRadius(), center[1]]], null, projection);

            const radiusMeters = coordinate_distance(lineGeographic[0], lineGeographic[1]);

            //  Buffer a point at the (reprojected) center with the radius in meters
            const geometryFactory = new jsts.geom.GeometryFactory();
            const point = geometryFactory.createPoint(new jsts.geom.Coordinate(lineGeographic[0][0], lineGeographic[0][1]));
            const polyGeographic = point.buffer(radiusMeters) as jsts.geom.Polygon;

            //  Transform the coordinates in the new polygon into the map projection and create a polygon from them
            const ringCoordinates = polyGeographic.getExteriorRing().getCoordinates() as jsts.geom.Coordinate[];
            const coordinates = ringCoordinates.map(c => {
                const mapCoord = GeometryTransformer.TransformCoordinate([c.x, c.y] as Coordinate, projection, null);
                return new jsts.geom.Coordinate(mapCoord[0], mapCoord[1]);
            });

            return geometryFactory.createPolygon(geometryFactory.createLinearRing(coordinates));
        } catch {
            //  Geometry is not valid - user is probably drawing something outside the bounds of the US!
            return null;
        }
    }

    public static CircleToPointGeojsonAndRadiusFt(circle: Circle, appendToPrevGeoJson: object, prevRadiusFt: number): { geoJson: object, radiusFt: number } {
        //  Transform the line from the center to the edge into geographic coordinates.
        const center = circle.getCenter();
        const projection = GeometryTransformer.GetProjectionForCoordinate(center);
        const lineGeographic = GeometryTransformer.TransformCoordinates([center, [center[0] + circle.getRadius(), center[1]]], null, projection);

        let radiusFt = coordinate_distance(lineGeographic[0], lineGeographic[1]) / MapConstants.METERS_PER_FOOT;

        const centerLatLon = GeometryTransformer.TransformCoordinate(center, null, MapConstants.LATLON_PROJECTION);

        const geometryFactory = new jsts.geom.GeometryFactory();
        let point = geometryFactory.createPoint(new jsts.geom.Coordinate(centerLatLon[0], centerLatLon[1]));

        if (prevRadiusFt) {
            //  Union point with prevRadiusFt and return the largest of the 2 radius's
            const reader = new jsts.io.GeoJSONReader();
            point = point.union(reader.read(appendToPrevGeoJson));

            if (prevRadiusFt && (prevRadiusFt > radiusFt))
                radiusFt = prevRadiusFt;
        }

        const writer = new jsts.io.GeoJSONWriter();
        return {
            geoJson: writer.write(point),
            radiusFt: Math.round(radiusFt)
        };
    }

    /**
     * Creates a Circle with the given center point and radius.  Properly handles the distance in the radius
     * by re-projecting into a geographic coordinate system, applying the radius, and then returning a circle
     * in the default map projection.
     * @param center
     * @param radius
     */
    public static CreateCircle(center: Coordinate, radiusMeters: number): Circle {
        const projection = GeometryTransformer.GetProjectionForCoordinate(center);

        const centerGeographic = GeometryTransformer.TransformCoordinate(center, null, projection);

        //  Translate an edge vertex (computed from centerGeographic + radiusMeters) back into the default map projection
        const edgeVertex = GeometryTransformer.TransformCoordinate([centerGeographic[0] + radiusMeters, centerGeographic[1]], projection, null);

        //  The radius is now the distance from the given center point to the edgeVertex
        const radius = coordinate_distance(center, edgeVertex);

        return new Circle(center, radius);
    }

    public static BufferGeometry(geom: Geometry, bufferFeet: number): Geometry {
        if (!geom)
            return null;
        if (!bufferFeet || (bufferFeet < 1))
            return geom;

        const resultPolys: Polygon[] = [];

        switch (geom.getType()) {
            case 'LineString':
                return GeometryUtils.BufferLineString(geom as LineString, bufferFeet);
            case 'MultiLineString': {
                const mls = geom as MultiLineString;
                mls.getLineStrings().forEach(ls => {
                    const bufferedGeom = GeometryUtils.BufferLineString(ls, bufferFeet);
                    if (bufferedGeom)
                        resultPolys.push(bufferedGeom);
                });
                break;
            }
            default:
                console.error("BufferGeometry: Unsupported geometry type: " + geom.getType());
                return geom;
        }

        if (resultPolys.length === 0)
            return null;
        else if (resultPolys.length === 1)
            return resultPolys[0];

        const parser = GeometryUtils.CreateJstsOLParser();
        const jstsPolyList = resultPolys.map(olPoly => parser.read(olPoly));
        const unionedGeom = this.UnionJstsGeometries(jstsPolyList);

        return parser.write(unionedGeom);
    }

    public static BufferLineString(geom: LineString, bufferFeet: number): Polygon {
        try {
            //  Transform the LineString into a geographic coordinate system so that the buffer can be done accurately
            const projection = GeometryTransformer.GetProjectionForCoordinate(geom.getFirstCoordinate());
            const coordinates = GeometryTransformer.TransformCoordinates(geom.getCoordinates(), null, projection);

            //  Create a jsts LineString and buffer it to create a polygon
            const geometryFactory = new jsts.geom.GeometryFactory();
            const line = geometryFactory.createLineString(coordinates.map(c => new jsts.geom.Coordinate(c[0], c[1])));
            const poly = line.buffer(bufferFeet * MapConstants.METERS_PER_FOOT);

            //  Create an OpenLayers polygon from the rings and transform it back to the map projection.  It could contain
            //  a hole if you connect the line to itself!
            const olRings: Coordinate[][] = [];
            olRings.push(GeometryTransformer.TransformJstsCoordsToOlCoords(poly.getExteriorRing().getCoordinates(), projection, null));

            for (let i = 0; i < poly.getNumInteriorRing(); i++)
                olRings.push(GeometryTransformer.TransformJstsCoordsToOlCoords(poly.getInteriorRingN(i).getCoordinates(), projection, null));

            return new Polygon(olRings);        //  1st ring = shell, others = holes, docs say takes array of linear rings, but that's not true - linear ring = coord[] in this case!
        } catch {
            //  geometry outside bounds of US!
            return null;
        }
    }

    public static BufferPolygon(geom: Polygon, bufferFeet: number): Geometry {
        if (geom.getType() !== "Polygon")
            return null;

        const jstsPoly = GeometryUtils.MakeValidPolygon(geom as Polygon);
        if (!jstsPoly)
            return null;

        if (!bufferFeet)
            return GeometryUtils.JstsGeometryToOpenLayersGeometry(jstsPoly);

        const bufferedGeom = GeometryUtils.BufferJstsPolygonOrMultiPolygon(jstsPoly, bufferFeet);
        return GeometryUtils.JstsGeometryToOpenLayersGeometry(bufferedGeom);
    }

    public static BufferJstsPolygonOrMultiPolygon(geom: jsts.geom.Geometry, bufferFeet: number): jsts.geom.Polygon | jsts.geom.MultiPolygon {
        if (!geom)
            return null;

        if (geom.getGeometryType() === "Polygon")
            return GeometryUtils.BufferJstsPolygon(geom, bufferFeet);
        else if (geom.getGeometryType() === "MultiPolygon") {
            const multiPoly = geom as jsts.geom.MultiPolygon;
            const polyList: jsts.geom.Polygon[] = [];
            for (let i = 0; i < multiPoly.getNumGeometries(); i++) {
                const bufferedPoly = GeometryUtils.BufferJstsPolygon(multiPoly.getGeometryN(i), bufferFeet);
                if (bufferedPoly)
                    polyList.push(bufferedPoly);
            }

            if (polyList.length === 0)
                return null;

            //  buffer(0) seems to help resolve side location conflicts when later checking to see if a point is "within" this geom...
            const geometryFactory = new jsts.geom.GeometryFactory();
            return geometryFactory.createMultiPolygon(polyList).buffer(0);
        }

        return null;
    }

    private static BufferJstsPolygon(poly: jsts.geom.Polygon, bufferFeet: number): jsts.geom.Polygon {
        try {
            const exteriorRing = poly.getExteriorRing();
            const firstCoord = exteriorRing.getCoordinateN(0) as jsts.geom.Coordinate;
            const projection = GeometryTransformer.GetProjectionForCoordinate([firstCoord.x, firstCoord.y]);

            const transformedPoly = GeometryTransformer.TransformJstsPolygon(poly, null, projection);

            const bufferedPoly = transformedPoly.buffer(bufferFeet * MapConstants.METERS_PER_FOOT);
            return GeometryTransformer.TransformJstsPolygon(bufferedPoly, projection, null);
        } catch {
            //  Geometry not in bounds of US!
            return null;
        }
    }

    /**
     * Determines the centroid of the given feature in GeoJson format.  Returns the centroid as GeoJson.
     * @param geoJson
     */
    public static CentroidOfGeoJson(geoJson: object): string {
        if (!geoJson)
            return null;

        const format = new GeoJSON();
        const features = format.readFeatures(geoJson, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: MapConstants.LATLON_PROJECTION }) as Feature<any>[];
        if (!features || (features.length !== 1))
            return null;

        //  Convert into jsts geometries because that library has the distance calculations we need.
        const parser = GeometryUtils.CreateJstsOLParser();
        const jstsGeom = parser.read(features[0].getGeometry()) as jsts.geom.Geometry;
        if (!jstsGeom)
            return null;

        const centroid = jstsGeom.getCentroid();
        if (!centroid)
            return null;

        const writer = new jsts.io.GeoJSONWriter();
        return writer.write(centroid);
    }

    /**
     * Returns the number of feet between 2 GeoJson objects.  Returns 0 if either is empty/null/invalid.
     * @param geoJson1
     * @param geoJson2
     */
    public static DistanceFtBetweenGeoJsonObjects(geoJson1: object, geoJson2: object): number {
        if (_.isEmpty(geoJson1) || _.isEmpty(geoJson2))
            return null;

        try {
            //  Very round-about way to transform these from geoJson -> a jsts in UTM projection...
            //  Would definitely be more efficient to use only jsts to do this but need to find the right
            //  way to transform all different types of geometries and just got lazy.

            //  Need a coordinate to figure out the correct UTM zone to get the UTM projection.
            const reader = new jsts.io.GeoJSONReader();
            const geom1 = reader.read(geoJson1);
            const latLonCoord = geom1.getCoordinate();

            const projection = GeometryTransformer.GetProjectionForCoordinate([latLonCoord.x, latLonCoord.y], true);

            //  Read the geoJson and transform into the UTM projection
            const format = new GeoJSON();
            const features1 = format.readFeatures(geoJson1, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: projection }) as Feature<any>[];
            const features2 = format.readFeatures(geoJson2, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: projection }) as Feature<any>[];
            if (!features1 || (features1.length !== 1) || !features2 || (features2.length !== 1))
                return null;

            //  Convert into jsts geometries because that library has the distance calculations we need.
            const parser = GeometryUtils.CreateJstsOLParser();
            const jstsGeom1 = parser.read(features1[0].getGeometry()) as jsts.geom.Geometry;
            const jstsGeom2 = parser.read(features2[0].getGeometry()) as jsts.geom.Geometry;

            const distanceFt = jstsGeom1.distance(jstsGeom2) * MapConstants.FEET_PER_METER;
            return Math.trunc(distanceFt);

        } catch (e) {
            console.error("Exception in DistanceFtBetweenGeoJsonObjects", e);
            return 0;
        }
    }

    public static LengthFt(geom: Geometry): number {
        let lineList: LineString[];
        switch (geom.getType()) {
            case OLGeometryTypeEnum.LineString:
                lineList = [geom as LineString];
                break;
            case OLGeometryTypeEnum.MultiLineString: {
                const mls = geom as MultiLineString;
                lineList = mls.getLineStrings();
                break;
            }
            default:
                return 0;
        }

        //  For a MultiLineString, this is going to sum up the length of all segments.  Which will NOT be what is expected
        //  when it's a multi-lane highway!  But there's no way to know how to calculate that without trying to figure out
        //  which segments are for each lane.  Could maybe break everything up in to the individual segments and then relate them
        //  together by similar slope that are within [x] ft of each other and then exclude one of them?  But these will not relate
        //  1-to-1 (could have a long segment for 1 lane and then several very small segments for the other lane).
        return lineList
            .map(l => {
                const geom = GeometryTransformer.ToGeography(l);
                return geom ? geom.getLength() * MapConstants.FEET_PER_METER : 0;
            })
            .reduce((total, len) => total + len);
    }

    public static AreaSqFt(geom: Geometry): number {
        let polyList: Polygon[];
        switch (geom.getType()) {
            case OLGeometryTypeEnum.Polygon:
                polyList = [geom as Polygon];
                break;
            case OLGeometryTypeEnum.MultiPolygon: {
                const mp = geom as MultiPolygon;
                polyList = mp.getPolygons();
                break;
            }
            default:
                return 0;
        }

        return polyList
            .map(p => {
                const jstsPoly = GeometryUtils.OpenLayersGeometryToJstsGeometry(p, null);

                const exteriorRing = jstsPoly.getExteriorRing();
                const firstCoord = exteriorRing.getCoordinateN(0) as jsts.geom.Coordinate;
                const projection = GeometryTransformer.GetProjectionForCoordinate([firstCoord.x, firstCoord.y]);

                return GeometryTransformer.TransformJstsPolygon(jstsPoly, null, projection).getArea() * MapConstants.SQFEET_PER_SQMETER;
            })
            .reduce((total, len) => total + len);
    }

    public static DimensionsFt(geom: Geometry): { width: number, height: number } {
        //  Can't just translate the extent - it results in the wrong values for rectangles!
        //  Must translate the entire geometry and can then get the correct extent from it.
        const jstsGeom = GeometryUtils.OpenLayersGeometryToJstsGeometry(geom, null);
        if (!jstsGeom)
            return { width: 0, height: 0 };

        const env = jstsGeom.getEnvelopeInternal();
        const projection = GeometryTransformer.GetProjectionForCoordinate([env.getMinX(), env.getMinY()]);
        const transformedGeom = GeometryTransformer.TransformJstsGeom(jstsGeom, null, projection);
        if (!transformedGeom)
            return { width: 0, height: 0 };

        const transformedEnv = transformedGeom.getEnvelopeInternal();
        //console.warn("DimensionsFt", env, transformedEnv);

        return {
            width: (transformedEnv.getMaxX() - transformedEnv.getMinX()) * MapConstants.FEET_PER_METER,
            height: (transformedEnv.getMaxY() - transformedEnv.getMinY()) * MapConstants.FEET_PER_METER
        };
    }
}
