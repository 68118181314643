export enum ReceiverTypeEnum {
    Email = 0,
    Fax = 1,
    WebRequest = 2,
    IVR = 3,                //  i.e.  Outbound Excavator Positive Response or Automated Ticket Callout
    ManualCall = 4,
    SMS = 5,
    RestApiJson = 6
}

export enum ReceiverTypeEnumDescriptions {
    Email = "Email",
    Fax = "Fax",
    WebRequest = "Web Request (XML)",
    IVR = "IVR",
    ManualCall = "Manual Call",
    SMS = "SMS",
    RestApiJson = "REST API (JSON)"
}
