<fieldset class="primary-border-color">
    <legend class="primary-color">Feedback:</legend>
    <form [formGroup]="FormGroup" layout="row" class="container" style="display: flex; flex-direction: column;">
        <mat-checkbox [formControl]="EverythingCorrectFormControl" color="primary" (change)="EverythingCorrectChanged($event)">Please check to approve step, if unchecked please provide changes</mat-checkbox>
        <mat-error iqValidationMessage [control]="EverythingCorrectFormControl"></mat-error>
        <mat-form-field>
            <mat-label>Requested Changes</mat-label>
            <textarea matInput formControlName="RequestedChanges" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" #autosize="cdkTextareaAutosize" cdkTextareaAutosize></textarea>
            <mat-error iqValidationMessage [control]="RequestedChangesFormControl"></mat-error>
        </mat-form-field>
    </form>
</fieldset>
