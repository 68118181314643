import { OLGeometryTypeEnum } from "Enums/GeometryType.enum";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { FeatureItemResponse } from "Models/Maps/FeatureItemResponse.model";
import { GeometryUtils } from "Shared/Components/Maps/GeometryUtils";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import * as jsts from "jsts"; //  JavaScript Topology Suite: 2.7.2 changes how to import, see https://github.com/bjornharrtell/jsts/issues/442
import { Feature } from "ol";
import { Extent } from "ol/extent";
import { GeoJSON } from "ol/format";
import { Geometry, MultiLineString, MultiPolygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import RenderFeature from "ol/render/Feature";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import { GeometryTransformer } from "../GeometryTransformer";

export abstract class VectorLayerBase {

    private _VectorLayer: VectorLayer<Feature<Geometry>>
    get Layer(): VectorLayer<Feature<Geometry>> {
        return this._VectorLayer;
    }

    private _VectorSource: VectorSource<Feature<Geometry>>
    public get VectorSource(): VectorSource<Feature<Geometry>> {
        return this._VectorSource;
    }

    constructor(protected MapToolService: MapToolService, public LayerName: string, public Opacity: number, public LegendName: string = null) {
        this.CreateLayer();

        if (!LegendName)
            this.LegendName = LayerName;
    }

    public OnDestroy(): any {
        this._VectorSource = null;
        this._VectorLayer = null;

        return null;
    }

    private CreateLayer(): void {
        this._VectorSource = this.CreateSource();

        this._VectorLayer = new VectorLayer({
            source: this._VectorSource,
            style: (feature, resolution) => this.BuildStyleForFeature(feature, false, this.DetermineFeatureStyleOfFeature(feature), resolution),
            opacity: this.Opacity
        });

        this._VectorLayer.set("name", this.LayerName);
    }

    protected CreateSource(): VectorSource<Feature<Geometry>> {
        return new VectorSource({
            format: new GeoJSON(),
            overlaps: false     //  Makes the polygon fills look better where polygon edges butt-up against each other
        });
    }

    public abstract BuildStyleForFeature(feature: Feature<any> | RenderFeature, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[];

    public DetermineFeatureStyleOfFeature(feature: Feature<any> | RenderFeature): MapFeatureStyleEnum {
        const geomType = GeometryUtils.GeometryTypeOfFeature(feature);

        switch (geomType) {
            case OLGeometryTypeEnum.Point:
            case OLGeometryTypeEnum.MultiPoint:
                return MapFeatureStyleEnum.Point;
            case OLGeometryTypeEnum.Circle:
                return MapFeatureStyleEnum.Circle;
            case OLGeometryTypeEnum.LineString:
            case OLGeometryTypeEnum.MultiLineString:
                return MapFeatureStyleEnum.Line;
            case OLGeometryTypeEnum.Polygon:
            case OLGeometryTypeEnum.MultiPolygon:
                return MapFeatureStyleEnum.Polygon;
            default:        //  ???
                console.warn("DetermineFeatureStyleFromCurrentFeatures.DetermineFeatureStyleFromCurrentFeatures", geomType, this._VectorSource.getFeatures());
                return null;
        }
    }

    public GetBestFitExtents(): Extent {
        if (!this._VectorSource || (this._VectorSource.getFeatures().length === 0))
            return null;

        return this._VectorSource.getExtent();
    }

    public LoadGeoJSON(geoJson: object | object[], name?: string, clearExisting: boolean = true): void {
        if (clearExisting)
            this._VectorSource.clear();

        if (!geoJson)
            return;

        let list: object[];
        if (geoJson instanceof Array) {
            if (geoJson.length === 0)
                return;
            list = geoJson;
        }
        else
            list = [geoJson];

        list.forEach(g => {
            const features = GeometryUtils.GeoJsonToFeatures(g);
            if (features) {
                if (name)
                    features.forEach(f => f.set("name", name));
                this._VectorSource.addFeatures(features);
            }
        });
    }

    public LoadGeoJSONCollection(list: object[]): void {
        this._VectorSource.clear();

        if (!list)
            return;

        list.forEach(geoJson => {
            const features = GeometryUtils.GeoJsonToFeatures(geoJson);
            if (features)
                this._VectorSource.addFeatures(features);
        });
    }

    public GeometryTypeOfFeatures(): OLGeometryTypeEnum {
        return GeometryUtils.GeometryTypeOfFeatures(this._VectorSource.getFeatures());
    }

    /**
     *  Called after features have been drawn to ensure that they are "clean" and meet whatever requirements
     *  we have for them (such as minimum size).
     */
    public CleanAndAddFeature(feature: Feature<any>, bufferFeatures: boolean, clearExisting: boolean = false): void {
        const cleanedFeatures: Feature<any>[] = []

        const geom = feature.getGeometry();
        const geomType = GeometryUtils.GeometryTypeOfFeature(feature);

        //  Reduce any "Multi" geometries into multiple geometries so that we can dissolve them together if necessary.
        let olGeomList: Geometry[] = [];
        switch (geomType) {
            case OLGeometryTypeEnum.MultiPolygon:
                olGeomList = (geom as MultiPolygon).getPolygons();
                break;
            case OLGeometryTypeEnum.MultiLineString:
                olGeomList = (geom as MultiLineString).getLineStrings();
                break;
            case OLGeometryTypeEnum.Circle:
                //  Skip all the extra processing for Circles.  We can just add them directly as they are.  If we process them,
                //  we'll just end up converting them to a Polygon and then immediately converting them back to a Circle immediately after.
                //  When we actually fetch the contents to store somewhere, they get converted to a Polygon (in GetGeoJson()).
                cleanedFeatures.push(feature);
                break;
            default:
                olGeomList = [geom];
                break;
        }

        //  Only buffer if it's a point or line feature.  Otherwise, buffering a polygon will expand the existing feature
        //  by the buffer size!
        bufferFeatures = bufferFeatures && ((geomType === OLGeometryTypeEnum.Point) || (geomType === OLGeometryTypeEnum.MultiPoint)
            || (geomType === OLGeometryTypeEnum.LineString) || (geomType === OLGeometryTypeEnum.MultiLineString));

        const cleanedGeomList: Geometry[] = [];
        const parser = GeometryUtils.CreateJstsOLParser();
        olGeomList.forEach(singleGeom => {
            //  Clean each geometry.  If we pass in the MapToolService, it will also buffer lines to produce polygons.
            const jstsGeom = GeometryUtils.OpenLayersGeometryToJstsGeometry(singleGeom, bufferFeatures ? this.MapToolService : null);
            if (jstsGeom) {
                if (jstsGeom.getGeometryType() === "MultiPolygon") {
                    //  Break up MultiPolygons into multiple features so that they can be edited individually.
                    //  These can happen if you draw something that self-intersects (like a bow tie shape).
                    const multiPoly = jstsGeom;// as jsts.geom.MultiPolygon;  this causes an eslint error because the type is also "any".  Can't use @types/jsts because they are incomplete and also include an old version of @types/ol
                    for (let i = 0; i < multiPoly.getNumGeometries(); i++)
                        cleanedGeomList.push(parser.write(multiPoly.getGeometryN(i)));
                }
                else
                    cleanedGeomList.push(parser.write(jstsGeom));
            }
        });

        cleanedGeomList.forEach(p => {
            const f = new Feature();

            //  Check to see if the geometry is a circle.  The backend (or geojson for that matter) does not support a
            //  Circle type like OpenLayers does.  So Circles are stored as a polygon.  This detects that and reconstructs
            //  an OpenLayers Circle.  This makes the editing nicer because you can drag on the edge to expand/shrink.
            const circle = GeometryUtils.PolygonToCircle(p);
            if (circle)
                f.setGeometry(circle);
            else
                f.setGeometry(p);

            //  Preserve any attributes on the original
            GeometryUtils.CopyAttributesFromFeature(feature, f);

            cleanedFeatures.push(f);
        });

        if (cleanedFeatures.length > 0) {
            if (clearExisting)
                this._VectorSource.clear();
               this._VectorSource.addFeatures(cleanedFeatures);
        }
    }

    /**
     *  Returns the geometry in the layer as GeoJson.
     *  Also unions all features together and ensures that polygons are valid (do not self-intersect).
     */
    public GetGeoJson(applyBuffers: boolean): object {
        return this.GetGeoJsonForFeatures(this._VectorSource.getFeatures(), applyBuffers);
    }

    public GetGeoJsonForFeatures(features: Feature<any>[], applyBuffers: boolean): object {
        if (!features || (features.length === 0))
            return null;

        //  OpenLayers does not include a "union" function.  So using jsts (JavaScript Topology Suite) to do that.
        let unionedGeom: jsts.geom.Geometry = null;
        const parser = GeometryUtils.CreateJstsOLParser();
        for (let i = 0; i < features.length; i++) {
            const jstsGeom = GeometryUtils.OpenLayersGeometryToJstsGeometry(features[i].getGeometry(), applyBuffers ? this.MapToolService : null);

            if (jstsGeom) {
                if (unionedGeom)
                    unionedGeom = unionedGeom.union(jstsGeom);
                else
                    unionedGeom = jstsGeom;
            }
        }

        if (!unionedGeom)
            return null;

        const geom = parser.write(unionedGeom);           //  Convert back to OpenLayers geometry

        const formatter = new GeoJSON();
        return formatter.writeGeometryObject(geom, { dataProjection: MapConstants.LATLON_PROJECTION, featureProjection: MapConstants.MAP_PROJECTION });
    }

    /**
     *  Returns the geometry in the layer as an array of features.
     *  Does *NOT* union features together so that it can preserve all attribute values.
     * @param applyBuffers
     */
    public GetFeatures(applyBuffers: boolean): Feature<any>[] {
        const features = this._VectorSource.getFeatures();
        if (!features || (features.length === 0))
            return null;

        if (applyBuffers) {
            const parser = GeometryUtils.CreateJstsOLParser();

            //  Apply buffers to any Line features
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                const geomType = GeometryUtils.GeometryTypeOfFeature(feature);

                switch (geomType) {
                    case OLGeometryTypeEnum.LineString:
                    case OLGeometryTypeEnum.MultiLineString: {
                        const jstsGeom = GeometryUtils.OpenLayersGeometryToJstsGeometry(feature.getGeometry(), this.MapToolService);
                        feature.setGeometry(parser.write(jstsGeom));           //  Convert back to OpenLayers geometry
                        break;
                    }
                }
            }
        }

        return features;
    }

    public GetFeaturesInExtent(llExtent: Extent, layerName: string, propertyName: string): FeatureItemResponse[] {
        const features: FeatureItemResponse[] = [];

        const mapExtent = GeometryTransformer.TransformExtent(llExtent, MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);

        this._VectorSource.forEachFeatureIntersectingExtent(mapExtent, feature => {
            //  Only return if feature has a value for the property name
            const attr = feature.get(propertyName);
            if (attr)
                features.push(new FeatureItemResponse(layerName, attr, null));
        });

        return features;
    }

    public HaveFeaturesInExtent(llExtent: Extent): boolean {
        const mapExtent = GeometryTransformer.TransformExtent(llExtent, MapConstants.LATLON_PROJECTION, MapConstants.MAP_PROJECTION);

        let haveFeatures = false;
        this._VectorSource.forEachFeatureIntersectingExtent(mapExtent, () => haveFeatures = true);
        return haveFeatures;
    }
}
