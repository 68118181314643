
export class StepNameConstants {
    //  Step Names:
    public static readonly GENERAL_INFO: string = "GeneralInfo";
    public static readonly HOURS_AND_HOLIDAYS: string = "HoursAndHolidays";
    public static readonly CONTACTS: string = "Contacts";
    public static readonly DELIVERY_RULES: string = "DeliveryRules";
    public static readonly ATTRIBUTES: string = "Attributes";
    public static readonly TICKET_ASSIGNMENT: string = "TicketAssignment";
}
