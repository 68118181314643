<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div style="width: 100%; height: 95%; display: flex; flex-direction: column;">
    <label>
        Using the email address <b>{{Model?.Email}}</b>.
    </label>

    <div [formGroup]="group" style="height: 100%; overflow: auto; display: flex; flex-direction: column;">

        <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput type="search" autocomplete="off" formControlName="username" (keyup.enter)="onSignUp()" />
            <mat-error iq-aws-cognito-validator [control]="usernameControl"></mat-error>
        </mat-form-field>

        <div class="mat-caption" *ngIf="PasswordRequirements != null" style="text-align: initial;">
            <p>Password requirements:</p>
            <ul>
                <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
                <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
                <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
                <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
                <li *ngIf="PasswordRequirements.RequireASpecialCharacter">
                    Must have a special character ($ * . ? - ! &#64; # % &)
                </li>
            </ul>
        </div>
        <iq-aws-cognito-password-input [FormControl]="passwordControl" (keyup.enter)="onSignUp()"></iq-aws-cognito-password-input>

        <iq-aws-cognito-password-input Placeholder="Confirm Password" [FormControl]="confirmPasswordControl" blockPaste (keyup.enter)="onSignUp()"></iq-aws-cognito-password-input>
    </div>


    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>
    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="ErrorInstructions">{{ErrorInstructions}}</label>

    <button mat-flat-button (click)="onSignUp()" color="primary" [disabled]="!group.valid || isBusy">
        Submit
    </button>

    <div style="text-align: right; padding: 20px 0;">
        Already have a login on this or another Exactix system?
        <a class="link" (keyup.enter)="onReturnToLogin()" (click)="onReturnToLogin()" tabindex="0">click here</a>
    </div>
</div>
