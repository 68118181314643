import { Injectable } from '@angular/core';
import { EntityEnum } from 'Enums/EntityType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { ExcavatorCompany } from 'Models/Excavators/ExcavatorCompany.model';
import { ExcavatorOffice } from 'Models/Excavators/ExcavatorOffice.model';
import { Person, PersonInsertRequest } from 'Models/People/Person.model';
import { PersonLogin } from 'Models/People/PersonLogin.model';
import { PersonMember } from 'Models/People/PersonMember.model';
import { PersonServiceArea } from 'Models/People/PersonServiceArea.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { AppUser } from 'Models/Security/AppUser.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { BulkJoinActionRequest, CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';
import { RoleService } from '../../RolesAndPermissions/Services/Role.service';

@Injectable({
    providedIn: 'root'
})
export class PersonService extends CRUDBaseService<Person> {
    //public Model: Person = null;//Maybe should be in the base, but we only need it when we have properties that not editiable, but we need to show. ie the email verified flag on a person

    protected apiPath: string = "Administration/Person";

    ViewPermission: PermissionsEnum = PermissionsEnum.Person_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.Person_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.Person_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.Person_Delete;//Needs to be overriden and set to the proper permission
    CopyPermission: PermissionsEnum = PermissionsEnum.Person_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices, private roleService: RoleService) {
        super(services);
    }

    CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null): Observable<boolean> {
        switch (action) {
            case 'View':
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);

            //    return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, [itemID]);
            //case 'Edit':
            //    return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, [itemID]);
            //case 'Create':
            //    return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, [itemID]);
            //case 'Delete':
            //    return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, [itemID]);
            //default:
            //    return of(false);
        }

        return super.CanPerformAction(action, itemID);
    }

    GetPersonMember(id: string): Observable<PersonMember[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<PersonMember[]>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.get<PersonMember[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetMembers/" + id);
        }));
    }

    GetPersonServiceArea(id: string): Observable<PersonServiceArea[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<PersonServiceArea[]>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.get<PersonServiceArea[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetServiceAreas/" + id);
        }));
    }

    GetPersonExcavators(id: string): Observable<ExcavatorCompany[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ExcavatorCompany[]>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.get<ExcavatorCompany[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetExcavators/" + id);
        }));
    }

    GetPersonExcavatorOffices(id: string): Observable<ExcavatorOffice[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ExcavatorOffice[]>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.get<ExcavatorOffice[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetExcavatorOffices/" + id);
        }));
    }

    /**
     *  Fetches the email addresses for the person.  If person is null/undefined, returns the emails for the current user.
     * @param personID
     */
    public GetEmails(personID: string = null): Observable<string[]> {
        let url = "/GetEmails";
        if (personID)
            url += "/" + personID;
        return this.services.http.get<string[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + url);
    }

    AddPersonMember(item: PersonMember): Observable<PersonMember> {
        return this.CanPerformAction('Create').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<PersonMember>({} as PersonMember)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.post<PersonMember>(this.services.settingsService.ApiBaseUrl + "/PersonMember", item);
        }));
    }

    RemovePersonMember(item: PersonMember): Observable<PersonMember> {
        return this.CanPerformAction('Delete').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<PersonMember>({} as PersonMember)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.put<PersonMember>(this.services.settingsService.ApiBaseUrl + "/PersonMember/Delete", item);
        }));
    }

    UpdatePersonMember(item: PersonMember): Observable<PersonMember> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<PersonMember>({} as PersonMember)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.put<PersonMember>(this.services.settingsService.ApiBaseUrl + "/PersonMember/Update", item);
        }));
    }

    //  Marks *ALL* Person records as not having accepted the Terms and Conditions
    public ReacceptTermsAndConditions(): void {
        //  Don't have to wait for this - fire and forget it
        this.services.http.post(this.services.settingsService.ApiBaseUrl + "/Administration/Person/ReacceptTermsAndConditions", null).subscribe();
    }

    public RemoveLogin(personID: string, providerID): Observable<any> {
        if (!personID || !providerID)
            return;

        return this.services.http.post(this.services.settingsService.ApiBaseUrl + "/Administration/Person/RemoveLogin", { PersonID: personID, ProviderID: providerID });
    }

    public GenerateApiKey(personID: string): Observable<PersonLogin> {
        return this.services.http.get<PersonLogin>(this.services.settingsService.ApiBaseUrl + "/Administration/Person/GenerateApiKey/" + personID);
    }

    //  This api call only exists to link a 4iq admin to a login - it is not called for normal users!
    Add4iQAdminPersonAndLinkLogin(request: PersonInsertRequest): Observable<AppUser> {
        return this.services.http.post<AppUser>(this.services.settingsService.ApiBaseUrl + "/Administration/Person/Add4iQAdminPersonAndLinkLogin", request);
    }

    SelfRegisterUser(request: any): Observable<AppUser> {
        return this.services.http.post<AppUser>(this.services.settingsService.ApiBaseUrl + "/Administration/Person/SelfRegisterUser", request);
    }

    public SendRegistrationEmail(personID: string): void {
        if (!personID)
            return;

        this.services.http.post(this.services.settingsService.ApiBaseUrl + "/Administration/Person/SendUserRegistrationEmail", { ID: personID })
            .subscribe(() => this.services.toastrService.success("Registration E-Mail sent"));
    }

    AddCollectionToEntity(entityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)//If they don't have permission then return an empty list, else do the search
                return new BehaviorSubject<any>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            let request = new BulkJoinActionRequest();
            request.All = all;
            request.Exclude = excludeIDs;
            request.PropertyID = entityID;
            request.PropertyName = propertyName;
            request.Items = ids;

            let apiPath: string;

            if (propertyName === "RoleID") {
                apiPath = "/PersonRole";
            }

            return this.services.http.post(this.services.settingsService.ApiBaseUrl + apiPath + '/Add', request);
        }));

        
    }
    RemoveCollectionFromEntity(entityID: string, propertyName: string, ids: string[], all: boolean = false, excludeIDs: boolean = false): Observable<any> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)//If they don't have permission then return an empty list, else do the search
                return new BehaviorSubject<any>(null)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response


            let request = new BulkJoinActionRequest();
            request.All = all;
            request.Exclude = excludeIDs;
            request.PropertyID = entityID;
            request.PropertyName = propertyName;
            request.Items = ids;

            let apiPath: string;

            if (propertyName === "RoleID") {
                apiPath = "/PersonRole";
            }

            return this.services.http.put(this.services.settingsService.ApiBaseUrl + apiPath + '/Remove', request);
        }));
    }

    AddRequiredRegistrationInfo(email, roles, personID, sendRegistration) {
        return this.services.http.post<Person>(this.services.settingsService.ApiBaseUrl + '/' + this.apiPath + '/UpdateRequiredRegistrationInfo', {
            PersonID: personID,
            Email: email,
            Roles: roles,
            SendRegistration: sendRegistration
        });
    }

    public ValidatePersonRoles(personID: string): Observable<string[]> {
        return this.services.http.get<string[]>(this.services.settingsService.ApiBaseUrl + '/' + this.apiPath + '/ValidatePersonRoles/' + personID);
    }

    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {

        let columns = [new SearchColumn("Name", "Name", "Name", "Name")];

        const contactTypesColumn: SearchColumn = new SearchColumn("ContactTypes", "Contact Type(s)", "ContactTypes", "IsContactType");
        contactTypesColumn.CanSort = false;         //  Need to disable sorting because it's very inefficient
        contactTypesColumn.width = "8rem";         //  use 'rem' not 'em' so that the headers calculate to same size (they are larger font)
        contactTypesColumn.filterOptions = of([
            new SelectOption(EntityEnum.Member, "Member"),
            new SelectOption(EntityEnum.ServiceArea, "Service Area"),
            new SelectOption(EntityEnum.ServiceProvider, "Service Provider"),
            new SelectOption(EntityEnum.Destination, "Destination"),
            new SelectOption(EntityEnum.ExcavatorContact, "Excavator Contact")
        ]);
        contactTypesColumn.filterOperator = SearchFilterOperatorEnum.CustomArray;
        columns.push(contactTypesColumn);

        const contactOfColumn: SearchColumn = new SearchColumn("ContactEntities", "Contact Of", "ContactEntities", "IsContactOf");
        contactOfColumn.CanSort = false;            //  Need to disable sorting because it's very inefficient
        contactOfColumn.width = "15rem";            //  use 'rem' not 'em' so that the headers calculate to same size (they are larger font)
        contactOfColumn.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(contactOfColumn);

        const hasLoginColumn: SearchColumn = new SearchColumn("HasLogin", "Has Login", "HasLogin", "HasLogin");
        hasLoginColumn.width = "3rem";
        hasLoginColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        columns.push(hasLoginColumn);

        const hasApiKeyColumn: SearchColumn = new SearchColumn("HasApiKey", "Has Api Key", "HasApiKey", "HasApiKey");
        hasApiKeyColumn.width = "4rem";
        hasApiKeyColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        columns.push(hasApiKeyColumn);

        const username = new SearchColumn("Login", "Login", "Login", "Login");
        username.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(username);

        const lastLoginDateColumn: SearchColumn = new SearchColumn("LastLoginDate", "Last Login", "LastLoginDate", "LastLoginDate");
        lastLoginDateColumn.width = "9rem";
        lastLoginDateColumn.useDateSearch = true;
        lastLoginDateColumn.ShowFutureDateOptions = false;
        lastLoginDateColumn.formatType = 'date';
        lastLoginDateColumn.format = UIDateTimeFormat;
        columns.push(lastLoginDateColumn);

        const lastTicketCreatedDateColumn: SearchColumn = new SearchColumn("ExcavatorContact_LastTicketCreateDate", "Last Ticket Created", "ExcavatorContact.LastTicketCreateDate", "ExcavatorContact.LastTicketCreateDate");
        lastTicketCreatedDateColumn.width = "9rem";
        lastTicketCreatedDateColumn.useDateSearch = true;
        lastTicketCreatedDateColumn.ShowFutureDateOptions = false;
        lastTicketCreatedDateColumn.formatType = 'date';
        lastTicketCreatedDateColumn.format = UIDateTimeFormat;
        columns.push(lastTicketCreatedDateColumn);


        const registrationDateColumn: SearchColumn = new SearchColumn("RegistrationDate", "Registration Date", "RegistrationDate", "RegistrationDate");
        registrationDateColumn.useDateSearch = true;
        registrationDateColumn.ShowFutureDateOptions = false;
        registrationDateColumn.formatType = 'date';
        registrationDateColumn.format = UIDateTimeFormat;
        columns.push(registrationDateColumn);

        const hasRoleColumn: SearchColumn = new SearchColumn("HasRole", "Has Role", "HasRole", "HasRole");
        hasRoleColumn.width = "3rem";
        hasRoleColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")])
        columns.push(hasRoleColumn);

        const roleColumn: SearchColumn = new SearchColumn("Roles", "Roles", "Roles", "RoleIDs");
        roleColumn.autoComplete = true;
        roleColumn.autocompleteResultDisplayValue = "Name";
        roleColumn.autoCompleteSearchFunction = (filter: SearchRequest) => {
            filter.EntityType = EntityEnum.Role;
            filter.Filters[0].PropertyName = "Name";
            return this.roleService.SearchForAutocomplete(filter);
        };
        columns.push(roleColumn);

        const selfRegisteredColumn: SearchColumn = new SearchColumn("SelfRegistered", "Self Registered", "SelfRegistered", "SelfRegistered");
        selfRegisteredColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")])
        columns.push(selfRegisteredColumn);
        
        const hasEmailColumn: SearchColumn = new SearchColumn("HasEmail", "Has Email", "HasEmail", "HasEmail");
        hasEmailColumn.width = "3rem";
        hasEmailColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")])
        columns.push(hasEmailColumn);

        const emailColumn: SearchColumn = new SearchColumn("SendToEmail", "Email", "SendToEmail", "SendToEmail");
        columns.push(emailColumn);

        const hasPhoneColumn: SearchColumn = new SearchColumn("HasPhone", "Has Phone", "HasPhone", "HasPhone");
        hasPhoneColumn.width = "3rem";
        hasPhoneColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")])
        columns.push(hasPhoneColumn);

        const phoneColumn: SearchColumn = new SearchColumn("PhoneNumber", "Phone Number", "PhoneNumber", "PhoneNumber");
        phoneColumn.formatType = "phone";
        columns.push(phoneColumn);

        //  LastBrowserInfo is a json column.  Can search/filter/sort on the child properties in the json without any other server changes!
        columns.push(new SearchColumn("LastBrowserInfo_Browser_Version", "Browser Version", "LastBrowserInfo.Browser.Version", "LastBrowserInfo.Browser.Version"));
        columns.push(new SearchColumn("LastBrowserInfo_OS_Version", "Browser OS", "LastBrowserInfo.OS.Version", "LastBrowserInfo.OS.Version"));
        columns.push(new SearchColumn("LastBrowserInfo_Language_Preferred", "Browser Language", "LastBrowserInfo.Language.Preferred", "LastBrowserInfo.Language.Preferred"));

        columns = columns.sort((a, b) => a.name.localeCompare(b.name));
        return of({ columns: columns, filters: columns });
    }
}
