<div class="mat-caption" *ngIf="PasswordRequirements != null">
    <p>Password requirements:</p>
    <ul>
        <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
        <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
        <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
        <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
        <li *ngIf="PasswordRequirements.RequireASpecialCharacter">Must have a special character ($ * . ? - ! &#64; # % &)</li>
    </ul>
</div>

<!-- div's are so that the input controls do not expand to fill the entire width.  No one needs to enter a password that big! -->
<div style="display:flex">
    <mat-form-field style="flex-basis:50%; padding-right:1em">
        <mat-label>Password</mat-label>
        <input matInput type="{{ShowPassword ? 'text' : 'password'}}" autocomplete="off" [formControl]="PasswordFormControl" />
        <fa-icon matSuffix [icon]="Icon" (click)="ToggleShowPassword()"></fa-icon>
        <mat-error iqValidationMessage [control]="PasswordFormControl"></mat-error>
    </mat-form-field>
    <mat-form-field style="flex-basis:50%; padding-left:1em">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="{{ShowPassword ? 'text' : 'password'}}" autocomplete="off" [formControl]="ConfirmPasswordFormControl" />
        <fa-icon matSuffix [icon]="Icon" (click)="ToggleShowPassword()"></fa-icon>
        <mat-error iqValidationMessage [control]="ConfirmPasswordFormControl"></mat-error>
    </mat-form-field>
</div>
