export enum ProfileVerificationSectionStatusEnum {
    Unreviewed = 1,
    Confirmed = 2,
    Changed = 3
}

export enum ProfileVerificationSectionStatusEnumDescription {
    Unreviewed = "Not reviewed",
    Confirmed = "Confirmed",
    Changed = "Changed"
}
