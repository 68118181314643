import { TicketAttachmentTypeEnum } from "Enums/TicketAttachmentType.enum";

export class TicketAttachment {
    public ID: string;

    public FileUploadID: string;
    public URL: string;

    public Description: string;

    public AttachmentType: TicketAttachmentTypeEnum;

    public Public: boolean;
    public IsBlacklisted: boolean;

    //  These are actually stored in the FileUpload but translated into this object to make it easier to work with
    public FileName: string;
    public ContentType: string;
    public Size: number;
}
