<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Activate Registration</h1>
<mat-dialog-content [formGroup]="FormGroup" style="display:flex; flex-direction:column;">
    <div style="padding: 20px 2vw 0 2vw; display: flex; flex-wrap: wrap; column-gap: 20px;">
        <div style="width: 100%;">{{ActivateMessage}}</div>
        <mat-slide-toggle [color]="'primary'" formControlName="ScheduleActivation" (change)="ScheduleActivationToggle($event)" style="margin-top: 15px; width: 45%;">
            Schedule Activation
        </mat-slide-toggle>
        <mat-form-field *ngIf="ShowActivationDate" style="width: 50%;">
            <mat-label>Choose an activation date</mat-label>
            <input matInput type="search" [mtxDatetimepicker]="picker" formControlName="ActivationDate"
                   (focus)="picker.open()">
            <mtx-datetimepicker-toggle matSuffix [for]="picker"></mtx-datetimepicker-toggle>
            <mtx-datetimepicker type="datetime" [timeInput]="true" mode="portrait" [multiYearSelector]="true" [twelvehour]="true" [timeInterval]="15" (opened)="datePickerOpened()" #picker></mtx-datetimepicker>
            <mat-error iqValidationMessage [control]="ActivationDateFormControl"></mat-error>
        </mat-form-field>
        <span *ngIf="CanCancel" tabindex="0" class="link" (click)="OnCancel()" style="margin-left: 48%; top: -25px; position: relative;">cancel</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" [mat-dialog-close]="false">Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [mat-dialog-close]="true" (click)="Save()">{{ActivateButtonText}}</iq-icon-button>
</mat-dialog-actions>

