import { Type, TypeDecorator } from "@angular/core";

//  This came from here: https://stackoverflow.com/a/60114267
//  ** As of Angular 9, the components may be excluded from the production optimized build by the
//     tree shaking process.  The fix I found was to just add the components to the providers section
//     of the module where they are defined.
//     https://github.com/angular/angular-cli/issues/16246

//  To register a component: add this right before it's @Component() definition
//      @ComponentLookup('TestDialogComponent')
//  * Also add the component to the providers section of the module where it is defined.
//  To get the type, just call:
//      const classRef = ComponentLookupRegistry.get('TestDialogComponent');
//  Now can use classRef to open a dialog like this:
//      this._Dialog.open(classRef, { data: { item: 1 } })

export class ComponentLookupOptions {
    /**
     * Used when the component is a view used in a dialog (a ticket function dialog that implements ITicketFunctionContentView
     * and is opened via TicketActionsService).
     * Defaults to true which will cause the dialog to auto focus to the first control in the tab order.
     * Set to false to prevent the element from being focused when the page loads.
     * Needed if the component view sets focus to another control using the iqAutoFocus directive.
     */
    public AutoFocus: boolean;
}

export class ComponentLookupValue {
    constructor(
        public Class: Type<any>,
        public Options: ComponentLookupOptions
    ) { }
}

export const ComponentLookupRegistry: Map<string, ComponentLookupValue> = new Map();

//  Needed to avoid an error (only in prod build) like: Function expressions are not supported in decorators in 'ComponentLookup'
//  https://github.com/angular/angular/issues/33539
export function setComponent(key: string, options: ComponentLookupOptions = undefined): TypeDecorator {
    return (cls: Type<any>) => {
        ComponentLookupRegistry.set(key, new ComponentLookupValue(cls, options));
    }
}

export const ComponentLookup = setComponent;

//  This is the same thing (that causes the prod build error) but without the exported function
//export const ComponentLookup = (key: string): TypeDecorator => {
//    return (cls) => {
//        ComponentLookupRegistry.set(key, cls);
//    };
//};
