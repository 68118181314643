import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchFilter } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { TicketListItemViewEnum } from './TicketListItemViewEnum';

export class TicketSearchQueryConfiguration {
    public Name: string;
    public Description: string;
    public Count: number;
    public StoreFilterKeyValue: number;
    public FocusedObservable: Subject<boolean>;

    public FiltersChanged?: Subject<void>;

    /**
     *  Columns to fetch and display in the Desktop ticket list.
     */
    public Columns: string[];

    /*
     *  Additional columns to fetch but that are not displayed in the Desktop ticket list.
     */
    public FetchAdditionalColumns?: string[];

    //  ViewFilters = Filters that vary based on the view (i.e. the radio buttons on the web dashboard).
    //  DefaultFilters = The default filters used by the tab (i.e. filter on expiration date if this is the "expired tickets" tab).
    //  Both of these are automatically combined together when submitting the query to the api.
    //  This makes it much easier to change the filter for those radio buttons - previously, the web dashboard was
    //  doing a bunch of stuff to try to remove filters which did not always work correctly (and then caused more problems
    //  when we needed to support different tabs based on those radio buttons).
    public ViewFilters: SearchFilter[];
    public DefaultFilters: SearchFilter[];

    //  Filters that should be included when doing a search by ticket number (from the top of the ticket list page).
    //  Needed to enforce things like Status=Complete or "Current Response" only.  Otherwise, all of those required
    //  filters will be ommitted and we'll show rows we should not (and the extra rows may not make sense with the
    //  current columns - like if we are showing a list of current responses and then we suddenly show ALL responses!).
    public FiltersRequiredForTicketNumberSearch?: SearchFilter[];

    public OrderBy: SearchOrderBy[];

    //  Specifies a list of ColumnNames that require a filter.  The DesktopTicketListBase has a list of defaults that are used when filtering.
    //  But should ALWAYS specify any filters that are required here - the phone filtering does not have defaults like that and it
    //  makes more sense to have this configured along with the rest of the query config.
    public RequiredFilters: string[];

    //  The Component used to render the view.
    //  If a component is not set for a device type, this configuration will not be visible (i.e. No Manual Call Outs list on Phone).
    public DesktopComponent: Type<any>;
    public PhoneComponent: Type<any>;

    public ListItemView?: TicketListItemViewEnum;
}
