import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { AppUser } from 'Models/Security/AppUser.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import { StringUtils } from 'Shared/Utils/StringUtils';
import _ from 'lodash';
import { takeUntil } from 'rxjs';
import { ChatService } from '../Chat.service';

@Component({
    selector: 'iq-chat-five9',
    templateUrl: './Five9Chat.component.html',
    styleUrls: ['./Five9Chat.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class Five9ChatComponent extends ComponentWithDestroySubscription implements AfterViewInit, OnDestroy {
    private _MaximizeButton: HTMLDivElement;

    constructor(private _AuthenticationService: AuthenticationService, private _ChatService: ChatService,
        private _ElementRef: ElementRef, private _Renderer: Renderer2)
    {
        super();

        _ChatService.PositionAboveMobileToolbar.pipe(takeUntil(this.Destroyed)).subscribe(() => this.UpdateButtonPosition());
    }

    public static IsEnabled(user: AppUser): boolean {
        if (_.isEmpty(user.OneCallCenterSettings?.Chat_Five9_Tenant))
            return false;       //  Five9 chat not enabled at all

        if (user.IsServiceAreaUser && !_.isEmpty(user.OneCallCenterSettings.Chat_Five9_ServiceAreaProfile))
            return true;        //  Service Area user and profile set
        if (user.IsExcavatorUser && !_.isEmpty(user.OneCallCenterSettings.Chat_Five9_ExcavatorProfile))
            return true;        //  Excavator user and profile set

        return false;           //  Not a Service Area or Excavator user or those profiles are not configured
    }

    public ngAfterViewInit(): void {
        const existingWidget = document.querySelector('.five9-frame');
        if (existingWidget)
            return;

        const embedElement = this._ElementRef.nativeElement.querySelector("#chat-five9-embed");

        const script = this._Renderer.createElement('script');
        script.type = "text/javascript";
        script.onload = () => this.ShowChatWidget();        //  Must set this before "src" or may not fire all the time
        script.src = "https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js";
        this._Renderer.appendChild(embedElement, script);
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();

        //  The script that is loaded during initialization, creates a div that is rooted at the base of the document.
        //  Need to remove it or re-initialization will not work and/or these elements will be duplicated.
        const widget = document.querySelector('.five9-frame');
        if (widget)
            widget.remove();
    }

    private ShowChatWidget(): void {
        const title = "Chat Portal";     //  Don't see this anywhere in the chat widget so hardcoding for now.

        const tenant = this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_Five9_Tenant;

        let profiles: string = "";
        if (this._AuthenticationService.CurrentUser.IsServiceAreaUser)
            profiles = this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_Five9_ServiceAreaProfile;
        if (this._AuthenticationService.CurrentUser.IsExcavatorUser)
            profiles = StringUtils.AppendToString(profiles, this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_Five9_ExcavatorProfile, ", ");

        const logo = window.location.origin + "/assets/Images/Logos/" + this._AuthenticationService.CurrentUser.OneCallCenterSettings.LogoImage;

        const embedElement = this._ElementRef.nativeElement.querySelector("#chat-five9-embed");

        const script = this._Renderer.createElement('script');
        const scriptCode = `
var options = {
    "rootUrl": "https://app.five9.com/consoles/",
    "type": "chat",
    "title": "` + title + `",
    "tenant": "` + tenant + `",
    "profiles": "` + profiles + `",
    "showProfiles": true,
    "autostart": true,
    "profileLabel": "Please enter the following items to continue.",
    "theme": "high-contrast.css",
    "logo": "`+ logo + `",
    "surveyOptions": {
        "showComment": false,
        "requireComment": false
    },
    "fields": {
        "name": {
            "value": "",
            "show": true,
            "label": "Name"
        },
        "email": {
            "value": "",
            "show": true,
            "label": "Email"
        },
        "UserLocale": {
            "value": "en",
            "show": false
        }
    },
    "playSoundOnMessage": true,
    "allowCustomerToControlSoundPlay": true,
    "showEmailButton": false,
    "hideDuringAfterHours": false,
    "useBusinessHours": false,
    "showPrintButton": false,
    "allowUsabilityMenu": false,
    "enableCallback": false,
    "callbackList": "",
    "allowRequestLiveAgent": false
};
Five9SocialWidget.addWidget(options);`;
        const scriptContent = this._Renderer.createText(scriptCode);
        this._Renderer.appendChild(script, scriptContent);
        this._Renderer.appendChild(embedElement, script);

        //  onload event does not fire for an inline script.  But at soon as it's added to the DOM, the elements we need are there.
        setTimeout(() => {
            this._MaximizeButton = document.querySelector('#five9-maximize-button');
            this.UpdateButtonPosition();
        });
    }

    private UpdateButtonPosition(): void {
        if (!this._MaximizeButton)
            return;

        if (this._ChatService.PositionAboveMobileToolbar.value)
            this._MaximizeButton.style.bottom = "48px";
        else
            this._MaximizeButton.style.bottom = "0px";
    }
}
