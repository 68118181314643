import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrintingService } from 'Services/Printing.service';
import { CommonService } from 'Services/CommonService';
import { SettingsService } from 'Services/SettingsService';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { BaseListDisplayPageClassService } from 'Shared/BaseClasses/BaseListDisplayPage.class';
import { TicketActionsService } from '../../Services/TicketActions.service';
import { DesktopTicketListBase } from './DesktopTicketListBase.component';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { EntityEnum } from 'Enums/EntityType.enum';

@Component({
    selector: 'desktop-ticket-search',
    templateUrl: './DesktopTicketListBase.component.html',
    styleUrls: ['./DesktopTicketListBase.component.scss'],
    providers: [CommonService]
})
export class DesktopTicketSearchComponent extends DesktopTicketListBase {
    NewDefaultFilter: SearchFilter[] = [new SearchFilter("TakenEndDate", SearchFilterOperatorEnum.PastDaysFromToday, [new SearchFilterValue("30", "30")])];

    public override EntityType: EntityEnum = EntityEnum.Ticket;

    constructor(ticketService: TicketService, baseServices: BaseListDisplayPageClassService, ticketActionsService: TicketActionsService,
        settingService: SettingsService, toastr: ToastrService, printService: PrintingService, commonService: CommonService)
    {
        super(ticketService, baseServices, ticketActionsService, settingService, toastr, printService, commonService);

        ticketService.ClearTicket();
    }

    //  item.ID is the Ticket.ID
    protected override EntityIDForRow(item: any): string { return item.ID; }
}
