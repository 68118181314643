<div style="flex-grow: 1; overflow-y: auto; overflow-x: hidden;">
    <h4 style="margin: 0px;">Attributes: </h4>
    <div style="margin: 10px;">
        <ng-container *ngIf="hasAttributes; else noAttributes">
            <ng-container *ngFor="let attr of OriginalProfile.Attributes; index as i">
                <div class="profile-field">
                    <label class="iq-caption gray-primary-text profile-label">{{attr.AttributeTypeName}}: </label>
                    <span>{{attr.Value}}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-template #noAttributes>
        <div>The One Call center does not have any attributes configured.</div>
    </ng-template>
</div>
<iq-profile-verifications-default-form [VerificationResult]="VerificationResult"></iq-profile-verifications-default-form>
