/**
 *  This should always be placed after the AuthenticationGuard so that we can make sure that the user info is loaded.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PermissionsService } from '../PermissionsService';

export const PermissionGuardService: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    permissionsService: PermissionsService = inject(PermissionsService),
    router: Router = inject(Router)) => {
    const observable = next.data && next.data.permission ? permissionsService.CurrentUserHasOneOfPermission(next.data.permission, null, true) : of(true);

    return observable.pipe(map(val => {
        if (!val) {
            router.navigateByUrl('/home');
            return false;
        }

        return true;
    }));
}
