import { Component, ViewChild } from '@angular/core';
import { ProfileVerificationSectionStatusEnum } from 'Enums/ProfileVerificationSectionStatusEnum.enum';
import { ProfileVerificationSectionBase } from 'Models/ProfileVerifications/ProfileVerificationSectionBase.model';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { ServiceArea } from 'Models/ServiceAreas/ServiceArea.model';
import { ProfileVerificationsDefaultFormComponent } from '../Forms/ProfileVerificationsDefaultForm.component';
import { takeUntil } from 'rxjs';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';

@Component({
    templateUrl: './ProfileVerificationsStepBase.component.html',
    styleUrls: ['./ProfileVerificationsStepBase.component.scss']
})

export abstract class ProfileVerificationsStepBaseComponent extends ComponentWithDestroySubscription {
    @ViewChild(ProfileVerificationsDefaultFormComponent) public VerificationForm: ProfileVerificationsDefaultFormComponent;

    public IsExisting: boolean;
    public ProfileVerification: ProfileVerification;
    public CurrentStep: number;
    public OriginalProfile: ServiceArea;

    public VerificationResult: ProfileVerificationSectionBase = new ProfileVerificationSectionBase();

    public abstract Name: string;

    constructor() {
        super();
    }

    public SetData(data: ProfileVerification, currentStep: number, IsExisting: boolean, formIsReadonly: boolean, setButtonFlagsCallback: () => void): void {
        this.ProfileVerification = data;
        this.CurrentStep = currentStep;
        this.OriginalProfile = JSON.parse(data.OriginalProfile) as ServiceArea;

        if (!data.VerificationResults[this.Name]) {
            data.VerificationResults[this.Name] = new ProfileVerificationSectionBase();
            data.VerificationResults[this.Name].Status = ProfileVerificationSectionStatusEnum.Unreviewed;
        }

        this.VerificationResult = data.VerificationResults[this.Name];

        setTimeout(() => {
            this.IsExisting = IsExisting;

            if (formIsReadonly)
                this.VerificationForm.FormIsReadonly();

            setButtonFlagsCallback();
            this.VerificationForm.FormGroup.valueChanges.pipe(takeUntil(this.Destroyed)).subscribe(() => {
                setButtonFlagsCallback();
            });
        });
    }
}
