import { Injectable } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'Services/SettingsService';
import { BehaviorSubject, delay, map, mergeMap, Observable, of, take } from 'rxjs';
import { ProfileVerification } from 'Models/ProfileVerifications/ProfileVerification.model';
import { ProfileVerificationsGeneralStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsGeneralStepInfo.component';
import { ProfileVerificationsHoursAndHolidaysStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsHoursAndHolidaysStepInfo.component';
import { ProfileVerificationsContactsStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsContactsStepInfo.component';
import { ProfileVerificationsTicketAssignmentStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsTicketAssignmentStepInfo.component';
import { ProfileVerificationsDeliveryRulesStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsDeliveryRulesStepInfo.component';
import { ProfileVerificationsAttributesStepInfoComponent } from 'Pages/ProfileVerifications/Steps/ProfileVerificationsAttributesStepInfo.component';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { ProfileVerificationStatusEnum } from 'Enums/ProfileVerificationStatusEnum.enum';
import { ProfileVerificationReminder } from 'Models/ProfileVerifications/ProfileVerificationReminder.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ProfileVerificationStepInfo } from 'Pages/ProfileVerifications/Models/ProfileVerificationStepInfo.model';
import { StepNameConstants } from 'Pages/ProfileVerifications/Steps/StepNameConstants';

@Injectable({
    providedIn: 'root'
})
export class ServiceAreaProfileVerificationService extends CRUDBaseService<ProfileVerification> {
    protected apiPath: string = "Administration/ProfileVerification";

    ViewPermission: PermissionsEnum = PermissionsEnum.ProfileVerification_View;
    EditPermission: PermissionsEnum = PermissionsEnum.ProfileVerification_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.ProfileVerification_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.ProfileVerification_Delete;
    CopyPermission: PermissionsEnum = PermissionsEnum.ProfileVerification_Copy;

    //ProfileVerificationStepInfo(Name, DisplayOrder, ComponentType)
    private readonly GeneralStep = new ProfileVerificationStepInfo(StepNameConstants.GENERAL_INFO, 10, ProfileVerificationsGeneralStepInfoComponent);
    private readonly HoursAndHolidaysStep = new ProfileVerificationStepInfo(StepNameConstants.HOURS_AND_HOLIDAYS, 20, ProfileVerificationsHoursAndHolidaysStepInfoComponent);
    private readonly ContactsStep = new ProfileVerificationStepInfo(StepNameConstants.CONTACTS, 30, ProfileVerificationsContactsStepInfoComponent);
    private readonly DeliveryRulesStep = new ProfileVerificationStepInfo(StepNameConstants.DELIVERY_RULES, 40, ProfileVerificationsDeliveryRulesStepInfoComponent);

    constructor(public http: HttpClient, public settingsService: SettingsService, protected services: CRUDServices, private _AuthenticationService: AuthenticationService) {
        super(services);
    }

    //  TODO: This method is completely wrong...  Some permissions REQUIRE the EntityID to be passed in to check the permissions correctly.
    //  Not passing that in when it's needed will cause the permission check to fail.
    //  There is absolutely no handling of this in CRUDBaseService!  The only way to really fix this is to override it - which is *NOT*
    //  being done everywhere it should be!
    //  We might be able to handle this in this base class by adding an abstract PermissionEntityID property and requiring each derived class
    //  to specify it or define it to be not used (undefined).
    public override CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', EntityIDForRole: string = null, propertyName: string = null): Observable<boolean> {
        //  The best we can do given the information we have when this function is called is to just check to see if the user has
        //  this permission somewhere (for any EntityID).  The server will enforce it properly (I hope).  And the UI should not be allowing the
        //  user to do something upfront anyway.
        if (!EntityIDForRole)
            EntityIDForRole = this.EntityID;

        switch (action) {
            case 'View':
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, [EntityIDForRole]);
            case 'Create': {
                //if EntityIDForRole === undefined we need to pass null for the collection of itemIDs and true for the hasAnywhere flag
                //this will make CurrentUserHasPermission check if the user has the permission for any ServiceArea
                if (EntityIDForRole === undefined)
                    return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, null, true);
                else
                    return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, [EntityIDForRole]);
            }
            case 'Delete':
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, [EntityIDForRole]);
            case 'Edit':
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, [EntityIDForRole]);
        }
    }

    public GetPastDueVerificationsForPerson(): Observable<ProfileVerificationReminder[]> {
        return this.CanPerformAction("Create").pipe(
            mergeMap(allowed => {
                if (!allowed)
                    return of(null);
                return this._AuthenticationService.CurrentUserObserver();
            }),
            take(1),
            map(appUser => {
                if (!appUser)
                    return false;
                return !appUser.IsLocalUser;
            }),
            mergeMap(allowed => {
                if (!allowed)
                    return of(null);
                return this.http.get<ProfileVerificationReminder[]>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetPastDueVerificationsForPerson");
            })
        );
    }

    public UpdateVerificationStatus(profileVerificationID: string, status: ProfileVerificationStatusEnum): Observable<ProfileVerification>
    {
        return this.CanPerformAction('Edit', this.EntityID).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ProfileVerification>({} as ProfileVerification)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.post<ProfileVerification>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/UpdateVerificationStatus/" + profileVerificationID + "/" + status, null);
        }));
    }

    public UnlockProfileVerification(profileVerificationID: string): Observable<ProfileVerification> {
        return this.CanPerformAction('Edit', this.EntityID).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ProfileVerification>({} as ProfileVerification)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.post<ProfileVerification>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/UnlockProfileVerification/" + profileVerificationID, null);
        }));
    }

    public StartProfileVerification(serviceAreaID: string): Observable<ProfileVerification> {
        return this.CanPerformAction('Create', serviceAreaID).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ProfileVerification>({} as ProfileVerification)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.get<ProfileVerification>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/StartProfileVerification/" + serviceAreaID);
        }));
    }

    public MarkAsReviewed(profileVerificationID: string): Observable<ProfileVerification> {
        return this.CanPerformAction('Edit', this.EntityID).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<ProfileVerification>({} as ProfileVerification)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.post<ProfileVerification>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/MarkAsReviewed/" + profileVerificationID, null);
        }));
    }

    public GetDynamicStepsForOneCall(OCCCode: string): ProfileVerificationStepInfo[] {
        const steps: ProfileVerificationStepInfo[] = [this.GeneralStep, this.HoursAndHolidaysStep, this.ContactsStep, this.DeliveryRulesStep];

        if (OCCCode === 'FL811' || OCCCode === 'AZ811')
            steps.push(new ProfileVerificationStepInfo(StepNameConstants.ATTRIBUTES, 31, ProfileVerificationsAttributesStepInfoComponent));
        
        if (OCCCode !== 'UDIGNY' && OCCCode !== 'IDDIGLINE' && OCCCode !== 'SC811' && OCCCode !== 'IN811')
            steps.push(new ProfileVerificationStepInfo(StepNameConstants.TICKET_ASSIGNMENT, 41, ProfileVerificationsTicketAssignmentStepInfoComponent));

        return steps.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }
}
