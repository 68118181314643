import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ExcavatorAutocompleteResponse } from 'Models/Excavators/ExcavatorAutocompleteResponse.model';
import { ExcavatorCompany } from 'Models/Excavators/ExcavatorCompany.model';
import { ExcavatorOffice } from 'Models/Excavators/ExcavatorOffice.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { ExcavatorCompanyService } from 'Pages/Excavators/ExcavatorCompanies/Services/ExcavatorCompany.service';
import { ExcavatorOfficeService } from 'Pages/Excavators/ExcavatorOffices/Services/ExcavatorOffice.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoCompleteFormControl } from 'Shared/Components/Forms/FormControls/AutoCompleteFormControl';

@Component({
    selector: 'iq-find-company',
    templateUrl: './FindCompany.component.html',
    styleUrls: ['./FindCompany.component.scss']
})
export class FindCompanyComponent implements OnDestroy {

    private _Destroyed = new Subject<void>();

    @Input()
    public OneCallCenterCode: string;

    @Output()
    public CompanySelected: EventEmitter<ExcavatorCompany> = new EventEmitter();

    @Input()
    public placeholder: string = "Search Companies";

    @Input()
    public OnlyWithDeliveryRules: boolean = undefined;

    public CompanyAutoCompleteFormControl: AutoCompleteFormControl;

    constructor(private _CompanyService: ExcavatorCompanyService, private _OfficeService: ExcavatorOfficeService) {

        this.CompanyAutoCompleteFormControl = new AutoCompleteFormControl(filter => this.CompanyAutoCompleteCall(filter));
        this.CompanyAutoCompleteFormControl.itemDisplayName = "ActionName";
    }

    public ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    public CompanyAutoCompleteDisplayFn(item?: ExcavatorAutocompleteResponse): string | undefined {
        return item ? item.CompanyName : undefined;
    }

    private CompanyAutoCompleteCall = (filter: SearchRequest) => {
        return this._CompanyService.TicketAutoComplete(filter.Filters[0].Values[0].FilterValue, "Name,CompanyID", this.OneCallCenterCode, this.OnlyWithDeliveryRules)
            .pipe(map((val: ExcavatorAutocompleteResponse[]) => {
                const response = new SearchResponse();
                response.Items = val;
                response.TotalCount = val.length
                return response;
            }));
    };

    public CompanyAutoCompleteSelected() {
        const selectedItem: ExcavatorAutocompleteResponse = this.CompanyAutoCompleteFormControl.value;

        const selectedCompany = new ExcavatorCompany(selectedItem.ExcavatorCompanyID, selectedItem.CompanyName);
        selectedCompany.CompanyID = selectedItem.CompanyID;

        if (selectedItem.NumOffices > 1) {
            //this._OfficeService.TicketAutoComplete(null, "Name", this.CompanyAutoCompleteFormControl.value.ExcavatorCompanyID).subscribe((val: ExcavatorAutocompleteResponse[]) => {
            //  If there are multiple offices, we need to fetch all of them.  And can't use the TicketAutoComplete to do that!
            //  It is limited to only 10 results!  "OfficesForCompany" returns all of them.
            this._OfficeService.OfficesForCompany(selectedItem.ExcavatorCompanyID, this.OneCallCenterCode)
                .subscribe(officeList => {
                    if (officeList) {
                        //  Not sure if this is necessary but it definitely should not be...afraid to remove it...
                        officeList.forEach(o => o.ExcavatorCompany = selectedCompany);

                        selectedCompany.ExcavatorOffices = officeList;

                        this.CompanySelected.emit(selectedCompany);
                    }
                });
        }
        else if (selectedItem.ExcavatorOfficeID) {
            const office = new ExcavatorOffice(selectedItem.ExcavatorOfficeID, selectedItem.OfficeName);
            office.OfficeID = selectedItem.OfficeID;
            office.Address = selectedItem.Address;
            office.ExcavatorCompanyID = selectedItem.ExcavatorCompanyID;
            office.ExcavatorCompany = selectedCompany;

            selectedCompany.ExcavatorOffices = [office];

            this.CompanySelected.emit(selectedCompany);
        }
        else
            this.CompanySelected.emit(selectedCompany);
    }
}
