import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';
import { MainMenuService } from 'Services/MainMenuService';
import { SettingsService } from 'Services/SettingsService';
import { PermissionsEnum } from '../../Enums/RolesAndPermissions/Permissions.enum';
import { ProfileVerificationReminder } from 'Models/ProfileVerifications/ProfileVerificationReminder.model';
import { ServiceAreaProfileVerificationService } from '../ProfileVerifications/Services/ServiceAreaProfileVerification.service';

@Component({
    selector: 'app-home',
    templateUrl: './Home.component.html',
    styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnDestroy {
    busyKey = this.busyService.createNew();

    showAnnouncements = false;
    showProfileVerifications = false;

    public TicketViewPermission = PermissionsEnum.Ticket_View;
    public TicketCreatePermission = PermissionsEnum.Ticket_Create;

    public ShowNewTicketButton: boolean = false;
    public ShowEmergencyContactsPage: boolean = false;

    public ProfileVerificationReminders: ProfileVerificationReminder[] = [];

    private _Destroyed = new Subject<void>();

    constructor(private authService: AuthenticationService, private busyService: BusyService, mainMenuService: MainMenuService,
        settingsService: SettingsService, public DeviceDetectorService: DeviceDetectorService, private _ProfileVerificationsService: ServiceAreaProfileVerificationService)
    {
        this.busyService.showByKey(this.busyKey);

        //  Make sure this is always shown in case it was hidden on a phone view
        mainMenuService.HideTopMenuBar = false;

        this.authService.FetchedUserInfo.pipe(filter(val => val == true), take(1))
            .subscribe(val => {
                this.showAnnouncements = true;
                this.busyService.hideByKey(this.busyKey);
            });

        //  takeUntil destroyed in case user switches one calls while on this page
        this.authService.CurrentUserObserver().pipe(takeUntil(this._Destroyed))
            .subscribe(userInfo => {
                this.ShowEmergencyContactsPage = (userInfo.EmergencyContactPageContactTypes?.length ?? 0) > 0 ?? false;
            })

        this.ShowNewTicketButton = settingsService.ShowNewTicketOnHomePage();

        this._ProfileVerificationsService.GetPastDueVerificationsForPerson().subscribe(response => {
            this.showProfileVerifications = response && response.length > 0;
            if (this.showProfileVerifications)
                this.ProfileVerificationReminders = response;
        });
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();

        this.busyService.removeByKey(this.busyKey);
    }
}
