<div style="display: flex; flex-direction: column; height: 100%;">
    <mat-toolbar color="primary" class="iq-display-1">
        <div class="iq-phone-header-left">
            <iq-phone-button icon="Cancel" (click)="Close()">Cancel</iq-phone-button>
        </div>
        <div class="iq-phone-header-center">Verify Profile</div>
        <div class="iq-phone-header-right">
            <iq-phone-button *ngIf="ButtonFlags.SaveProgressButtonVisible" icon="OK" (click)="SaveProgress()" style="margin-right: 10px;">Save</iq-phone-button>
            <div *ngIf="!ButtonFlags.SaveProgressButtonVisible" style="min-width: 75px;"></div>
        </div>
    </mat-toolbar>
    <div style="padding: 10px; overflow-y: scroll; overflow-x: hidden; height: 100%;">
        <ng-template dynamic-content></ng-template>
    </div>
    <mat-toolbar color="primary" class="iq-display-1">
        <div class="iq-phone-header-left" style="margin-left: 10px;">
            <iq-phone-button icon="Previous" [disabled]="!ButtonFlags.CanClickPrevious" (click)="PreviousClicked()" [DebounceTime]="0"></iq-phone-button>
        </div>
        <div class="iq-phone-header-center">
            <iq-phone-button icon="Next" [disabled]="!ButtonFlags.CanClickNext" (click)="NextClicked()" [DebounceTime]="0"></iq-phone-button>
        </div>
        <div class="iq-phone-header-right" style="margin-right: 10px;">
            <iq-phone-button icon="Save" *ngIf="ButtonFlags.SubmitButtonVisible" [disabled]="!ButtonFlags.CanSubmitProfile" (click)="Submit()">Submit</iq-phone-button>
            <iq-phone-button icon="Save" *ngIf="ButtonFlags.UnsubmitButtonVisible" (click)="Unsubmit()">Unsubmit</iq-phone-button>
        </div>
    </mat-toolbar>
</div>
