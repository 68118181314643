import { NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { AppUser } from 'Models/Security/AppUser.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { ComponentWithDestroySubscription } from 'Shared/BaseClasses/ComponentWithDestroySubscription';
import _ from 'lodash';
import { takeUntil } from 'rxjs';
import { ChatService } from '../Chat.service';

@Component({
    selector: 'iq-chat-myaskai',
    templateUrl: './MyAskAIChat.component.html',
    styleUrls: ['./MyAskAIChat.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class MyAskAIChatComponent extends ComponentWithDestroySubscription implements AfterViewInit, OnDestroy {

    constructor(private _AuthenticationService: AuthenticationService, private _ChatService: ChatService,
        private _ElementRef: ElementRef, private _Renderer: Renderer2)
    {
        super();

        _ChatService.PositionAboveMobileToolbar.pipe(takeUntil(this.Destroyed)).subscribe(() => this.UpdateButtonPosition());
    }

    public static IsEnabled(user: AppUser): boolean {
        return !_.isEmpty(user.OneCallCenterSettings.Chat_MyAskAI_AccountID);
    }

    public ngAfterViewInit(): void {
        const existingWidget = document.querySelector(".askai-frame-widget");
        if (existingWidget)
            return;

        const script = this._Renderer.createElement("script");
        script.src = "https://myaskai.com/api/1.1/wf/embed-v2?i=HVnIz3JNPCsADEMajDHIph6411Kafc";
        script.type = "text/javascript";
        script.id = this._AuthenticationService.CurrentUser.OneCallCenterSettings.Chat_MyAskAI_AccountID;

        this._Renderer.appendChild(document.body, script);
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();

        //  The script that is loaded during initialization, creates 2 divs and loads a new script.  All are rooted at the based on the document.
        //  Need to remove them or re-initialization will not work and/or these elements will be duplicated.
        const widget = document.querySelector(".askai-frame-widget");
        if (widget)
            widget.remove();

        const button = document.querySelector("#askai-floating-button");
        if (button)
            button.remove();

        const scripts = document.scripts;
        for (let i = 0; i < scripts.length; i++) {
            const script = scripts[i];
            if (script.src.startsWith("https://myaskai.com"))
                script.remove();
        }
    }

    private UpdateButtonPosition(): void {
        const chatButton: HTMLDivElement = document.querySelector("#askai-floating-button");
        if (!chatButton)
            return;

        if (this._ChatService.PositionAboveMobileToolbar.value)
            chatButton.style.bottom = "58px";
        else
            chatButton.style.bottom = "15px";
    }
}
