
import {debounceTime} from 'rxjs/operators';
/*
Used to show a busy indicator for a given area.
Usage:
To use this you need to import the BusyService into your component and call the createNew method.  That will give you a "key" back that is tied to the observables needed for this component.
    You then use that "key" to pass into here in the html:
    <busy-indicator [busyKey]="key"></busy-indicator>
    Once that is setup, you then just have to call the methods on the BusyService when you want to hide or show the busy indicator.

So in your componnet you will decalare a key:
    private busyKey: string = this.busyService.createNew();
Add this to the html *Note the parent tag needs the style of 'position: relative;' so that this will fill the whole area and be centered:
    <busy-indicator [busyKey]="busyKey"></busy-indicator>

And to show the indicator you call:
    this.busyService.showByKey(this.busyKey);
And to hide the indcator you call:
    this.busyService.hideByKey(this.busyKey);
*/

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusyService } from 'Services/BusyService';

@Component({
    selector: 'busy-indicator',
    templateUrl: './Busy.component.html',
    styleUrls: ['./Busy.component.scss']
})
export class BusyComponent implements OnInit, OnDestroy {

    private subscription: Subscription = null;

    show: boolean;
    @Input() busyKey: string;
    @Input() diameter: number = 100;
    @Input() debounceTime: number = 500;    //  Wait for half a second before showing this to help with screen flashes

    constructor(private busyService: BusyService) {
    }

    ngOnInit() {
        if (!this.busyService.getByKey(this.busyKey))
            this.busyService.addByKey(this.busyKey);

        setTimeout(()=> this.createServiceSubscription());
    }

    ngOnDestroy() {
        //Unsubscribe and remove the item from the collection in the service
        if (this.subscription)
            this.subscription.unsubscribe();
        this.busyService.removeByKey(this.busyKey);
    }

    private createServiceSubscription() {
        let subj = this.busyService.getByKey(this.busyKey);
        if (!subj)
            return;     //  not sure how, but have seen a bunch of errors in server log where this is null/undefined so added this check

        this.subscription = subj.pipe(
            debounceTime(this.debounceTime))
            .subscribe(show => {
                if (show) {
                    this.start();
                } else {
                    this.stop();
                }
            });
    }

    start() {
        this.show = true;
    }

    stop() {
        this.show = false;
    }
}
